import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  affirmationsTitle: {
    id: 'Statistics.affirmationsTitle',
    defaultMessage: 'Documents confirmations',
    description: 'affirmations Statistics Title',
  },
  affirmationsProgressed: {
    id: 'Statistics.affirmationsProgressed',
    defaultMessage: 'Confirmed',
    description: 'affirmations Statistics Progressed Label',
  },
  affirmationsRemaining: {
    id: 'Statistics.affirmationsRemaining',
    defaultMessage: 'Not confirmed',
    description: 'affirmations Statistics Remaining Label',
  },
  misconductsProgressed: {
    id: 'Statistics.misconductsProgressed',
    defaultMessage: 'Assigned',
    description: 'misconducts Statistics Progressed Label',
  },
  misconductsRemaining: {
    id: 'Statistics.misconductsRemaining',
    defaultMessage: 'Not assigned',
    description: 'misconducts Statistics Remaining Label',
  },
});
