import React from 'react';

import * as CONST from './constants';

import Textarea from 'components/Textarea';
import DatePicker from 'components/DatePicker';
import Input from 'components/Input';
import SimpleSelect from 'components/SimpleSelect';
import RadioButton from 'components/RadioButton';
import { StyledLabel } from 'styles/commonStyles';

import { InputWrapper, RadioButtonGroup, RequiredMark } from './style';
import Tooltip from 'components/Tooltip';

export const renderField = ({ option, answer, onChange }) => {
  const { id, label, placeholder, required, type, choices, tip } = option;

  switch (type) {
    default:
      return null;
    case CONST.SHORT_TEXT:
      return (
        <Textarea
          key={id}
          label={label}
          placeholder={placeholder}
          id={id}
          required={required}
          value={answer || ''}
          onChange={(evt) => onChange({ id, answer: evt.target.value })}
          minLength={CONST.MIN_VALUE_LENGTH}
          tooltip={tip}
        />
      );
    case CONST.LONG_TEXT:
      return (
        <Textarea
          key={id}
          label={label}
          placeholder={placeholder}
          id={id}
          required={required}
          value={answer || ''}
          onChange={(evt) => onChange({ id, answer: evt.target.value })}
          isLarge
          minLength={CONST.MIN_VALUE_LENGTH}
          tooltip={tip}
        />
      );
    case CONST.DATE:
      return (
        <InputWrapper>
          <DatePicker
            key={id}
            label={label}
            placeholder={placeholder}
            required={required}
            initialValue={answer}
            onChange={(value) => onChange({ id, answer: value })}
            tooltip={tip}
            // maxWidth={'50%'}
          />
        </InputWrapper>
      );
    case CONST.STRING:
      return (
        <InputWrapper>
          <Input
            key={id}
            label={label}
            placeholder={placeholder}
            required={required}
            id={id}
            value={answer || ''}
            onChange={(evt) => onChange({ id, answer: evt.target.value })}
            minLength={CONST.MIN_VALUE_LENGTH}
            tooltip={tip}
            onBlur={undefined}
            // maxWidth={'50%'}
          />
        </InputWrapper>
      );
    case CONST.EMAIL:
      return (
        <InputWrapper>
          <Input
            key={id}
            label={label}
            placeholder={placeholder}
            required={required}
            id={id}
            value={answer || ''}
            onChange={(evt) => onChange({ id, answer: evt.target.value })}
            minLength={CONST.MIN_VALUE_LENGTH}
            tooltip={tip}
            type="email"
            // maxWidth={'50%'}
          />
        </InputWrapper>
      );
    case CONST.SELECT:
      return (
        <>
          {label && (
            <StyledLabel htmlFor={id}>
              {label}
              {required && <RequiredMark>*</RequiredMark>}
              {tip && <Tooltip id={id} content={tip} />}
            </StyledLabel>
          )}
          <InputWrapper>
            <SimpleSelect
              id={id}
              placeholder={placeholder}
              required={required}
              options={choices}
              value={answer}
              onChange={(evt) => onChange({ id, answer: evt.target.value })}
              data-testid={`${id}-select-input`}
            />
          </InputWrapper>
        </>
      );
    case CONST.RADIO:
      return (
        <>
          {label && (
            <StyledLabel htmlFor={id}>
              {label}
              {required && <RequiredMark>*</RequiredMark>}
              {tip && <Tooltip id={id} content={tip} />}
            </StyledLabel>
          )}

          <RadioButtonGroup>
            {choices.map(({ label, id: option_id, tip: radioTip }) => {
              return (
                <RadioButton
                  key={option_id}
                  name={id}
                  optionId={option_id}
                  label={label}
                  isChecked={answer === option_id}
                  onChange={() => onChange({ id, answer: option_id })}
                  tooltip={radioTip}
                />
              );
            })}
          </RadioButtonGroup>
        </>
      );
  }
};
