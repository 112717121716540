import api from 'api';
import { setToastMessage } from './toastMessages';
import { toastContext, toastType } from 'helpers/toastConstants';
import { clearState } from './documentEdit';

// Actions
export const STORE_DOCUMENT_DETAILS = 'document.STORE_DOCUMENT_DETAILS';
export const SET_DOCUMENT_DELETED = 'document.SET_DOCUMENT_DELETED';
export const SET_LOADING = 'document.SET_LOADING_STATE';
export const SET_PROCESSING = 'document.SET_PROCESSING';

// Reducer
const initialState = {
  isLoading: true,
  document: null,
  isProcessing: false,
  documentDeleted: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_PROCESSING:
      return {
        ...state,
        isProcessing: action.payload,
      };
    case STORE_DOCUMENT_DETAILS:
      return {
        ...state,
        document: action.payload,
        documentDeleted: false,
      };
    case SET_DOCUMENT_DELETED:
      return {
        ...state,
        documentDeleted: true,
      };
    default:
      return state;
  }
}

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload });

export const setProcessing = (payload) => ({ type: SET_PROCESSING, payload });

export const storeDocumentDetails = (payload) => ({
  type: STORE_DOCUMENT_DETAILS,
  payload,
});

export const setDocumentDeleted = () => ({ type: SET_DOCUMENT_DELETED });

// Side Effects
export const getDocumentDetails = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await api.documents.getDocument(id);
    dispatch(storeDocumentDetails(response));
  } catch (error) {
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDocumentOwnerDetails = (id) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await api.me.getDocumentOwnerPreview(id);
    dispatch(storeDocumentDetails(response.data));
  } catch (error) {
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const archiveDocument = (id) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await api.documents.archiveDocument(id);
    dispatch(storeDocumentDetails(response));
    dispatch(
      setToastMessage({
        content: { translationKey: 'documentArchiveSuccess' },
        context: toastContext.DOCUMENTS,
        type: toastType.SUCCESS,
      })
    );
  } catch (error) {
    dispatch(setLoading(false));
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const restoreDocument = (id) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await api.documents.restoreDocument(id);
    dispatch(storeDocumentDetails(response));
    dispatch(
      setToastMessage({
        content: { translationKey: 'documentRestoreSuccess' },
        context: toastContext.DOCUMENTS,
        type: toastType.SUCCESS,
      })
    );
  } catch (error) {
    dispatch(setLoading(false));
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteDocument = (id) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    await api.documents.deleteDocument(id);
    dispatch(setDocumentDeleted());
    dispatch(
      setToastMessage({
        content: { translationKey: 'documentDeleteSuccess' },
        context: toastContext.DOCUMENTS,
        type: toastType.SUCCESS,
      })
    );
  } catch (error) {
    dispatch(setLoading(false));
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const editDocumentDetails = (id, documentData) => async (dispatch) => {
  dispatch(setProcessing(true));

  try {
    await api.documents.editDocument(id, documentData);
    dispatch(clearState());
    dispatch(getDocumentDetails(id));
    dispatch(
      setToastMessage({
        content: {
          translationKey: 'documentEditedSuccess',
          param: documentData.name,
        },
        context: toastContext.DOCUMENT_EDIT,
        type: toastType.SUCCESS,
      })
    );
  } catch (error) {
    dispatch(
      setToastMessage({
        content: {
          translationKey: 'documentEditedFailed',
          param: documentData.name,
        },
        context: toastContext.DOCUMENT_EDIT,
        type: toastType.ALERT,
      })
    );
  } finally {
    dispatch(setProcessing(false));
  }
};
