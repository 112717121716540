import React from 'react';
import PropTypes from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const propTypes = { height: PropTypes.number };
const defaultPropTypes = { height: 40 };

const SingleSkeleton = ({ height, ...props }) => {
  return (
    <SkeletonTheme {...props}>
      <Skeleton height={height} />
    </SkeletonTheme>
  );
};

SingleSkeleton.defaultProps = defaultPropTypes;
SingleSkeleton.propTypes = propTypes;

export default SingleSkeleton;
