import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  dragAndDrop: {
    id: 'FileUploader.dragAndDrop',
    defaultMessage: 'Drag & Drop a file here',
    description: 'Drag & Drop Label',
  },
  selectFromDrive: {
    id: 'FileUploader.selectFromDrive',
    defaultMessage: 'Select from drive',
    description: 'Select from Drive Label',
  },
  acceptableFileTypes: {
    id: 'FileUploader.acceptableFileTypes',
    defaultMessage: 'Acceptable file types: {types}.',
    description: 'Acceptable file types Label',
  },
  invalidFileType: {
    id: 'FileUploader.invalidFileType',
    defaultMessage: 'File type not supported',
    description: 'File type not supported message',
  },
  maxSize: {
    id: 'FileUploader.maxSize',
    defaultMessage: 'Max size {size}',
    description: 'Max Size Label',
  },
  uploading: {
    id: 'FileUploader.uploading',
    defaultMessage: 'Uploading',
    description: 'Uploading Label',
  },
  maxFileNumberIs: {
    id: 'FileUploader.maxFileNumberIs',
    defaultMessage: 'Maximum file count is {number}',
    description: 'Maximum file number Error',
  },
  maxFileUploadSizeIs: {
    id: 'FileUploader.maxFileUploadSizeIs',
    defaultMessage: 'Maximum file upload size is {size}',
    description: 'Maximum file upload size Error',
  },
  confirmFileUpload: {
    id: 'FileUploader.confirmFileUpload',
    defaultMessage: '[TD] Confirm file upload',
    description: 'Confirm file upload modal title',
  },
  confirmFileUploadText: {
    id: 'FileUploader.confirmFileUploadText',
    defaultMessage: '[TD] Are you sure you want to upload the files listed below?',
    description: 'Confirm file upload modal text',
  },
  cancelUpload: {
    id: 'FileUploader.cancelUpload',
    defaultMessage: '[TD] Cancel',
    description: 'Cancel button in confirmation modal',
  },
  confirmUpload: {
    id: 'FileUploader.confirmUpload',
    defaultMessage: '[TD] Confirm',
    description: 'Confirm button in confirmation modal',
  },
});
