import React from 'react';
import PropTypes from 'prop-types';

import {
  CheckboxLabel,
  CheckboxLabelWrapper,
  FakeCheckbox,
  Label,
  RealCheckbox,
  TickIcon,
} from './style';

const defaultPropTypes = {
  isChecked: false,
  value: '',
  disabled: false,
};

const propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isDark: PropTypes.bool,
  testingId: PropTypes.string,
  provideEmailStyle: PropTypes.bool,
};

const Checkbox = ({
  isChecked,
  label,
  value,
  onChange,
  disabled,
  isDark,
  testingId,
  provideEmailStyle,
  ...props
}) => {
  return (
    <CheckboxLabel provideEmailStyle={provideEmailStyle} {...props}>
      <RealCheckbox
        onChange={onChange}
        checked={isChecked}
        value={value}
        disabled={disabled}
        data-testid={testingId}
      />
      <CheckboxLabelWrapper provideEmailStyle={provideEmailStyle}>
        {label && provideEmailStyle && <Label provideEmailStyle>{label}</Label>}

        <FakeCheckbox isDark={isDark} provideEmailStyle={provideEmailStyle}>
          <TickIcon provideEmailStyle={provideEmailStyle} />
        </FakeCheckbox>

        {label && !provideEmailStyle && <Label>{label}</Label>}
      </CheckboxLabelWrapper>
    </CheckboxLabel>
  );
};

Checkbox.defaultProps = defaultPropTypes;
Checkbox.propTypes = propTypes;

export default Checkbox;
