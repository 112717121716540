export const GENERAL_KEY = 'general_information';
export const DETAILED_KEY = 'detailed_information';
export const ACTIONS_KEY = 'proposed_actions';

// Actions
export const STORE_FORM_STRUCTURE = 'misconductForm.STORE_FORM_STRUCTURE';
export const STORE_FORM_STEP = 'misconductForm.STORE_FORM_STEP';
export const STORE_FORM_SUMMARY = 'misconductForm.STORE_FORM_SUMMARY';
export const UPDATE_FORM_DATA = 'misconductForm.UPDATE_FORM_DATA';
export const SET_LOADING = 'misconductForm.SET_LOADING';
export const SET_ERROR = 'misconductForm.SET_ERROR';
export const CLEAR_FORM_STRUCTURE = 'misconductForm.CLEAR_FORM_STRUCTURE';
export const SET_MAX_STEPS = 'misconductForm.SET_MAX_STEPS';
export const SET_CURRENT_STEP = 'misconductForm.SET_CURRENT_STEP';
export const SET_SUMMARY_MODAL = 'misconductForm.SET_SUMMARY_MODAL';
export const SET_FORM_ID = 'misconductForm.SET_FORM_ID';
export const SET_HAS_NEXT_STEP = 'misconductForm.SET_HAS_NEXT_STEP';
export const SET_HAS_PREVIOUS_STEP = 'misconductForm.SET_HAS_PREVIOUS_STEP';

// Reducer
const initialState = {
  isLoading: false,
  formStructure: null,
  // stepsCount: null,
  currentStep: 0,
  summaryModal: null,
  formStep: null,
  formSummary: null,
  step: null,
  formId: null,
  hasNextStep: null,
  hasPreviousStep: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_FORM_STRUCTURE:
      return {
        ...state,
        formStructure: action.payload,
      };
    case STORE_FORM_STEP:
      return {
        ...state,
        formStep: action.payload,
      };
    case STORE_FORM_SUMMARY:
      return {
        ...state,
        formSummary: action.payload,
      };
    case UPDATE_FORM_DATA:
      return {
        ...state,
        formStep: {
          ...state.formStep,
          sections: state.formStep.sections.map((section) => ({
            ...section,
            inputs: section.inputs.map((input) =>
              input.id === action.payload.id
                ? { ...input, answer: action.payload.answer }
                : { ...input }
            ),
          })),
        },
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_MAX_STEPS:
      return {
        ...state,
        stepsCount: action.payload,
      };
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case SET_SUMMARY_MODAL:
      return {
        ...state,
        summaryModal: action.payload,
      };
    case CLEAR_FORM_STRUCTURE:
      return initialState;
    case SET_FORM_ID:
      return {
        ...state,
        formId: action.payload,
      };
    case SET_HAS_NEXT_STEP:
      return {
        ...state,
        hasNextStep: action.payload,
      };
    case SET_HAS_PREVIOUS_STEP:
      return {
        ...state,
        hasPreviousStep: action.payload,
      };

    default:
      return state;
  }
}

// Action Creators
export function storeFormStructure(data) {
  return { type: STORE_FORM_STRUCTURE, payload: data };
}

export function storeFormStep(data) {
  return { type: STORE_FORM_STEP, payload: data };
}

export function storeFormSummary(data) {
  return { type: STORE_FORM_SUMMARY, payload: data };
}

export function updateFormData(id, answer) {
  return { type: UPDATE_FORM_DATA, payload: { id, answer } };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

export function setError(error) {
  return { type: SET_ERROR, payload: error };
}

export function setStepsCount(steps) {
  return { type: SET_MAX_STEPS, payload: steps };
}

export function setCurrentStep(step) {
  return { type: SET_CURRENT_STEP, payload: step };
}

export function setSummaryModal(step) {
  return { type: SET_SUMMARY_MODAL, payload: step };
}

export function clearFormStructure() {
  return { type: CLEAR_FORM_STRUCTURE };
}

export function setFormId(id) {
  return { type: SET_FORM_ID, payload: id };
}

export function setHasNextStep(hasNextStep) {
  return { type: SET_HAS_NEXT_STEP, payload: hasNextStep };
}

export function setHasPreviousStep(hasPreviousStep) {
  return { type: SET_HAS_PREVIOUS_STEP, payload: hasPreviousStep };
}
