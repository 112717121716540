import React from 'react';

import Tile from 'components/Tile';
import { TilesSectionWrapper } from './styles';
import ReportIcon from 'components/Svg/ReportIcon';
import DocumentSearchIcon from 'components/Svg/DocumentSearchIcon';
import { useDispatch } from 'react-redux';
import { setFilters } from 'stores/reports';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTING } from 'routing';
import { useIntl } from 'react-intl';
import { messages } from '../messages';
import PropTypes from 'prop-types';

const propTypes = {
  unassignedNumber: PropTypes.number.isRequired,
  inProgressNumber: PropTypes.number.isRequired,
};

export const TilesSection = ({ unassignedNumber, inProgressNumber }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  return (
    <TilesSectionWrapper>
      <Tile
        icon={<ReportIcon />}
        onClick={() => {
          dispatch(setFilters({ assigned_to_id: [null] }));
          history.push(ROUTING.REPORTS);
        }}
        title={intl.formatMessage(messages.reportsUnassigned)}
        count={unassignedNumber}
      />
      <Tile
        icon={<DocumentSearchIcon />}
        onClick={() => {
          dispatch(setFilters({ status: ['in_progress'] }));
          history.push(ROUTING.REPORTS);
        }}
        title={intl.formatMessage(messages.reportsInProgress)}
        count={inProgressNumber}
      />
    </TilesSectionWrapper>
  );
};

TilesSection.propTypes = propTypes;

export default TilesSection;
