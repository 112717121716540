import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { MISCONDUCT_RIGHTS } from 'containers/private/ReportDetails/parts/Access/constants';
import { appLanguagesSelectData } from './commonConstants';

export const devModeOnly =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const clearStorages = () => {
  localStorage.clear();
  sessionStorage.clear();

  devModeOnly && console.warn('Storages cleared!'); // eslint-disable-line no-console
};

export const clearCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });

  devModeOnly && console.warn('Browser cookies cleared!'); // eslint-disable-line no-console
};

export const clearStorageAndCookies = () => {
  clearStorages();
  clearCookies();
};

export const areKeysPresentInObject = (object, keys) => {
  return keys.every((item) => Object.prototype.hasOwnProperty.call(object, item));
};

export const getRequiredFields = (object) => {
  const requiredFieldsObject = object.filter((o) => o.required);
  let requiredFields = [];
  requiredFieldsObject.map((obj) => {
    return requiredFields.push(obj.id);
  });

  return requiredFields;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getFileFormat = (fileName) => {
  return fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
};

export const getDateRangeStr = (array, format = 'Do MMMM YYYY') => {
  const filteredArray = array.filter((el) => {
    return el !== null && typeof el !== 'undefined' && el.length > 0;
  });

  return filteredArray.map((date) => moment(date).format(format)).join(' - ');
};

export const getDateStr = (date, format = 'DD-MM-YYYY HH:mm') =>
  date ? moment(date).format(format) : '—';

export const getDateFromNow = (date) => moment(date).fromNow();

export const isDeeplyEqual = (obj1, obj2) => isEqual(obj1, obj2);

export const getTranslatedOptions = (arrayOfOptions, translator, nameKey = 'name') => {
  return arrayOfOptions.map(({ name, value }) => {
    return {
      [nameKey]: translator(name),
      value,
    };
  });
};

export const openFile = (f) => {
  const file = new Blob([f], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

export const allowAccess = ({ userPermissions, checkAgainst }) => {
  const fullAccess = [MISCONDUCT_RIGHTS.FULL_ACCESS];
  const allowed = checkAgainst.concat(fullAccess);

  return userPermissions.some((val) => allowed.includes(val));
};

// @todo - a temporary util, to be removed down the road
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const createQueryParams = (object) => {
  return {
    per_page: object?.perPage,
    page: object?.page,
    query: object?.query.length > 0 ? object.query : null,
    statuses: object?.filters,
  };
};

const browserLang = window.navigator.language.split('-')[0];
export const getBrowserLanguage = () => {
  if (!appLanguagesSelectData.map((lang) => lang.value).includes(browserLang)) {
    return 'en';
  } else {
    return browserLang;
  }
};

export const getUserLanguage = (language) => {
  if (
    !appLanguagesSelectData.map((lang) => lang.value).includes(language) ||
    language === 'se'
  ) {
    return getBrowserLanguage();
  } else {
    return language;
  }
};

export const getSubdomain = () => {
  return window && window.location.hostname.split('.')[0];
};

export const arelanguagesFromMisconducts = (customConfig) => {
  return (
    customConfig &&
    customConfig['languagesFromMisconducts'] &&
    customConfig['languagesFromMisconducts'].includes(getSubdomain())
  );
};
