import { defineMessages } from 'react-intl.macro';

export const reportMessages = defineMessages({
  saveCurrent: {
    id: 'commonReportMessages.saveCurrent',
    defaultMessage: 'Save current',
    description: 'Save Access rights button label',
  },
  access: {
    id: 'commonReportMessages.access',
    defaultMessage: 'Access',
    description: 'Access Information header',
  },
  summary: {
    id: 'commonReportMessages.summary',
    defaultMessage: 'Summary',
    description: 'Summary Information header',
  },
  reminders: {
    id: 'commonReportMessages.reminders',
    defaultMessage: 'Reminders',
    description: 'Reminders Tab Header',
  },
  recipientsPlaceholder: {
    id: 'commonReportMessages.recipientsPlaceholder',
    defaultMessage: 'Search by a name',
    description: 'Recipients select placeholder',
  },
  recipientsSelectLabel: {
    id: 'commonReportMessages.recipientsSelectLabel',
    defaultMessage: 'Add recipients',
    description: 'Recipients Select Label',
  },
  recipientsSelectHint: {
    id: 'commonReportMessages.recipientsSelectHint',
    defaultMessage: 'Start typing to add a person',
    description: 'Recipients hint',
  },
  addRecipient: {
    id: 'commonReportMessages.addRecipient',
    defaultMessage: 'Add',
    description: 'Button label',
  },
  uploadedFiles: {
    id: 'commonReportMessages.uploadedFiles',
    defaultMessage: 'Uploaded files',
    description: 'Uploaded files Header',
  },
  sent: {
    id: 'commonReportMessages.sent',
    defaultMessage: 'Sent',
    description: 'sent Status',
  },
  open: {
    id: 'commonReportMessages.open',
    defaultMessage: 'Open',
    description: 'open Status',
  },
  in_progress: {
    id: 'commonReportMessages.in_progress',
    defaultMessage: 'In progress',
    description: 'in_progress Status',
  },
  closed: {
    id: 'commonReportMessages.closed',
    defaultMessage: 'Closed',
    description: 'closed Status',
  },
  closed_approved: {
    id: 'commonReportMessages.closed_approved',
    defaultMessage: 'Closed (substantiated)',
    description: 'closed Status extended',
  },
  closed_not_approved: {
    id: 'commonReportMessages.closed_not_approved',
    defaultMessage: 'Closed (unsubstantiated)',
    description: 'closed Status extended',
  },
  closed_partially_approved: {
    id: 'commonReportMessages.closed_partially_approved',
    defaultMessage: 'Closed (partially substantiated)',
    description: 'closed Status extended',
  },
  closed_irrelevant: {
    id: 'commonReportMessages.closed_irrelevant',
    defaultMessage: 'Closed (irrelevant)',
    description: 'closed Status extended',
  },
  reportName: {
    id: 'commonReportMessages.reportName',
    defaultMessage: 'Report {reportName}',
    description: 'Report Name header',
  },
  report: {
    id: 'commonReportMessages.report',
    defaultMessage: 'Report',
    description: 'Report Status header',
  },
  reportStatus: {
    id: 'commonReportMessages.reportStatus',
    defaultMessage: 'Overview',
    description: 'Report Status subheader',
  },
  communication: {
    id: 'commonReportMessages.communication',
    defaultMessage: 'Dialogue with the whistleblower',
    description: 'Report Status Sidebar Header',
  },
  officersChatHeader: {
    id: 'commonReportMessages.officersChatHeader',
    defaultMessage: 'Officers Chat',
    description: 'Officers chat tab Header (inside)',
  },
  unassigned: {
    id: 'commonReportMessages.unassigned',
    defaultMessage: 'Unassigned',
    description: 'Unassigned Priority Label',
  },
  low: {
    id: 'commonReportMessages.low',
    defaultMessage: 'Low',
    description: 'Low Priority Label',
  },
  medium: {
    id: 'commonReportMessages.medium',
    defaultMessage: 'Medium',
    description: 'Medium Priority Label',
  },
  high: {
    id: 'commonReportMessages.high',
    defaultMessage: 'High',
    description: 'High Priority Label',
  },
  allowUploads: {
    id: 'commonReportMessages.allowUploads',
    defaultMessage: 'Enable uploads',
    description: 'Button allow/disallow label',
  },
  disallowUploads: {
    id: 'commonReportMessages.disallowUploads',
    defaultMessage: 'Disable uploads',
    description: 'Button allow/disallow label',
  },
  anonymizeFragment: {
    id: 'commonReportMessages.anonymizeFragment',
    defaultMessage: 'Anonymise selected fragment',
    description: 'Selected fragment button',
  },
  whistleblowerInfo: {
    id: 'commonReportMessages.whistleblowerInfo',
    defaultMessage: 'Whistleblower contact details',
    description: 'Whistleblower details header',
  },
  whistleblowerFiles: {
    id: 'commonReportMessages.whistleblowerFiles',
    defaultMessage: 'Attached files',
    description: 'Whistleblower files header',
  },
  writeAcessSaveInstructions: {
    id: 'commonReportMessages.writeAcessSaveInstructions',
    defaultMessage:
      'To grant or modify access, after your edits use the "Save" button in the panel on the right side of the screen.',
    description: 'Access Tab instructions',
  },
  anonymized: {
    id: 'commonReportMessages.anonymized',
    defaultMessage: 'Anonymized',
    description: 'Anonymized label',
  },
  summaryButton: {
    id: 'commonReportMessages.summaryButton',
    defaultMessage: 'Summary',
    description: 'Mainly used for button labels',
  },
  summaryHeader: {
    id: 'commonReportMessages.summaryHeader',
    defaultMessage: 'Summary',
    description: 'Report summary header',
  },
});
