import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { rem, rgba } from 'polished';

import CompanyLogoSmall from 'components/CompanyLogo/CompanyLogoSmall';
import { StyledButton } from 'components/Button/style';

import {
  breakpoints,
  colorName,
  colors,
  font,
  globalSize,
  layers,
  transitions,
  variables,
} from 'styles';

export const TOP_MENU_ITEM_WIDTH = 48;
export const TOP_MENU_BUTTON_HEIGHT = 32;
export const INDICATOR_SIZE = 8;
export const ICON_SIZE = 28;
export const AVATAR_SIZE = 32;
export const OPEN_MENU_SIZE = 220;
export const SIDE_NOTIFICATION_SIZE = 19;
export const OFFICER_BUTTON_WIDTH = 190;
export const OFFICER_BUTTON_HEIGHT = 44;
export const MENU_ICON_SIZE = 24;

// Wrapper and Menu Holder
export const FullScreenWrapper = styled.div`
  background: transparent;

  ${({ minHeight }) => `
    min-height: ${minHeight ? rem(minHeight) : '100vh'};
  `};

  @media ${breakpoints.mediumScreen} {
    display: flex;
    flex-direction: column;
    background: ${colors.darkestGray};
  }
`;

export const SiteWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: ${rem(globalSize.mobileMenuSize)} 0 0;

  @media ${breakpoints.mediumScreen} {
    padding: 0 0 0 ${rem(globalSize.desktopMenuSize)};
  }
`;

export const MenusWrapper = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${layers.top};
  display: flex;
  align-items: stretch;
  min-height: ${rem(globalSize.mobileMenuSize)};
  max-height: ${rem(globalSize.mobileMenuSize)};
  background: ${colors.darkestGray};

  @media ${breakpoints.tablet} {
    z-index: ${layers.low};
  }

  @media ${breakpoints.mediumScreen} {
    position: relative;
    align-items: center;
    min-height: ${rem(globalSize.desktopMenuSize)};
    max-height: ${rem(globalSize.desktopMenuSize)};
    background: ${colorName.white};
    box-shadow: 0 ${rem(16)} ${rem(32)} ${rgba(colors.darkestGray, 0.04)};
  }
`;

// Top Menu
export const UserMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: ${colors.mediumGray};

  ${StyledButton} {
    min-height: ${rem(TOP_MENU_BUTTON_HEIGHT)};
    margin: 0 ${rem(TOP_MENU_BUTTON_HEIGHT / 4)} 0 0;
    padding: 0 ${rem(TOP_MENU_BUTTON_HEIGHT / 2)};
  }

  @media ${breakpoints.mediumScreen} {
    height: ${rem(globalSize.desktopMenuSize)};
  }
`;

export const DropdownIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(24)};
  height: ${rem(24)};
  margin-right: ${rem(12)};

  & > svg,
  & > img {
    max-height: 100%;
  }
`;

export const StyledDropdownItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: ${rem(6)} ${rem(globalSize.defaultPadding)};
  color: ${({ disabled }) => (disabled ? colors.mediumGray : colorName.secondary)};
  white-space: nowrap;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  user-select: none;

  &:hover {
    background-color: ${({ disabled }) => !disabled && colors.mischka};
  }

  ${({ isFluid }) =>
    isFluid &&
    `
    flex: 0 0 100%;

      &:after {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        height: 1px;
        background: ${colors.athensGray};
        content: '';
      }
  `};

  @media ${breakpoints.smallScreen} {
    flex: 0 0 ${({ isFluid }) => (isFluid ? '100%' : '50%')};
  }
`;

export const UserDropdownList = styled.ul`
  position: fixed;
  top: ${rem(globalSize.mobileMenuSize)};
  left: 0;
  z-index: 2;
  width: 100vh;
  height: 92vh;
  margin: 0;
  padding: 0;
  overflow: auto;
  background: ${colorName.white};
  border-radius: 0 0 ${rem(variables.borderRadius)} ${rem(variables.borderRadius)};
  box-shadow: 0 ${rem(16)} ${rem(32)} ${rgba(colors.darkestGray, 0.08)};
  transform: translateY(${({ isVisible }) => (isVisible ? 0 : rem(12))});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition-timing-function: ${transitions.spring};
  transition-duration: 0.4s;
  transition-property: opacity, transform;
  pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};

  @media ${breakpoints.smallScreen} {
    position: absolute;
    top: ${rem(globalSize.desktopMenuSize + 12)};
    right: ${rem(12)};
    left: auto;
    display: flex;
    flex-wrap: wrap;
    width: ${rem(320)};
    height: unset;
    background: ${colorName.white};
    border-radius: ${rem(variables.borderRadius)};
    box-shadow: 0 ${rem(16)} ${rem(32)} ${rgba(colors.darkestGray, 0.08)};

    &:before {
      position: absolute;
      right: ${rem(12)};
      display: block;
      width: ${rem(16)};
      height: ${rem(16)};
      background: ${colorName.white};
      border-radius: ${rem(variables.borderRadius)};
      transform: translateY(-6px) rotate(45deg);
      content: '';
      pointer-events: none;
    }
  }
`;

export const DropdownItemLabel = styled.span`
  font-size: ${rem(font.size.small)};
`;

export const DropdownOutsideClick = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
`;

export const UserMenuItem = styled.button`
  position: relative;
  display: flex;
  flex: 0 0 ${rem(TOP_MENU_ITEM_WIDTH)};
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
  color: ${({ color }) => color && color};
  font-family: inherit;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: background ${transitions.uiTiming} ${transitions.spring};
  appearance: none;

  &:last-child {
    flex: 0 0 ${rem(TOP_MENU_ITEM_WIDTH + 12)};
  }

  ${({ isNew }) =>
    isNew &&
    `
    &:before {
      position: absolute;
      top: 53%;
      left: 60%;
      content: '';
      display: block;
      width: ${rem(INDICATOR_SIZE)};
      height: ${rem(INDICATOR_SIZE)};
      background: ${colors.darkRed};
      border-radius: 100%;
    }
  `};

  svg {
    display: block;
    width: ${rem(ICON_SIZE)};
    height: ${rem(ICON_SIZE)};

    path {
      transition-timing-function: ${transitions.spring};
      transition-duration: ${transitions.uiTiming};
      transition-property: fill, stroke;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    background: ${colorName.white};
    outline: 0;
    box-shadow: none;

    & > svg {
      path {
        fill: ${colorName.secondary};
        stroke: ${colorName.secondary};

        &.stroke {
          stroke: ${colorName.white};
        }
      }
    }
  }
`;

export const MainMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  ${({ minHeight }) => `
    min-height: ${minHeight ? rem(minHeight) : '100vh'};
  `};

  @media ${breakpoints.mediumScreen} {
    position: fixed;
    align-items: flex-start;
    overflow: hidden;
    background: ${colors.darkestGray};
    transition: width 0.6s ${transitions.spring};

    ${({ isOpen }) => `
      width: ${isOpen ? rem(OPEN_MENU_SIZE) : rem(globalSize.desktopMenuSize)};
    `};
  }
`;

// Side Menu
export const BurgerWrapper = styled.div`
  position: relative;
  width: ${rem(globalSize.mobileMenuSize)};
  height: ${rem(globalSize.mobileMenuSize)};
  cursor: pointer;
  pointer-events: all;

  @media ${breakpoints.mediumScreen} {
    width: ${rem(globalSize.desktopMenuSize)};
    height: ${rem(globalSize.desktopMenuSize)};
  }
`;

export const BurgerMenu = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: ${rem(24)};
  height: ${rem(2)};
  background: ${colorName.white};
  border-radius: ${rem(variables.borderRadius)};
  transform: translate(-50%, -50%);
  transition: background ${transitions.uiTiming} ${transitions.spring};

  &:before,
  &:after {
    position: absolute;
    display: block;
    width: inherit;
    height: inherit;
    background: ${colorName.white};
    border-radius: inherit;
    transition: transform ${transitions.uiTiming} ${transitions.spring};
    content: '';
  }

  &:before {
    transform: translateY(${rem(-8)});
  }

  &:after {
    transform: translateY(${rem(8)});
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    background: transparent;
    
    &:before {
      transform: translateY(0) rotate(45deg);
    }
  
    &:after {
      transform: translateY(0) rotate(-45deg);
    }
  `};
`;

export const MainMenuWrapper = styled.ul`
  position: absolute;
  top: ${rem(globalSize.mobileMenuSize)};
  left: 0;
  z-index: -1;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  list-style: none;
  background: ${colors.darkestGray};
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: transform 0.6s ${transitions.spring};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};

  @media ${breakpoints.mediumScreen} {
    top: ${rem(globalSize.desktopMenuSize)};
    left: 0;
    z-index: 0;
    width: 100%;
    transform: translateX(0);
    pointer-events: all;
  }
`;

export const MenuItem = styled.li`
  position: relative;
  display: block;
  margin: 0;
  padding: 0;

  ${({ isOfficerButton }) =>
    isOfficerButton &&
    `
    margin: ${rem(20)} 0 0;
    
    &:before {
      position: absolute;
      top: ${rem(-20)};
      left: ${rem(16)};
      width: calc(100% - ${rem(32)});
      height: ${rem(1)};
      background: ${colors.darkGray};
      content: '';
    }
  `}
`;

const menuItemCommonStyles = css`
  display: flex;
  align-items: center;
  color: ${colors.gray};
  font-size: ${rem(font.size.small)};
  text-decoration: none;
  cursor: pointer;
  transition-timing-function: ${transitions.spring};
  transition-duration: ${transitions.uiTiming};
  transition-property: background, color;
  appearance: none;
  user-select: none;
`;

export const MenuLink = styled(NavLink)`
  ${menuItemCommonStyles};
  height: ${rem(globalSize.desktopMenuSize)};
  padding: 0 ${rem(24)};

  @media ${breakpoints.mediumScreen} {
    padding: 0;
  }

  &:hover,
  &.active {
    color: ${colorName.black};
    background: ${colorName.primary};
  }
`;

export const OfficerMenuButton = styled.button`
  ${menuItemCommonStyles};
  width: calc(100% - ${rem(24)});
  max-width: ${rem(OFFICER_BUTTON_WIDTH)};
  height: ${rem(OFFICER_BUTTON_HEIGHT)};
  margin: 0 ${rem(12)} 0;
  padding: ${rem(6)} ${rem(14)};
  overflow: hidden;
  color: ${colorName.black};
  background: ${colorName.primary};
  border: 0;
  border-radius: ${rem(variables.borderRadius)};

  & > svg {
    width: ${rem(MENU_ICON_SIZE - 4)};
    min-width: ${rem(MENU_ICON_SIZE - 4)};
    height: ${rem(MENU_ICON_SIZE - 4)};
    margin: 0 ${rem(MENU_ICON_SIZE - 8)} 0 0;
  }

  &:hover,
  &.active {
    color: ${colorName.black};
    background: ${colorName.primary};
  }

  @media ${breakpoints.mediumScreen} {
    margin: ${rem(20)} ${rem(12)} 0;
    padding: ${rem(4)} ${rem(14)};

    & > svg {
      margin: 0 ${rem(MENU_ICON_SIZE - 16)} 0 0;
    }
  }
`;

export const OfficerMenuLabel = styled.span`
  font-size: ${rem(font.size.small)};
  transition-timing-function: ${transitions.spring};
  transition-duration: ${transitions.uiTiming};
  transition-property: opacity, width;

  @media ${breakpoints.mediumScreen} {
    width: ${({ isExpanded }) => (isExpanded ? 'auto' : 0)};
    margin: 0 0 0 ${rem(16)};
    white-space: nowrap;
    opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
  }
`;

export const IconWrapper = styled.span`
  position: relative;
  flex: 0 0 ${rem(MENU_ICON_SIZE)};
  height: ${rem(MENU_ICON_SIZE)};
  margin: 0 ${rem(MENU_ICON_SIZE / 2)} 0 0;

  & > svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  @media ${breakpoints.mediumScreen} {
    flex: 0 0 ${rem(globalSize.desktopMenuSize)};
    height: ${rem(28)};
    margin: 0;
  }
`;

export const NotificationBubble = styled.b`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(SIDE_NOTIFICATION_SIZE)};
  height: ${rem(SIDE_NOTIFICATION_SIZE)};
  color: ${colorName.white};
  font-size: ${rem(font.size.tiny)};
  line-height: ${rem(SIDE_NOTIFICATION_SIZE)};
  background: ${colorName.secondary};
  border-radius: 100%;
`;

export const MenuLabel = styled.span`
  flex: 1 1 auto;
`;

export const Logotype = styled(CompanyLogoSmall)`
  position: absolute;
  left: 50%;
  display: block;
  height: ${rem(40)};
  transform: translateX(-50%);
`;

export const OfficerLoginBox = styled.div`
  width: 100%;
  max-width: ${rem(340)};
  margin: 0 auto;
  text-align: left;
`;

// @todo: Move to common classes later when there's more helpers like this
export const VerticalToHorizontalButtons = styled.div`
  & > * {
    display: flex;
    width: 100%;

    &:not(:last-child) {
      margin: 0 0 ${rem(16)};
    }
  }

  @media ${breakpoints.tablet} {
    display: flex;
    justify-content: center;

    & > * {
      flex: 0 1 ${rem(184)};
      width: auto;

      &:not(:last-child) {
        margin: 0 ${rem(24)} 0 0;
      }
    }
  }
`;

export const OfficerDetails = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 ${rem(32)};
`;

export const DetailsMeta = styled.div`
  margin: 0 0 0 ${rem(16)};
`;

export const SessionTimeoutTimerWrapper = styled.div`
  display: flex;
  gap: ${rem(4)};
  margin-right: ${rem(12)};
  color: ${colors.white};

  @media ${breakpoints.mediumScreen} {
    color: ${colors.darkestGray};
  }
`;
