import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import EmailIcon from 'components/Svg/EmailIcon';
import LockIcon from 'components/Svg/LockIcon';
import TicketIcon from 'components/Svg/TicketIcon';

import {
  StyledLoginInput,
  LoginInputWrapper,
  IconWrapper,
  LoginLabel,
  InputMainWrapper,
} from './style';

const PASSWORD = 'password';
const EMAIL = 'email';
const TEXT = 'text';

const typeToIcon = {
  [PASSWORD]: <LockIcon />,
  [EMAIL]: <EmailIcon />,
  [TEXT]: <TicketIcon />,
};

const propTypes = {
  type: PropTypes.oneOf([PASSWORD, EMAIL, TEXT]).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  autocomplete: PropTypes.string,
  bottomMargin: PropTypes.number,
};

const LoginInput = ({
  type,
  onChange,
  label,
  placeholder,
  invalid,
  autocomplete,
  bottomMargin,
  ...props
}) => {
  const inputRef = label.replace(' ', '-');
  const [touched, setTouched] = useState(false);

  const handleBlur = useCallback(() => setTouched(true), [setTouched]);

  return (
    <InputMainWrapper bottomMargin={bottomMargin}>
      <LoginLabel htmlFor={inputRef}>{label}</LoginLabel>

      <LoginInputWrapper>
        <IconWrapper>{typeToIcon[type]}</IconWrapper>

        <StyledLoginInput
          aria-label={label}
          type={type}
          onChange={onChange}
          onBlur={handleBlur}
          id={inputRef}
          required
          placeholder={placeholder}
          invalid={invalid && touched}
          autocomplete={autocomplete || 'off'}
          {...props}
        />
      </LoginInputWrapper>
    </InputMainWrapper>
  );
};

LoginInput.propTypes = propTypes;

export default LoginInput;
