import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem, MenuLink, IconWrapper, MenuLabel, NotificationBubble } from '../style';

const propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  newNotifications: PropTypes.number,
};

const MainMenuItem = ({ to, label, icon, newNotifications }) => {
  return (
    <MenuItem>
      <MenuLink to={to}>
        <IconWrapper>
          {newNotifications && (
            <NotificationBubble>{newNotifications}</NotificationBubble>
          )}
          {icon}
        </IconWrapper>
        <MenuLabel>{label}</MenuLabel>
      </MenuLink>
    </MenuItem>
  );
};

MainMenuItem.propTypes = propTypes;

export default MainMenuItem;
