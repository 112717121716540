import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
} from 'helpers/sharedConstants';
import { setFileLoading } from './me';
import { setChatTouched, getMisconductRoomData, getInternalChatFiles } from './chatrooms';
import { setToastMessage } from './toastMessages';
import { toastContext, toastType } from 'helpers/toastConstants';
import { INTERVAL_ONE_DAY } from 'helpers/commonConstants';
import { MISCONDUCT_RIGHTS } from 'containers/private/ReportDetails/parts/Access/constants';
import {
  deserializeReport,
  deserializeReportReminders,
  serializeReportReminders,
  deserializeUserGroups,
  deserializeFiles,
  deserializeLogs,
} from 'deserializers';
import uniqBy from 'lodash/uniqBy';

// Actions
export const SET_LOADING_REPORT_DETAILS = 'reportDetails.SET_LOADING_REPORT_DETAILS';
export const SET_LOADING_TAB = 'reportDetails.SET_LOADING_TAB';
export const SET_LOADING_REPORT_FILES = 'reportDetails.SET_LOADING_REPORT_FILES';
export const SET_SUBMITTING_REPORT = 'reportDetails.SET_SUBMITTING_REPORT';
export const SET_LOGS_LOADING = 'reportDetails.SET_LOGS_LOADING';
export const RESTORE_INITIAL_STATE = 'reportDetails.RESTORE_INITIAL_STATE';
export const SET_REPORT_CHANGED = 'reportDetails.SET_REPORT_CHANGED';
export const STORE_REPORT_DETAILS = 'reportDetails.STORE_REPORT_DETAILS';
export const STORE_REPORT_FILES = 'reportDetails.STORE_REPORT_FILES';
export const STORE_REPORT_NOTE = 'reportDetails.STORE_REPORT_NOTE';
export const STORE_REPORT_NOTE_TEXT = 'reportDetails.STORE_REPORT_NOTE_TEXT';
export const UPDATE_REPORT_FILES = 'reportDetails.UPDATE_REPORT_FILES';
export const UPDATE_REPORT_UPLOAD_STATUS = 'reportDetails.UPDATE_REPORT_UPLOAD_STATUS';
export const STORE_REPORT_STATE = 'reportDetails.STORE_REPORT_STATE';
export const STORE_SUMMARY_MESSAGE = 'reportDetails.STORE_SUMMARY_MESSAGE';
export const STORE_LOGS = 'reportDetails.STORE_LOGS';
export const STORE_LOGS_META = 'reportDetails.STORE_LOGS_META';
export const STORE_REPORT_NAME = 'reportDetails.STORE_REPORT_NAME';
export const UPDATE_REMINDER_STATUS = 'reportDetails.UPDATE_REMINDER_STATUS';
export const UPDATE_REMINDER_MESSAGE_STATUS =
  'reportDetails.UPDATE_REMINDER_MESSAGE_STATUS';
export const UPDATE_REMINDER_INTERVAL = 'reportDetails.UPDATE_REMINDER_INTERVAL';
export const UPDATE_REMINDER_MESSAGE = 'reportDetails.UPDATE_REMINDER_MESSAGE';
export const STORE_DEFAULT_REPORT_REMINDERS =
  'reportDetails.STORE_DEFAULT_REPORT_REMINDERS';
export const TOGGLE_ANONYMIZATION = 'reportDetails.TOGGLE_ANONYMIZATION';
export const ANONYMIZE_REPORT_INFO = 'reportDetails.ANONYMIZE_REPORT_INFO';
export const SET_REPORT_ANONYMIZED = 'reportDetails.SET_REPORT_ANONYMIZED';
export const ARCHIVE_REPORT = 'reportDetails.ARCHIVE_REPORT';
export const STORE_ARCHIVE_DATE = 'reportDetails.STORE_ARCHIVE_DATE';
export const SET_ARCHIVE_REVERSABLE = 'reportDetails.SET_ARCHIVE_REVERSABLE';
export const SET_MODAL_ERROR = 'reportDetails.SET_MODAL_ERROR';
export const UPDATE_ARCHIVE_REMINDER_INTERVAL =
  'reportDetails.UPDATE_ARCHIVE_REMINDER_INTERVAL';
export const SET_ARCHIVE_NOTIFICATION_DATE =
  'reportDetails.SET_ARCHIVE_NOTIFICATION_DATE';
export const SET_WHISTLEBLOWER_DETAILS = 'reportDetails.SET_WHISTLEBLOWER_DETAILS';
export const SET_CURRENT_REPORT = 'reportDetails.SET_CURRENT_REPORT';
export const STORE_WHISTLEBLOWER_FILES = 'reportDetails.STORE_WHISTLEBLOWER_FILES';
export const UPDATE_WHISTLEBLOWER_ANONYMIZED_FILE_IDS =
  'reportDetails.UPDATE_WHISTLEBLOWER_ANONYMIZED_FILE_IDS';
export const STORE_USERS = 'reportDetails.STORE_USERS';
export const SET_USERS_RIGHTS = 'reportDetails.SET_USERS_RIGHTS';
export const DELETE_USER = 'reportDetails.DELETE_USER';
export const ADD_USER = 'reportDetails.ADD_USER';
export const ADD_GROUP = 'reportDetails.ADD_GROUP';
export const STORE_GROUPS = 'reportDetails.STORE_GROUPS';
export const SET_GROUP_RIGHTS = 'reportDetails.SET_GROUP_RIGHTS';
export const DELETE_GROUP = 'reportDetails.DELETE_GROUP';
export const ANONYMIZE_WHISTLEBLOWER_DETAILS =
  'reportDetails.ANONYMIZE_WHISTLEBLOWER_DETAILS';
export const STORE_RIGHTS_ARRAY = 'reportDetails.STORE_RIGHTS_ARRAY';
export const SET_ACCESS_SAVING = 'reportDetails.SET_ACCESS_SAVING';
export const SET_PERMISSIONS = 'reportDetails.SET_PERMISSIONS';
export const STORE_REPORT_CREATED_DATE = 'reportDetails.STORE_REPORT_CREATED_DATE';
export const SET_MISCONDUCT_CLOSED_DATE = 'reportDetails.SET_MISCONDUCT_CLOSED_DATE';
export const SET_MISCONDUCT_LANGUAGE = 'reportDetails.SET_MISCONDUCT_LANGUAGE';
export const SET_MISCONDUCT_INTAKE_METHOD = 'reportDetails.SET_MISCONDUCT_INTAKE_METHOD';
export const SET_MISCONDUCT_DAYS_OPEN = 'reportDetails.SET_MISCONDUCT_DAYS_OPEN';

// Reducer
const initialState = {
  reportInfo: null,
  whistleblowerDetails: [],
  reportState: null,
  details: null,
  requireSummary: false,
  anonymizationStarted: false,
  reportChanged: false,
  reportSummary: '',
  reportUploadedFiles: [],
  reminders: null,
  archiveDate: null,
  isLoading: true,
  isSubmitting: false,
  isLoadingFiles: false,
  archiveReminderInterval: INTERVAL_ONE_DAY,
  isUploadAllowed: false,
  isAnonymized: false,
  archiveReminder: true,
  archiveReminderDate: null,
  archiveReversable: true,
  isLoadingTabbedContent: true,
  isProcessingAccessData: false,
  modalError: false,
  isLoadingLogs: false,
  isArchived: false,
  currentReport: null,
  createdAt: '',
  closedAt: '',
  intakeMethod: '',
  language: '',
  logs: [],
  whistleblowerFiles: [],
  whistleblowerAnonymizedFileIds: [],
  userPermissions: [],
  rightsArray: [],
  reportNote: {},
  reportNoteText: '',
  users: [],
  groups: [],
  logsMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_REPORT_DETAILS:
      return {
        ...state,
        reportInfo: action.payload,
      };
    case SET_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    case STORE_REPORT_CREATED_DATE:
      return {
        ...state,
        createdAt: action.payload,
      };
    case SET_MISCONDUCT_CLOSED_DATE:
      return {
        ...state,
        closedAt: action.payload,
      };
    case SET_MISCONDUCT_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_MISCONDUCT_DAYS_OPEN:
      return {
        ...state,
        daysOpen: action.payload,
      };
    case SET_MISCONDUCT_INTAKE_METHOD:
      return {
        ...state,
        intakeMethod: action.payload,
      };
    case SET_ACCESS_SAVING:
      return {
        ...state,
        isProcessingAccessData: action.payload,
      };
    case STORE_RIGHTS_ARRAY:
      return {
        ...state,
        rightsArray: action.payload,
      };
    case SET_CURRENT_REPORT:
      return {
        ...state,
        currentReport: action.payload,
      };
    case TOGGLE_ANONYMIZATION:
      return {
        ...state,
        anonymizationStarted: action.payload,
      };
    case SET_ARCHIVE_NOTIFICATION_DATE:
      return {
        ...state,
        archiveReminderDate: action.payload,
      };
    case ARCHIVE_REPORT:
      return {
        ...state,
        isArchived: action.payload,
      };
    case SET_MODAL_ERROR:
      return {
        ...state,
        modalError: action.payload,
      };
    case SET_ARCHIVE_REVERSABLE:
      return {
        ...state,
        archiveReversable: action.payload,
      };
    case STORE_ARCHIVE_DATE:
      return {
        ...state,
        archiveDate: action.payload,
      };
    case UPDATE_ARCHIVE_REMINDER_INTERVAL:
      return {
        ...state,
        archiveReminderInterval: action.payload,
      };
    case STORE_REPORT_NAME:
      return {
        ...state,
        reportName: action.payload,
      };
    case STORE_DEFAULT_REPORT_REMINDERS:
      return {
        ...state,
        reminders: action.payload,
      };
    case SET_LOADING_REPORT_DETAILS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_LOADING_TAB:
      return {
        ...state,
        isLoadingTabbedContent: action.payload,
      };
    case SET_LOADING_REPORT_FILES:
      return {
        ...state,
        isLoadingFiles: action.payload,
      };
    case SET_SUBMITTING_REPORT:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case SET_REPORT_CHANGED:
      return {
        ...state,
        reportChanged: action.payload,
      };
    case SET_WHISTLEBLOWER_DETAILS:
      return {
        ...state,
        whistleblowerDetails: action.payload,
      };
    case STORE_REPORT_STATE:
      return {
        ...state,
        reportState: action.payload,
      };
    case SET_REPORT_ANONYMIZED:
      return {
        ...state,
        isAnonymized: action.payload,
      };
    case UPDATE_REMINDER_STATUS: {
      const { id, value } = action.payload;
      return {
        ...state,
        reminders: {
          ...state.reminders,
          [id]: {
            ...state.reminders[id],
            active: value,
          },
        },
      };
    }
    case UPDATE_REMINDER_MESSAGE_STATUS: {
      const { id, value } = action.payload;
      return {
        ...state,
        reminders: {
          ...state.reminders,
          [id]: {
            ...state.reminders[id],
            forwardMessage: value,
          },
        },
      };
    }
    case UPDATE_REMINDER_INTERVAL: {
      const { id, value } = action.payload;
      return {
        ...state,
        reminders: {
          ...state.reminders,
          [id]: {
            ...state.reminders[id],
            type: value,
          },
        },
      };
    }
    case UPDATE_REMINDER_MESSAGE: {
      const { id, value } = action.payload;
      return {
        ...state,
        reminders: {
          ...state.reminders,
          [id]: {
            ...state.reminders[id],
            message: value,
          },
        },
      };
    }
    case STORE_REPORT_FILES:
      return {
        ...state,
        reportUploadedFiles: uniqBy([...state.reportUploadedFiles, action.payload], 'id'),
      };
    case STORE_REPORT_NOTE:
      return {
        ...state,
        reportNote: action.payload,
      };
    case STORE_REPORT_NOTE_TEXT:
      return {
        ...state,
        reportNoteText: action.payload,
      };
    case STORE_WHISTLEBLOWER_FILES:
      return {
        ...state,
        whistleblowerFiles: action.payload,
      };
    case UPDATE_WHISTLEBLOWER_ANONYMIZED_FILE_IDS:
      return {
        ...state,
        whistleblowerAnonymizedFileIds: action.payload,
      };
    case UPDATE_REPORT_FILES:
      return {
        ...state,
        reportUploadedFiles: action.payload,
      };
    case UPDATE_REPORT_UPLOAD_STATUS:
      return {
        ...state,
        isUploadAllowed: action.payload,
      };
    case STORE_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
      };
    case ADD_GROUP:
      return {
        ...state,
        groups: [...state.groups, action.payload],
      };
    case SET_USERS_RIGHTS:
      return {
        ...state,
        users: state.users.map((item) =>
          item.id === action.payload.id
            ? { ...item, permissions: action.payload.value }
            : item
        ),
      };
    case DELETE_GROUP:
      return {
        ...state,
        groups: state.groups.filter((item) => item.id !== action.payload),
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((item) => item.id !== action.payload),
      };
    case SET_GROUP_RIGHTS:
      return {
        ...state,
        groups: state.groups.map((item) =>
          item.id === action.payload.id
            ? { ...item, permissions: action.payload.value }
            : item
        ),
      };
    case STORE_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case SET_LOGS_LOADING:
      return {
        ...state,
        isLoadingLogs: action.payload,
      };
    case STORE_LOGS:
      return {
        ...state,
        logs: action.payload,
      };
    case STORE_LOGS_META:
      return {
        ...state,
        logsMeta: action.payload,
      };
    case RESTORE_INITIAL_STATE:
      return { ...initialState };
    case ANONYMIZE_REPORT_INFO: {
      const { id, value } = action.payload;
      return {
        ...state,
        reportInfo: [
          ...state.reportInfo.map((part) => ({
            ...part,
            inputs: part.inputs.map((input) =>
              input.id === id ? { ...input, value: value } : { ...input }
            ),
          })),
        ],
      };
    }
    case ANONYMIZE_WHISTLEBLOWER_DETAILS: {
      const { id, value } = action.payload;
      return {
        ...state,
        whistleblowerDetails: [
          ...state.whistleblowerDetails.map((part) => ({
            ...part,
            inputs: part.inputs.map((input) =>
              input.id === id ? { ...input, value: value } : { ...input }
            ),
          })),
        ],
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function storeReportDetails(data) {
  return { type: STORE_REPORT_DETAILS, payload: data };
}

export function storeCreatedDate(data) {
  return { type: STORE_REPORT_CREATED_DATE, payload: data };
}

export const updateReportInfoValues = ({ id, value }) => ({
  type: ANONYMIZE_REPORT_INFO,
  payload: { id, value },
});

export const updateWhistleblowerDetails = ({ id, value }) => ({
  type: ANONYMIZE_WHISTLEBLOWER_DETAILS,
  payload: { id, value },
});

export const updateWhistleblowerAnonymizedFileIds = (fileIds) => ({
  type: UPDATE_WHISTLEBLOWER_ANONYMIZED_FILE_IDS,
  payload: fileIds,
});

export function storeReportName(data) {
  return { type: STORE_REPORT_NAME, payload: data };
}

export function storeReportState(state) {
  return { type: STORE_REPORT_STATE, payload: state };
}

export function setUserPermissions(permissions) {
  return { type: SET_PERMISSIONS, payload: permissions };
}

export function setLoadingReportDetails(bool) {
  return { type: SET_LOADING_REPORT_DETAILS, payload: bool };
}

export function setModalError(bool) {
  return { type: SET_MODAL_ERROR, payload: bool };
}

export function setAccessSaving(bool) {
  return { type: SET_ACCESS_SAVING, payload: bool };
}

export function setReportAnonymized(bool) {
  return { type: STORE_REPORT_STATE, payload: bool };
}

export function setReportAnonymizedOnLoad(bool) {
  return { type: SET_REPORT_ANONYMIZED, payload: bool };
}

export function setReportArchiveNotificationDate(string) {
  return { type: SET_ARCHIVE_NOTIFICATION_DATE, payload: string };
}

export function toggleAnonymization(bool) {
  return { type: TOGGLE_ANONYMIZATION, payload: bool };
}

export function setLoadingTab(bool) {
  return { type: SET_LOADING_TAB, payload: bool };
}

export const setUserRights = ({ id, value }) => ({
  type: SET_USERS_RIGHTS,
  payload: { id, value },
});

export const setGroupRights = ({ id, value }) => ({
  type: SET_GROUP_RIGHTS,
  payload: { id, value },
});

export const storeGroups = (groups) => ({ type: STORE_GROUPS, payload: groups });
export const deleteGroup = (group) => ({ type: DELETE_GROUP, payload: group });

export function setLoadingReportFiles(bool) {
  return { type: SET_LOADING_REPORT_FILES, payload: bool };
}

export function setSubmittingReport(bool) {
  return { type: SET_SUBMITTING_REPORT, payload: bool };
}

export function setReportChanged(bool) {
  return { type: SET_REPORT_CHANGED, payload: bool };
}

export function setReportFiles(file) {
  return { type: STORE_REPORT_FILES, payload: file };
}

export function setReportNote(data) {
  return { type: STORE_REPORT_NOTE, payload: data };
}

export function setReportNoteText(data) {
  return { type: STORE_REPORT_NOTE_TEXT, payload: data };
}

export function updateFilesAfterDeletion(files) {
  return { type: UPDATE_REPORT_FILES, payload: files };
}

export function updateFileUploadStatus(bool) {
  return { type: UPDATE_REPORT_UPLOAD_STATUS, payload: bool };
}

export function setArchiveReversable(bool) {
  return { type: SET_ARCHIVE_REVERSABLE, payload: bool };
}

export function storeDefaultReminders(reminders) {
  return { type: STORE_DEFAULT_REPORT_REMINDERS, payload: reminders };
}

export function clearMisconductDetails() {
  return { type: RESTORE_INITIAL_STATE };
}

export const updateReminderStatus = ({ id, value }) => ({
  type: UPDATE_REMINDER_STATUS,
  payload: { id, value },
});

export const updateReminderMessageStatus = ({ id, value }) => ({
  type: UPDATE_REMINDER_MESSAGE_STATUS,
  payload: { id, value },
});

export const updateReportArchive = (payload) => ({
  type: ARCHIVE_REPORT,
  payload,
});

export const updateArchiveReminderInterval = (payload) => ({
  type: UPDATE_ARCHIVE_REMINDER_INTERVAL,
  payload,
});

export const updateReminderInterval = ({ id, value }) => ({
  type: UPDATE_REMINDER_INTERVAL,
  payload: { id, value },
});

export const updateReminderMessage = ({ id, value }) => ({
  type: UPDATE_REMINDER_MESSAGE,
  payload: { id, value },
});

export function setWhistleblowerDetails(data) {
  return { type: SET_WHISTLEBLOWER_DETAILS, payload: data };
}

export const setCurrentReportView = (payload) => ({ type: SET_CURRENT_REPORT, payload });
export const setLogsLoading = (payload) => ({ type: SET_LOGS_LOADING, payload });
export const storeLogs = (payload) => ({ type: STORE_LOGS, payload });
export const storeLogsMeta = (payload) => ({ type: STORE_LOGS_META, payload });
export const storeUsers = (payload) => ({ type: STORE_USERS, payload });
export const addGroup = (payload) => ({ type: ADD_GROUP, payload });
export const addUser = (payload) => ({ type: ADD_USER, payload });
export const deleteUser = (payload) => ({ type: DELETE_USER, payload });
export const storeArchiveDate = (payload) => ({ type: STORE_ARCHIVE_DATE, payload });
export const setDateClosed = (payload) => ({ type: SET_MISCONDUCT_CLOSED_DATE, payload });
export const setLanguage = (payload) => ({ type: SET_MISCONDUCT_LANGUAGE, payload });
export const setDaysOpen = (payload) => ({
  type: SET_MISCONDUCT_DAYS_OPEN,
  payload,
});
export const setIntakeMethod = (payload) => ({
  type: SET_MISCONDUCT_INTAKE_METHOD,
  payload,
});
export const storeMisconductsRighsArray = (payload) => ({
  type: STORE_RIGHTS_ARRAY,
  payload,
});
export const storeWhistleblowerFiles = (payload) => ({
  type: STORE_WHISTLEBLOWER_FILES,
  payload,
});

// Side Effects
export function getReportDetails(id) {
  return (dispatch) => {
    dispatch(setLoadingReportDetails(true));
    return api.misconduct
      .getReportDetails(id)
      .then((res) => {
        const deserializedReport = deserializeReport(res.data);
        const userPermissions = deserializedReport.permissions;

        dispatch(setCurrentReportView(id));
        dispatch(storeReportName(deserializedReport.reportName));
        dispatch(storeCreatedDate(deserializedReport.dateCreated));
        dispatch(setUserPermissions(userPermissions));
        dispatch(updateReportArchive(deserializedReport.isArchived));
        dispatch(setDateClosed(deserializedReport.dateClosed));
        dispatch(setDaysOpen(deserializedReport.daysOpen));
        dispatch(setIntakeMethod(deserializedReport.intakeMethod));
        dispatch(setLanguage(deserializedReport.language));
        dispatch(storeReportDetails(deserializedReport.reportInfo));
        dispatch(storeReportState(deserializedReport.reportState));
        dispatch(updateFileUploadStatus(deserializedReport.isUploadAllowed));
        dispatch(setReportAnonymizedOnLoad(deserializedReport.isAnonymized));
        dispatch(storeArchiveDate(deserializedReport.archiveDate));
        dispatch(
          setReportArchiveNotificationDate(
            deserializedReport.archiveReminder?.notification_date
          )
        );
        dispatch(
          updateArchiveReminderInterval(deserializedReport.archiveReminder.interval || '')
        );
        dispatch(setArchiveReversable(deserializedReport.archiveReversable));

        if (
          userPermissions.includes(MISCONDUCT_RIGHTS.READ_CHAT_MESSAGES) ||
          userPermissions.includes(MISCONDUCT_RIGHTS.FULL_ACCESS)
        ) {
          dispatch(getMisconductRoomData(id));
        }

        dispatch(setLoadingReportDetails(false));
        dispatch(setLoadingTab(false));
      })
      .catch(() => {
        dispatch(setLoadingReportDetails(false));
        dispatch(setLoadingTab(false));
      });
  };
}

export function getReportFiles(id, folders) {
  return (dispatch) => {
    return api.misconduct
      .getReportFiles(id, folders)
      .then((res) => {
        const deserializedFiles = deserializeFiles(res.data);
        dispatch(storeWhistleblowerFiles(deserializedFiles));
      })
      .catch(() => {
        // @todo: Add Error handling
      });
  };
}

export function getReportRightsAndData(id) {
  return (dispatch) => {
    dispatch(setLoadingTab(true));
    return api.misconduct
      .getRightsKeys()
      .then((res) => {
        const rightsArray = res.data.map((item) => ({
          value: item.key,
          type: item.type,
          key: item.key,
          label: item.label,
          derivedPermissions: item.derived_permissions,
        }));
        dispatch(storeMisconductsRighsArray(rightsArray));
        return api.misconduct
          .getReportAccessDetails(id)
          .then((res) => {
            const deserializedUsers = deserializeUserGroups(res.data.users);
            const deserializedGroups = deserializeUserGroups(res.data.groups);
            dispatch(storeUsers(deserializedUsers));
            dispatch(storeGroups(deserializedGroups));
            dispatch(setLoadingTab(false));
          })
          .catch(() => {
            dispatch(setLoadingTab(false));
          });
      })
      .catch(() => {
        dispatch(setLoadingTab(false));
      });
  };
}

export function setReportAccessDetails(id, users, groups) {
  return (dispatch) => {
    dispatch(setAccessSaving(true));
    return api.misconduct
      .setReportAccessDetails(id, users, groups)
      .then(() => {
        dispatch(storeUsers(users));
        dispatch(storeGroups(groups));
        dispatch(setAccessSaving(false));
        setToastMessage({
          content: { translationKey: 'reportUpdated' },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.SUCCESS,
        })(dispatch);
      })
      .catch((err) => {
        setToastMessage({
          content: { message: err.response.data.error },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.WARNING,
        })(dispatch);
        dispatch(setAccessSaving(false));
      });
  };
}

export function updateReportState(id, reportState) {
  return (dispatch) => {
    dispatch(setSubmittingReport(true));
    return api.misconduct
      .setReportStates(id, reportState)
      .then(() => {
        dispatch(storeReportState(reportState));
        dispatch(setSubmittingReport(false));
        setToastMessage({
          content: { translationKey: 'reportUpdated' },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.SUCCESS,
        })(dispatch);
      })
      .catch((err) => {
        setToastMessage({
          content: { message: err.response.data.error },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.WARNING,
        })(dispatch);
        dispatch(setSubmittingReport(false));
      })
      .finally(() => {
        dispatch(getLogs({ id }));
        return api.misconduct.getReportDetails(id).then((res) => {
          dispatch(setDateClosed(res.data.date_closed));
        });
      });
  };
}

export function getWhistleblowerDetails(id) {
  return (dispatch) => {
    return api.misconduct
      .getWhistleblowerDetails(id)
      .then((res) => {
        dispatch(setWhistleblowerDetails(res.data));
      })
      .catch(() => {
        // @todo: Handle errors?
      });
  };
}

export function getReportOfficerFiles(id) {
  return (dispatch) => {
    return api.misconduct
      .getMisconductOfficerFiles(id)
      .then((res) => {
        res.data.files.map((file) => {
          return dispatch(setReportFiles(file));
        });
      })
      .catch(() => {
        //@todo: Handle errors
      });
  };
}

export function getReportInternalNote(id) {
  return (dispatch) => {
    return api.misconduct
      .getMisconductInternalNote(id)
      .then((res) => {
        dispatch(setReportNote(res.data));
        dispatch(setReportNoteText(res.data.text));
      })
      .catch(() => {
        //@todo: Handle errors
      });
  };
}

export function submitAnonymizationChanges({ id, params }) {
  return (dispatch, getState) => {
    const { rooms, internalRooms } = getState().chatrooms;
    const { currentReport, whistleblowerAnonymizedFileIds } = getState().reportDetails;
    const chatMessages = rooms[currentReport];
    const internalChatMessages = internalRooms[currentReport];

    dispatch(setChatTouched(false));
    dispatch(setLoadingReportDetails(true));
    dispatch(toggleAnonymization(false));

    return api.misconduct
      .anonymizeReport({
        id,
        params,
        chat: chatMessages,
        files: whistleblowerAnonymizedFileIds,
        internalChat: internalChatMessages,
      })
      .then(() => {
        dispatch(setReportChanged(false));

        dispatch(getReportDetails(id));
        dispatch(getReportFiles(id, ['report_attachments', 'public_chat_attachments']));
        dispatch(getInternalChatFiles(id, ['internal_chat_attachments']));
        dispatch(updateWhistleblowerAnonymizedFileIds([]));
        setToastMessage({
          content: { translationKey: 'reportAnonymized' },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.SUCCESS,
        })(dispatch);
      })
      .catch((err) => {
        dispatch(getReportDetails(id));
        setToastMessage({
          content: { message: err.response.data.error },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.WARNING,
        })(dispatch);
      })
      .finally(() => {
        dispatch(setLoadingReportDetails(false));
        dispatch(toggleAnonymization(false));
      });
  };
}

export function revertAnonymization(id) {
  return (dispatch) => {
    dispatch(setLoadingReportDetails(true));
    return api.misconduct
      .revertReportAnonymization(id)
      .then(() => {
        dispatch(getReportFiles(id, ['report_attachments', 'public_chat_attachments']));
        dispatch(getInternalChatFiles(id, ['internal_chat_attachments']));
        dispatch(getReportDetails(id));
        setToastMessage({
          content: { translationKey: 'reportAnonymizationReverted' },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.SUCCESS,
        })(dispatch);
      })
      .catch((err) => {
        setToastMessage({
          content: { message: err.response.data.error },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.WARNING,
        })(dispatch);
      })
      .finally(() => {
        dispatch(setLoadingReportDetails(false));
      });
  };
}

export function getReportReminders(id) {
  return (dispatch) => {
    dispatch(setLoadingTab(true));
    return api.misconduct
      .getReportReminders(id)
      .then((res) => {
        const deserializedReminders = deserializeReportReminders(res.data);
        dispatch(setLoadingTab(true));
        dispatch(storeDefaultReminders(deserializedReminders));
      })
      .catch((err) => {
        setToastMessage({
          content: { message: err.response.data.error },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.WARNING,
        })(dispatch);
      })
      .finally(() => {
        dispatch(setLoadingTab(false));
      });
  };
}

export function submitUpdatedReminders(id, reminders, isOfficer) {
  const triggerSuccessMessage = (dispatch) => {
    if (isOfficer) {
      setToastMessage({
        content: { translationKey: 'reportUpdated' },
        context: toastContext.REPORT_MANAGEMENT,
        type: toastType.SUCCESS,
      })(dispatch);
    } else {
      setToastMessage({
        content: { translationKey: 'reportUpdated' },
        context: toastContext.REPORT_MANAGEMENT_AS_USER,
        type: toastType.SUCCESS,
      })(dispatch);
    }
  };

  return (dispatch) => {
    const serializedReminders = serializeReportReminders(reminders);
    dispatch(setLoadingTab(true));

    return api.misconduct
      .setReportReminders(id, serializedReminders)
      .then(() => {
        triggerSuccessMessage(dispatch);
      })
      .catch((error) => {
        if (isOfficer) {
          dispatch(
            setToastMessage({
              content: { translationKey: error.response.data.error },
              context: toastContext.REPORT_MANAGEMENT,
              type: toastType.ALERT,
            })
          );
        } else {
          dispatch(
            setToastMessage({
              content: { translationKey: error.response.data.error },
              context: toastContext.REPORT_MANAGEMENT_AS_USER,
              type: toastType.ALERT,
            })
          );
        }
      })
      .finally(() => {
        dispatch(setLoadingTab(false));
      });
  };
}

export const getLogs =
  ({ id, page = PAGINATE_DEFAULT_PAGE, perPage = PAGINATE_DEFAULT_PER_PAGE }) =>
  async (dispatch) => {
    dispatch(setLogsLoading(true));

    try {
      const res = await api.misconduct.getLogs({ id, page, perPage });
      const deserializedLogs = deserializeLogs(res.data);
      dispatch(storeLogs(deserializedLogs.items));
      dispatch(storeLogsMeta({ page, perPage, totalPages: deserializedLogs.pages }));
    } catch (err) {
      setToastMessage({
        content: { message: err.response.data.error },
        context: toastContext.REPORT_MANAGEMENT,
        type: toastType.WARNING,
      })(dispatch);
    } finally {
      dispatch(setLogsLoading(false));
    }
  };

export function toggleFileUpload({ id, allowed }) {
  return (dispatch) => {
    return api.misconduct
      .allowUploads({ id, allowed })
      .then(() => {
        dispatch(updateFileUploadStatus(allowed));
      })
      .catch((err) => {
        setToastMessage({
          content: { message: err.response.data.error },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.WARNING,
        })(dispatch);
      });
  };
}

export function archiveReport({ id, dueDate, active, interval }) {
  return (dispatch) => {
    dispatch(setLoadingTab(true));
    return api.misconduct
      .archiveMisconduct({ id, dueDate, active, interval })
      .then(() => {
        dispatch(getReportDetails(id));
        setToastMessage({
          content: { translationKey: 'reportArchived' },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.SUCCESS,
        })(dispatch);
      })
      .catch((err) => {
        dispatch(setModalError(true));
        setToastMessage({
          content: { translationKey: err.response.data.error },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.WARNING,
        })(dispatch);
      })
      .finally(() => {
        dispatch(setLoadingTab(false));
      });
  };
}

export function restoreReport(id) {
  return (dispatch) => {
    dispatch(setLoadingTab(true));

    return api.misconduct
      .restoreMisconduct(id)
      .then(() => {
        dispatch(getReportDetails(id));
        setToastMessage({
          content: { translationKey: 'reportRestored' },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.SUCCESS,
        })(dispatch);
      })
      .catch((err) => {
        setToastMessage({
          content: { message: err.response.data.error },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.WARNING,
        })(dispatch);
      })
      .finally(() => {
        dispatch(setLoadingTab(false));
      });
  };
}

export function uploadFiles(files) {
  return (dispatch) => {
    dispatch(setLoadingReportFiles(true));

    files
      .reduce((promise, file) => {
        return promise.then(() =>
          api.misconduct
            .uploadFile(file)
            .then((res) => {
              dispatch(setReportFiles(res.data));
            })
            .catch(() => {
              dispatch(
                setToastMessage({
                  content: {
                    translationKey: 'uploadFileError',
                    param: file.name,
                  },
                  context: toastContext.REPORT_MANAGEMENT,
                  type: toastType.ALERT,
                })
              );
            })
        );
      }, Promise.resolve())
      .then(() => {
        dispatch(setLoadingReportFiles(false));
        dispatch(setFileLoading(false));
      });
  };
}

export function updateFiles(id, files) {
  return (dispatch) => {
    let fileIds = files.map(({ id }) => id);
    dispatch(setLoadingReportFiles(true));

    return api.misconduct
      .setReportFiles(id, fileIds)
      .then((res) => {
        dispatch(setLoadingReportFiles(false));
        setToastMessage({
          content: { translationKey: 'reportFilesUpdated' },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.SUCCESS,
        })(dispatch);
        dispatch(updateFilesAfterDeletion(res.data.files));
      })
      .catch((err) => {
        setToastMessage({
          content: { message: err.response.data.error },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.WARNING,
        })(dispatch);
        dispatch(setLoadingReportFiles(false));
      });
  };
}

export function updateReportNote(id, text) {
  return (dispatch) => {
    dispatch(setLoadingReportFiles(true));

    return api.misconduct
      .updateMisconductInternalNote(id, text)
      .then(() => {
        dispatch(setLoadingReportFiles(false));
        setToastMessage({
          content: { translationKey: 'reportNoteUpdated' },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.SUCCESS,
        })(dispatch);
        dispatch(getReportInternalNote(id));
      })
      .catch((err) => {
        setToastMessage({
          content: { message: err.response.data.error },
          context: toastContext.REPORT_MANAGEMENT,
          type: toastType.WARNING,
        })(dispatch);
        dispatch(setLoadingReportFiles(false));
      });
  };
}

export function deleteFile(file) {
  return (dispatch, getState) => {
    const {
      reportDetails: { reportUploadedFiles },
    } = getState();
    const newFilesArray = reportUploadedFiles.filter((item) => {
      return item.id !== file;
    });

    dispatch(updateFilesAfterDeletion(newFilesArray));
  };
}

export function clearTemporaryFiles() {
  return (dispatch) => {
    dispatch(updateFilesAfterDeletion([]));
  };
}
