import React from 'react';
import PropTypes from 'prop-types';

import { filePropTypes } from 'models';

import FileItem from 'components/FileItem';

const propTypes = {
  files: PropTypes.arrayOf(filePropTypes).isRequired,
  deleteFile: PropTypes.func,
  fileUrlResolver: PropTypes.func,
  misconductId: PropTypes.string,
  allowDelete: PropTypes.bool,
  isAnonymizable: PropTypes.bool,
};

const FilesList = ({
  files,
  fileUrlResolver,
  deleteFile,
  isDownloadable,
  isAnonymizable,
  misconductId,
  allowDelete,
}) => {
  return files.map((file) => (
    <FileItem
      key={file.id}
      file={file}
      withDownloadIcon={isDownloadable}
      fileUrlResolver={fileUrlResolver}
      misconductId={misconductId}
      deleteFile={deleteFile}
      allowDelete={allowDelete}
      isAnonymizable={isAnonymizable}
    />
  ));
};

FilesList.propTypes = propTypes;

export default FilesList;
