import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'components/Typography';

import { colorName } from 'styles';
import { ReportHint, StickyWrapper } from './style';

const propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node])
    .isRequired,
  children: PropTypes.node,
};

const ReportingColumn = ({ header, subheader, children }) => {
  return (
    <StickyWrapper>
      <ReportHint>
        <Typography
          className="mobile-hidden"
          size="large"
          color={colorName.white}
          bottomMargin={12}
        >
          {header}
        </Typography>

        {subheader}
      </ReportHint>

      {children}
    </StickyWrapper>
  );
};

ReportingColumn.propTypes = propTypes;

export default ReportingColumn;
