import React from 'react';
import PropTypes from 'prop-types';

import { StyledControl, IconWrapper } from 'components/AutocompleteSelect/style';

const propTypes = {
  menuIsOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.shape({
    isDark: PropTypes.bool,
    isError: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  }),
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }).isRequired,
};

export const Control = ({
  innerProps,
  children,
  menuIsOpen,
  selectProps: { icon: Icon, isDark, isError },
}) => {
  return (
    <StyledControl
      isMenuOpen={menuIsOpen}
      isDark={isDark}
      isError={isError}
      {...innerProps}
    >
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}

      {children}
    </StyledControl>
  );
};

Control.propTypes = propTypes;
