import styled from 'styled-components';
import { rem } from 'polished';

import { RadioButtonLabel } from 'components/RadioButton/style';
import { CheckboxLabel } from 'components/Checkbox/style';

import {
  breakpoints,
  colors,
  colorName,
  transitions,
  globalSize,
  variables,
  font,
} from 'styles';
import SurveyArrowIcon from 'components/Svg/SurveyArrowIcon';
import CompanyLogoSmall from 'components/CompanyLogo/CompanyLogoSmall';

const PADDING = globalSize.defaultPadding;

export const QuestionGroup = styled.div`
  margin: 0 0 ${({ noMargin }) => (noMargin ? 0 : rem(40))};
`;

export const StepsContainer = styled.div`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

export const StepsWrapper = styled.p`
  display: flex;
  justify-content: space-between;
`;

export const StepIndicator = styled.span`
  flex: 0 0 49.5%;
  height: ${rem(6)};
  background: ${({ isFilled }) => (isFilled ? colors.green : colors.mako)};
  border-radius: ${rem(variables.borderRadius)};
  transition: background 0.8s ${transitions.spring};
`;

export const SurveyNavigationBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${rem(globalSize.desktopMenuSize)};
  padding: 0 ${rem(PADDING)};
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  .mobile-hidden {
    display: none;
  }

  @media ${breakpoints.mobile} {
    .mobile-hidden {
      display: block;
    }

    padding: 0 ${rem(PADDING * 2)};
  }

  @media ${breakpoints.smallScreen} {
    position: sticky;
  }
`;

export const NavButtonsWrapper = styled.div`
  display: flex;
  gap: ${rem(PADDING)};
  width: 100%;

  @media ${breakpoints.mobile} {
    width: unset;
  }
`;

export const BackSurveyArrowIcon = styled(SurveyArrowIcon)`
  transform: scaleX(-1);
`;

export const QuestionsWrapper = styled.div`
  margin: 0 0 ${rem(12)};
`;

export const StickyWrapper = styled.div`
  @media ${breakpoints.smallScreen} {
    position: sticky;
    top: ${rem(80)};
  }

  @media ${breakpoints.mediumScreen} {
    top: ${rem(44)};
  }
`;

export const ReportHint = styled.div`
  .mobile-hidden {
    display: none;
  }

  @media ${breakpoints.smallScreen} {
    margin: 0 0 ${rem(32)};

    .mobile-hidden {
      display: block;
    }
  }
`;

export const RadioButtonGroup = styled.div`
  ${RadioButtonLabel} {
    display: flex;

    &:not(:last-child) {
      margin: 0 0 ${rem(8)} 0;
    }
  }

  /* @media ${breakpoints.smallScreen} {
    ${RadioButtonLabel} {
      max-width: 60%;
    }
  } */
`;

export const InputWrapper = styled.div`
  @media ${breakpoints.smallScreen} {
    max-width: 50%;
  }
`;

export const DocumentLink = styled.a`
  color: inherit;
`;

export const StepsButtonWrapper = styled.div`
  @media ${breakpoints.tablet} {
    max-width: ${rem(245)};
    margin: 0 auto;
  }
`;

export const TokenModalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${rem(PADDING * 1.5)} ${rem(PADDING)} ${rem(PADDING)};
  text-align: center;

  @media ${breakpoints.tablet} {
    width: ${rem(580)};
    padding: ${rem(PADDING * 2.5)} ${rem(PADDING * 2)};
  }

  ${CheckboxLabel} {
    display: flex;
    margin: 0;
    text-align: left;

    @media ${breakpoints.tablet} {
      display: inline-flex;
    }
  }

  @media ${breakpoints.smallScreen} {
    & > a {
      display: flex;
      margin: 0 auto;
    }
  }
`;

export const TokenBox = styled.p`
  width: 100%;
  max-width: ${rem(540)};
  margin: 0 auto ${rem(24)};
  padding: ${rem(12)} ${rem(globalSize.defaultPadding)};
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.huge)};
  letter-spacing: ${rem(4)};
  text-align: center;
  text-transform: uppercase;
  background-color: ${colorName.primary};
  border-radius: ${rem(variables.borderRadius)};

  @media ${breakpoints.tablet} {
    font-size: ${rem(font.size.gigantic)};
  }
`;

export const TokenEmailBox = styled.form`
  margin: 0 0 ${rem(16)};

  & > div {
    margin: 0 0 ${rem(12)};
  }

  & > button {
    align-self: center;
    margin: ${rem(24)} 0 0;
  }

  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${rem(360)};
    margin: 0 auto ${rem(32)};
    text-align: left;
  }
`;

export const HeaderLogo = styled(CompanyLogoSmall)`
  display: block;
  width: ${({ isRestricted }) => (isRestricted ? rem(100) : rem(40))};
  max-height: ${({ isRestricted }) => (isRestricted ? rem(100) : rem(40))};
  margin: 0;
`;

export const MainHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(globalSize.defaultPadding * 2)};
`;

export const HeaderWrapper = styled.div`
  margin: 0 0 ${rem(24)};
`;

export const HintWrapper = styled.div`
  padding: 1px ${rem(12)};
  background-color: ${colors.lightGray};
  border-radius: ${rem(4)};
`;

export const SummarySectionHeader = styled.div`
  display: flex;
  gap: rem(12);
  align-items: flex-start;
  justify-content: space-between;
`;

export const RequiredMark = styled.span`
  color: ${colors.darkestRed};
`;

export const TermsCheckboxWrapper = styled.div`
  @media ${breakpoints.tablet} {
    max-width: ${rem(356)};
    margin: 0 auto;
  }
`;

export const Separator = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? rem(marginTop) : rem(24))};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? rem(marginBottom) : rem(32))};
  border-bottom: 1px solid ${colorName.separator};
`;

export const Stepper = styled.div`
  position: absolute;
  top: ${rem(-4)};
  left: 0;
  width: ${({ currentStep, totalSteps }) => `${(currentStep / totalSteps) * 100}%`};
  height: ${rem(3)};
  background-color: ${colors.yellow};
`;

export const WithNotificationWrapper = styled.div`
  margin: ${rem(16)} 0;
  padding: ${rem(16)};
  text-align: left;
  background-color: ${colors.lightGray};
  border-radius: ${rem(8)};
`;

export const DataSavedWrapper = styled.div`
  margin: ${rem(16)} 0;
  padding: ${rem(16)};
  text-align: left;
  background-color: ${colors.successGreen};
  border-radius: ${rem(8)};
`;

export const InstructionButton = styled.div`
  @media ${breakpoints.tablet} {
    display: none;
  }
`;
