import React from 'react';

function AnonymizeFileIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
        className="nc-icon-wrapper"
      >
        <path strokeLinecap="butt" d="M8.468 15.532L15.528 8.472" data-cap="butt" />
        <path d="M3 1H21V23H3z" />
        <circle cx="12" cy="12" r="5" />
      </g>
    </svg>
  );
}

export default AnonymizeFileIcon;
