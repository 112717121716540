import React from 'react';

const FlagIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64" {...props}>
      <path
        fill="#000"
        d="M47.7 52H41V10c0-.6-.4-1-1-1H16c-.4 0-.7.2-.9.5-.2.3-.2.7 0 1l4.7 7.5-4.7 7.5c-.2.3-.2.7 0 1 .2.3.5.5.9.5h23v25h-8c-.6 0-1 .4-1 1s.4 1 1 1h16.7c.6 0 1-.4 1-1s-.4-1-1-1zM17.8 25l4-6.5c.2-.3.2-.7 0-1.1l-4-6.4H39v14H17.8z"
      />
    </svg>
  );
};

export default FlagIcon;
