import React from 'react';
import PropTypes from 'prop-types';

import { dataPortionShape } from 'models';

import ProgressBar from 'components/ProgressBar';

import { Header, Icon, Content, Comment } from './style';

const propTypes = {
  children: PropTypes.node,
  portionProgressed: PropTypes.shape(dataPortionShape),
  portionRemaining: PropTypes.shape(dataPortionShape),
  title: PropTypes.node,
  icon: PropTypes.node,
  comment: PropTypes.node,
  isAnimated: PropTypes.bool,
};

const defaultProps = {
  isAnimated: true,
};

export const ProgressVisualization = ({
  children,
  portionProgressed,
  portionRemaining,
  icon,
  title,
  comment,
  isAnimated,
}) => (
  <section>
    {title && (
      <Header>
        {icon && <Icon>{icon}</Icon>}
        {title}
      </Header>
    )}

    {children && <Content>{children}</Content>}

    {portionProgressed && portionRemaining && (
      <ProgressBar
        portionProgressed={portionProgressed}
        portionRemaining={portionRemaining}
        isAnimated={isAnimated}
      />
    )}

    {comment && <Comment>{comment}</Comment>}
  </section>
);

ProgressVisualization.propTypes = propTypes;
ProgressVisualization.defaultProps = defaultProps;

export default ProgressVisualization;
