import React from 'react';

function WarningOutlineIcon(props) {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.54821 51.027L29.2662 7.733C29.6288 7.05823 30.1675 6.49433 30.825 6.10124C31.4825 5.70816 32.2342 5.50058 33.0002 5.50058C33.7662 5.50058 34.518 5.70816 35.1754 6.10124C35.8329 6.49433 36.3716 7.05823 36.7342 7.733L60.4522 51.027C60.8193 51.6845 61.0095 52.426 61.0042 53.179C60.9989 53.9321 60.7983 54.6708 60.4221 55.3231C60.0458 55.9754 59.5067 56.5189 58.8575 56.9005C58.2083 57.282 57.4712 57.4886 56.7182 57.5H9.28221C8.52925 57.4886 7.79214 57.282 7.14293 56.9005C6.49372 56.5189 5.95463 55.9754 5.57835 55.3231C5.20207 54.6708 5.00148 53.9321 4.9962 53.179C4.99092 52.426 5.18112 51.6845 5.54821 51.027Z"
        stroke="#2E2E38"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M33 20.5V42.5"
        stroke="#2E2E38"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M33 50.5C33.5523 50.5 34 50.0523 34 49.5C34 48.9477 33.5523 48.5 33 48.5C32.4477 48.5 32 48.9477 32 49.5C32 50.0523 32.4477 50.5 33 50.5Z"
        stroke="#2E2E38"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}

export default WarningOutlineIcon;
