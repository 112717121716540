import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
  SORT_DIRECTION_ASCENDING,
} from 'helpers/sharedConstants';

const DEFAULT_SORT_PARAMS = { key: 'name', direction: SORT_DIRECTION_ASCENDING };

// Actions
export const STORE_GROUPS_LIST = 'groups.STORE_GROUPS_LIST';
export const STORE_GROUPS_LIST_META = 'groups.STORE_GROUPS_LIST_META';
export const SET_LOADING = 'groups.SET_LOADING';
export const RESET_GROUPS_LIST = 'groups.RESET_GROUPS_LIST';

// Reducer
const initialState = {
  groupList: [],
  isLoading: true,
  groupListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
    sortParams: DEFAULT_SORT_PARAMS,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_GROUPS_LIST:
      return {
        ...state,
        groupList: action.payload,
      };
    case STORE_GROUPS_LIST_META:
      return {
        ...state,
        groupListMeta: action.payload,
      };
    case RESET_GROUPS_LIST:
      return initialState;
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function storeGroups(data) {
  return { type: STORE_GROUPS_LIST, payload: data };
}

export function storeGroupsMeta(data) {
  return { type: STORE_GROUPS_LIST_META, payload: data };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

export function resetGroupsList() {
  return { type: RESET_GROUPS_LIST };
}

// Side Effects
export function getGroups({
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
  sortParams = DEFAULT_SORT_PARAMS,
  query,
}) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return api.groups
      .getGroups({
        page,
        perPage,
        sortBy: sortParams?.key,
        sortOrder: sortParams?.direction,
        query,
      })
      .then((res) => {
        dispatch(storeGroups(res.data.items));
        dispatch(
          storeGroupsMeta({
            page: res.data.current_page,
            perPage,
            totalPages: res.data.pages,
            sortParams,
          })
        );
      })
      .catch(() => {
        //todo handle error
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}
