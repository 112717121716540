import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarChartLegendPlugin } from '../Plugins/BarChartLegendPlugin';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { CustomLegend, Wrapper } from './style';
import { font } from 'styles';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const propTypes = {
  data: PropTypes.object,
  datasetLabels: PropTypes.array,
  legendId: PropTypes.string,
};

const BarChart = ({ data, datasetLabels, legendId }) => {
  return (
    data &&
    !!data.groups.length &&
    datasetLabels && (
      <>
        <Wrapper columnsCount={data.groups.length}>
          <Bar
            options={{
              responsive: true,
              maintainAspectRatio: false,
              indexAxis: 'y',
              layout: {
                padding: {
                  right: 30,
                },
              },
              plugins: {
                htmlLegend: {
                  containerID: legendId,
                },
                legend: {
                  display: false,
                  labels: {
                    font: {
                      size: 30,
                    },
                  },
                },
                tooltip: {
                  callbacks: {
                    title: (ctx) => {
                      return data.groups[ctx[0].dataIndex].label;
                    },
                  },
                },
              },
              scales: {
                x: {
                  stacked: true,
                  grid: { display: false },
                  display: false,
                },
                y: {
                  ticks: {
                    font: {
                      family: font.family,
                    },
                  },
                  stacked: true,
                  grid: {
                    display: false,
                  },
                  border: {
                    display: false,
                  },
                },
              },
            }}
            plugins={[BarChartLegendPlugin, ChartDataLabels]}
            data={{
              labels: data.groups.map((item) =>
                window.matchMedia('(max-width: 700px)').matches
                  ? item.label.length > 10
                    ? item.label.slice(0, 10) + '...'
                    : item.label
                  : item.label.length > 20
                  ? item.label.slice(0, 20) + '...'
                  : item.label
              ),
              datasets: [
                {
                  label: datasetLabels[0],
                  data: data.groups.map((item) => item.open),
                  backgroundColor: '#FFE600',
                  borderRadius: 4,
                  barPercentage: 0.6,
                  datalabels: {
                    color: '#2E2E38',
                    font: {
                      family: font.family,
                    },
                    formatter: (value) => {
                      return value >= 1 ? value : '';
                    },
                  },
                },
                {
                  label: datasetLabels[1],
                  data: data.groups.map((item) => item.closed),
                  backgroundColor: '#2E2E38',
                  borderRadius: 4,
                  barPercentage: 0.6,
                  datalabels: {
                    color: '#fff',
                    font: {
                      family: font.family,
                    },
                    formatter: (value) => {
                      return value >= 1 ? value : '';
                    },
                  },
                },
                {
                  label: datasetLabels[2],
                  data: data.groups.map(() => 0),
                  backgroundColor: 'rgba(24,91,62,0)',
                  datalabels: {
                    font: {
                      family: font.family,
                    },
                    backgroundColor: () => 'white',
                    formatter: (value, ctx) => {
                      let total = 0;
                      let index = ctx.dataIndex;
                      ctx.chart.data.datasets.map((d, i) => {
                        if (i === 2) return;
                        total += d.data[index];
                      });
                      return total;
                    },
                    align: 'end',
                    anchor: 'end',
                    padding: 0,
                  },
                },
              ],
            }}
          />
        </Wrapper>
        <CustomLegend id={legendId} />
      </>
    )
  );
};

BarChart.propTypes = propTypes;

export default BarChart;
