import React from 'react';

function NotificationsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="transparent"
        d="M12.48 26.07c.29.52.72.94 1.23 1.24.51.3 1.09.46 1.69.47.59.01 1.18-.15 1.7-.44.52-.29.95-.71 1.25-1.22"
      />

      <path
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M12.48 26.07c.29.52.72.94 1.23 1.24.51.3 1.09.46 1.69.47.59.01 1.18-.15 1.7-.44.52-.29.95-.71 1.25-1.22"
      />

      <path
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M23.69 14.5v-3.4c0-4.4-3.86-8.22-8.3-8.22-2.19.05-4.27.94-5.82 2.49a8.496 8.496 0 00-2.49 5.82v3.32c0 3.78-3.03 4.36-3.03 6.48 0 1.91 4.36 3.4 11.33 3.4s11.33-1.49 11.33-3.4c.01-2.13-3.02-2.71-3.02-6.49z"
      />
    </svg>
  );
}

export default NotificationsIcon;
