import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  hello: {
    id: 'LandingPage.hello',
    defaultMessage: 'Hello!',
    description: 'Landing page header',
  },
  loginReportingHeader: {
    id: 'LandingPage.loginReportingHeader',
    defaultMessage:
      'There’s something bothering you, but you want your information to remain confidential?',
    description: 'Reporting misconduct header on login page',
  },
  reportMisconduct: {
    id: 'LandingPage.reportMisconduct',
    defaultMessage: 'Add new report',
    description: 'Report Misconduct button label',
  },
  checkMisconductStatus: {
    id: 'LandingPage.checkMisconductStatus',
    defaultMessage: 'Check updates for your report',
    description: 'Check misconduct report button label',
  },
  downloadInstructions: {
    id: 'LandingPage.downloadInstructions',
    defaultMessage: 'Get familiar with our reporting instructions',
    description: 'Download instruction link label',
  },
  goToLoginButton: {
    id: 'LandingPage.goToLoginButton',
    defaultMessage: 'Login for users with access rights',
    description: 'Login for users with access rights link label',
  },
  photoText: {
    id: 'LandingPage.photoText',
    defaultMessage:
      'Prevent further harm: Reporting misconduct can help to prevent future wrongdoing by drawing attention to issues that need to be addressed.',
    description: 'Photo text',
  },
});
