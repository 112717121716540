import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { STATUS_READY } from 'stores/tenantProperties';

import LoginBackground from 'assets/images/login-bg.jpg';

import MatchMedia from 'components/MatchMedia';

import { colors, deviceSizes } from 'styles';
import { LoginTemplate, PictureWrapper, LoginContent, TextWrapper } from './style';
import Footer from 'components/Footer';
import Typography from 'components/Typography';
import { FormattedMessage } from 'react-intl.macro';
import { messages } from 'containers/public/Auth/LandingPage/messages';

const propTypes = {
  imageUrl: PropTypes.string,
  children: PropTypes.any.isRequired,
  withFooter: PropTypes.bool,
};

const EntryWrapper = ({ children, withFooter }) => {
  const { status, properties } = useSelector((state) => state.tenantProperties);

  const imageUrl = properties.loginImageUrl || LoginBackground;

  return (
    <>
      <LoginTemplate>
        <LoginContent role="main">{children}</LoginContent>
        <MatchMedia minWidth={deviceSizes.smallScreen}>
          <PictureWrapper imageUrl={status === STATUS_READY ? imageUrl : null}>
            <TextWrapper>
              <Typography color={colors.white} size="huge">
                <FormattedMessage {...messages.photoText} />
              </Typography>
            </TextWrapper>
          </PictureWrapper>
        </MatchMedia>
      </LoginTemplate>
      {withFooter && <Footer />}
    </>
  );
};

EntryWrapper.propTypes = propTypes;

export default EntryWrapper;
