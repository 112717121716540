import React from 'react';
import PropTypes from 'prop-types';

import { IconWrapper, TileWrapper, TopSection } from './style';
import { colorName } from 'styles';
import Button from 'components/Button';
import SurveyArrowIcon from 'components/Svg/SurveyArrowIcon';
import Typography from 'components/Typography';

const defaultPropTypes = { backgroundColor: colorName.white };

const propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClick: PropTypes.func.isRequired,
  count: PropTypes.number,
  title: PropTypes.string.isRequired,
};

const Tile = ({ icon, onClick, count, title }) => {
  return (
    <TileWrapper>
      <TopSection>
        <IconWrapper>{icon}</IconWrapper>
        <Button secondary isIcon onClick={onClick} data-testid="go-back-button">
          <SurveyArrowIcon />
        </Button>
      </TopSection>
      <Typography size="gigantic">{count}</Typography>
      {title}
    </TileWrapper>
  );
};

Tile.defaultProps = defaultPropTypes;
Tile.propTypes = propTypes;

export default Tile;
