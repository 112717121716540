import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { getDateStr, getDateRangeStr } from 'helpers';
import { DATE_FORMAT_TRANSFER } from 'helpers/sharedConstants';

import CalendarInfo from './parts/CalendarInfo';
import CalendarNav from './parts/CalendarNav';
import SingleDatePicker from './parts/SingleDatePicker';
import RangeDatePicker from './parts/RangeDatePicker';

import CalendarIcon from 'components/Svg/CalendarIcon';

import {
  DatePickerWrapper,
  DatePickerComponent,
  RequiredMark,
  IconWrapper,
} from './style';
import { StyledLabel } from 'styles/commonStyles';
import { InputContentWrapper, StyledInput } from 'components/Input/style';
import Tooltip from 'components/Tooltip';

const defaultPropTypes = {
  maxWidth: 380,
  label: 'Misconduct Time Frame',
  placeholder: 'Select Time Frame',
  dateStrFormat: 'DD.MM.YYYY',
  required: true,
};

const propTypes = {
  isSingleDatePicker: PropTypes.bool,
  maxWidth: PropTypes.number,
  dateStrFormat: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  withoutClearAction: PropTypes.bool,
};

let uniqueId = 0;

const getStringValue = (value, format) => {
  return Array.isArray(value)
    ? getDateRangeStr(
        value.filter((date) => !!date),
        format
      )
    : getDateStr(value, format);
};

const getFormattedValue = (value, isSingleDate) => {
  return isSingleDate
    ? value.format(DATE_FORMAT_TRANSFER)
    : [
        value.startDate && value.startDate.format(DATE_FORMAT_TRANSFER),
        (value.endDate && value.endDate.format(DATE_FORMAT_TRANSFER)) || null,
      ];
};

const DatePicker = ({
  maxWidth,
  label,
  placeholder,
  onChange,
  required,
  isSingleDatePicker,
  dateStrFormat,
  initialValue,
  tooltip,
  withoutClearAction,
}) => {
  const DayPicker = isSingleDatePicker ? SingleDatePicker : RangeDatePicker;
  const [isDayPickerOpen, setDayPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const inputId = useMemo(() => `date-picker-${uniqueId++}`, []);
  const [hasError, setHasError] = useState(false);
  const [wasTouched, setWasTouched] = useState(false);

  const clearDatePicker = useCallback(() => {
    setInputValue('');
    setSelectedDate(null);
    onChange(isSingleDatePicker ? '' : ['', '']);
  }, [onChange, isSingleDatePicker]);

  const setInitialState = useCallback(() => {
    if (initialValue && Array.isArray(initialValue)) {
      if (initialValue[0] && initialValue[0].length > 0) {
        setSelectedDate({ startDate: moment(initialValue[0]) });
      }

      if (initialValue[1] && initialValue[0].length > 0) {
        setSelectedDate((prev) => ({ ...prev, endDate: moment(initialValue[1]) }));
      }

      setInputValue(getStringValue(initialValue, dateStrFormat));
    } else {
      setSelectedDate(initialValue ? moment(initialValue) : null);
      setInputValue(initialValue ? getStringValue(initialValue, dateStrFormat) : '');
    }
  }, [dateStrFormat, initialValue]);

  useEffect(() => {
    setInitialState();
  }, [setInitialState]);

  useEffect(() => {
    required && setHasError(!inputValue);
  }, [inputValue, required]);

  const onDateChange = useCallback(
    (value) => {
      setSelectedDate(value);
      const formattedValue = getFormattedValue(value, isSingleDatePicker);
      setInputValue(getStringValue(formattedValue, dateStrFormat));
      onChange(formattedValue);
    },
    [dateStrFormat, isSingleDatePicker, onChange]
  );

  const closeCalendar = useCallback(() => {
    inputRef.current.focus();
    inputRef.current.blur();
    setDayPickerOpen(false);
  }, []);

  const renderCalendarInfo = useCallback(
    () => (
      <CalendarInfo
        withoutClearAction={withoutClearAction}
        initialValue={initialValue}
        clearDatePicker={clearDatePicker}
        closeCalendar={closeCalendar}
      />
    ),
    [clearDatePicker, closeCalendar, initialValue, withoutClearAction]
  );

  const renderDayContents = useCallback(
    (day) => <span className="dayContent">{day.format('D')}</span>,
    []
  );

  const handleBlur = () => {
    setWasTouched(true);
  };

  return (
    <DatePickerComponent maxWidth={maxWidth}>
      {label && (
        <StyledLabel htmlFor={inputId}>
          {label}
          {required && <RequiredMark>*</RequiredMark>}
          {tooltip && <Tooltip id={inputId} content={tooltip} />}
        </StyledLabel>
      )}

      <DatePickerWrapper isDayPickerOpen={isDayPickerOpen}>
        <InputContentWrapper onClick={() => setDayPickerOpen(true)}>
          <StyledInput
            type="text"
            ref={inputRef && inputRef}
            id={inputId}
            value={inputValue}
            placeholder={placeholder}
            marginBottom={0}
            icon={CalendarIcon}
            readOnly
            required={required}
            onBlur={handleBlur}
            wasTouched={wasTouched}
            hasError={hasError}
          />

          <IconWrapper>
            <CalendarIcon />
          </IconWrapper>
        </InputContentWrapper>

        {isDayPickerOpen && (
          <DayPicker
            dateControllerConfig={{
              onOutsideClick: closeCalendar,
              navPrev: <CalendarNav />,
              navNext: <CalendarNav next />,
              numberOfMonths: 1,
              verticalBorderSpacing: 2,
              renderDayContents: renderDayContents,
              hideKeyboardShortcutsPanel: true,
              noBorder: true,
              transitionDuration: 0,
              renderCalendarInfo: renderCalendarInfo,
            }}
            selectedDate={selectedDate}
            onChange={onDateChange}
          />
        )}
      </DatePickerWrapper>
    </DatePickerComponent>
  );
};

DatePicker.defaultProps = defaultPropTypes;
DatePicker.propTypes = propTypes;

export default DatePicker;
