import { INTERVAL_ONE_DAY } from 'helpers/commonConstants';

// Actions
export const SET_LOADING = 'documentEdit.SET_LOADING_STATE';
export const SET_PROCESSING = 'documentEdit.SET_PROCESSING';
export const SET_TITLE = 'documentEdit.SET_TITLE';
export const SET_OWNER = 'documentEdit.SET_OWNER';
export const SET_DESCRIPTION = 'documentEdit.SET_DESCRIPTION';
export const STORE_FILES = 'documentEdit.STORE_FILES';
export const DELETE_FILE = 'documentEdit.DELETE_FILE';
export const STORE_DUE_DATE = 'documentEdit.STORE_DUE_DATE';
export const STORE_GROUPS = 'documentEdit.STORE_GROUPS';
export const TOGGLE_GROUPS_CONFIRMATION = 'documentEdit.TOGGLE_GROUPS_CONFIRMATION';
export const DELETE_GROUP = 'documentEdit.DELETE_GROUP';
export const STORE_USERS = 'documentEdit.STORE_USERS';
export const TOGGLE_USERS_CONFIRMATION = 'documentEdit.TOGGLE_USERS_CONFIRMATION';
export const DELETE_USER = 'documentEdit.DELETE_USER';
export const INITIALIZE_DATA_FROM_DOCUMENT = 'documentEdit.INITIALIZE_DATA_FROM_DOCUMENT';
export const CLEAR_DATA = 'documentEdit.CLEAR_DATA';
export const SET_ALERT = 'documentEdit.SET_ALERT';
export const UNSET_ALERT = 'documentEdit.UNSET_ALERT';
export const SET_ALERT_INTERVAL = 'documentEdit.SET_ALERT_INTERVAL';
export const SET_ALERT_MESSAGE = 'documentEdit.SET_ALERT_MESSAGE';

// Reducer
const initialState = {
  isLoading: true,
  title: '',
  owner: [],
  description: '',
  dueDate: '',
  alerts: [
    {
      active: true,
      type: INTERVAL_ONE_DAY,
      message: '',
    },
  ],
  groups: [],
  users: [],
  files: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_TITLE:
      return {
        ...state,
        title: payload,
      };
    case SET_OWNER:
      return {
        ...state,
        owner: payload,
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        description: payload,
      };
    case STORE_FILES:
      return {
        ...state,
        files: [...state.files, payload],
      };
    case DELETE_FILE:
      return {
        ...state,
        files: state.files.filter((item) => item.id !== payload),
      };
    case STORE_DUE_DATE:
      return {
        ...state,
        dueDate: payload,
      };
    case STORE_GROUPS:
      return {
        ...state,
        groups: [...state.groups, payload],
      };
    case TOGGLE_GROUPS_CONFIRMATION:
      return {
        ...state,
        groups: state.groups.map((item) =>
          item.id === payload.id
            ? { ...item, confirmation_required: payload.required }
            : item
        ),
      };
    case DELETE_GROUP:
      return {
        ...state,
        groups: state.groups.filter((item) => item.id !== payload),
      };
    case STORE_USERS:
      return {
        ...state,
        users: [...state.users, payload],
      };
    case TOGGLE_USERS_CONFIRMATION:
      return {
        ...state,
        users: state.users.map((item) =>
          item.id === payload.id
            ? { ...item, confirmation_required: payload.required }
            : item
        ),
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((item) => item.id !== payload),
      };
    case SET_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            ...state.alerts[payload],
            active: true,
            type: INTERVAL_ONE_DAY,
            message: '',
          },
        ],
      };
    case UNSET_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter((item, index) => index < payload),
      };
    case SET_ALERT_MESSAGE:
      return {
        ...state,
        alerts: state.alerts.map((alert, index) => {
          if (index === payload.id) {
            return { ...alert, message: payload.value };
          }

          return alert;
        }),
      };
    case SET_ALERT_INTERVAL:
      return {
        ...state,
        alerts: state.alerts.map((alert, index) => {
          if (index === payload.id) {
            return { ...alert, type: payload.value };
          }

          return alert;
        }),
      };
    case INITIALIZE_DATA_FROM_DOCUMENT:
      return {
        ...initialState,
        title: payload.name,
        owner: payload.owner,
        description: payload.description,
        dueDate: payload.due_date,
        alerts: payload.alerts,
        groups: payload.groups,
        users: payload.users,
        files: payload.files,
      };
    case CLEAR_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload });
export const setDocumentTitle = (payload) => ({ type: SET_TITLE, payload });
export const setDocumentOwner = (payload) => ({ type: SET_OWNER, payload });
export const setDocumentDescription = (payload) => ({ type: SET_DESCRIPTION, payload });
export const storeDueDate = (payload) => ({ type: STORE_DUE_DATE, payload });
export const storeGroups = (payload) => ({ type: STORE_GROUPS, payload });
export const toggleGroupConfirmation = (payload) => ({
  type: TOGGLE_GROUPS_CONFIRMATION,
  payload,
});
export const toggleUserConfirmation = (payload) => ({
  type: TOGGLE_USERS_CONFIRMATION,
  payload,
});
export const deleteGroup = (payload) => ({ type: DELETE_GROUP, payload });
export const storeUsers = (payload) => ({ type: STORE_USERS, payload });
export const deleteUser = (payload) => ({ type: DELETE_USER, payload });
export const storeFiles = (payload) => ({ type: STORE_FILES, payload });
export const deleteFile = (payload) => ({ type: DELETE_FILE, payload });
export const setAlert = (payload) => ({ type: SET_ALERT, payload });
export const unsetAlert = (payload) => ({ type: UNSET_ALERT, payload });
export const setAlertMessage = ({ id, value }) => ({
  type: SET_ALERT_MESSAGE,
  payload: { id, value },
});
export const setAlertInterval = ({ id, value }) => ({
  type: SET_ALERT_INTERVAL,
  payload: { id, value },
});
export const initializeDataFromDocument = (payload) => ({
  type: INITIALIZE_DATA_FROM_DOCUMENT,
  payload,
});
export const clearState = (payload) => ({ type: CLEAR_DATA, payload });
