import { globalMessages } from 'translations';
import {
  SUPPORTED_LOCALES,
  LANG_EN,
  LANG_ES,
  LANG_PL,
  LANG_DE,
  LANG_EL,
  LANG_BG,
  LANG_CS,
  LANG_HR,
  LANG_HU,
  LANG_RO,
  LANG_RU,
  LANG_SL,
  LANG_SR,
  LANG_TR,
  LANG_IT,
  LANG_UK,
  LANG_PT,
  LANG_FR,
  LANG_SV,
  LANG_SK,
  LANG_FI,
  LANG_NL,
  LANG_AR,
  LANG_ET,
} from 'helpers/sharedConstants';

export const INTERVAL_ONE_DAY = 'one_day';
export const INTERVAL_TWO_DAYS = 'two_days';
export const INTERVAL_THREE_DAYS = 'three_days';
export const INTERVAL_FOUR_DAYS = 'four_days';
export const INTERVAL_FIVE_DAYS = 'five_days';
export const INTERVAL_SIX_DAYS = 'six_days';
export const INTERVAL_SEVEN_DAYS = 'seven_days';
export const INTERVAL_TWO_WEEKS = 'two_weeks';
export const INTERVAL_THREE_WEEKS = 'three_weeks';
export const INTERVAL_FOUR_WEEKS = 'four_weeks';
export const INTERVAL_TWO_MONTHS = 'two_months';
export const INTERVAL_THREE_MONTHS = 'three_months';

export const dailyIntervals = [
  {
    name: globalMessages.oneDay,
    value: INTERVAL_ONE_DAY,
  },
  {
    name: globalMessages.twoDays,
    value: INTERVAL_TWO_DAYS,
  },
  {
    name: globalMessages.threeDays,
    value: INTERVAL_THREE_DAYS,
  },
  {
    name: globalMessages.fourDays,
    value: INTERVAL_FOUR_DAYS,
  },
  {
    name: globalMessages.fiveDays,
    value: INTERVAL_FIVE_DAYS,
  },
  {
    name: globalMessages.sixDays,
    value: INTERVAL_SIX_DAYS,
  },
  {
    name: globalMessages.sevenDays,
    value: INTERVAL_SEVEN_DAYS,
  },
];

export const weeklyIntervals = [
  {
    name: globalMessages.twoWeeks,
    value: INTERVAL_TWO_WEEKS,
  },
  {
    name: globalMessages.threeWeeks,
    value: INTERVAL_THREE_WEEKS,
  },
  {
    name: globalMessages.fourWeeks,
    value: INTERVAL_FOUR_WEEKS,
  },
];

export const monthlyIntervals = [
  {
    name: globalMessages.twoMonths,
    value: INTERVAL_TWO_MONTHS,
  },
  {
    name: globalMessages.threeMonths,
    value: INTERVAL_THREE_MONTHS,
  },
];

export const sortKeys = {
  NAME: 'name',
  CREATED_AT: 'created_at',
  DOCS_RECEIVED: 'documents_received',
  DOCS_OVERDUE: 'documents_overdue',
  STATUS: 'status',
};

export const userStatus = {
  ACTIVE: 'active',
  INVITED: 'invited',
  INACTIVE: 'archived',
};

export const statisticTypes = {
  AFFIRMATIONS: 'affirmations',
  MISCONDUCTS: 'misconducts',
};

export const langCodes = {
  en: LANG_EN,
  es: LANG_ES,
  pl: LANG_PL,
  de: LANG_DE,
  el: LANG_EL,
  bg: LANG_BG,
  cs: LANG_CS,
  hr: LANG_HR,
  hu: LANG_HU,
  ro: LANG_RO,
  ru: LANG_RU,
  sl: LANG_SL,
  sr: LANG_SR,
  tr: LANG_TR,
  it: LANG_IT,
  uk: LANG_UK,
  pt: LANG_PT,
  fr: LANG_FR,
  sv: LANG_SV,
  sk: LANG_SK,
  fi: LANG_FI,
  nl: LANG_NL,
  ar: LANG_AR,
  et: LANG_ET,
};

export const langPhrases = {
  [langCodes.en]: 'English',
  [langCodes.es]: 'Español',
  [langCodes.pl]: 'Polski',
  [langCodes.de]: 'Deutsch',
  [langCodes.el]: 'Ελληνικά',
  [langCodes.bg]: 'български',
  [langCodes.cs]: 'Čeština',
  [langCodes.hr]: 'Hrvatski',
  [langCodes.hu]: 'Magyar',
  [langCodes.ro]: 'Română',
  [langCodes.ru]: 'Pусский',
  [langCodes.sl]: 'Slovenščina',
  [langCodes.sr]: 'Srpski',
  [langCodes.tr]: 'Türkçe',
  [langCodes.it]: 'Italiano',
  [langCodes.uk]: 'Yкраїнський',
  [langCodes.pt]: 'Português',
  [langCodes.fr]: 'Français',
  [langCodes.sv]: 'Svenska',
  [langCodes.sk]: 'Slovenský',
  [langCodes.fi]: 'Suomi',
  [langCodes.nl]: 'Nederlands',
  [langCodes.ar]: 'العربية',
  [langCodes.et]: 'Eesti',
};

export const langFlags = {
  [langCodes.en]: '🇬🇧',
  [langCodes.es]: '🇪🇸',
  [langCodes.pl]: '🇵🇱',
  [langCodes.de]: '🇩🇪',
  [langCodes.el]: '🇬🇷',
  [langCodes.bg]: '🇧🇬',
  [langCodes.cs]: '🇨🇿',
  [langCodes.hr]: '🇭🇷',
  [langCodes.hu]: '🇭🇺',
  [langCodes.ro]: '🇷🇴',
  [langCodes.ru]: '🇷🇺',
  [langCodes.sl]: '🇸🇮',
  [langCodes.sr]: '🇷🇸',
  [langCodes.tr]: '🇹🇷',
  [langCodes.it]: '🇮🇹',
  [langCodes.uk]: '🇺🇦',
  [langCodes.pt]: '🇵🇹',
  [langCodes.fr]: '🇫🇷',
  [langCodes.sv]: '🇸🇪',
  [langCodes.sk]: '🇸🇰',
  [langCodes.fi]: '🇫🇮',
  [langCodes.nl]: '🇳🇱',
  [langCodes.ar]: '🇦🇪',
  [langCodes.et]: '🇪🇪',
};

export const appLanguagesSelectData = SUPPORTED_LOCALES.map((language) => {
  return {
    name: langFlags[language] + '\xa0\xa0' + langPhrases[language],
    value: langCodes[language],
  };
});
