import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';

import { IconWrapper, PillWrapper, StyledButton } from './style';

const buttonTypes = ['button', 'submit', 'reset'];
const defaultPropTypes = { component: 'button', type: 'button' };

const propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  transparent: PropTypes.bool,
  disabled: PropTypes.bool,
  isDark: PropTypes.bool,
  isDelete: PropTypes.bool,
  isUtility: PropTypes.bool,
  isFluid: PropTypes.bool,
  isSmall: PropTypes.bool,
  isIcon: PropTypes.bool,
  isProcessing: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  pillContent: PropTypes.node,
  children: PropTypes.node,
  maxWidth: PropTypes.number,
  component: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(buttonTypes),
};

const Button = ({
  component,
  isProcessing,
  isSmall,
  isIcon,
  disabled,
  leftIcon,
  rightIcon,
  pillContent,
  children,
  onClick,
  type,
  ...props
}) => {
  return (
    <StyledButton
      as={component}
      type={component === 'button' && type}
      isSmall={isSmall}
      disabled={disabled || isProcessing}
      isProcessing={isProcessing}
      pill={pillContent}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      isIcon={isIcon}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
      {...props}
    >
      {isProcessing ? (
        <Spinner size={isSmall ? 12 : 24} color="currentColor" />
      ) : (
        <>
          {leftIcon && <IconWrapper>{leftIcon}</IconWrapper>}

          <span>{children}</span>

          {pillContent && <PillWrapper>{pillContent}</PillWrapper>}

          {rightIcon && <IconWrapper>{rightIcon}</IconWrapper>}
        </>
      )}
    </StyledButton>
  );
};

Button.defaultProps = defaultPropTypes;
Button.propTypes = propTypes;

export default Button;
