import React from 'react';

function DownloadIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 16" {...props}>
      <title>Download file</title>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11 0C7.612 0 4.771 2.255 4.298 5.26 1.848 5.947 0 7.995 0 10.5 0 13.59 2.786 16 6.1 16h10.5c2.928 0 5.4-2.13 5.4-4.875 0-2.365-1.845-4.254-4.214-4.735a2.7 2.7 0 00.014-.265C17.8 2.691 14.7 0 11 0zM5.25 5.709C5.49 3.119 7.915 1 11 1c3.258 0 5.8 2.347 5.8 5.125 0 .134-.018.25-.041.404l-.031.21a.5.5 0 00.438.564c2.209.253 3.834 1.9 3.834 3.822C21 13.213 19.085 15 16.6 15H6.1C3.229 15 1 12.933 1 10.5c0-2.066 1.606-3.849 3.86-4.35a.5.5 0 00.39-.441zM11 6a.5.5 0 01.5.5v4.991l1.5-1.345a.5.5 0 11.707.708L11.354 13a.5.5 0 01-.707 0l-2.354-2.146A.5.5 0 019 10.146l1.5 1.345V6.5A.5.5 0 0111 6z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default DownloadIcon;
