import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { node } from 'prop-types';

const propTypes = {
  children: node.isRequired,
};

export const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

ScrollToTop.propTypes = propTypes;
