import { useState, useEffect, useContext, useRef } from 'react';

import { RecaptchaConfigContext } from './RecaptchaConfigProvider';

export const useRecaptcha = (language) => {
  const recaptchaScriptId = useRef(`recaptcha-script-${Date.now()}`);

  let resolveGrecaptchaPromise;
  let rejectGrecaptchaPromise;

  const { siteKey, recaptchaDisabled } = useContext(RecaptchaConfigContext);

  const getRecaptchaURL = (siteKey, language) => {
    return `https://www.google.com/recaptcha/api.js?render=${siteKey}${
      language ? `&hl=${language}` : ''
    }`;
  };

  const [grecaptchaPromise] = useState(
    new Promise((resolve, reject) => {
      resolveGrecaptchaPromise = resolve;
      rejectGrecaptchaPromise = reject;
    })
  );

  const createScriptElement = (
    siteKey,
    resolveGrecaptchaPromise,
    rejectGrecaptchaPromise
  ) => {
    const newScriptElement = document.createElement('script');
    newScriptElement.id = recaptchaScriptId.current;

    newScriptElement.onload = () => {
      if (!window.grecaptcha) {
        rejectGrecaptchaPromise();
      }
      window.grecaptcha.ready(() => {
        return resolveGrecaptchaPromise(window.grecaptcha);
      });
    };
    document.head.appendChild(newScriptElement);
  };

  const cleanup = () => {
    const nodeBadge = document.querySelector('.grecaptcha-badge');

    if (nodeBadge && nodeBadge.parentNode) {
      document.body.removeChild(nodeBadge.parentNode);
    }
    const script = document.getElementById(recaptchaScriptId.current);

    if (script) {
      recaptchaScriptId.current = null;
      script.remove();
    }
  };

  useEffect(() => {
    if (!recaptchaDisabled) {
      if (!document.getElementById(recaptchaScriptId.current)) {
        createScriptElement(siteKey, resolveGrecaptchaPromise, rejectGrecaptchaPromise);
      }
      document.getElementById(recaptchaScriptId.current).src = getRecaptchaURL(
        siteKey,
        language
      );
    } else {
      resolveGrecaptchaPromise({ execute: () => Promise.resolve(null) });
    }
  }, [
    recaptchaDisabled,
    siteKey,
    language,
    resolveGrecaptchaPromise,
    rejectGrecaptchaPromise,
  ]);

  useEffect(() => cleanup, []);

  return async () => {
    return grecaptchaPromise.then((grecaptcha) => {
      return grecaptcha.execute(siteKey);
    });
  };
};
