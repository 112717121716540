import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { colorName, colors, font, transitions } from 'styles';

const INPUT_HEIGHT = 36;
const ICON_SIZE = 18;

export const loginInputStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${rem(INPUT_HEIGHT)};
  padding: 0 ${rem(8)} 0 ${rem(32)};
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid
    ${({ invalid }) => (invalid ? colorName.error : colors.grayish)};
  transition: border ${transitions.uiTiming} ${transitions.spring};

  &:focus,
  &:hover,
  &:invalid {
    outline: 0;
    box-shadow: none;
  }

  &:focus,
  &:hover {
    border-bottom: 1px solid ${colorName.secondary};
  }
`;

export const InputMainWrapper = styled.div`
  margin: 0 0 ${({ bottomMargin }) => (bottomMargin ? rem(bottomMargin) : rem(24))};
`;

export const LoginLabel = styled.label`
  display: block;
  margin: 0 0 ${rem(4)};
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.small)};
`;

export const LoginInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: ${rem(4)};
  width: ${rem(ICON_SIZE)};
  height: ${rem(ICON_SIZE)};
  transform: translateY(-50%);
`;

export const StyledLoginInput = styled.input`
  ${loginInputStyles};
`;
