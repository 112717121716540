import React from 'react';

const InfoIcon = (props) => {
  return (
    <svg width={21} height={21} {...props} viewBox="0 0 21 21">
      <path
        d="M10.5.083C4.75.083.083 4.75.083 10.5S4.75 20.917 10.5 20.917 20.917 16.25 20.917 10.5 16.25.083 10.5.083zm1.042 15.625H9.458v-6.25h2.084v6.25zm0-8.333H9.458V5.292h2.084v2.083z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default InfoIcon;
