import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledMenu,
  StyledMenuButtonWrapper,
  StyledMenuDecorator,
} from 'components/AutocompleteSelect/style';

const propTypes = {
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  innerRef: PropTypes.func.isRequired,
  selectProps: PropTypes.shape({
    isDark: PropTypes.bool,
    menuButton: PropTypes.node,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    inputValue: PropTypes.string,
  }),
};

export const SimpleMenu = ({
  children,
  innerRef,
  innerProps,
  selectProps: { isDark, icon, menuButton: MenuButton },
}) => {
  return (
    <StyledMenu {...innerProps} ref={innerRef} isDark={isDark}>
      <StyledMenuDecorator icon={icon} />
      {children}

      {MenuButton && (
        <StyledMenuButtonWrapper isDark={isDark}>{MenuButton}</StyledMenuButtonWrapper>
      )}
    </StyledMenu>
  );
};

SimpleMenu.propTypes = propTypes;
