import React from 'react';

const LockIcon = (props) => (
  <svg fill="none" viewBox="0 0 18 18" {...props}>
    <title>Password</title>
    <path
      d="M15.188 17.438H2.812a1.125 1.125 0 01-1.124-1.125V8.438c0-.622.504-1.126 1.125-1.126h12.374c.622 0 1.126.504 1.126 1.125v7.876c0 .62-.504 1.125-1.125 1.125z"
      stroke="#A2A2A8"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.063 7.313V4.5a3.938 3.938 0 017.875 0v2.813M9 14.063a1.687 1.687 0 100-3.375 1.687 1.687 0 000 3.374z"
      stroke="#A2A2A8"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LockIcon;
