import React from 'react';

function ErrorIcon(props) {
  return (
    <svg viewBox="0 0 60 60" fill="none" {...props}>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M29.875 58.75c15.947 0 28.875-12.928 28.875-28.875S45.822 1 29.875 1 1 13.928 1 29.875 13.928 58.75 29.875 58.75z"
      />
      <rect
        width={30.281}
        height={2}
        fill="#000"
        rx={1}
        transform="scale(-1 1) rotate(45 -42.935 -38.784)"
      />
      <rect
        width={30.281}
        height={2}
        fill="#000"
        rx={1}
        transform="matrix(.7071 .7071 .7071 -.7071 19 20)"
      />
    </svg>
  );
}

export default ErrorIcon;
