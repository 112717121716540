import React from 'react';

const TicketIcon = (props) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path
      fill="none"
      stroke="#A2A2A8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M5.5 5.5h5M5.5 10.5h5M13.5 8.5c0-1.1.9-2 2-2v-4c0-.6-.4-1-1-1h-13c-.6 0-1 .4-1 1V6c1.1 0 2 .9 2 2s-.9 2-2 2v3.5c0 .6.4 1 1 1h13c.6 0 1-.4 1-1v-3c-1.1 0-2-.9-2-2z"
    />
  </svg>
);

export default TicketIcon;
