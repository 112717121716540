import styled from 'styled-components';
import { rem } from 'polished';
import { globalSize, colorName, breakpoints, colors } from 'styles';

const PADDING = globalSize.defaultPadding;

export const PageTemplate = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-bottom: ${({ isThereBottomComponent }) =>
    isThereBottomComponent ? rem(globalSize.desktopMenuSize) : 0};

  @media ${breakpoints.smallScreen} {
    flex-direction: row;
    margin-bottom: 0;
  }

  @media ${breakpoints.mediumScreen} {
    min-height: 100vh;
  }
`;

export const ContentColumn = styled.div`
  position: relative;
  flex: 1;
  padding: ${rem(PADDING * 1.5)} ${rem(PADDING)};
  background: ${({ isWhiteBackground }) =>
    isWhiteBackground ? colorName.white : colorName.mainBackground};

  @media ${breakpoints.tablet} {
    justify-content: center;
    padding: ${rem(PADDING * 2)} ${rem(PADDING * 3)} ${rem(32)};
  }

  @media ${breakpoints.smallScreen} {
    padding: 0;
    background-color: ${colors.athensGray};
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  background: ${({ isWhiteBackground }) =>
    isWhiteBackground ? colorName.white : colorName.mainBackground};

  @media ${breakpoints.smallScreen} {
    height: 100%;
    margin: auto;
    padding: ${rem(PADDING * 2.5)} ${rem(PADDING * 3)} ${rem(32)};
  }

  @media ${breakpoints.mediumScreen} {
    max-width: ${rem(globalSize.leftColumnContentWidth)};
  }
`;

export const SideColumn = styled.div`
  display: ${({ hideColumnComponentOnMobile }) => hideColumnComponentOnMobile && 'none'};
  padding: ${rem(PADDING * 2)} ${rem(PADDING)};
  color: ${colorName.white};
  background: ${colorName.sidebarBackground};

  @media ${breakpoints.tablet} {
    display: block;
  }

  @media ${breakpoints.smallScreen} {
    position: initial;
    bottom: auto;
    z-index: 0;
    flex: 1 1 auto;
    width: auto;
    max-width: ${rem(globalSize.rightColumnContentWidth)};
    padding: ${rem(PADDING * 3)} ${rem(PADDING)} 0;
    background: ${colorName.sidebarBackground};
    box-shadow: 100vh 0 0 100vh ${colorName.sidebarBackground};
  }

  @media ${breakpoints.mediumScreen} {
    max-width: ${rem(globalSize.rightColumnContentWidthWider)};
    padding: ${rem(PADDING * 3.25)}
      ${({ smallPadding }) => (smallPadding ? rem(PADDING * 2) : rem(PADDING * 4))} 0;
  }
`;

export const SideContainer = styled.div`
  @media ${breakpoints.smallScreen} {
    height: 100%;
  }
`;
