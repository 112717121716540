import React from 'react';

const AttentionIcon = (props) => {
  return (
    <svg viewBox="0 0 21 21" {...props}>
      <path
        d="M10.5.917C5.21.917.917 5.21.917 10.5s4.293 9.583 9.583 9.583 9.583-4.293 9.583-9.583S15.79.917 10.5.917zm.958 14.375H9.542v-1.917h1.916v1.917zm0-3.834H9.542v-5.75h1.916v5.75z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default AttentionIcon;
