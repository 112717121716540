import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import SplashScreen from 'components/SplashScreen';
import ErrorIcon from 'components/Svg/ErrorIcon';

import { ErrorWrapper, Logo } from './style';
import { messages } from './messages';

const propTypes = {
  children: PropTypes.any,
  intl: PropTypes.object,
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      const { intl } = this.props;

      return (
        <ErrorWrapper>
          <a href="/">
            <Logo />
          </a>

          <SplashScreen
            title={intl.formatMessage(messages.errorTitle)}
            subtitle={intl.formatMessage(messages.errorSubtitle)}
            icon={ErrorIcon}
          />
        </ErrorWrapper>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = propTypes;

export default injectIntl(ErrorBoundary);
