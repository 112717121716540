import Typography from 'components/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { messages } from './messages';

const ReportSteps = () => {
  const intl = useIntl();
  const { currentStep, stepsCount } = useSelector((state) => state.misconductForm);

  return (
    <Typography size="small" data-testid="report-steps">
      {stepsCount
        ? intl.formatMessage(messages.stepNumberWithMax, {
            step: currentStep,
            maxSteps: stepsCount,
          })
        : intl.formatMessage(messages.stepNumber, {
            step: currentStep,
          })}
    </Typography>
  );
};

export default ReportSteps;
