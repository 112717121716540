import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { rem } from 'polished';

import { colorName, colors, font, breakpoints } from 'styles';

export const InputGroup = styled.div`
  margin: 0 0 ${rem(12)};
`;

export const ForgotPasswordLink = styled(Link)`
  float: right;
  margin: 0 0 ${rem(32)};
  color: ${colorName.link};
  font-weight: ${font.weight.bold};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 80%;
  margin: auto;
  padding: ${rem(16)} 0 ${rem(32)} 0;
  line-height: 1.6;

  @media ${breakpoints.mediumScreen} {
    margin: 0 auto auto;
  }
`;

export const ToggleLoginButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: ${rem(8)};
  font-size: ${rem(12)};
  border: 1px solid transparent;
  border-radius: ${rem(8)};
  cursor: pointer;

  ${({ expanded }) =>
    expanded &&
    `
    margin-bottom: ${rem(16)};
  `};

  &:hover {
    border: 1px solid ${colors.grayish};
    transition: border 0.2s ease-in;
  }

  @media ${breakpoints.tablet} {
    max-width: 75%;
  }
`;

export const ToggleArrow = styled.div`
  width: 0;
  height: 0;
  margin: 0 0 0 ${rem(4)};
  border-color: ${colorName.primary} transparent transparent transparent;
  border-style: solid;
  border-width: ${rem(20)} ${rem(12)} 0 ${rem(12)};
`;

export const ToggleLabel = styled.span`
  display: block;
  width: 80%;
  text-align: center;
`;
