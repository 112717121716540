import api from 'api';
import { history } from 'helpers/appHistory';
import { resetGroupDetails } from './groupDetails';

// Actions
export const ADD_USER = 'groupUsers.ADD_USER';
export const DELETE_USER = 'groupUsers.DELETE_USER';
export const RESET_USERS = 'groupUsers.RESET_USERS';
export const SET_LOADING = 'groupUsers.SET_LOADING';

// Reducer
const initialState = {
  addedUsers: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_USER:
      return {
        ...state,
        addedUsers: [...state.addedUsers, action.payload],
      };
    case DELETE_USER:
      return {
        ...state,
        addedUsers: state.addedUsers.filter((m) => m.value !== action.payload),
      };
    case RESET_USERS:
      return initialState;
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function addNewUser(user) {
  return { type: ADD_USER, payload: user };
}

export function deleteNewUser(id) {
  return { type: DELETE_USER, payload: id };
}

export function resetUsers() {
  return { type: RESET_USERS };
}

export function setLoading(isLoading) {
  return { type: SET_LOADING, payload: isLoading };
}

// Side Effects
export function submitUsers(id, addedUsers) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return api.groups
      .addUsers(id, addedUsers)
      .then(() => {
        dispatch(resetUsers());
        dispatch(resetGroupDetails());
        history.goBack();
      })
      .catch(() => {
        dispatch(resetUsers());
        dispatch(setLoading(false));
        //@todo handle error
      });
  };
}

export function addUser(user) {
  return (dispatch) => {
    dispatch(addNewUser(user));
  };
}

export function deleteUser({ id }) {
  return (dispatch) => {
    dispatch(deleteNewUser(id));
  };
}
