import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isExpiredPassword } from 'api';

import { isPublicRoute } from 'routing';

import { getMyDetails } from 'stores/auth';
import { clearTemporaryFiles, clearMisconductDetails } from 'stores/reportDetails';
import { clearState } from 'stores/anonymousMisconductForm';
import { clearDocumentLogs } from '../../stores/documentLogs';

const RouteListener = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    let prevLocation;

    history.listen((location) => {
      const isPublicPage = isPublicRoute(location.pathname);

      if (prevLocation !== location && !isPublicPage) {
        isAuthenticated && !isExpiredPassword && dispatch(getMyDetails());
        dispatch(clearTemporaryFiles());
        dispatch(clearMisconductDetails());
        dispatch(clearDocumentLogs());
        dispatch(clearState());
      }

      prevLocation = location;
    });
  }, [isAuthenticated, dispatch, history]);

  return null;
};

export default RouteListener;
