import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import CloseIcon from 'components/Svg/CloseIcon';

import { CustomModal, customModalStyles, CloseButton } from './style';
import { globalMessages } from 'translations';

const propTypes = {
  isOpen: PropTypes.bool,
  ariaHideApp: PropTypes.bool,
  isNarrowContent: PropTypes.bool,
  onRequestClose: PropTypes.func,
  children: PropTypes.node,
  intl: PropTypes.object,
  autoHeight: PropTypes.bool,
  disableBackClick: PropTypes.bool,
  isFullHeight: PropTypes.bool,
};

// setting ariaHideApp=false only in the testing env where getElementById('root')
// sought by setAppElement cannot be resolved
const defaultProps = {
  ariaHideApp: process.env.NODE_ENV !== 'test',
};

CustomModal.setAppElement(document.getElementById('root'));

const Modal = ({
  isOpen,
  onRequestClose,
  children,
  intl,
  ariaHideApp,
  autoHeight,
  disableBackClick,
  isFullHeight,
}) => {
  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={!disableBackClick ? onRequestClose : () => {}}
      ariaHideApp={ariaHideApp}
      portalClassName="ModalPortal"
      overlayClassName="ModalOverlay"
      className="ModalContent"
      bodyOpenClassName="Modal--open"
      shouldCloseOnEsc
      autoHeight={autoHeight}
      isFullHeight={isFullHeight}
      style={customModalStyles}
    >
      <CloseButton
        aria-label={intl.formatMessage(globalMessages.close)}
        tabIndex={1}
        onClick={onRequestClose}
      >
        <CloseIcon />
      </CloseButton>

      {children}
    </CustomModal>
  );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default injectIntl(Modal);
export { StandardModalContent } from './style';
