import React from 'react';
import { useSelector } from 'react-redux';

import PoweredByLogo from 'assets/images/eylogo.svg';

import { colors } from 'styles';
import {
  FooterWrapper,
  LeftContent,
  PoweredLogotype,
  RightContent,
  StyledLink,
} from './style';
import Typography from 'components/Typography';
import { FormattedMessage } from 'react-intl.macro';
import { globalMessages } from 'translations';

const Footer = () => {
  const {
    properties: {
      misconductInstructionsFile,
      misconductInstructionsLabel,
      impressumFile,
      impressumLabel,
      poweredBy,
    },
  } = useSelector((state) => state.tenantProperties);
  return (
    <FooterWrapper>
      <LeftContent>
        {poweredBy && <PoweredLogotype src={PoweredByLogo} alt="Powered by EY" />}
        <Typography size="small" color={colors.athensGray}>
          <FormattedMessage {...globalMessages.footerText} />
        </Typography>
      </LeftContent>
      <RightContent>
        {impressumFile && (
          <StyledLink href={impressumFile} download target="_blank">
            <Typography
              size="normal"
              weight="bold"
              color={colors.white}
              maxWidth={280}
              bottomMargin={12}
            >
              {impressumLabel}
            </Typography>
          </StyledLink>
        )}
        {misconductInstructionsFile && (
          <StyledLink href={misconductInstructionsFile} download target="_blank">
            <Typography
              size="normal"
              weight="bold"
              color={colors.white}
              maxWidth={280}
              // bottomMargin={12}
            >
              {misconductInstructionsLabel}
            </Typography>
          </StyledLink>
        )}
      </RightContent>
    </FooterWrapper>
  );
};

export default Footer;
