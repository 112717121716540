import React from 'react';
import PropTypes from 'prop-types';

import ToastMessages from 'components/ToastMessages';
import {
  PageTemplate,
  ContentColumn,
  ContentContainer,
  SideColumn,
  SideContainer,
} from './style';

const propTypes = {
  isWhiteBackground: PropTypes.bool,
  children: PropTypes.any.isRequired,
  columnContentSmallPadding: PropTypes.bool,
  columnComponent: PropTypes.node.isRequired,
  bottomComponent: PropTypes.node,
  withNotifications: PropTypes.bool,
  hideColumnComponentOnMobile: PropTypes.bool,
};

const defaultProps = {
  withNotifications: true,
};

const DoubleColumnLayout = ({
  children,
  columnContentSmallPadding,
  columnComponent,
  bottomComponent,
  isWhiteBackground,
  withNotifications,
  hideColumnComponentOnMobile,
}) => (
  <PageTemplate isThereBottomComponent={!!bottomComponent}>
    <ContentColumn isWhiteBackground={isWhiteBackground}>
      <ContentContainer isWhiteBackground={isWhiteBackground} role="main">
        {withNotifications && <ToastMessages />}
        {children}
      </ContentContainer>
      {bottomComponent}
    </ContentColumn>

    <SideColumn
      smallPadding={columnContentSmallPadding}
      hideColumnComponentOnMobile={hideColumnComponentOnMobile}
    >
      <SideContainer role="aside">{columnComponent}</SideContainer>
    </SideColumn>
  </PageTemplate>
);

DoubleColumnLayout.propTypes = propTypes;
DoubleColumnLayout.defaultProps = defaultProps;

export default DoubleColumnLayout;
