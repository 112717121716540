import React from 'react';

const DocumentAffirmedIcon = (props) => (
  <svg viewBox="0 0 24 24" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 8.625H15.5"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M6.75 12.375H15.5"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M6.75 16.125H10.5"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M11.75 21.75H5C3.89543 21.75 3 20.8546 3 19.75V5C3 3.89543 3.89543 3 5 3H17.25C18.3546 3 19.25 3.89543 19.25 5V13"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M14.1021 18.3663L17.0499 21.2996L22.6045 15.745"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

export default DocumentAffirmedIcon;
