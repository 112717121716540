import { reportMessages } from 'translations';

export const UNASSIGNED = 'unassigned';
export const LOW = 'low';
export const MEDIUM = 'medium';
export const HIGH = 'high';

export const priorities = [
  {
    name: reportMessages[LOW],
    value: LOW,
  },
  {
    name: reportMessages[MEDIUM],
    value: MEDIUM,
  },
  {
    name: reportMessages[HIGH],
    value: HIGH,
  },
];

export const SENT = 'sent';
export const OPEN = 'open';
export const IN_PROGRESS = 'in_progress';
export const CLOSED = 'closed';
export const CLOSED_APPROVED = 'closed_approved';
export const CLOSED_NOT_APPROVED = 'closed_not_approved';
export const CLOSED_PARTIALLY_APPROVED = 'closed_partially_approved';
export const CLOSED_IRRELEVANT = 'closed_irrelevant';

export const whistleblowerVisibleStatuses = [
  {
    name: reportMessages[SENT],
    value: SENT,
  },
  {
    name: reportMessages[OPEN],
    value: OPEN,
  },
  {
    name: reportMessages[IN_PROGRESS],
    value: IN_PROGRESS,
  },
  {
    name: reportMessages[CLOSED],
    value: CLOSED,
  },
];

export const officerAvailableStatuses = [
  {
    name: reportMessages[SENT],
    value: SENT,
  },
  {
    name: reportMessages[OPEN],
    value: OPEN,
  },
  {
    name: reportMessages[IN_PROGRESS],
    value: IN_PROGRESS,
  },
  {
    name: reportMessages[CLOSED_APPROVED],
    value: CLOSED_APPROVED,
  },
  {
    name: reportMessages[CLOSED_NOT_APPROVED],
    value: CLOSED_NOT_APPROVED,
  },
  {
    name: reportMessages[CLOSED_PARTIALLY_APPROVED],
    value: CLOSED_PARTIALLY_APPROVED,
  },
  {
    name: reportMessages[CLOSED_IRRELEVANT],
    value: CLOSED_IRRELEVANT,
  },
];

export const closedStatuses = [
  CLOSED,
  CLOSED_APPROVED,
  CLOSED_NOT_APPROVED,
  CLOSED_PARTIALLY_APPROVED,
  CLOSED_IRRELEVANT,
];
