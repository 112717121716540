import React from 'react';
import PropTypes from 'prop-types';

import { DropdownIconWrapper, StyledDropdownItem, DropdownItemLabel } from '../style';

const propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isFluid: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

export const DropdownListItem = ({ icon, disabled, onClick, children, isFluid }) => (
  <StyledDropdownItem
    role="button"
    disabled={disabled}
    onClick={disabled ? () => {} : onClick}
    isFluid={isFluid}
  >
    <DropdownIconWrapper>{icon}</DropdownIconWrapper>

    <DropdownItemLabel>{children}</DropdownItemLabel>
  </StyledDropdownItem>
);

DropdownListItem.propTypes = propTypes;
DropdownListItem.defaultProps = defaultProps;
