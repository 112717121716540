import api from 'api';
import { setToastMessage } from './toastMessages';
import { toastContext, toastType } from 'helpers/toastConstants';
import { setFileLoading } from './me';

// Actions
export const ADD_FILE = 'newDocumentVersion.ADD_FILE';
export const DELETE_FILE = 'newDocumentVersion.DELETE_FILE';
export const SET_DUE_DATE = 'newDocumentVersion.REMOVE_FILE';
export const SET_LOADING = 'newDocumentVersion.SET_LOADING';
export const NEW_VERSION_CREATED = 'newDocumentVersion.NEW_VERSION_CREATED';
export const CLEAR_DATA = 'newDocumentVersion.CLEAR_DATA';

// Reducer
const initialState = {
  isLoading: false,
  files: [],
  dueDate: '',
  newVersionCreated: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FILE:
      return {
        ...state,
        files: [...state.files, action.payload],
      };
    case DELETE_FILE:
      return {
        ...state,
        files: state.files.filter(({ id }) => id !== action.payload),
      };
    case SET_DUE_DATE:
      return {
        ...state,
        dueDate: action.payload,
      };
    case NEW_VERSION_CREATED:
      return {
        ...state,
        newVersionCreated: true,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function addFile(payload) {
  return { type: ADD_FILE, payload };
}

export function deleteFile(payload) {
  return { type: DELETE_FILE, payload };
}

export function setDueDate(payload) {
  return { type: SET_DUE_DATE, payload };
}

export function newVersionCreated() {
  return { type: NEW_VERSION_CREATED };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

export function clearData() {
  return { type: CLEAR_DATA };
}

// Side Effects
export function uploadFiles(pickedFiles) {
  return (dispatch) => {
    pickedFiles.forEach(async (file) => {
      try {
        const uploadedFile = await api.documents.uploadFile(file);
        dispatch(addFile(uploadedFile.data));
        dispatch(setFileLoading(false));
      } catch {
        dispatch(setFileLoading(false));
        dispatch(
          setToastMessage({
            content: {
              translationKey: 'uploadFileError',
              param: file.name,
            },
            context: toastContext.DOCUMENT_EDIT,
            type: toastType.ALERT,
          })
        );
      }
    });
  };
}

export function createNewVersion(documentId) {
  return async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const { dueDate, files } = getState().newDocumentVersion;
      await api.documents.createNewVersion(documentId, { dueDate, files });
      dispatch(newVersionCreated());
    } catch (error) {
      dispatch(
        setToastMessage({
          content: {
            translationKey: 'documentNewVersionFailed',
          },
          context: toastContext.DOCUMENT_EDIT,
          type: toastType.ALERT,
        })
      );
      dispatch(setLoading(false));
    }
  };
}
