import styled from 'styled-components';
import { rem } from 'polished';

import { colorName, colors, font, globalSize, variables } from 'styles';

const SMALL_ICON_SIZE = 8;
const BUTTON_SIZE = 16;

export const FileName = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colorName.black};
  font-weight: ${font.weight.regular};
  font-size: ${rem(font.size.small)};
  line-height: ${rem(16)};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
`;

export const FileBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${rem(globalSize.fileItemHeight)};
  padding: 0 ${rem(16)};
  background-color: ${(props) => props.anonymized && colors.lightGray};
  border: 1px solid ${(props) => (props.anonymized ? colors.darkGray : colors.gray)};
  border-radius: ${rem(variables.borderRadius)};
  opacity: ${(props) => props.anonymized && 0.3};
  &:not(:last-child) {
    margin-bottom: ${rem(8)};
  }
`;

export const DownloadButton = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(16)};
  padding-left: ${rem(16)};
  color: ${colorName.secondary};
  text-decoration: none;
  border-left: 1px solid ${colors.mediumGray};
  cursor: pointer;

  & > svg {
    height: 100%;
  }

  &:hover,
  &:focus {
    outline: 0;
  }
`;

export const AnonymizeButton = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(16)};
  padding-left: ${rem(16)};
  color: ${colors.darkRed};
  text-decoration: none;
  border-left: 1px solid ${colors.mediumGray};
  cursor: pointer;
  opacity: 0.7;

  & > svg {
    height: 100%;
  }

  &:hover,
  &:focus {
    outline: 0;
    opacity: 1;
  }
`;

export const CloseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(BUTTON_SIZE)};
  height: ${rem(BUTTON_SIZE)};
  color: ${colors.darkestGray};
  cursor: pointer;

  & > svg {
    display: block;
    width: ${rem(SMALL_ICON_SIZE)};
    height: ${rem(SMALL_ICON_SIZE)};
  }
`;
