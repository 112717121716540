import styled from 'styled-components';
import { rem } from 'polished';

import { colorName, breakpoints, colors, font, variables, globalSize } from 'styles';
import { StyledButton } from 'components/Button/style';
import { TypographyElement } from 'components/Typography/style';
import { DatePickerComponent } from 'components/DatePicker/style';
import { CheckboxLabel } from 'components/Checkbox/style';
import Button from 'components/Button';

const DEFAULT_PADDING = globalSize.defaultPadding;
const SECTION_BOTTOM_SPACING = 40;
const NOTIFICATION_PADDING = 24;

export const DocumentSection = styled.div`
  margin: 0 0 ${rem(SECTION_BOTTOM_SPACING)};
  padding: 0 0 ${rem(SECTION_BOTTOM_SPACING)};

  &:not(:last-child) {
    border-bottom: 1px solid ${colorName.separator};
  }

  & > ${DatePickerComponent} {
    margin: 0 0 ${rem(globalSize.defaultPadding * 2)};
  }
`;

export const StickyDocumentBar = styled.div`
  @media ${breakpoints.smallScreen} {
    position: sticky;
    top: ${rem(80)};
  }
`;

export const DocumentActions = styled.div`
  & > a {
    &:not(:last-child) {
      margin: 0 0 ${rem(16)};

      @media ${breakpoints.tablet} {
        margin: 0 ${rem(16)} 0 0;
      }
    }
  }

  @media ${breakpoints.tablet} {
    display: flex;
    justify-content: center;
  }
`;

export const ButtonsWrapper = styled.div`
  ${StyledButton} {
    &:not(:last-child) {
      margin: 0 0 ${rem(16)} 0;
    }
  }
`;

export const Version = styled.div`
  display: inline-flex;
  align-items: center;
  height: ${rem(24)};
  margin: 0 ${rem(8)} ${rem(16)} 0;
  padding: 0 ${rem(8)};
  color: ${colors.yellow};
  font-weight: ${font.weight.regular};
  font-size: ${rem(font.size.small)};
  background: ${colors.brownYellow};
  border-radius: ${rem(variables.borderRadius)};
`;

export const DataSection = styled.div`
  margin: 0 0 ${rem(24)};
  padding: 0 0 ${rem(8)};
  border-bottom: 1px solid ${colorName.separator};
`;

export const DataRow = styled.div`
  margin: 0 0 ${rem(12)};

  @media ${breakpoints.smallScreen} {
    display: flex;

    ${TypographyElement} {
      flex: 1 1 auto;

      &:first-child {
        flex: 0 0 18%;
        margin: 0 ${rem(8)} 0 0;
      }
    }
  }
`;

export const NotificationsWrapper = styled.div`
  ${CheckboxLabel} {
    margin: 0 0 ${rem(16)} 0;
  }
`;

export const NotificationGroup = styled.div`
  margin: 0 0 ${rem(24)};
  padding: 0 0 0 ${rem(NOTIFICATION_PADDING)};
`;

export const SectionHeaderWithAction = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const NewVersionModalContent = styled.div`
  padding: ${rem(globalSize.mobileMenuSize + DEFAULT_PADDING)} ${rem(DEFAULT_PADDING)}
    ${rem(DEFAULT_PADDING)};
  text-align: left;

  @media ${breakpoints.tablet} {
    padding: ${rem(24)} ${rem(32)} ${rem(16)};
  }
`;

export const NewVersionModalButtonWrapper = styled.div`
  margin: ${rem(32)} 0 ${rem(16)};
`;

export const VersionDetails = styled.div`
  display: flex;
  justify-content: stretch;

  &:not(:last-child) {
    margin-bottom: ${rem(24)};
  }
`;

export const VersionNumber = styled.div`
  width: ${rem(100)};
  font-weight: ${font.weight.regular};
  font-size: ${rem(font.size.small)};
  line-height: ${rem(globalSize.fileItemHeight)};
`;

export const VersionFiles = styled.div`
  flex: 1;
`;

export const VersionFilesEmpty = styled.div`
  flex: 1;
  color: ${colors.darkGray};
  font-weight: ${font.weight.regular};
  line-height: ${rem(globalSize.fileItemHeight)};
`;

export const ReminderButton = styled(Button)`
  padding: 0;
`;

export const SelectRow = styled.div`
  margin: 0 0 ${rem(12)};

  ${TypographyElement} {
    margin: 0 0 ${rem(8)};
  }
`;

export const SelectWrapper = styled.div`
  display: flex;

  ${StyledButton} {
    margin: 0 0 0 ${rem(8)};
  }
`;
