import styled from 'styled-components';
import { rem } from 'polished';

import { StyledButton } from 'components/Button/style';
import { breakpoints } from 'styles';

export const ButtonWrapper = styled.div`
  padding: 0 ${rem(16)};

  @media ${breakpoints.tablet} {
    display: flex;
    justify-content: center;
  }

  ${StyledButton} {
    &:not(:last-child) {
      margin: 0 0 ${rem(16)} 0;
    }

    @media ${breakpoints.tablet} {
      justify-content: center;

      &:not(:last-child) {
        margin: 0 ${rem(24)} 0 0;
      }
    }
  }
`;
