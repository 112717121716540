import snakeCase from 'lodash/snakeCase';

export const appendQueryParams = (url, paramsObj = {}) => {
  const paramsObjSnakeCase = Object.keys(paramsObj).reduce((acc, key) => {
    if (paramsObj[key] === undefined || paramsObj[key] === '') {
      return acc;
    } else {
      if (!Array.isArray(paramsObj[key])) {
        return {
          ...acc,
          [encodeURIComponent(snakeCase(key))]: encodeURIComponent(paramsObj[key]),
        };
      } else {
        return acc;
      }
    }
  }, {});

  const paramsStringified = new URLSearchParams(paramsObjSnakeCase).toString();

  const arrayParams = Object.keys(paramsObj).map((key) => {
    if (Array.isArray(paramsObj[key])) {
      const urlArray = paramsObj[key].map((item) => {
        return `${encodeURIComponent(snakeCase(key))}[]=${encodeURIComponent(item)}`;
      });

      return urlArray.join('&');
    } else {
      return null;
    }
  });

  const paramsWithArray = paramsStringified + (arrayParams[0] || '');

  const paramsString = paramsWithArray ? `?${paramsWithArray}` : '';

  return url + paramsString;
};
