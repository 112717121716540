import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  hello: {
    id: 'Login.hello',
    defaultMessage: 'Hello again!',
    description: 'Login header',
  },
  loginReportingHeader: {
    id: 'Login.loginReportingHeader',
    defaultMessage: 'Login to your VCO account',
    description: 'Login description',
  },
  passwordSet: {
    id: 'Login.passwordSet',
    defaultMessage: 'Your password has been set.',
    description: 'Password has been set',
  },
  goToLogin: {
    id: 'Login.goToLogin',
    defaultMessage: 'Go to login page',
    description: 'Button label when password has been set',
  },
  emailHasBeenSent: {
    id: 'Login.emailHasBeenSent',
    defaultMessage:
      'If the email address is correct, a password reset link has been sent.',
    description: 'Email has been sent text',
  },
  lostPassword: {
    id: 'Login.lostPassword',
    defaultMessage: 'Forgot your password? Please enter your email address.',
    description: 'Lost password header',
  },
  youWillReceiveLink: {
    id: 'Login.youWillReceiveLink',
    defaultMessage: 'You will receive a link to create a new password via email.',
    description: 'Lost password header second part',
  },
  resetPassword: {
    id: 'Login.resetPassword',
    defaultMessage: 'Reset password',
    description: 'Reset Password button label',
  },
  emailPlaceholder: {
    id: 'Login.emailPlaceholder',
    defaultMessage: 'Your email address',
    description: 'Email input placeholder',
  },
  passwordRequirementsHeading: {
    id: 'Login.passwordRequirementsHeading',
    defaultMessage: 'Make sure your password:',
    description: 'Password strength requirements heading',
  },
  passwordLength: {
    id: 'Login.passwordLength',
    defaultMessage:
      'Contains at least {param} characters (For compliance officer 15 charaters)',
    description: 'Password strength requirement',
  },
  passwordUpperLowerCaseLetters: {
    id: 'Login.passwordUpperLowerCase',
    defaultMessage: 'Contains both lower (a–z) and upper case letters (A–Z)',
    description: 'Password strength requirement',
  },
  passwordNumbers: {
    id: 'Login.passwordNumbers',
    defaultMessage: 'Contains at least one number (0–9)',
    description: 'Password strength requirement',
  },
  passwordSpecialCharacters: {
    id: 'Login.passwordSpecialCharacters',
    defaultMessage: 'Contains at least one special character',
    description: 'Password strength requirement',
  },
  passwordRequirementFulfilled: {
    id: 'Login.passwordStrengthRequirementFulfilled',
    defaultMessage: 'Requirement fulfilled: ',
    description: 'Alternative text for checkmark icon next to requirement',
  },
  passwordRequirementNotFulfilled: {
    id: 'Login.passwordStrengthRequirementNotFulfilled',
    defaultMessage: 'Requirement not fulfilled: ',
    description: 'Alternative text for cross icon next to requirement',
  },
});
