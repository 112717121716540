import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const RecaptchaConfigContext = createContext();

const propTypes = {
  siteKey: PropTypes.string,
  children: PropTypes.node,
};

const RecaptchaConfigProvider = ({ siteKey, children }) => {
  const { recaptchaActive } = useSelector((state) => state.tenantProperties);

  const recaptchaDisabled =
    ['true', 'TRUE', '1'].includes(process.env.REACT_APP_RECAPTCHA_DISABLED) ||
    !recaptchaActive;

  const siteKeyFromEnv = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const siteKeyToUse = siteKey || siteKeyFromEnv;

  if (!recaptchaDisabled && !siteKeyToUse) {
    throw new Error(
      'Recaptcha site key has to be provided in REACT_APP_RECAPTCHA_SITE_KEY env variable or as a prop of this component'
    );
  }

  return (
    <RecaptchaConfigContext.Provider value={{ siteKey: siteKeyToUse, recaptchaDisabled }}>
      {children}
    </RecaptchaConfigContext.Provider>
  );
};

RecaptchaConfigProvider.propTypes = propTypes;

export default RecaptchaConfigProvider;
