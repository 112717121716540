import styled from 'styled-components';
import { rem } from 'polished';
import { colorName, colors, font, transitions, variables } from 'styles';

export const TextareaWrapper = styled.div`
  margin-bottom: ${({ marginBottom }) => rem(marginBottom)};
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  height: ${({ isLarge, height }) => (height ? height : isLarge ? rem(155) : rem(94))};
  padding: ${rem(10)} ${rem(14)};
  color: ${colorName.secondary};
  font-size: ${rem(font.size.small)};
  line-height: 1.36;
  border: 1px solid ${colors.gray};
  border-radius: ${rem(variables.borderRadius)};
  outline: 0;
  transition: border ${transitions.uiTiming} ${transitions.spring};
  resize: ${({ isResizable }) => (isResizable ? 'vertical' : 'none')};

  ${({ wasTouched, hasError }) =>
    wasTouched &&
    `
      border: 1px solid ${hasError ? colorName.error : colors.green};
    `};

  ::placeholder {
    color: ${colors.mediumGray};
  }

  &:focus,
  &:hover,
  &:invalid {
    box-shadow: none;
  }

  &:hover {
    border: 1px solid ${colors.mediumGray};
    cursor: pointer;
  }

  &:focus {
    border: 2px solid ${colors.navy};
    caret-color: ${colors.navy};
    cursor: pointer;
  }
`;

export const RequiredMark = styled.span`
  color: ${colors.darkestRed};
`;
