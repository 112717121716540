import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SideMenu from './parts/SideMenu';
import TopMenu from './parts/TopMenu';

import MatchMedia from 'components/MatchMedia';
import FloatingChat from 'components/FloatingChat';

import { deviceSizes } from 'styles';
import { FullScreenWrapper, Logotype, MenusWrapper, SiteWrapper } from './style';
import { useSelector } from 'react-redux';

const propTypes = {
  children: PropTypes.any,
};

// @todo: Add Tests for Wrapper With Menu components
const WrapperWithMenu = ({ children }) => {
  const { isOfficer } = useSelector((state) => state.auth);
  const [browserHeight, setHeight] = useState();
  const getDimensions = useCallback(() => {
    const delay = 500;
    let timeout = setTimeout(getDimensions, delay);
    clearTimeout(timeout);
    window && setHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', getDimensions);
    return () => window.removeEventListener('resize', getDimensions);
  }, [getDimensions]);

  return (
    <FullScreenWrapper minHeight={browserHeight}>
      <MenusWrapper>
        <SideMenu minHeight={browserHeight} />

        <MatchMedia minWidth={deviceSizes.mediumScreen}>
          <Logotype isEyLogoSmall />
        </MatchMedia>

        <TopMenu />
      </MenusWrapper>

      <SiteWrapper>
        {children}

        {!isOfficer && <FloatingChat />}
      </SiteWrapper>
    </FullScreenWrapper>
  );
};

WrapperWithMenu.propTypes = propTypes;

export default WrapperWithMenu;
