import PropTypes from 'prop-types';

export const defaultPropTypes = {
  isChecked: false,
};

export const propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
};
