import styled from 'styled-components';
import { rem } from 'polished';

import { colors, font } from 'styles';

export const Wrapper = styled.div`
  margin-bottom: ${({ bottomMargin }) => (bottomMargin ? rem(bottomMargin) : rem(0))};
  color: ${({ color }) => (color ? color : colors.black)};
  font-size: ${({ fontSize }) => (fontSize ? rem(fontSize) : rem(font.size.normal))};

  & > p {
    line-height: 1.44;
    white-space: pre-wrap;
  }
`;

export const StyledLink = styled.a`
  color: inherit;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

export const YellowText = styled.span`
  color: ${colors.yellow};
`;
