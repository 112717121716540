/* eslint-disable no-console*/
import findKey from 'lodash/findKey';

const warningPatterns = {
  nonBoolean: /Warning: Received .* for a non-boolean attribute/im,
  domElement: /Warning: React does not recognize the .* prop on a DOM element./im,
  cssStylesheet: /Could not parse CSS stylesheet/im,
  unmountedComponent: {
    pattern: /Warning: Can't perform a React state update on an unmounted component./im,
    conditions: [
      (args) => args[2].includes('DayPicker'),
      (args) => args[2].includes('AsyncPaginateBase'),
    ],
  },
  // @todo: remove below when a new version of react-dates is released
  hasBeenRenamed: /Warning: .* has been renamed, and is not recommended for use./im,
  wrapInAct: /Warning: An update to .* inside a test was not wrapped in act/im,
};

const runningOnCI = process.env.CI === 'true';

export const suppressUnwantedWarnings = (excludedPatterns = warningPatterns) => {
  const originalConsoleError = console.error;
  const originalConsoleWarn = console.warn;

  overrideConsoleMethod('error', originalConsoleError);
  overrideConsoleMethod('warn', originalConsoleWarn);

  function overrideConsoleMethod(methodName, originalMethod) {
    console[methodName] = function () {
      const restoreDefault = () => {
        originalMethod.apply(console, arguments);
        if (runningOnCI) {
          throw new Error(arguments[0]); // writing to console.warn or console.error should make the CI build fail
        }
      };

      const errorMessage = arguments[0];

      if (typeof errorMessage !== 'string') {
        restoreDefault();
        return;
      }

      const exclusionRuleKey = findKey(excludedPatterns, (exclusion) => {
        const pattern = exclusion.pattern ? exclusion.pattern : exclusion;
        return pattern.test(errorMessage);
      });

      if (!exclusionRuleKey) {
        restoreDefault();
        return;
      }

      const { conditions } = excludedPatterns[exclusionRuleKey];
      if (conditions && conditions.length) {
        const isConditionallyExcluded = conditions.some((conditional) =>
          conditional(arguments)
        );
        if (!isConditionallyExcluded) {
          restoreDefault();
        }
      }
    };
  }
};
