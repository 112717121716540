import React from 'react';
import { ChartWithLegendWrapper, ChartWrapper, CustomLegend } from './style';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { LegendPlugin } from '../Plugins/LegendPlugin';
import PropTypes from 'prop-types';
import { TextInsideDonutPlugin } from '../Plugins/TextInsideDonutPlugin';

ChartJS.register(ArcElement, Tooltip, Legend);

const propTypes = {
  options: PropTypes.object,
  data: PropTypes.object.isRequired,
  textInside: PropTypes.string.isRequired,
  numberInside: PropTypes.number.isRequired,
  legendId: PropTypes.string,
};

const DonutChart = ({ options, data, legendId, numberInside, textInside }) => {
  return (
    <>
      <ChartWithLegendWrapper>
        <ChartWrapper>
          <Doughnut
            options={{
              responsive: true,
              maintainAspectRatio: false,
              elements: {
                mainText: {
                  text: numberInside,
                  color: '#000', // Default is #000000
                },
                bottomText: {
                  text: textInside,
                  color: '#000', // Default is #000000
                },
              },
              cutout: '80%',
              plugins: {
                htmlLegend: {
                  containerID: legendId,
                },
                legend: {
                  display: false,
                },
              },
              ...options,
            }}
            plugins={[LegendPlugin, TextInsideDonutPlugin]}
            data={data}
            width={240}
            height={240}
          />
        </ChartWrapper>
        <CustomLegend id={legendId} />
      </ChartWithLegendWrapper>
    </>
  );
};

DonutChart.propTypes = propTypes;

export default DonutChart;
