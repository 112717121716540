import styled from 'styled-components';
import { rem } from 'polished';

import { globalSize, breakpoints, colorName } from 'styles';

import ArrowIcon from 'components/Svg/ArrowIcon';

const ICON_SIZE = 16;
const MOBILE_ICON_SIZE = 12;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 ${rem(24)};
  padding: 0 0 ${rem(12)} 0;
  border-bottom: 1px solid
    ${({ withoutSeparator }) => (withoutSeparator ? 'transparent' : colorName.separator)};

  @media ${breakpoints.tablet} {
    margin: 0 0 ${({ marginBottom }) => rem(marginBottom)};
    padding: 0 0 ${rem(globalSize.defaultPadding * 1.25)} 0;
  }
`;

export const BackArrowIcon = styled(ArrowIcon)`
  width: ${rem(MOBILE_ICON_SIZE)};
  height: ${rem(MOBILE_ICON_SIZE)};

  @media ${breakpoints.tablet} {
    width: ${rem(ICON_SIZE)};
    height: ${rem(ICON_SIZE)};
  }
`;

export const BackButton = styled.button.attrs({
  type: 'button',
  'aria-label': 'Go back',
})`
  display: flex;
  align-items: center;
  align-self: stretch;
  width: ${rem(ICON_SIZE * 1.2)};
  margin: 0 ${rem(12)} 0 0;
  margin-bottom: ${({ withBottomMargin }) => withBottomMargin && rem(16)};
  padding: 0;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;

  @media ${breakpoints.tablet} {
    margin: 0 ${rem(12)} ${rem(-4)} 0;
  }
`;
