import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { object } from 'prop-types';

import { ApiContext } from 'api';
import { ROUTING } from 'routing';

import EntryWrapper from 'components/EntryWrapper';
import Typography from 'components/Typography';
import Button from 'components/Button';
import LoginInput from 'components/LoginInput';
import LoginHeader from 'components/LoginHeader';

import { colors } from 'styles';

import { messages } from '../Login/messages';
import { LoginMiddleSectionForm } from 'styles/commonStyles';
import { globalMessages } from 'translations';

const propTypes = {
  intl: object,
};

const ForgotPassword = ({ intl }) => {
  const { api } = useContext(ApiContext);
  const [isLoading, setLoading] = useState(false);
  const [isEmailSubmitted, setEmailSubmitted] = useState(false);
  const [email, setEmail] = useState('');

  const resetPassword = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await api.auth.forgotPassword(email);
      setEmailSubmitted(true);
    } catch (error) {
      // @todo - update unexpected error handling
    } finally {
      setLoading(false);
    }
  };

  return (
    <EntryWrapper>
      <LoginHeader />

      {isEmailSubmitted ? (
        <LoginMiddleSectionForm>
          <Typography
            size="small"
            bottomMargin={24}
            textAlign="center"
            weight="bold"
            color={colors.darkGray}
            maxWidth={220}
          >
            {intl.formatMessage(messages.emailHasBeenSent)}
          </Typography>

          <Button component={Link} to={ROUTING.INDEX} primary isFluid>
            {intl.formatMessage(messages.goToLogin)}
          </Button>
        </LoginMiddleSectionForm>
      ) : (
        <LoginMiddleSectionForm onSubmit={resetPassword} autocomplete="off">
          <Typography size="small" bottomMargin={24} color={colors.darkGray}>
            {intl.formatMessage(messages.lostPassword)}
            <br />
            {intl.formatMessage(messages.youWillReceiveLink)}
          </Typography>

          <LoginInput
            type="email"
            autocomplete="new-password"
            label={intl.formatMessage(globalMessages.email)}
            placeholder={intl.formatMessage(messages.emailPlaceholder)}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button primary isFluid isProcessing={isLoading} type="submit">
            {intl.formatMessage(messages.resetPassword)}
          </Button>

          <Button
            secondary
            isFluid
            disabled={isLoading}
            component={Link}
            to={ROUTING.INDEX}
          >
            {intl.formatMessage(globalMessages.back)}
          </Button>
        </LoginMiddleSectionForm>
      )}
    </EntryWrapper>
  );
};

ForgotPassword.propTypes = propTypes;

export default injectIntl(ForgotPassword);
