import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  statisticsTitle: {
    id: 'CoDashboard.statisticsTitle',
    defaultMessage: 'Compliance Officer dashboard',
    description: 'Dashboard statistics section title',
  },
  documentsTitle: {
    id: 'CoDashboard.documentsTitle',
    defaultMessage: 'Recent documents',
    description: 'Dashboard Documents section title',
  },
  noDocumentsTitle: {
    id: 'CoDashboard.noDocumentsTitle',
    defaultMessage: 'You do not have any recent documents',
    description: 'Dashboard documents preview empty state title',
  },
  allDocuments: {
    id: 'CoDashboard.allDocuments',
    defaultMessage: 'See all documents',
    description: 'See all documents button label',
  },
  noMisconductsTitle: {
    id: 'CoDashboard.noMisconductsTitle',
    defaultMessage: 'There are no reports yet',
    description: 'Dashboard misconducts preview empty state title',
  },
  allMisconducts: {
    id: 'CoDashboard.allMisconducts',
    defaultMessage: 'See all reports',
    description: 'See all misconducts button label',
  },
  reportsUnassigned: {
    id: 'CoDashboard.reportsUnassigned',
    defaultMessage: 'Unassigned reports',
    description: 'Unassigned reports stats tile title',
  },
  reportsInProgress: {
    id: 'CoDashboard.reportsInProgress',
    defaultMessage: 'Reports in progress',
    description: 'Reports in progress stats tile title',
  },
  statisticsTooltip: {
    id: 'CoDashboard.statisticsTooltip',
    defaultMessage: 'Applies to charts only',
    description: 'Statistics tooltip text',
  },
  donutChartTitle: {
    id: 'CoDashboard.donutChartTitle',
    defaultMessage: 'Reports by status',
    description: 'Donut chart tile header',
  },
  barChartTitle: {
    id: 'CoDashboard.barChartTitle',
    defaultMessage: 'Reports by: ',
    description: 'Bar chart tile header',
  },
  barChartOpened: {
    id: 'CoDashboard.barChartOpened',
    defaultMessage: 'Opened',
    description: 'Opened bar dataset label',
  },
  barChartClosed: {
    id: 'CoDashboard.barChartClosed',
    defaultMessage: 'Closed',
    description: 'Closed bar dataset label',
  },
  barChartTotal: {
    id: 'CoDashboard.barChartTotal',
    defaultMessage: 'Total',
    description: 'Total bar dataset label',
  },
  sortOpenAsc: {
    id: 'CoDashboard.sortOpenAsc',
    defaultMessage: 'Open reports: ascending',
    description: 'Sort select option open ascending',
  },
  sortOpenDesc: {
    id: 'CoDashboard.sortOpenDesc',
    defaultMessage: 'Open reports: descending',
    description: 'Sort select option open descending',
  },
  sortClosedAsc: {
    id: 'CoDashboard.sortOpenAsc',
    defaultMessage: 'Closed reports: ascending',
    description: 'Sort select option closed ascending',
  },
  sortClosedDesc: {
    id: 'CoDashboard.sortOpenDesc',
    defaultMessage: 'Closed reports: descending',
    description: 'Sort select option closed descending',
  },
  sortTotalAsc: {
    id: 'CoDashboard.sortOpenAsc',
    defaultMessage: 'Total reports: ascending',
    description: 'Sort select option total ascending',
  },
  sortTotalDesc: {
    id: 'CoDashboard.sortOpenDesc',
    defaultMessage: 'Total reports: descending',
    description: 'Sort select option total descending',
  },
  filterOneMonth: {
    id: 'CoDashboard.filterOneMonth',
    defaultMessage: 'Last 1 month',
    description: 'Filter by time 1 month option',
  },
  filterTwoMonths: {
    id: 'CoDashboard.filterTwoMonths',
    defaultMessage: 'Last 2 months',
    description: 'Filter by time 2 months option',
  },
  filterThreeMonths: {
    id: 'CoDashboard.filterThreeMonths',
    defaultMessage: 'Last 3 months',
    description: 'Filter by time 3 months option',
  },
  filterOneYear: {
    id: 'CoDashboard.filterOneYear',
    defaultMessage: 'Last 1 year',
    description: 'Filter by time 1 year option',
  },
  filterCurrentYear: {
    id: 'CoDashboard.filterCurrentYear',
    defaultMessage: 'Current year',
    description: 'Filter by time current year option',
  },
  filterAll: {
    id: 'CoDashboard.filterAll',
    defaultMessage: 'All',
    description: 'Filter by time all option',
  },
  textInsideDonut: {
    id: 'CoDashboard.textInsideDonut',
    defaultMessage: 'Total reports',
    description: 'Filter by time all option',
  },
});
