import styled, { keyframes } from 'styled-components';
import { rem } from 'polished';

const spinAround = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinCircle = styled.p`
  position: relative;
  display: block;
  width: ${({ size }) => size && rem(size)};
  height: ${({ size }) => size && rem(size)};
  margin: 0 auto;
  border: ${({ size }) => (size > 24 ? '2px' : '1px')} solid
    ${({ color }) => color && color};
  border-left: ${({ size }) => (size > 24 ? '2px' : '1px')} solid transparent;
  border-radius: 100%;
  transform: translateZ(0);
  animation: ${spinAround} 0.8s infinite linear;
`;
