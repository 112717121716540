import styled from 'styled-components';
import { rem } from 'polished';

import CloseIcon from 'components/Svg/CloseIcon';
import UserIcon from 'components/Svg/UserIcon';
import Checkbox from 'components/Checkbox';

import { colorName, colors, font, transitions } from 'styles';

const CLOSE_ICON_SIZE = 8;
const CLOSE_BTN_SIZE = 20;
const USER_ICON_SIZE = 18;
const ARROW_ICON_WIDTH = 16;

export const Table = styled.table`
  width: 100%;
  font-weight: ${font.weight.regular};
  table-layout: fixed;
  transition: height ${transitions.uiTiming} ${transitions.spring};
`;

export const Column = styled.td`
  padding: ${rem(12)} 0;

  ${({ withStatusPills }) =>
    withStatusPills &&
    `
    text-align: right;
  `};
`;

export const Row = styled.tr`
  &:not(:last-child) {
    ${Column} {
      border-bottom: 1px ${({ isGroupTable }) => (isGroupTable ? 'solid' : 'dotted')}
        ${colors.grayish};
    }
  }
`;

export const TableHead = styled.thead``;

export const HeadColumn = styled.th`
  width: ${({ width }) => (width ? rem(width) : 'auto')};
  padding: ${rem(16)} 0;
`;

export const TableBody = styled.tbody`
  font-size: ${rem(font.size.small)};
  line-height: 1.5;
  ${({ isGroupTable, isActive }) =>
    isGroupTable &&
    `
    opacity: ${isActive ? 1 : 0};
    transition: opacity .4s ${transitions.spring};
  `};
`;

export const DeleteIcon = styled(CloseIcon)`
  width: ${rem(CLOSE_ICON_SIZE)};
  height: ${rem(CLOSE_ICON_SIZE)};
`;

export const RegularUserIcon = styled(UserIcon)`
  display: block;
  float: left;
  width: ${rem(USER_ICON_SIZE)};
  height: ${rem(USER_ICON_SIZE)};
  margin: 0 ${rem(10)} 0 0;
  color: ${colors.mediumGray};
`;

export const ArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(ARROW_ICON_WIDTH)};
  height: ${rem(ARROW_ICON_WIDTH)};
  margin: 0 ${rem(12)} 0 0;
  background-color: ${colorName.secondary};
  border-radius: 100%;

  & > svg {
    display: block;
    width: ${rem(6)};
    height: ${rem(6)};
    color: ${colors.white};
    transform: rotate(${({ isActive }) => (isActive ? '180deg' : '0deg')});
    transition: transform ${transitions.uiTiming} ${transitions.spring};
  }
`;

export const DeleteButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(CLOSE_BTN_SIZE)};
  height: ${rem(CLOSE_BTN_SIZE)};
  margin: 0 0 0 auto;
  padding: 0;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
`;

export const DetailsButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  color: ${colorName.secondary};
  font-size: ${rem(font.size.normal)};
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
`;

export const GroupCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  padding: 0 ${rem(16)} 0 0;
  border-right: 1px solid ${colors.grayish};
`;
