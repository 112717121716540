import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  errorTitle: {
    id: 'ErrorBoundary.errorTitle',
    defaultMessage: 'Oops, something went wrong',
    description: 'Error Title',
  },
  errorSubtitle: {
    id: 'ErrorBoundary.errorSubtitle',
    defaultMessage:
      'This page is currently not available. We are working on the problem and appreciate your patience.',
    description: 'Error Subtitle',
  },
});
