import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

import { ROUTING } from 'routing';

import Typography from 'components/Typography';
import NotOnMobileIcon from 'components/Svg/NotOnMobileIcon';
import RoundIconWrap from 'components/RoundIconWrap';
import Button from 'components/Button';

import { colors } from 'styles';
import { Wrapper, ButtonWrapper } from './style';
import { globalMessages } from 'translations';

const defaultPropTypes = {
  title: <FormattedMessage {...globalMessages.tryDesktopVersion} />,
  subtitle: <FormattedMessage {...globalMessages.featureUnavailable} />,
  icon: NotOnMobileIcon,
  verticalSpacing: 24,
};

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  verticalSpacing: PropTypes.number,
  withBackButton: PropTypes.bool,
  intl: PropTypes.object,
};

const SplashScreen = ({
  title,
  subtitle,
  icon: Icon,
  verticalSpacing,
  withBackButton,
  intl,
}) => {
  return (
    <Wrapper verticalSpacing={verticalSpacing}>
      <RoundIconWrap icon={<Icon />} />

      <Typography size="medium" tabletSize="large" bottomMargin={16}>
        {title}
      </Typography>

      <Typography
        size="small"
        tabletSize="medium"
        color={colors.darkGray}
        bottomMargin={0}
      >
        {subtitle}
      </Typography>

      {withBackButton && (
        <ButtonWrapper>
          <Button component={Link} primary to={ROUTING.DASHBOARD_EMPLOYEE}>
            {intl.formatMessage(globalMessages.returnToDashboard)}
          </Button>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

SplashScreen.defaultProps = defaultPropTypes;
SplashScreen.propTypes = propTypes;

export default injectIntl(SplashScreen);
