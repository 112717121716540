import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import { NewVersionModalContent } from 'containers/private/Documents/style';
import Typography from 'components/Typography';
import { messages } from '../messages';
import { useIntl } from 'react-intl';
import { ModalButtonsWrapper, ModalFilesListWrapper } from '../style';
import Button from 'components/Button';
import { FileBox, FileName } from 'components/FileItem/style';
import FileBadge from 'components/FileBadge';
import { getFileFormat } from 'helpers';

const propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  isModalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  confirmUpload: PropTypes.func,
};

const ConfirmUploadModal = ({ files, isModalOpen, onClose, confirmUpload }) => {
  const intl = useIntl();
  return (
    <Modal isOpen={isModalOpen} autoHeight onRequestClose={() => onClose()}>
      <NewVersionModalContent>
        <Typography size="large" bottomMargin={16}>
          {intl.formatMessage(messages.confirmFileUpload)}
        </Typography>

        <Typography size="normal" bottomMargin={32}>
          {intl.formatMessage(messages.confirmFileUploadText)}
        </Typography>

        <ModalFilesListWrapper>
          {files.map((fileItem) => {
            return (
              <FileBox key={fileItem.name}>
                <FileName rel="noopener noreferrer" target="_blank">
                  <FileBadge>{getFileFormat(fileItem.name)}</FileBadge>

                  {fileItem.name}
                </FileName>
              </FileBox>
            );
          })}
        </ModalFilesListWrapper>

        <ModalButtonsWrapper>
          <Button secondary onClick={() => onClose()}>
            {intl.formatMessage(messages.cancelUpload)}
          </Button>
          <Button primary onClick={() => confirmUpload()}>
            {intl.formatMessage(messages.confirmUpload)}
          </Button>
        </ModalButtonsWrapper>
      </NewVersionModalContent>
    </Modal>
  );
};

ConfirmUploadModal.propTypes = propTypes;

export default ConfirmUploadModal;
