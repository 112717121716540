export const MISCONDUCT_ALLOWED_FILETYPES = [
  // ALLOWED ALL TYPES BECAUSE OF ISSUES WITH MIMETYPE TO .msg FILES
  // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  // 'application/msword',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // 'application/vnd.ms-excel',
  // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // 'image/jpeg',
  // 'image/png',
  // 'application/pdf',
  // 'audio/aac',
  // 'audio/mpeg',
  // 'audio/ogg',
  // 'audio/wav',
  // 'audio/mp4',
  // 'application/vnd.ms-outlook',
  // 'application/octet-stream',
];

export const MISCONDUCT_MAX_FILE_SIZE = 20971520;
export const MISCONDUCT_MAX_FILES = 5;
