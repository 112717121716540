import React from 'react';

export const ReportIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <path fill="currentColor" d="M11.5 0.5H12.5V4.5H11.5z" />
      <path
        fill="currentColor"
        d="M16.09 4.5H19.92V5.5H16.09z"
        transform="rotate(-45.001 18 5)"
      />
      <path
        fill="currentColor"
        d="M5.56 3.06H6.56V7.0600000000000005H5.56z"
        transform="rotate(-45.001 6.06 5.06)"
      />
      <path
        fill="currentColor"
        d="M7 23.5C3.42 23.5.5 20.58.5 17s2.92-6.5 6.5-6.5h15.72l-1.88 5h-7.53c.12.5.18 1 .18 1.5.01 3.58-2.91 6.5-6.49 6.5zm0-12c-3.03 0-5.5 2.47-5.5 5.5s2.47 5.5 5.5 5.5 5.5-2.47 5.5-5.5c0-.62-.11-1.23-.32-1.83l-.24-.67h8.21l1.12-3H7z"
      />
      <path
        fill="currentColor"
        d="M7 19.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5zm0-4c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z"
      />
    </svg>
  );
};

export default ReportIcon;
