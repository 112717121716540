import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { setUserLanguage } from 'stores/auth';
import { appLanguagesSelectData } from 'helpers/commonConstants';

import Select from 'components/SimpleSelect';

const propTypes = {
  availableLanguages: PropTypes.array,
};

const MisconductLanguageSwitch = ({ availableLanguages }) => {
  const { userLanguage } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const languages = availableLanguages
    ? appLanguagesSelectData.filter((lang) => availableLanguages.includes(lang.value))
    : appLanguagesSelectData;
  const isLanguageAvailableOnList = availableLanguages?.includes(userLanguage);

  const languageSwitch = (e) => {
    dispatch(setUserLanguage(e.target.value));
  };

  useEffect(() => {
    if (!isLanguageAvailableOnList && !!availableLanguages) {
      dispatch(setUserLanguage(availableLanguages[0]));
    }
  }, [isLanguageAvailableOnList, dispatch, availableLanguages]);

  return (
    <Select
      id="report-language"
      options={languages}
      value={userLanguage}
      onChange={languageSwitch}
      marginBottom={0}
      maxWidth={152}
      isCentered
      data-testid="report-language-switcher"
    />
  );
};

MisconductLanguageSwitch.propTypes = propTypes;

export default MisconductLanguageSwitch;
