import styled from 'styled-components';
import { rem } from 'polished';

import { colors, globalSize } from 'styles';
import { breakpoints } from 'styles';

const CONTAINER_PADDING = rem(globalSize.defaultPadding);

export const Wrapper = styled.section`
  margin: 0 -${CONTAINER_PADDING};

  @media ${breakpoints.tablet} {
    margin: 0;
  }
`;

export const Content = styled.div`
  padding: 0 ${CONTAINER_PADDING};

  @media ${breakpoints.tablet} {
    padding: 0;
  }
`;

export const FilterWrapper = styled.div`
  min-width: 100%;

  @media ${breakpoints.tablet} {
    min-width: ${rem(200)};
  }
`;

export const Statistics = styled.div``;

export const TopRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(24)};
  margin-bottom: ${rem(24)};

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

export const Statistic = styled.div`
  min-width: 70vw;
  margin-top: ${rem(24)};
  padding: ${rem(24)};
  background-color: ${colors.white};
  border-radius: ${rem(8)};

  &:only-child {
    min-width: 100%;
  }

  &:first-child {
    padding-left: ${CONTAINER_PADDING};
  }

  &:last-child {
    padding-right: ${CONTAINER_PADDING};
  }

  @media ${breakpoints.tablet} {
    flex: 1;
    min-width: 0;
  }
`;

export const LeftContentWrapper = styled.div`
  @media ${breakpoints.tablet} {
    width: 50%;
  }
`;

export const TilesSectionWrapper = styled.div`
  display: flex;
  gap: ${rem(16)};
`;

export const RightContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(24)};
  background-color: ${colors.white};
  border-radius: ${rem(8)};

  @media ${breakpoints.tablet} {
    width: 50%;
  }
`;

export const BarChartWrapper = styled.div`
  padding: ${rem(24)};
  background-color: ${colors.white};
  border-radius: ${rem(8)};
`;

export const BarChartHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  align-items: left;
  margin-bottom: ${rem(12)};

  @media ${breakpoints.tablet} {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: space-between;
  margin-bottom: ${rem(24)};

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

export const ChartWrapper = styled.div`
  margin: auto 0;
`;
