import styled from 'styled-components';
import { rem, rgba } from 'polished';
import ReactModal from 'react-modal';

import {
  globalSize,
  colorName,
  colors,
  breakpoints,
  layers,
  transitions,
  variables,
} from 'styles';

const PADDING = globalSize.defaultPadding;
const CLOSE_ICON = PADDING;

export const CustomModal = styled(ReactModal)`
  position: relative;
  display: block;
  height: ${({ isFullHeight }) => isFullHeight && '100%'};
  margin: 0;
  background: ${colorName.white};
  outline: 0;
  box-shadow: none;

  @media ${breakpoints.tablet} {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: ${rem(728)};
    min-height: ${({ autoHeight }) => (autoHeight ? 'auto' : rem(660))};
    background: ${colorName.white};
    border-radius: ${rem(variables.borderRadius * 3)};
    transform: translate(-50%, 0);
    opacity: 0;
    transition-timing-function: ${transitions.spring};
    transition-duration: ${transitions.uiTiming};
    transition-property: top, transform;

    &.ReactModal__Content--after-open {
      top: 10%;
      opacity: 1;
    }
  }

  @media ${breakpoints.smallScreen} {
    max-width: ${rem(880)};
  }
`;

export const CloseButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: ${rem(PADDING)};
  right: ${rem(PADDING / 1.5)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(CLOSE_ICON)};
  height: ${rem(CLOSE_ICON)};
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  appearance: none;

  & > svg {
    display: block;
    width: ${rem(CLOSE_ICON / 1.25)};
    height: ${rem(CLOSE_ICON / 1.25)};
    color: ${colors.mediumGray};
    transition: color ${transitions.uiTiming} ${transitions.spring};
  }

  &:hover,
  &:focus {
    outline: 0;
    box-shadow: none;

    & > svg {
      color: ${colorName.black};
    }
  }

  @media ${breakpoints.tablet} {
    top: ${rem(PADDING * 1.5)};
    right: ${rem(PADDING * 1.5)};
  }
`;

export const StandardModalContent = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: ${rem(PADDING * 1.5)} ${rem(PADDING)} ${rem(PADDING)};
  text-align: ${({ align }) => (align ? align : 'center')};

  @media ${breakpoints.tablet} {
    width: ${({ isNarrowContent }) => isNarrowContent && rem(580)};
    padding: ${rem(PADDING * 2.5)} ${rem(PADDING * 2)};
  }
`;

export const customModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: layers.medium,
    background: rgba(colorName.black, 0.25),
    overflow: 'auto',
  },
};
