import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { checkStatus, clearState } from 'stores/checkMisconductStatus';

import MisconductDetails from './parts/MisconductDetails';
import TokenForm from './parts/TokenForm';
import { useRecaptchaContext } from 'components/Recaptcha/UseRecaptchaContext';

const CheckMisconductStatus = () => {
  const useRecaptcha = useRecaptchaContext();
  const executeRecaptcha = useRecaptcha();

  const dispatch = useDispatch();
  const { misconductData, error, archived } = useSelector(
    (state) => state.checkMisconductStatus
  );

  useEffect(() => {
    dispatch(clearState());
    return () => dispatch(clearState());
  }, [dispatch]);

  const handleTokenSubmit = useCallback(
    (token) => dispatch(checkStatus(token, executeRecaptcha)),
    [dispatch, executeRecaptcha]
  );

  return !misconductData ? (
    <TokenForm statusCheckError={error} tokenSubmitted={handleTokenSubmit} />
  ) : (
    <MisconductDetails misconductData={misconductData} archived={archived} />
  );
};

export default CheckMisconductStatus;
