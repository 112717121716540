import { useProgressingNumber } from 'helpers/hooks';
import React from 'react';
import PropTypes from 'prop-types';

import { dataPortionShape } from 'models';

import { Wrapper, Bar, Labels, Label, LabelExplanation } from './style';

const propTypes = {
  portionProgressed: PropTypes.shape(dataPortionShape).isRequired,
  portionRemaining: PropTypes.shape(dataPortionShape).isRequired,
  hasLabels: PropTypes.bool,
  hasExplanations: PropTypes.bool,
  isAnimated: PropTypes.bool,
};

const defaultProps = {
  hasLabels: true,
  hasExplanations: true,
  isAnimated: true,
};

const LabelComponent = ({ value, label }) => (
  <Label>
    {value}
    {label && <LabelExplanation>{label}</LabelExplanation>}
  </Label>
);
LabelComponent.propTypes = dataPortionShape;

const PROGRESS_ANIMATION_DURATION_MS = 800;

export const ProgressBar = ({
  portionProgressed,
  portionRemaining,
  hasLabels,
  hasExplanations,
  isAnimated,
}) => {
  const progressedVal = useProgressingNumber({
    start: isAnimated ? 0 : portionProgressed.value,
    target: portionProgressed.value,
    duration: PROGRESS_ANIMATION_DURATION_MS,
  });
  const remainingVal = useProgressingNumber({
    start: isAnimated ? 0 : portionRemaining.value,
    target: portionRemaining.value,
    duration: PROGRESS_ANIMATION_DURATION_MS,
  });

  return (
    <Wrapper>
      <Bar
        value={progressedVal}
        max={portionProgressed.value + portionRemaining.value}
        isAnimated={isAnimated}
        duration={PROGRESS_ANIMATION_DURATION_MS}
      />

      {hasLabels && (
        <Labels>
          <LabelComponent
            value={progressedVal}
            label={hasExplanations ? portionProgressed.label : ''}
          />

          <LabelComponent
            value={remainingVal}
            label={hasExplanations ? portionRemaining.label : ''}
          />
        </Labels>
      )}
    </Wrapper>
  );
};

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
