import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { HeaderWrapper, ChartWrapper, FilterWrapper, BarChartHeader } from './styles';
import Typography from 'components/Typography';
import { messages } from '../messages';
import { globalMessages } from 'translations';
import BarChart from 'components/Charts/BarChart';
import SimpleSelect from 'components/AutocompleteSelect/SimpleSelect';
import { useDispatch, useSelector } from 'react-redux';
import { getBarStatistics } from 'stores/coDashboard';

export const sortOptions = [
  {
    value: 'open_asc',
    label: <FormattedMessage {...messages.sortOpenAsc} />,
    sortBy: 'open',
    sortOrder: 'asc',
  },
  {
    value: 'open_desc',
    label: <FormattedMessage {...messages.sortOpenDesc} />,
    sortBy: 'open',
    sortOrder: 'desc',
  },
  {
    value: 'closed_asc',
    label: <FormattedMessage {...messages.sortClosedAsc} />,
    sortBy: 'closed',
    sortOrder: 'asc',
  },
  {
    value: 'closed_desc',
    label: <FormattedMessage {...messages.sortClosedDesc} />,
    sortBy: 'closed',
    sortOrder: 'desc',
  },
  {
    value: 'total_asc',
    label: <FormattedMessage {...messages.sortTotalAsc} />,
    sortBy: 'total',
    sortOrder: 'asc',
  },
  {
    value: 'total_desc',
    label: <FormattedMessage {...messages.sortTotalDesc} />,
    sortBy: 'total',
    sortOrder: 'desc',
  },
];

export const BarChartSection = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { barStatistics, barCategory, barSort, timeFilter, statisticsGroups } =
    useSelector((state) => state.coDashboard);

  return (
    <>
      <HeaderWrapper>
        <BarChartHeader>
          <Typography size="large" unsetWidth xMargin={0}>
            {intl.formatMessage(messages.barChartTitle)}
          </Typography>
          <FilterWrapper>
            <SimpleSelect
              id="reports-category-select"
              options={statisticsGroups}
              value={
                Object.keys(barCategory).length ? [barCategory] : [statisticsGroups[0]]
              }
              onChange={(evt) => {
                dispatch(
                  getBarStatistics({
                    timeFilter,
                    barCategory: evt.target.value,
                    barSort,
                  })
                );
              }}
              marginBottom={0}
            />
          </FilterWrapper>
        </BarChartHeader>
        <BarChartHeader>
          <Typography xMargin={0} unsetWidth>
            {intl.formatMessage(globalMessages.sort)}:
          </Typography>
          <FilterWrapper>
            <SimpleSelect
              id="reports-sort-select"
              options={sortOptions}
              value={Object.keys(barSort).length ? [barSort] : [sortOptions[0]]}
              onChange={(evt) => {
                dispatch(
                  getBarStatistics({
                    timeFilter,
                    barCategory,
                    barSort: evt.target.value,
                  })
                );
              }}
              marginBottom={0}
            />
          </FilterWrapper>
        </BarChartHeader>
      </HeaderWrapper>
      <ChartWrapper>
        <BarChart
          data={barStatistics}
          datasetLabels={[
            intl.formatMessage(messages.barChartOpened),
            intl.formatMessage(messages.barChartClosed),
            intl.formatMessage(messages.barChartTotal),
          ]}
          legendId="barChartLegend"
        />
      </ChartWrapper>
    </>
  );
};

export default BarChartSection;
