import React from 'react';
import { useIntl } from 'react-intl';

import { HeaderWrapper, ChartWrapper } from './styles';
import Typography from 'components/Typography';
import DonutChart from 'components/Charts/DonutChart';
import { messages } from '../messages';
import { useSelector } from 'react-redux';

export const DonutChartSection = () => {
  const intl = useIntl();
  // const dispatch = useDispatch();
  const { donutStatistics } = useSelector((state) => state.coDashboard);

  // const updateData = (filters) => {
  //   dispatch(getDonutStatistics({ timeFilter, donutFilters: filters }));
  // };

  return (
    <>
      <HeaderWrapper>
        <Typography size="large">
          {intl.formatMessage(messages.donutChartTitle)}
        </Typography>
        {/* <Multiselect
          fullWidth
          label={intl.formatMessage(globalMessages.filters)}
          options={dashboard.statisticsFilterOptions.donut}
          value={donutFilters}
          onChange={(isChecked, option) => {
            isChecked
              ? updateData([...donutFilters, option.value])
              : updateData(donutFilters?.filter((a) => a !== option.value));
          }}
        /> */}
      </HeaderWrapper>
      {donutStatistics && !!donutStatistics.groups.length && (
        <ChartWrapper>
          <DonutChart
            data={{
              labels: donutStatistics.groups.map((item) => `${item.label}`),
              datasets: [
                {
                  data: donutStatistics.groups.map((item) => item.total),
                  backgroundColor: [
                    '#FFE600',
                    '#3D108A',
                    '#FF6D00',
                    '#2E2E38',
                    '#188CE5',
                    '#FF4136',
                    '#27ACAA',
                  ],
                  borderWidth: 0,
                },
              ],
            }}
            numberInside={donutStatistics.total}
            textInside={intl.formatMessage({ ...messages.textInsideDonut })}
            legendId="donutChartLegend"
          />
        </ChartWrapper>
      )}
    </>
  );
};

export default DonutChartSection;
