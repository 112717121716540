import { rem } from 'polished';
import styled from 'styled-components';

export const CustomLegend = styled.div`
  float: right;
`;

export const Wrapper = styled.div`
  height: ${({ columnsCount }) => rem(`${columnsCount * 60}px`)};
`;
