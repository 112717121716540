export const ROUTING = {
  INDEX: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  SET_PASSWORD: '/set-password/:token',
  RESET_PASSWORD: '/reset-password/:token',
  CHECK_STATUS: '/check-status',
  REPORT_STATUS: '/my-report',
  REPORT_MISCONDUCT: '/report-misconduct',
  SURVEYS_SURVEY: '/surveys/:customPath',
  REPORTS: '/reports',
  REPORT_PREVIEW: '/reports/:id',
  ADD_REPORT: '/add-report',
  DASHBOARD_EMPLOYEE: '/dashboard',
  DASHBOARD_CO: '/dashboard-co',
  NOTIFICATIONS: '/notifications',
  TRAININGS: '/trainings',
  DOCUMENTS: '/documents',
  NEW_DOCUMENT: '/documents/create',
  DOCUMENT_EDIT: '/documents/edit-document/:id',
  USER_DOCUMENTS_LIST: '/documents/preview',
  USER_MISCONDUCTS_LIST: '/misconducts/preview',
  USER_MISCONDUCT_DETAILS: '/misconducts/preview/:id',
  DOCUMENT_PREVIEW: '/documents/preview/:id',
  DOCUMENT_OWNER: '/documents/preview/owned/:id',
  GROUPS: '/groups',
  GROUPS_ADD_NEW: '/groups/add-group',
  GROUPS_DETAILS: '/groups/edit-group/:id',
  GROUPS_ADD_USERS: '/groups/add-users/:id',
  USER_MANAGEMENT: '/users',
  MANUAL_IMPORT: '/users/manual-import',
  FILE_IMPORT: '/users/file-import',
  USER_DETAILS: '/users/profile/:id',
  USER_DOCUMENTS: '/users/documents/:id',
  CO_CHATS: '/chats',
  EMPLOYEE_SESSION_TIMEOUT: '/employee-session-timeout',
  CO_SESSION_TIMEOUT: '/co-session-timeout',
  ACCOUNT: '/account/change-password',
  UNAUTHORIZED: '/unauthorized',
  NOT_FOUND: '/404',
};

export const generateRouteLink = (route, id) => {
  const routeString = route;
  const param = id;
  const routeWithParams = routeString.substring(0, routeString.indexOf(':'));

  return routeWithParams + param;
};

export const resolveDashboardRoute = (hasOfficerRights) =>
  hasOfficerRights ? ROUTING.DASHBOARD_CO : ROUTING.DASHBOARD_EMPLOYEE;

export const resolveDocumentsRoute = (hasOfficerRights) =>
  hasOfficerRights ? ROUTING.DOCUMENTS : ROUTING.USER_DOCUMENTS_LIST;

export const resolveMisconductsRoute = (hasOfficerRights) =>
  hasOfficerRights ? ROUTING.REPORTS : ROUTING.USER_MISCONDUCTS_LIST;

export const isPublicRoute = (route) => {
  const publicRoutes = [
    ROUTING.CO_SESSION_TIMEOUT,
    ROUTING.EMPLOYEE_SESSION_TIMEOUT,
    ROUTING.REPORT_MISCONDUCT,
    ROUTING.SURVEYS_SURVEY,
    ROUTING.CHECK_STATUS,
    ROUTING.FORGOT_PASSWORD,
    ROUTING.SET_PASSWORD,
    ROUTING.RESET_PASSWORD,
  ];

  const isIndexPage = route === ROUTING.INDEX;
  const isPublicPage = !!publicRoutes.find((element) => route.includes(element));

  return isPublicPage || isIndexPage;
};
