import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import SearchIcon from 'components/Svg/SearchIcon';
import Typography from 'components/Typography';

import { StyledLabel } from 'styles/commonStyles';
import { colorName } from 'styles';
import {
  IconWrapper,
  InputContentWrapper,
  InputWrapper,
  RequiredMark,
  StyledInput,
} from './style';
import { globalMessages } from 'translations';
import Tooltip from 'components/Tooltip';

const defaultPropTypes = {
  marginBottom: 24,
  minLength: 1,
};

const propTypes = {
  isDark: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  placeholder: PropTypes.string,
  marginBottom: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  isSearchInput: PropTypes.bool,
  innerRef: PropTypes.object,
  minLength: PropTypes.number,
  value: PropTypes.string,
  type: PropTypes.string,
  autocomplete: PropTypes.string,
  tooltip: PropTypes.string,
};

const Input = ({
  isDark,
  label,
  id,
  icon: Icon,
  placeholder,
  marginBottom,
  onChange,
  required,
  innerRef,
  isSearchInput,
  minLength,
  value,
  autocomplete,
  type,
  tooltip,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [hasError, setHasError] = useState(false);
  const [wasTouched, setWasTouched] = useState(false);

  const handleChange = (e) => {
    const valueLength = e.target.value.length;
    const isMinLengthError = valueLength < minLength;
    const isNotEmptyValue = valueLength > 0;
    let isError = false;

    if (required) {
      isError = isMinLengthError;
    } else {
      isError = isNotEmptyValue && isMinLengthError;
    }
    setHasError(isError);

    onChange && onChange(e);
  };

  const handleBlur = () => {
    if (!wasTouched) {
      setWasTouched(true);
      required && setHasError(!value);
    }
  };

  return (
    <InputWrapper marginBottom={marginBottom}>
      {label && (
        <StyledLabel htmlFor={id} isDark={isDark}>
          {label}
          {required && (
            <RequiredMark data-testid="required-mark" isDark={isDark}>
              *
            </RequiredMark>
          )}
          {tooltip && <Tooltip id={id} content={tooltip} />}
        </StyledLabel>
      )}

      <InputContentWrapper>
        <StyledInput
          isDark={isDark}
          aria-label={label ? label : placeholder}
          onChange={handleChange}
          id={id}
          placeholder={placeholder}
          icon={Icon}
          required={required}
          onBlur={required && handleBlur}
          hasError={hasError}
          wasTouched={wasTouched}
          ref={innerRef && innerRef}
          isSearchInput={isSearchInput}
          minLength={minLength}
          value={value}
          type={type}
          autocomplete={autocomplete || 'off'}
          {...props}
        />

        {isSearchInput && (
          <IconWrapper isSearchInput={isSearchInput}>
            <SearchIcon />
          </IconWrapper>
        )}

        {Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}
      </InputContentWrapper>

      {hasError && (
        <Typography size="tiny" color={colorName.error}>
          {formatMessage(globalMessages.minLengthHelperText, { minLength })}
        </Typography>
      )}
    </InputWrapper>
  );
};

Input.defaultProps = defaultPropTypes;
Input.propTypes = propTypes;

export default Input;
