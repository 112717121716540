import styled from 'styled-components';
import { breakpoints } from 'styles';

export const ChartWithLegendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media ${breakpoints.smallScreen} {
    flex-direction: row;
    justify-content: center;
  }
`;

export const ChartWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 240px;

  @media ${breakpoints.smallScreen} {
    width: 50%;
  }
`;

export const CustomLegend = styled.div`
  min-width: 100%;

  @media ${breakpoints.smallScreen} {
    min-width: 50%;
  }
`;
