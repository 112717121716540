import React from 'react';
import PropTypes from 'prop-types';
import SplashScreen from 'components/SplashScreen';
import { ErrorWrapper, Logo } from 'components/ErrorBoundary/style';
import ErrorIcon from 'components/Svg/ErrorIcon';

const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const SessionTimeoutLayout = ({ title, subtitle, children }) => {
  return (
    <ErrorWrapper>
      <Logo />

      <SplashScreen icon={ErrorIcon} title={title} subtitle={subtitle} />

      {children}
    </ErrorWrapper>
  );
};

SessionTimeoutLayout.propTypes = propTypes;

export default SessionTimeoutLayout;
