import moment from 'moment';

import api from 'api';
import {
  clearFormStructure,
  setCurrentStep,
  setFormId,
  setHasNextStep,
  setHasPreviousStep,
  setStepsCount,
  setSummaryModal,
  storeFormStep,
  storeFormSummary,
} from './misconductForm';
import {
  deserializeReportData,
  deserializeReportSummaryData,
  prepareStepAnswersPayload,
} from 'deserializers';
import { langCodes } from 'helpers/commonConstants';

// Actions
export const STORE_CUSTOM_SIDEBAR_TEXT =
  'anonymousMisconductForm.STORE_CUSTOM_SIDEBAR_TEXT';
export const STORE_PRIVACY_POLICY_URL =
  'anonymousMisconductForm.STORE_PRIVACY_POLICY_URL';
export const STORE_TERMS_URL = 'anonymousMisconductForm.STORE_TERMS_URL';
export const SET_PRIVACY_POLICY_ACCEPTED =
  'anonymousMisconductForm.SET_PRIVACY_POLICY_ACCEPTED';
export const SET_REPORT_TOKEN = 'anonymousMisconductForm.SET_REPORT_TOKEN';
export const SET_LOADING = 'anonymousMisconductForm.SET_LOADING';
export const SET_ERROR = 'anonymousMisconductForm.SET_ERROR';
export const CLEAR_STATE = 'anonymousMisconductForm.CLEAR_STATE';
export const SET_DATA_SAVED = 'anonymousMisconductForm.SET_DATA_SAVED';
export const STORE_CUSTOM_DETAILS_INSTRUCTIONS =
  'anonymousMisconductForm.STORE_CUSTOM_DETAILS_INSTRUCTIONS';
export const STORE_CUSTOM_DETAILS_FILE =
  'anonymousMisconductForm.STORE_CUSTOM_DETAILS_FILE';
export const STORE_FILES = 'anonymousMisconductForm.STORE_FILES';
export const DELETE_FILE = 'anonymousMisconductForm.DELETE_FILE';

// Reducer
const initialState = {
  privacyPolicyAccepted: false,
  personalDetailsInstructions: '',
  personalDetailsFile: '',
  isLoading: false,
  isDataSaved: false,
  files: [],
  reportToken: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_CUSTOM_SIDEBAR_TEXT:
      return {
        ...state,
        customSidebarText: action.payload,
      };
    case STORE_FILES:
      return {
        ...state,
        files: [...state.files, action.payload],
      };
    case DELETE_FILE:
      return {
        ...state,
        files: state.files.filter((item) => item.id !== action.payload),
      };
    case STORE_CUSTOM_DETAILS_INSTRUCTIONS:
      return {
        ...state,
        personalDetailsInstructions: action.payload,
      };
    case STORE_CUSTOM_DETAILS_FILE:
      return {
        ...state,
        personalDetailsFile: action.payload,
      };
    case STORE_PRIVACY_POLICY_URL:
      return {
        ...state,
        privacyPolicyUrl: action.payload,
      };
    case STORE_TERMS_URL:
      return {
        ...state,
        termsUrl: action.payload,
      };
    case SET_PRIVACY_POLICY_ACCEPTED:
      return {
        ...state,
        privacyPolicyAccepted: action.payload,
      };
    case SET_REPORT_TOKEN:
      return {
        ...state,
        reportToken: action.payload,
      };
    case SET_DATA_SAVED:
      return {
        ...state,
        isDataSaved: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setDataSaved(bool) {
  return { type: SET_DATA_SAVED, payload: bool };
}

export const storeFiles = (payload) => ({ type: STORE_FILES, payload });
export const deleteFile = (payload) => ({ type: DELETE_FILE, payload });

export function storeCustomSidebarText(data) {
  return { type: STORE_CUSTOM_SIDEBAR_TEXT, payload: data };
}

export function storePersonalDetailsInstruction(data) {
  return { type: STORE_CUSTOM_DETAILS_INSTRUCTIONS, payload: data };
}

export function storePersonalDetailsFile(data) {
  return { type: STORE_CUSTOM_DETAILS_FILE, payload: data };
}

export function storePrivacyPolicyUrl(data) {
  return { type: STORE_PRIVACY_POLICY_URL, payload: data };
}

export function storeTermsOfUseUrl(data) {
  return { type: STORE_TERMS_URL, payload: data };
}

export function setPrivacyPolicyAccepted(bool) {
  return { type: SET_PRIVACY_POLICY_ACCEPTED, payload: bool };
}

export function setReportToken(token) {
  return { type: SET_REPORT_TOKEN, payload: token };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

export function setError(error) {
  return { type: SET_ERROR, payload: error };
}

export function clearState() {
  return { type: CLEAR_STATE };
}

// Side Effects
export function initializeFormForAnonymousUser(executeRecaptcha, apiClient = api) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const langCode = getState().auth.userLanguage;
      if (langCode !== langCodes.en) {
        await import(`moment/locale/${langCode}`);
      }
      moment.locale(langCode);

      const recaptchaToken = await executeRecaptcha();

      const surveyIdResponse = await apiClient.misconduct.initializeSurvey(
        langCode,
        recaptchaToken
      );
      const surveyId = surveyIdResponse.data.response_id;
      const formStepResponse = await apiClient.misconduct.getSurveyStep(
        langCode,
        surveyId
      );

      const customInstructions = await apiClient.misconduct.getCustomInstructions(
        langCode
      );
      const serializedData = deserializeReportData(formStepResponse.data.response);

      dispatch(setHasNextStep(serializedData.has_next_step));
      dispatch(setHasPreviousStep(serializedData.has_previous_step));
      dispatch(setFormId(surveyId));
      dispatch(storeFormStep(serializedData.current_step));
      dispatch(setStepsCount(serializedData.steps_count));
      dispatch(setCurrentStep(serializedData.step_number));
      dispatch(setSummaryModal(serializedData.summary_modal));
      dispatch(storeCustomSidebarText(customInstructions.data.report_instructions));
      dispatch(storePersonalDetailsInstruction(customInstructions.data.report_tip));
    } catch (error) {
      // eslint-disable-next-line no-console

      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function sendCurrentStepAndGetNextOne(apiClient = api) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const langCode = getState().auth.userLanguage;
      const currentStepData = getState().misconductForm.formStep;
      const surveyId = getState().misconductForm.formId;
      const stepAnswersPayload = prepareStepAnswersPayload(currentStepData);

      if (langCode !== langCodes.en) {
        await import(`moment/locale/${langCode}`);
      }
      moment.locale(langCode);

      await apiClient.misconduct.postCurrentStepAnswers({
        language: langCode,
        answers: stepAnswersPayload,
        response_id: surveyId,
      });

      await apiClient.misconduct.initializeSurveyNextStep(surveyId);
      const formStepResponse = await apiClient.misconduct.getSurveyStep(
        langCode,
        surveyId
      );

      const serializedData = deserializeReportData(formStepResponse.data.response);
      dispatch(setHasNextStep(serializedData.has_next_step));
      dispatch(setHasPreviousStep(serializedData.has_previous_step));
      dispatch(setStepsCount(serializedData.steps_count));
      dispatch(setCurrentStep(serializedData.step_number));
      dispatch(setSummaryModal(serializedData.summary_modal));
      dispatch(storeFormStep(serializedData.current_step));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function sendCurrentStepAndGetSummary(apiClient = api) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const langCode = getState().auth.userLanguage;
      const currentStepData = getState().misconductForm.formStep;
      const surveyId = getState().misconductForm.formId;
      const stepAnswersPayload = prepareStepAnswersPayload(currentStepData);

      if (langCode !== langCodes.en) {
        await import(`moment/locale/${langCode}`);
      }
      moment.locale(langCode);

      await apiClient.misconduct.postCurrentStepAnswers({
        language: langCode,
        answers: stepAnswersPayload,
        response_id: surveyId,
      });

      // await api.misconduct.initializeSurveyNextStep(surveyId);
      const formSummaryResponse = await apiClient.misconduct.getSurveySummary(
        langCode,
        surveyId
      );

      const serializedData = deserializeReportSummaryData(
        formSummaryResponse.data.response
      );
      dispatch(storeFormSummary(serializedData));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function sendCurrentStepAndGetPreviousOne(apiClient = api) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const langCode = getState().auth.userLanguage;
      const currentStepData = getState().misconductForm.formStep;
      const surveyId = getState().misconductForm.formId;
      const stepAnswersPayload = prepareStepAnswersPayload(currentStepData);

      if (langCode !== langCodes.en) {
        await import(`moment/locale/${langCode}`);
      }
      moment.locale(langCode);

      await apiClient.misconduct.postCurrentStepAnswers({
        language: langCode,
        answers: stepAnswersPayload,
        response_id: surveyId,
      });

      await apiClient.misconduct.initializeSurveyPreviousStep(surveyId);
      const formStepResponse = await apiClient.misconduct.getSurveyStep(
        langCode,
        surveyId
      );
      const serializedData = deserializeReportData(formStepResponse.data.response);
      dispatch(setHasNextStep(serializedData.has_next_step));
      dispatch(setHasPreviousStep(serializedData.has_previous_step));
      dispatch(setStepsCount(serializedData.steps_count));
      dispatch(setCurrentStep(serializedData.step_number));
      dispatch(setSummaryModal(serializedData.summary_modal));
      dispatch(storeFormStep(serializedData.current_step));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function getSpecificPreviousStep(stepId, apiClient = api) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const langCode = getState().auth.userLanguage;
      const surveyId = getState().misconductForm.formId;

      await apiClient.misconduct.initializeSurveySpecificPreviousStep(surveyId, stepId);
      const formStepResponse = await apiClient.misconduct.getSurveyStep(
        langCode,
        surveyId
      );
      const serializedData = deserializeReportData(formStepResponse.data.response);
      dispatch(setHasNextStep(serializedData.has_next_step));
      dispatch(setHasPreviousStep(serializedData.has_previous_step));
      dispatch(setStepsCount(serializedData.steps_count));
      dispatch(setCurrentStep(serializedData.step_number));
      dispatch(storeFormStep(serializedData.current_step));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function sendMisconduct(apiClient = api) {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));

      const fileIds = getState().anonymousMisconductForm.files.map((file) => file.id);

      const langCode = getState().auth.userLanguage;
      const currentStepData = getState().misconductForm.formStep;
      const surveyId = getState().misconductForm.formId;
      const stepAnswersPayload = prepareStepAnswersPayload(currentStepData);

      await apiClient.misconduct.postCurrentStepAnswers({
        language: langCode,
        answers: stepAnswersPayload,
        response_id: surveyId,
      });

      if (fileIds && fileIds.length > 0) {
        await apiClient.misconduct.postMisconductFiles({
          file_ids: fileIds,
          response_id: surveyId,
        });
      }

      const intakeMethodExist = getState().officerMisconductForm.intakeMethod.length > 0;
      const metadata = intakeMethodExist
        ? {
            language: getState().auth.userLanguage,
            intake_method: getState().officerMisconductForm.intakeMethod,
            whistleblower_email: getState().officerMisconductForm.tokenEmail,
          }
        : {
            language: getState().auth.userLanguage,
          };

      const serializedData = JSON.stringify({
        metadata: metadata,
        response_id: surveyId,
      });

      const response = await apiClient.misconduct.submitSurvey(serializedData);
      dispatch(setReportToken(response.data.report_token));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function subscribeToUpdates({
  email,
  fullname,
  phone,
  reportToken,
  executeRecaptcha,
}) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const recaptchaToken = await executeRecaptcha();

      await api.misconduct.followUpdates({
        email,
        fullname,
        phone,
        token: reportToken,
        recaptchaToken,
      });

      dispatch(setDataSaved(true));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function clearFormForAnonymousUser() {
  return (dispatch) => {
    dispatch(clearState());
    dispatch(clearFormStructure());
  };
}
