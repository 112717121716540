import React from 'react';
import PropTypes from 'prop-types';

import ToastMessages from 'components/ToastMessages';

import { SingleColumnWrapper, LeftColumnContainer } from 'styles/commonStyles';

const propTypes = {
  children: PropTypes.node,
  isWhiteBackground: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  withNotifications: PropTypes.bool,
};

const defaultProps = {
  withNotifications: true,
};

const SingleColumn = ({
  children,
  isWhiteBackground,
  isFullscreen,
  withNotifications,
}) => (
  <SingleColumnWrapper isWhiteBackground={isWhiteBackground} isFullscreen={isFullscreen}>
    <LeftColumnContainer role="main">
      {withNotifications && <ToastMessages />}
      {children}
    </LeftColumnContainer>
  </SingleColumnWrapper>
);

SingleColumn.propTypes = propTypes;
SingleColumn.defaultProps = defaultProps;

export default SingleColumn;
