import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import { StyledLink, Wrapper, YellowText } from './style';

const propTypes = {
  source: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
  color: PropTypes.string,
  bottomMargin: PropTypes.number,
};

const MarkdownParser = ({ source, fontSize, color, bottomMargin }) => {
  return (
    <Wrapper fontSize={fontSize} color={color} bottomMargin={bottomMargin}>
      <ReactMarkdown
        components={{
          b: ({ ...props }) => <strong {...props} />,
          a: ({ ...props }) => <StyledLink target="_blank" {...props} />,
          code: ({ ...props }) => <YellowText {...props} />,
        }}
      >
        {source}
      </ReactMarkdown>
    </Wrapper>
  );
};

MarkdownParser.propTypes = propTypes;

export default MarkdownParser;
