import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  anonymousReportingChannel: {
    id: 'CheckMisconductStatus.anonymousReportingChannel',
    defaultMessage: 'View updates for your report',
    description: 'Check Status Header',
  },
  typeInToken: {
    id: 'CheckMisconductStatus.typeInToken',
    defaultMessage:
      'Enter the token you received when submitting your report to anonymously view report updates.',
    description: 'Check Status Subheader',
  },
  enterTokenAgain: {
    id: 'CheckMisconductStatus.enterTokenAgain',
    defaultMessage: 'Token incorrect. Please try again.',
    description: 'Check Status Error Message',
  },
  reportToken: {
    id: 'CheckMisconductStatus.reportToken',
    defaultMessage: 'Token',
    description: 'Token Input Label',
  },
  checkTheStatus: {
    id: 'CheckMisconductStatus.checkTheStatus',
    defaultMessage: 'Confirm',
    description: 'Check Status Button',
  },
  routeLoading: {
    id: 'MisconductStatus.routeLoading',
    defaultMessage: 'It will take just a moment...',
    description: 'Loader text',
  },
  misconductHasBeenArchived: {
    id: 'MisconductStatus.misconductHasBeenArchived',
    defaultMessage: 'Report has been archived',
    description: 'Archived misconduct info',
  },
});
