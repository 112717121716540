import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { breakpoints, colorName, colors, font, transitions, variables } from 'styles';

export const SMALL_BUTTON_HEIGHT = 24;
export const BUTTON_HEIGHT = 44;
export const SMALL_BUTTON_HORIZONTAL_PADDING = 12;
export const BUTTON_HORIZONTAL_PADDING = 22;
export const ICON_SIZE = 14;
export const ICON_SPACING = 8;
export const BUTTON_RADIUS = variables.borderRadius;

export const disabledBase = css`
  color: ${colors.darkGray};
  background: ${colors.disabledGray};
  pointer-events: none;
`;

export const iconWrapperBase = css`
  display: block;
  width: ${rem(ICON_SIZE)};
  height: ${rem(ICON_SIZE)};

  &:first-child {
    &:not(:last-child) {
      margin: 0 ${rem(ICON_SPACING)} 0 0;
    }
  }

  &:last-child {
    &:not(:first-child) {
      margin: 0 0 0 ${rem(ICON_SPACING)};
    }
  }
`;

export const pillWrapperBase = css`
  margin-left: ${rem(8)};
  padding: ${rem(2)} ${rem(4)};
  color: ${colorName.white};
  font-size: ${rem(font.size.tiny)};
  line-height: 1;
  background: ${colorName.confidentGrey};
  border-radius: ${rem(2)};
  transition: color, background ${transitions.uiTiming} ${transitions.ease};
`;

export const buttonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ isFluid }) => (isFluid ? '100%' : 'auto')};
  max-width: ${({ maxWidth, isFluid }) => !isFluid && maxWidth && rem(maxWidth)};
  min-height: ${rem(BUTTON_HEIGHT)};
  padding: 0 ${rem(BUTTON_HORIZONTAL_PADDING)};
  /* overflow: hidden; */
  color: ${colorName.confidentGrey};
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.small)};
  text-align: center;
  /* white-space: nowrap; */
  text-decoration: none;
  background: transparent;
  border: 1px solid transparent;
  border-radius: ${rem(BUTTON_RADIUS)};
  transition: border, color, background ${transitions.uiTiming} ${transitions.ease};
  user-select: none;

  &[disabled] {
    ${disabledBase};
  }

  ${({ isSmall }) =>
    isSmall &&
    `
    min-height: ${rem(SMALL_BUTTON_HEIGHT)};
    padding: 0 ${rem(SMALL_BUTTON_HORIZONTAL_PADDING)};
    font-size: ${rem(font.size.tiny)};
  `};

  ${({ primary, isDark }) =>
    primary &&
    `
    color: ${colorName.confidentGrey};
    background: ${colorName.primary};
    
    &:hover {
      color: ${colorName.white};
      background: ${colorName.confidentGrey};
      border: 1px solid ${isDark ? colorName.primary : 'transparent'};
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px ${colors.darkYellow};
    }
  `};

  ${({ secondary, isDark }) =>
    secondary &&
    `
    color: ${isDark ? colors.white : colorName.confidentGrey};
    background: ${isDark ? colorName.confidentGrey : colorName.white};
    border: 1px solid ${colors.gray};
    
    &:hover {
      color: ${isDark ? colorName.confidentGrey : colorName.white};
      background: ${isDark ? colorName.white : colorName.confidentGrey};

      ${PillWrapper} {
        color: ${colorName.confidentGrey};
        background: ${colorName.white};
      }
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px ${colors.mediumGray};
    }
    
    &[disabled] {
      border: 1px solid ${colors.disabledGray};
            
      ${PillWrapper} {
        background: ${colors.darkGray};
      }
    }
  `};

  ${({ transparent }) =>
    transparent &&
    `
    &:hover {
      color: ${colorName.confidentGrey};
      text-decoration: underline
    }
    
    &[disabled] {
      background: transparent;
    }
  `};

  ${({ isDelete }) =>
    isDelete &&
    `
    color: ${colors.white};
    background: ${colorName.error};
    border: 1px solid ${colorName.error};
    
    &:hover {
      color: ${colorName.white};
      background: ${colorName.secondary};
    }
  `};

  ${({ isUtility }) =>
    isUtility &&
    `
    color: ${colorName.secondary};
    background: ${colors.frenchGray};
    border: 1px solid ${colors.frenchGray};
    
    &:hover {
      color: ${colorName.white};
      background: ${colorName.secondary};
    }
  `};

  ${({ isIcon }) =>
    isIcon &&
    `
    padding:0 15px;
  `};

  :hover,
  :focus {
    outline: 0;
    cursor: pointer;
  }

  @media ${breakpoints.tablet} {
    max-width: ${({ maxWidth, isFluid }) => isFluid && maxWidth && rem(maxWidth)};
  }
`;

export const StyledButton = styled.button`
  ${buttonStyles};
`;

export const IconWrapper = styled.span`
  ${iconWrapperBase};
`;

export const PillWrapper = styled.span`
  ${pillWrapperBase}
`;
