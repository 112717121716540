import React from 'react';
import PropTypes from 'prop-types';

import { StyledMultiValueContainer } from 'components/AutocompleteSelect/style';

const propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  selectProps: PropTypes.shape({
    isDark: PropTypes.bool,
  }),
};

export const MultiValueContainer = ({
  children,
  selectProps: { isDark },
  data,
  ...props
}) => {
  return (
    <StyledMultiValueContainer isDark={isDark} type={data?.type} {...props}>
      {children}
    </StyledMultiValueContainer>
  );
};

MultiValueContainer.propTypes = propTypes;
