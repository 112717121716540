import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { StyledInput } from 'components/AutocompleteSelect/style';

const propTypes = {
  selectProps: PropTypes.object,
};

export const Input = ({ selectProps: { isDark }, ...props }) => {
  return (
    <StyledInput isDark={isDark}>
      <components.Input className="selectInput" {...props} />
    </StyledInput>
  );
};

Input.propTypes = propTypes;
