import React from 'react';

function DocumentsIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13 5.5H6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h7c.3 0 .5.2.5.5s-.2.5-.5.5zM13 9.5H6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h7c.3 0 .5.2.5.5s-.2.5-.5.5zM13 13.5H6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h7c.3 0 .5.2.5.5s-.2.5-.5.5z"
      />
      <path
        fill="currentColor"
        d="M15 18.5H4c-1.4 0-2.5-1.1-2.5-2.5V3C1.5 1.6 2.6.5 4 .5h11c1.4 0 2.5 1.1 2.5 2.5v13c0 1.4-1.1 2.5-2.5 2.5zM4 1.5c-.8 0-1.5.7-1.5 1.5v13c0 .8.7 1.5 1.5 1.5h11c.8 0 1.5-.7 1.5-1.5V3c0-.8-.7-1.5-1.5-1.5H4z"
      />
      <path
        fill="currentColor"
        d="M20 23.5H9c-1.4 0-2.5-1.1-2.5-2.5v-.8c0-.3.2-.5.5-.5s.5.2.5.5v.8c0 .8.7 1.5 1.5 1.5h11c.8 0 1.5-.7 1.5-1.5V8c0-.8-.7-1.5-1.5-1.5h-1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h1c1.4 0 2.5 1.1 2.5 2.5v13c0 1.4-1.1 2.5-2.5 2.5z"
      />
    </svg>
  );
}

export default DocumentsIcon;
