import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  preferredLanguage: {
    id: 'MisconductForm.preferredLanguage',
    defaultMessage: 'Preferred language',
    description: 'Preferred language header',
  },
  chooseLanguage: {
    id: 'MisconductForm.chooseLanguage',
    defaultMessage:
      'Please select your preferred language before sending an anonymous report.',
    description: 'Preferred language',
  },
  routeLoading: {
    id: 'MisconductForm.routeLoading',
    defaultMessage: 'It will take just a moment...',
    description: 'Loader text',
  },
  reporting: {
    id: 'MisconductForm.reporting',
    defaultMessage: 'Reporting process',
    description: 'Reporting Sidebar Header',
  },
  reportingSubheader: {
    id: 'MisconductForm.reportingSubheader',
    defaultMessage: 'When you complete the form just click the button below.',
    description: 'Reporting Sidebar Subheader',
  },
});
