export const TextInsideDonutPlugin = {
  beforeDraw: function (chart) {
    const visibleElementIndexes = chart.legend.legendItems
      .filter((item) => item.hidden === false)
      .map((item) => item.index);

    const visibleElementsTotalCount = chart.data.datasets[0].data
      .filter((item, index) => !!visibleElementIndexes.includes(index))
      .reduce((acc, item) => acc + item, 0);

    let width = chart.width,
      height = chart.height,
      ctx = chart.ctx;
    ctx.restore();
    let fontSize = (height / 4).toFixed(2);
    ctx.font = fontSize + 'px Noto Sans, sans-serif';
    ctx.textBaseline = 'middle';
    const textY = chart.chartArea.top + chart.chartArea.bottom / 2;
    let mainText = visibleElementsTotalCount,
      mainTextX = Math.round((width - ctx.measureText(mainText).width) / 2),
      mainTextY = textY / 1.1;
    ctx.fillText(mainText, mainTextX, mainTextY);

    ctx.font = (height / 20).toFixed(2) + 'px Noto Sans, sans-serif';
    let bottomText = chart.config.options.elements.bottomText.text,
      bottomTextX = Math.round((width - ctx.measureText(bottomText).width) / 2),
      bottomTextY = textY / 0.8;
    ctx.fillText(bottomText, bottomTextX, bottomTextY);
    ctx.save();
  },
};
