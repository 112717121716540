import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  stepNumber: {
    id: 'ReportSteps.stepNumber',
    defaultMessage: 'Step {step}',
    description: 'Step Number',
  },
  stepNumberWithMax: {
    id: 'ReportSteps.stepNumberWithMax',
    defaultMessage: 'Step {step} of {maxSteps}',
    description: 'Steps Indicator',
  },
  attachedFiles: {
    id: 'SecondStep.attachedFiles',
    defaultMessage: 'Add attachments',
    description: 'Attached Files Header',
  },
  attachedFilesTip: {
    id: 'SecondStep.attachedFilesTip',
    defaultMessage: 'You may add documentation supporting your report',
    description: 'Attached Files tip',
  },
  addingReport: {
    id: 'AddReport.addingReport',
    defaultMessage: 'Adding report',
    description: 'Adding report Sidebar Header',
  },
  reportingSubheader: {
    id: 'AddReport.reportingSubheader',
    defaultMessage: 'After completing the form just click the button below.',
    description: 'Reporting Sidebar Subheader',
  },
  anonymousReporting: {
    id: 'MisconductForm.anonymousReporting',
    defaultMessage: 'Misconduct reporting',
    description: 'Reporting Sidebar Header',
  },
  anonymousReportingSubheader: {
    id: 'MisconductForm.anonymousReportingSubheader',
    defaultMessage:
      'When you complete the form and feel ready to send the report to Compliance Officer, just click the button below.',
    description: 'Reporting Sidebar Subheader',
  },
  privacyPolicyLink: {
    id: 'MisconductForm.privacyPolicyLink',
    defaultMessage: 'Privacy Policy',
    description: 'Privacy policy link',
  },
  termsLink: {
    id: 'MisconductForm.termsLink',
    defaultMessage: 'Terms of Use',
    description: 'Terms link',
  },
  privacyPolicyDeclaration: {
    id: 'MisconductForm.privacyPolicyDeclaration',
    defaultMessage: 'I have read and agree to the {privacyPolicyLink} and {termsLink}',
    description: 'Privacy policy declaration',
  },
  addNewReport: {
    id: 'AddReport.addNewReport',
    defaultMessage: 'Add new report',
    description: 'Reporting Misconduct header',
  },
  addNewComplain: {
    id: 'MisconductForm.addNewComplain',
    defaultMessage: 'Send new anonymous report',
    description: 'Reporting Misconduct header',
  },
  exitModalHeading: {
    id: 'MisconductForm.exitModalHeading',
    defaultMessage: 'Are you sure you want to exit the survey?',
    description: 'Exit modal heading',
  },
  exitModalDescription: {
    id: 'MisconductForm.exitModalDescription',
    defaultMessage: 'You will lose all your progress',
    description: 'Exit modal description',
  },
  editModalHeading: {
    id: 'MisconductForm.editModalHeading',
    defaultMessage: 'Editing may delete your answers in subsequent steps',
    description: 'Edit modal heading',
  },
  editModalDescription: {
    id: 'MisconductForm.editModalDescription',
    defaultMessage: 'You will have to go through the rest of the survey to submit it',
    description: 'Edit modal description',
  },
  provideYourPersonalData: {
    id: 'MisconductForm.provideYourPersonalData',
    defaultMessage: 'Provide your personal data',
    description: 'Provide your personal data checkbox label',
  },
  notificationDataSaved: {
    id: 'MisconductForm.notificationDataSaved',
    defaultMessage: 'Thank you! Your data has been successfully saved.',
    description: 'Notification data saved',
  },
});
