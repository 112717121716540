import React from 'react';
import PropTypes from 'prop-types';

import { TypographyElement } from './style';

const sizesArray = [
  'tiny',
  'small',
  'normal',
  'medium',
  'big',
  'bigger',
  'large',
  'huge',
  'gigantic',
];
const weightArray = ['light', 'regular', 'bold', 'inherit'];
const alignArray = ['left', 'center', 'right'];

export const typographySizePropTypes = PropTypes.oneOf(sizesArray);

const propTypes = {
  children: PropTypes.any.isRequired,
  component: PropTypes.any,
  size: typographySizePropTypes,
  tabletSize: typographySizePropTypes,
  weight: PropTypes.oneOf(weightArray),
  color: PropTypes.string,
  bottomMargin: PropTypes.number,
  topMargin: PropTypes.number,
  xMargin: PropTypes.number,
  textAlign: PropTypes.oneOf(alignArray),
  unsetWidth: PropTypes.bool,
  maxWidth: PropTypes.number,
  wrapText: PropTypes.bool,
  testingId: PropTypes.string,
};

const defaultPropTypes = {
  component: 'p',
  size: 'normal',
  weight: 'regular',
  bottomMargin: 4,
  topMargin: 0,
  wrapText: false,
};

const Typography = ({ component, children, testingId, ...props }) => {
  return (
    <TypographyElement as={component} data-testid={testingId} {...props}>
      {children}
    </TypographyElement>
  );
};

Typography.defaultProps = defaultPropTypes;
Typography.propTypes = propTypes;

export default Typography;
