import React from 'react';

const DocumentConfirmedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 64" {...props}>
    <title>Document Confirmed</title>
    <path fill="#000" fillRule="evenodd" d="M43 2h2v11h11v2H43V2z" clipRule="evenodd" />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M7 1h37.414L57 13.586V63H7V1zm2 2v58h46V14.414L43.586 3H9z"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M45.414 24L28 41.414 18.586 32 20 30.586l8 8 16-16L45.414 24z"
      clipRule="evenodd"
    />
  </svg>
);

export default DocumentConfirmedIcon;
