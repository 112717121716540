import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { colors } from 'styles';
import { ROUTING } from 'routing';

import Input from 'components/Input';
import Modal from 'components/Modal';
import RoundIconWrap from 'components/RoundIconWrap';
import Typography from 'components/Typography';
import DocumentConfirmedIcon from 'components/Svg/DocumentConfirmedIcon';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { AlertNotification } from 'components/Notification';

import {
  TokenBox,
  TokenModalWrapper,
  TokenEmailBox,
  DocumentLink,
  WithNotificationWrapper,
  Separator,
  DataSavedWrapper,
} from './style';
import { globalMessages } from 'translations';
import { getSubdomain } from 'helpers/functions';
import { messages } from './messages';

const propTypes = {
  token: PropTypes.string.isRequired,
  subscriptionHandler: PropTypes.func.isRequired,
};

const TokenModal = ({ token, subscriptionHandler }) => {
  const intl = useIntl();
  const history = useHistory();
  const [email, updateEmail] = useState(undefined);
  const [fullname, updateFullName] = useState(undefined);
  const [phone, updatePhone] = useState(undefined);
  const [withNotification, toggleNotification] = useState(false);
  const { summaryModal } = useSelector((state) => state.misconductForm);

  const { isDataSaved, isLoading, personalDetailsInstructions } = useSelector(
    (state) => state.anonymousMisconductForm
  );

  const { properties } = useSelector((state) => state.tenantProperties);

  const handleFormSubmission = useCallback(
    (evt) => {
      evt.preventDefault();
      subscriptionHandler(email, fullname, phone);
    },
    [email, fullname, phone, subscriptionHandler]
  );

  return (
    <Modal
      isOpen={true}
      onRequestClose={() => history.push(ROUTING.INDEX)}
      disableBackClick
    >
      <TokenModalWrapper>
        <RoundIconWrap icon={<DocumentConfirmedIcon />} />

        <Typography size="medium" tabletSize="huge" bottomMargin={16}>
          {intl.formatMessage(globalMessages.reportSubmitted)}
        </Typography>

        <Typography
          size="small"
          weight="bold"
          tabletSize="medium"
          color={colors.darkGray}
          bottomMargin={4}
          testingId="token-modal"
        >
          {intl.formatMessage(globalMessages.writeDownYourToken)}
        </Typography>

        <Typography size="small" color={colors.darkGray} bottomMargin={8}>
          {intl.formatMessage(globalMessages.uponReviewing)}
        </Typography>

        {!properties.customConfig && (
          <>
            <AlertNotification>
              {intl.formatMessage(globalMessages.pleaseNote)}
            </AlertNotification>

            <TokenBox>{token}</TokenBox>
          </>
        )}

        {properties.customConfig &&
          properties.customConfig['disableToken'] &&
          !properties.customConfig['disableToken'].includes(getSubdomain()) && (
            <>
              <AlertNotification>
                {intl.formatMessage(globalMessages.pleaseNote)}
              </AlertNotification>

              <TokenBox>{token}</TokenBox>
            </>
          )}

        {properties.customConfig &&
          properties.customConfig['disableNotification'] &&
          !properties.customConfig['disableNotification'].includes(getSubdomain()) &&
          summaryModal?.display &&
          !isDataSaved && (
            <WithNotificationWrapper>
              <Checkbox
                label={intl.formatMessage(messages.provideYourPersonalData)}
                isChecked={withNotification}
                onChange={(evt) => toggleNotification(evt.target.checked)}
                testingId="token-modal-notification-checkbox"
                provideEmailStyle
              />
              <Separator marginTop={8} marginBottom={8} />
              <Typography
                size="small"
                color={withNotification ? colors.mako : colors.mediumGray}
                bottomMargin={16}
              >
                {intl.formatMessage(globalMessages.leaveEmail)}
              </Typography>

              {withNotification && (
                <>
                  <Typography
                    size="small"
                    color={colors.darkGray}
                    bottomMargin={16}
                    maxWidth={520}
                  >
                    {intl.formatMessage(globalMessages.provideEmail)}
                  </Typography>

                  {personalDetailsInstructions && (
                    <Typography size="small" color={colors.darkGray} maxWidth={520}>
                      {personalDetailsInstructions}
                    </Typography>
                  )}

                  {properties.misconductTipFile && (
                    <DocumentLink
                      href={properties.misconductTipFile}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography
                        size="small"
                        color={colors.darkGray}
                        bottomMargin={24}
                        maxWidth={520}
                      >
                        {properties.misconductTipLabel}
                      </Typography>
                    </DocumentLink>
                  )}

                  <TokenEmailBox onSubmit={handleFormSubmission}>
                    {summaryModal?.fields.email.visible && (
                      <Input
                        label={summaryModal?.fields.email.label}
                        placeholder={summaryModal?.fields.email.placeholder}
                        // label={intl.formatMessage(globalMessages.email)}
                        // placeholder={intl.formatMessage(globalMessages.email)}
                        id="email"
                        onChange={(evt) => updateEmail(evt.target.value)}
                        value={email}
                        data-testId="email-input"
                        // type="email"
                      />
                    )}

                    {summaryModal?.fields.fullname.visible && (
                      <Input
                        label={summaryModal?.fields.fullname.label}
                        placeholder={summaryModal?.fields.fullname.placeholder}
                        // label={intl.formatMessage(globalMessages.fullName)}
                        // placeholder={intl.formatMessage(globalMessages.fullName)}
                        id="fullName"
                        onChange={(evt) => updateFullName(evt.target.value)}
                        value={fullname}
                        data-testId="fullname-input"
                      />
                    )}

                    {summaryModal?.fields.phone.visible && (
                      <Input
                        label={summaryModal?.fields.phone.label}
                        placeholder={summaryModal?.fields.phone.placeholder}
                        // label={intl.formatMessage(globalMessages.phone)}
                        // placeholder={intl.formatMessage(globalMessages.phone)}
                        id="phone"
                        onChange={(evt) => updatePhone(evt.target.value)}
                        value={phone}
                        type="tel"
                        data-testId="phone-input"
                      />
                    )}

                    <Button
                      primary
                      isFluid
                      isProcessing={isLoading}
                      maxWidth={280}
                      type="submit"
                    >
                      {intl.formatMessage(globalMessages.save)}
                    </Button>
                  </TokenEmailBox>
                </>
              )}
            </WithNotificationWrapper>
          )}

        {isDataSaved && (
          <DataSavedWrapper>
            <Typography size="small">
              {intl.formatMessage(messages.notificationDataSaved)}
            </Typography>
          </DataSavedWrapper>
        )}

        {((!withNotification && !isDataSaved) || (withNotification && isDataSaved)) && (
          <Button component={Link} to={ROUTING.INDEX} primary isFluid maxWidth={280}>
            {intl.formatMessage(globalMessages.returnToLogin)}
          </Button>
        )}
      </TokenModalWrapper>
    </Modal>
  );
};

TokenModal.propTypes = propTypes;

export default TokenModal;
