import React from 'react';

function Tick(props) {
  return (
    // <svg viewBox="0 0 24 24" {...props}>
    //   <g fill="none" fillRule="evenodd">
    //     <path d="M0 0h24v24H0z" />
    //     <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
    //   </g>
    // </svg>
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Tick</title>
      <path
        d="M8.33335 2.5L3.75002 7.08333L1.66669 5"
        stroke="#F5F5FA"
        strokeWidth="1.6666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Tick;
