import styled from 'styled-components';
import { rem } from 'polished';

import { font } from 'styles';

export const StyledSpan = styled.span`
  color: ${({ color }) => color && color};
  font-weight: ${({ weight }) => weight && font.weight[weight]};
  font-size: ${({ size }) => size && rem(font.size[size])};
`;
