import styled from 'styled-components';
import { hideVisually, rem } from 'polished';

import Tick from 'components/Svg/Tick';

import { breakpoints, colorName, colors, font, transitions, variables } from 'styles';

const CHECKBOX_SIZE = 16;
const ICON_SIZE = 10;

export const CheckboxLabel = styled.label`
  display: inline-flex;
  width: ${({ provideEmailStyle }) => provideEmailStyle && '100%'};
`;

export const TickIcon = styled(Tick)`
  display: block;
  width: ${({ provideEmailStyle }) => (provideEmailStyle ? rem(12) : rem(ICON_SIZE))};
  height: ${({ provideEmailStyle }) => (provideEmailStyle ? rem(12) : rem(ICON_SIZE))};
  color: ${colorName.white};
  transform: rotate(-30deg) scale(0.8);
  opacity: 0;
  transition: transform 0.4s ${transitions.spring}, opacity 0.4s ${transitions.spring};

  @media ${breakpoints.smallScreen} {
    width: ${({ provideEmailStyle }) => (provideEmailStyle ? rem(12) : rem(ICON_SIZE))};
    height: ${({ provideEmailStyle }) => (provideEmailStyle ? rem(12) : rem(ICON_SIZE))};
  }
`;

export const CheckboxLabelWrapper = styled.div`
  display: flex;
  justify-content: ${({ provideEmailStyle }) => provideEmailStyle && 'space-between'};
  width: ${({ provideEmailStyle }) => provideEmailStyle && '100%'};
`;

export const FakeCheckbox = styled.div`
  display: inline-flex;
  flex: 0 0
    ${({ provideEmailStyle }) => (provideEmailStyle ? rem(20) : rem(CHECKBOX_SIZE))};
  align-items: center;
  justify-content: center;
  width: ${({ provideEmailStyle }) => (provideEmailStyle ? rem(20) : rem(CHECKBOX_SIZE))};
  height: ${({ provideEmailStyle }) =>
    provideEmailStyle ? rem(20) : rem(CHECKBOX_SIZE)};
  background: transparent;
  border: 1px solid ${colors.gray};
  border-radius: ${rem(variables.borderRadius)};
  cursor: pointer;
  transition: border 0.4s ${transitions.spring};

  @media ${breakpoints.smallScreen} {
    flex: 0 0
      ${({ provideEmailStyle }) => (provideEmailStyle ? rem(20) : rem(CHECKBOX_SIZE))};
    width: ${({ provideEmailStyle }) =>
      provideEmailStyle ? rem(20) : rem(CHECKBOX_SIZE)};
    height: ${({ provideEmailStyle }) =>
      provideEmailStyle ? rem(20) : rem(CHECKBOX_SIZE)};
  }
`;

export const Label = styled.span`
  display: block;
  margin: 0 0 0 ${({ provideEmailStyle }) => (provideEmailStyle ? 0 : rem(12))};
  padding: 0;
  color: ${colors.mako};
  font-weight: ${({ provideEmailStyle }) =>
    provideEmailStyle ? font.weight.bold : font.weight.regular};
  font-size: ${({ provideEmailStyle }) =>
    provideEmailStyle ? rem(font.size.medium) : rem(font.size.small)};
  line-height: 'inherit';

  @media ${breakpoints.smallScreen} {
    display: block;
    padding: 0;
    line-height: ${({ provideEmailStyle }) =>
      provideEmailStyle ? rem(20) : rem(CHECKBOX_SIZE)};
  }
`;

export const RealCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${hideVisually};

  &:hover {
    & + ${CheckboxLabelWrapper} {
      ${FakeCheckbox} {
        background: ${colorName.toggleSecondary};
        border-color: ${colorName.togglePrimary};
      }
      ${Label} {
        color: ${colors.black};
      }
    }
  }
  &:focus {
    & + ${CheckboxLabelWrapper} {
      ${FakeCheckbox} {
        background: ${colorName.white};
        border-color: ${colorName.togglePrimary};
        box-shadow: 0 0 0 4px ${colorName.toggleSecondary};
      }
      ${Label} {
        color: ${colors.black};
      }
    }
  }
  &:checked {
    & + ${CheckboxLabelWrapper} {
      ${FakeCheckbox} {
        background: ${colorName.togglePrimary};
        border-color: ${colorName.togglePrimary};

        ${TickIcon} {
          color: ${colorName.white};
          transform: rotate(0) scale(1);
          opacity: 1;
        }
      }
      ${Label} {
        color: ${colors.black};
      }
    }
  }
`;
