import React from 'react';
import PropTypes from 'prop-types';

import { StyledBadge } from './style';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const FileBadge = ({ children }) => <StyledBadge>{children}</StyledBadge>;

FileBadge.propTypes = propTypes;

export default FileBadge;
