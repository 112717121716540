import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  documents: {
    id: 'Documents.documents',
    defaultMessage: 'Documents',
    description: 'Documents Page title',
  },
  onDocumentsAdded: {
    id: 'Documents.onDocumentsAdded',
    defaultMessage: 'You haven’t added any document yet',
    description: 'CO Empty state header',
  },
  selectDocuments: {
    id: 'Documents.selectDocuments',
    defaultMessage: 'Please select the document you want to add',
    description: 'CO Empty state subheader',
  },
  generalInformation: {
    id: 'Documents.generalInformation',
    defaultMessage: 'General information',
    description: 'Section header',
  },
  documentTitle: {
    id: 'Documents.documentTitle',
    defaultMessage: 'Document title',
    description: 'Input label',
  },
  documentTitlePlaceholder: {
    id: 'Documents.documentTitlePlaceholder',
    defaultMessage: 'Document with legal regulations',
    description: 'Input placeholder',
  },
  documentOwner: {
    id: 'Documents.documentOwner',
    defaultMessage: 'Document owner',
    description: 'Select Input label',
  },
  documentOwnerPlaceholder: {
    id: 'Documents.documentOwnerPlaceholder',
    defaultMessage: 'Search for the owner by name or email',
    description: 'Select Input placeholder',
  },
  documentDescription: {
    id: 'Documents.documentDescription',
    defaultMessage: 'Document description',
    description: 'Textarea label',
  },
  dateNotifications: {
    id: 'Documents.dateNotifications',
    defaultMessage: 'Date & notifications',
    description: 'Section header',
  },
  dueDate: {
    id: 'Documents.dueDate',
    defaultMessage: 'Due date',
    description: 'Date Picker Label',
  },
  dueDatePlaceholder: {
    id: 'Documents.dueDatePlaceholder',
    defaultMessage: 'Select due date',
    description: 'Date Picker placeholder',
  },
  recipients: {
    id: 'Documents.recipients',
    defaultMessage: 'Recipients',
    description: 'Section header',
  },
  recipientsSelectPlaceholder: {
    id: 'Documents.recipientsSelectPlaceholder',
    defaultMessage: 'Search by the group of receipients or individual name to add',
    description: 'Recipients Select Placeholder',
  },
  recipientsSelectHint: {
    id: 'Documents.recipientsSelectHint',
    defaultMessage: 'Start typing to add a group of recipients or a person',
    description: 'Recipients Select Hint',
  },
  recipientsSelectLabel: {
    id: 'Documents.recipientsSelectLabel',
    defaultMessage: 'Add recipients',
    description: 'Recipients Select Label',
  },
  addRecipient: {
    id: 'Documents.addRecipient',
    defaultMessage: 'Add',
    description: 'Recipients Select Button',
  },
  publishDocumentHeader: {
    id: 'Documents.publishDocumentHeader',
    defaultMessage: 'Publish',
    description: 'Publish Document Header',
  },
  publishSubheader: {
    id: 'Documents.publishSubheader',
    defaultMessage:
      'Complete the form and click on the button below to send the document to all recipients.\n\n Document without recipients will be saved as draft and available only for Compliance Officers.',
    description: 'Publish Document Subheader',
  },
  publishButton: {
    id: 'Documents.publishButton',
    defaultMessage: 'Publish and notify recipients',
    description: 'Publish Button label',
  },
  noDocumentsToConfirm: {
    id: 'Documents.noDocumentsToConfirm',
    defaultMessage: 'There are no new documents that need your confirmation.',
    description: 'User Document List Empty State Header',
  },
  ownerBadge: {
    id: 'Documents.ownerBadge',
    defaultMessage: "You're the owner",
    description: 'Document owner badge label',
  },
  documentAdded: {
    id: 'Documents.documentAdded',
    defaultMessage: 'Document has been successfully added!',
    description: 'Document Added header',
  },
  documentAddedSubheader: {
    id: 'Documents.documentAddedSubheader',
    defaultMessage:
      'The document has been successfully added. It will be sent to all the recipients as soon as document owner approves it.',
    description: 'Document Added Subheader',
  },
  goToDashboard: {
    id: 'Documents.goToDashboard',
    defaultMessage: 'Go to dashboard',
    description: 'Button Label',
  },
  addAnotherDocument: {
    id: 'Documents.addAnotherDocument',
    defaultMessage: 'Add another document',
    description: 'Button Label',
  },
  documentLogHeader: {
    id: 'Documents.actionsLogHeader',
    defaultMessage: 'Document timeline',
    description: 'Document Log Header',
  },
  documentInformation: {
    id: 'Documents.documentInformation',
    defaultMessage: 'Document information',
    description: 'Document Sidebar Header',
  },
  addedOn: {
    id: 'Documents.addedOn',
    defaultMessage: 'Added on {date}',
    description: 'Added on Label',
  },
  lastModified: {
    id: 'Documents.lastModified',
    defaultMessage: 'Last modified on {date}',
    description: 'Last Modified Label',
  },
  saveAndExit: {
    id: 'Documents.saveAndExit',
    defaultMessage: 'Save and exit',
    description: 'Save and Exit Button',
  },
  archiveDoc: {
    id: 'Documents.archiveDoc',
    defaultMessage: 'Archive Document',
    description: 'Archive Document Button',
  },
  restoreDoc: {
    id: 'Documents.restoreDoc',
    defaultMessage: 'Restore document',
    description: 'Restore Document Button',
  },
  deleteDoc: {
    id: 'Documents.deleteDoc',
    defaultMessage: 'Delete Document',
    description: 'Delete Document Button',
  },
  employeesConfirmed: {
    id: 'Documents.employeesConfirmed',
    defaultMessage: 'Employees confirmed',
    description: 'Employees confirmed Label',
  },
  viewConfirm: {
    id: 'Documents.viewConfirm',
    defaultMessage: 'View & Confirm',
    description: 'view and confirm link',
  },
  documentConfirmationsTab: {
    id: 'Documents.documentConfirmationsTab',
    defaultMessage: 'Document confirmations',
    description: 'Document Confirmations Tab',
  },
  documentConfirmationsTitle: {
    id: 'Documents.documentConfirmationsTitle',
    defaultMessage: 'Search',
    description: 'Document Confirmations Title',
  },
  documentSearchPlaceholder: {
    id: 'Documents.documentSearchPlaceholder',
    defaultMessage: 'Search a group of recipients or a person by name or mail to add...',
    description: 'Document Confirmations Search Placeholder',
  },
  noRecipientsYet: {
    id: 'Documents.noRecipientsYet',
    defaultMessage: 'You haven’t added any recipient yet',
    description: 'Document Recipients Empty State Title',
  },
  pleaseSelectRecipients: {
    id: 'Documents.pleaseSelectRecipients',
    defaultMessage: 'Please select recipients you want to add',
    description: 'Document Recipients Empty State Subtitle',
  },
  savingTheDocument: {
    id: 'Documents.savingTheDocument',
    defaultMessage: 'Saving the document',
    description: 'Saving the Document Modal Title',
  },
  areYouSureAboutEdit: {
    id: 'Documents.areYouSureAboutEdit',
    defaultMessage:
      'Are you sure? You will not be able to access data prior to your edits.',
    description: 'Saving the Document Modal Subtitle',
  },
  saveTheDocument: {
    id: 'Documents.saveTheDocument',
    defaultMessage: 'Save the document',
    description: 'Save the Document Modal Button',
  },
  archiveTheDocument: {
    id: 'Documents.archiveTheDocument',
    defaultMessage: 'Archive the document',
    description: 'Archive the Document Modal Header and button label',
  },
  archiveDocumentDescription: {
    id: 'Documents.archiveDocumentDescription',
    defaultMessage: 'Are you sure? You will not be able to edit this document anymore.',
    description: 'Archive the Document Modal description',
  },
  restoreTheDocument: {
    id: 'Documents.restoreTheDocument',
    defaultMessage: 'Restore the document',
    description: 'Restore the Document Modal Header and button label',
  },
  restoreDocument: {
    id: 'Documents.restoreDocument',
    defaultMessage: 'Restore document',
    description: 'Restore the Document Modal button label',
  },
  restoreDocumentDescription: {
    id: 'Documents.restoreDocumentDescription',
    defaultMessage: 'Are you sure? Document will be sent to all the recipients.',
    description: 'Restore the Document Modal description',
  },
  dateAdded: {
    id: 'Documents.dateAdded',
    defaultMessage: 'Date added',
    description: 'document Archive label',
  },
  version: {
    id: 'Documents.version',
    defaultMessage: 'Version',
    description: 'document Archive label',
  },
  files: {
    id: 'Documents.files',
    defaultMessage: 'Files',
    description: 'document Archive label',
  },
  firstAlert: {
    id: 'Documents.firstAlert',
    defaultMessage: 'First user notification',
    description: 'Checkbox label for notifications',
  },
  secondAlert: {
    id: 'Documents.secondAlert',
    defaultMessage: 'Second user notification',
    description: 'Checkbox label for notifications',
  },
  thirdAlert: {
    id: 'Documents.thirdAlert',
    defaultMessage: 'Third user notification',
    description: 'Checkbox label for notifications',
  },
  selectableInterval: {
    id: 'Documents.selectableInterval',
    defaultMessage: 'Select notification date',
    description: 'Interval select label',
  },
  additionalMessage: {
    id: 'Documents.additionalMessage',
    defaultMessage: 'Additional message (optional)',
    description: 'Alert message',
  },
  additionalMessagePlaceholder: {
    id: 'Documents.additionalMessagePlaceholder',
    defaultMessage: 'Please fill in notification message',
    description: 'Alert message placeholder',
  },
  createNewVersionButton: {
    id: 'Documents.createNewVersion',
    defaultMessage: 'Create a new version',
    description: 'Create new version button',
  },
  createNewVersionModalTitle: {
    id: 'Documents.createNewVersionModalTitle',
    defaultMessage: 'Create new version',
    description: 'Create new version modal title',
  },
  createNewVersionModalInfo: {
    id: 'Documents.createNewVersionModalInfo',
    defaultMessage:
      'Adding new files automatically creates a new version of the document. Compliance Officers will still be able to access files assigned to older versions. Regular employees will only be able to view the newest version of the document.',
    description: 'Create new version modal bottom information',
  },
  versionsTab: {
    id: 'Documents.versionsTab',
    defaultMessage: 'Versions',
    description: 'Versions Tab',
  },
  currentVersionHeader: {
    id: 'Documents.currentVersionHeader',
    defaultMessage: 'Current version',
    description: 'Current document version header',
  },
  oldVersionsHeader: {
    id: 'Documents.oldVersionsHeader',
    defaultMessage: 'Old versions',
    description: 'Old document versions header',
  },
  noFilesInVersion: {
    id: 'Documents.noFilesInVersion',
    defaultMessage: 'There are no files in this version',
    description: 'No files in document version empty state',
  },
  versionIdentifier: {
    id: 'Documents.versionIdentifier',
    defaultMessage: 'Version {versionNumber}',
    description: 'Version identifier, usage example: Version 1',
  },
  sendReminder: {
    id: 'Documents.sendReminder',
    defaultMessage: 'Send reminder',
    description: 'Send document reminder button',
  },
  sendReminderUnavailable: {
    id: 'Documents.sendReminderUnavailable',
    defaultMessage: 'Unavailable',
    description: 'Sending document reminder unavailable button text',
  },
  reminderSent: {
    id: 'Documents.reminderSent',
    defaultMessage: 'Reminder sent',
    description: 'Document reminder sent button text',
  },
  deletingTheDocument: {
    id: 'Documents.deletingTheDocument',
    defaultMessage: 'Deleting the Document',
    description: 'Deleting the Document Modal Title',
  },
  areYouSureAboutDelete: {
    id: 'Documents.areYouSureAboutDelete',
    defaultMessage: 'Are you sure? You will not be able to edit this document anymore.',
    description: 'Deleting the Document Modal Subtitle',
  },
  searchPlaceholder: {
    id: 'Documents.searchPlaceholder',
    defaultMessage: 'Search documents',
    description: 'Documents search placeholder',
  },
});
