import React from 'react';
import PropTypes from 'prop-types';

import { colorName } from 'styles';
import { SpinCircle } from './style';

const defaultPropTypes = {
  color: colorName.white,
  size: 16,
};

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

const Spinner = ({ color, size }) => {
  return <SpinCircle role="loader" color={color} size={size} />;
};

Spinner.defaultProps = defaultPropTypes;
Spinner.propTypes = propTypes;

export default Spinner;
