import React from 'react';

const UserIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 9.333a2.778 2.778 0 100-5.555 2.778 2.778 0 000 5.555zM10 11.556c-3.376 0-5 2.105-5 3.333V16h10V14.89c0-1.222-1.624-3.333-5-3.333z"
      />
    </svg>
  );
};

export default UserIcon;
