import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router';

import { resolveDashboardRoute } from 'routing';

const propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export const AnonymousRoute = ({ component: Component, ...props }) => {
  const { isAuthenticated, isCo } = useSelector((state) => state.auth);

  const dashboardRoute = resolveDashboardRoute(isCo);

  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated ? <Redirect to={dashboardRoute} /> : <Component {...props} />
      }
    />
  );
};

AnonymousRoute.propTypes = propTypes;

export default AnonymousRoute;
