import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Typography from 'components/Typography';

import { BackButton, BackArrowIcon } from 'components/PageHeader/style';
import { Header, ExtraLabelWrapper } from './style';

const propTypes = {
  label: PropTypes.node.isRequired,
  extraLabel: PropTypes.node,
  withBackButton: PropTypes.bool,
  withSeparator: PropTypes.bool,
  history: PropTypes.object,
  children: PropTypes.node,
  titleComponentName: PropTypes.string,
  testingId: PropTypes.string,
};

const defaultProps = {
  titleComponentName: 'h1',
  withSeparator: true,
};

const HeaderWithActions = ({
  label,
  extraLabel,
  withBackButton,
  withSeparator,
  history,
  children,
  titleComponentName,
  testingId,
}) => {
  return (
    <Header withSeparator={withSeparator}>
      {withBackButton && (
        <BackButton onClick={() => history.goBack()} withBottomMargin>
          <BackArrowIcon />
        </BackButton>
      )}

      <Typography
        component={titleComponentName}
        size="big"
        tabletSize="huge"
        bottomMargin={0}
        testingId={testingId}
      >
        {label}
        {extraLabel && <ExtraLabelWrapper>{extraLabel}</ExtraLabelWrapper>}
      </Typography>

      {children && children}
    </Header>
  );
};

HeaderWithActions.propTypes = propTypes;
HeaderWithActions.defaultProps = defaultProps;

export default withRouter(HeaderWithActions);
