import api from 'api';
import { deserializeLogs } from 'deserializers';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
} from 'helpers/sharedConstants';

// Actions
export const STORE_DOCUMENT_LOGS = 'documentLogs.STORE_DOCUMENT_LOGS';
export const STORE_DOCUMENT_LOGS_META = 'documentLogs.STORE_DOCUMENT_LOGS_META';
export const CLEAR_LOGS_META = 'documentLogs.CLEAR_LOGS_META';
export const SET_LOADING = 'documentLogs.SET_LOADING_STATE';

// Reducer
const initialState = {
  isLoading: true,
  documentLogs: [],
  documentLogsMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case STORE_DOCUMENT_LOGS:
      return {
        ...state,
        documentLogs: action.payload,
      };
    case STORE_DOCUMENT_LOGS_META:
      return {
        ...state,
        documentLogsMeta: action.payload,
      };
    case CLEAR_LOGS_META:
      return {
        ...state,
        documentLogsMeta: {
          perPage: PAGINATE_DEFAULT_PER_PAGE,
          page: PAGINATE_DEFAULT_PAGE,
          totalPages: 1,
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload });

export const storeDocumentLogs = (payload) => ({
  type: STORE_DOCUMENT_LOGS,
  payload,
});

export const storeDocumentLogsMeta = (payload) => ({
  type: STORE_DOCUMENT_LOGS_META,
  payload,
});

export const clearDocumentLogs = () => ({ type: CLEAR_LOGS_META });

// Side Effects
export const getDocumentLogs =
  ({ id, page = PAGINATE_DEFAULT_PAGE, perPage = PAGINATE_DEFAULT_PER_PAGE }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const res = await api.documents.getLogs({ id, page, perPage });
      const deserializedLogs = deserializeLogs(res.data);
      dispatch(storeDocumentLogs(deserializedLogs.items));
      dispatch(
        storeDocumentLogsMeta({ page, perPage, totalPages: deserializedLogs.pages })
      );
    } catch (error) {
      // @todo - update error handling
    } finally {
      dispatch(setLoading(false));
    }
  };
