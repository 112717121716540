import React from 'react';
import PropTypes from 'prop-types';

import {
  TickIcon,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineLine,
  TimelineList,
  TimelineWrapper,
} from './style';
import { closedStatuses } from 'helpers/reportConstants';

const propTypes = {
  steps: PropTypes.array.isRequired,
  currentStep: PropTypes.string.isRequired,
  title: PropTypes.string,
  isDark: PropTypes.bool,
};

const ProgressSteps = ({ steps, currentStep, isDark }) => {
  const statusCount = steps.length;
  const currentStepIndex =
    (closedStatuses.includes(currentStep) && 3) ||
    steps.findIndex((step) => step.value === currentStep);

  return (
    <TimelineWrapper>
      <TimelineList>
        {steps.map(({ name, value }, id) => {
          const isItemCurrent = currentStepIndex === id;
          const isItemDone = currentStepIndex > id;
          const isItemLast = id === statusCount - 1;

          return (
            <TimelineItem key={value} data-testid={`step-${value}`}>
              <TimelineContent
                isItemCurrent={isItemCurrent}
                isItemDone={isItemDone}
                isDark={isDark}
              >
                <TimelineDot
                  data-content={name}
                  isItemCurrent={isItemCurrent}
                  isItemDone={isItemDone}
                  isDark={isDark}
                />

                {isItemDone && <TickIcon isDark={isDark} />}
              </TimelineContent>

              {!isItemLast && <TimelineLine isDark={isDark} />}
            </TimelineItem>
          );
        })}
      </TimelineList>
    </TimelineWrapper>
  );
};

ProgressSteps.propTypes = propTypes;

export default ProgressSteps;
