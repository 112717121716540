import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { getOfficerToken, logInUser, logInViaToken, logOutUser } from 'stores/auth';
import { EXTENDED_EMPLOYEE_SCOPE } from 'helpers/sharedConstants';
import { ROUTING } from 'routing';

import OfficerShieldIcon from 'components/Svg/OfficerShieldIcon';
import PeopleIcon from 'components/Svg/PeopleIcon';
import RoundIconWrap from 'components/RoundIconWrap';
import Modal, { StandardModalContent } from 'components/Modal';
import Typography from 'components/Typography';
import Button from 'components/Button';
import LoginInput from 'components/LoginInput';
import UserAvatar, { avatarSize } from 'components/UserAvatar';

import { globalMessages } from 'translations';
import { messages } from '../messages';
import { colors } from 'styles';
import {
  MenuItem,
  OfficerMenuButton,
  OfficerMenuLabel,
  OfficerLoginBox,
  OfficerDetails,
  DetailsMeta,
} from '../style';
import { ForgotPasswordLink } from 'containers/public/Auth/Login/style';

const propTypes = { isExpanded: PropTypes.bool };

const ExtendedEmployeeLogin = ({ isExpanded }) => {
  let history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { hasExtendedEmployeeRights, userData, isTokenSent, isLoading } = useSelector(
    (state) => state.auth
  );
  const {
    properties: { twoFactorLogin },
  } = useSelector((state) => state.tenantProperties);
  const [isModalOpen, setModalOpen] = useState(false);
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const userEmail = userData?.email;
  const [password, setPassword] = useState('');

  const getHigherRights2FA = (e) => {
    e.preventDefault();

    dispatch(logInViaToken({ email: userEmail, token, scope: EXTENDED_EMPLOYEE_SCOPE }))
      .then(() => {
        return history.push(ROUTING.DASHBOARD_CO);
      })
      .catch(() => {
        setError(intl.formatMessage(globalMessages.defaultError));
      });
  };

  const getHigherRightsPassword = (e) => {
    e.preventDefault();

    dispatch(
      logInUser({ login: userEmail, password, scope: EXTENDED_EMPLOYEE_SCOPE })
      // ).then(() => history.push(ROUTING.DASHBOARD_EMPLOYEE));
    ).then(() => history.go(0));
  };

  const getVerificationToken = (e) => {
    e.preventDefault();

    dispatch(getOfficerToken(userEmail));
  };

  const triggerAction = () => {
    if (hasExtendedEmployeeRights) {
      dispatch(logOutUser(EXTENDED_EMPLOYEE_SCOPE)).then(() =>
        history.push(ROUTING.DASHBOARD_EMPLOYEE)
      );
    } else {
      setModalOpen(true);
    }
  };

  const TwoFactorFlow = (
    <>
      {!isTokenSent && (
        <Button onClick={(e) => getVerificationToken(e)} primary isFluid>
          {intl.formatMessage(messages.sendCode)}
        </Button>
      )}

      {isTokenSent && (
        <form onSubmit={getHigherRights2FA} autoComplete="off">
          <LoginInput
            type="text"
            label={intl.formatMessage(messages.authenticationCode)}
            placeholder={intl.formatMessage(messages.code)}
            onChange={({ target }) => setToken(target.value)}
            bottomMargin={6}
          />

          {error && (
            <Typography size="small" color={colors.darkRed} weight="bold">
              {error}
            </Typography>
          )}

          <Typography
            component={ForgotPasswordLink}
            to={ROUTING.FORGOT_PASSWORD}
            size="small"
            weight="bold"
            textAlign="right"
            onClick={(e) => getVerificationToken(e)}
          >
            {intl.formatMessage(messages.resendCode)}
          </Typography>

          <Button type="submit" isProcessing={isLoading} primary isFluid>
            {intl.formatMessage(globalMessages.login)}
          </Button>
        </form>
      )}
    </>
  );

  const ClassicLoginFlow = (
    <form onSubmit={getHigherRightsPassword}>
      <LoginInput
        key="password"
        label={intl.formatMessage(globalMessages.password)}
        placeholder={intl.formatMessage(globalMessages.password)}
        type="password"
        value={password}
        onChange={({ target }) => setPassword(target.value)}
      />

      <Button type="submit" isProcessing={isLoading} primary isFluid>
        {intl.formatMessage(globalMessages.login)}
      </Button>
    </form>
  );

  return (
    <>
      <MenuItem isOfficerButton onClick={triggerAction}>
        <OfficerMenuButton>
          <OfficerShieldIcon />

          <OfficerMenuLabel isExpanded={isExpanded}>
            {hasExtendedEmployeeRights
              ? intl.formatMessage(messages.employeeView)
              : intl.formatMessage(messages.extendedEmployeeView)}
          </OfficerMenuLabel>
        </OfficerMenuButton>
      </MenuItem>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)}>
          <StandardModalContent>
            <RoundIconWrap icon={<PeopleIcon />} />

            <Typography size="medium" tabletSize="huge" bottomMargin={16}>
              {intl.formatMessage(messages.extendedEmployeeLoginHeader)}
            </Typography>

            <Typography
              size="small"
              tabletSize="medium"
              color={colors.darkGray}
              bottomMargin={52}
            >
              {twoFactorLogin
                ? intl.formatMessage(messages.officerTwoFactorLoginText)
                : intl.formatMessage(messages.extendedEmployeeLoginText)}
            </Typography>

            <OfficerLoginBox>
              <OfficerDetails>
                <UserAvatar
                  size={avatarSize.SMALL}
                  user={userData}
                  officerStyle={false}
                />

                <DetailsMeta>
                  <Typography size="tiny" color={colors.darkGray} bottomMargin={0}>
                    {intl.formatMessage(messages.loggedAs)}
                  </Typography>

                  <Typography size="tiny" bottomMargin={0}>
                    {userEmail}
                  </Typography>
                </DetailsMeta>
              </OfficerDetails>

              {twoFactorLogin ? TwoFactorFlow : ClassicLoginFlow}
            </OfficerLoginBox>
          </StandardModalContent>
        </Modal>
      )}
    </>
  );
};

ExtendedEmployeeLogin.propTypes = propTypes;

export default ExtendedEmployeeLogin;
