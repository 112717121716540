import styled from 'styled-components';
import { rem } from 'polished';

import { colors } from 'styles';

export const TileWrapper = styled.div`
  width: 100%;
  padding: ${rem(24)} ${rem(24)};
  background: ${colors.white};
  border-radius: ${rem(8)};
`;

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${rem(42)};
`;

export const IconWrapper = styled.div`
  width: ${rem(40)};
  height: ${rem(40)};
`;
