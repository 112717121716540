import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MisconductForm from '../MisconductForm';
import { Redirect } from 'react-router';
import { ROUTING } from '../../../../routing';
import api from 'api';

const MultipleMisconductForm = () => {
  const [cachedExists, setCachedExists] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { customPath } = useParams();

  useEffect(() => {
    api.config
      .getSurvey(customPath)
      .then(() => {
        setCachedExists(true);
      })
      .catch((err) => {
        if (err.status === 404) {
          return setCachedExists(false);
        } else {
          throw err;
        }
      })
      .finally(() => setIsLoading(false));
  }, [customPath]);

  if (isLoading && !cachedExists) {
    return null;
  }

  if (cachedExists) {
    return <MisconductForm />;
  }

  return <Redirect to={ROUTING.NOT_FOUND} />;
};

export default MultipleMisconductForm;
