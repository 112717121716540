import React from 'react';

const ChatIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 30.743 30.744"
      viewBox="0 0 30.743 30.744"
      xmlSpace="preserve"
    >
      <path
        fill="currentColor"
        d="M28.585 9.67h-.842v9.255c0 1.441-.839 2.744-2.521 2.744H8.743v.44c0 1.274 1.449 2.56 2.937 2.56h12.599l4.82 2.834-.699-2.834h.185c1.487 0 2.158-1.283 2.158-2.56V11.867c0-1.274-.671-2.197-2.158-2.197z"
      />
      <path
        fill="currentColor"
        d="M22.762 3.24H3.622C1.938 3.24 0 4.736 0 6.178v11.6c0 1.328 1.642 2.287 3.217 2.435l-1.025 3.891L8.76 20.24h14.002c1.684 0 3.238-1.021 3.238-2.462v-11.6c0-1.442-1.555-2.938-3.238-2.938zm-16.22 9.792a1.729 1.729 0 110-3.458 1.729 1.729 0 010 3.458zm6.458 0a1.729 1.729 0 110-3.458 1.729 1.729 0 010 3.458zm6.459 0a1.73 1.73 0 110-3.46 1.73 1.73 0 010 3.46z"
      />
    </svg>
  );
};

export default ChatIcon;
