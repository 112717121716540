import styled from 'styled-components';
import { rem } from 'polished';
import Button from 'components/Button';

import { breakpoints, colors } from 'styles';

export const MainContent = styled.div`
  margin: auto;

  @media ${breakpoints.smallScreen} {
    width: 100%;
    max-width: ${rem(360)};
  }
`;

export const PrimaryButton = styled(Button)`
  margin-bottom: ${rem(10)};
`;

export const StyledLink = styled.a`
  width: 100%;
  padding: ${rem(32)};
  padding-bottom: 0;
  color: inherit;
  text-decoration-color: ${colors.gray};
  cursor: pointer;

  &:hover {
    text-decoration-color: ${colors.darkGray};
  }

  &:hover p {
    color: ${colors.darkGray};
  }
`;
