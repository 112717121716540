import React from 'react';

const EmailIcon = (props) => (
  <svg viewBox="0 0 18 18" {...props}>
    <title>Login</title>
    <path
      fill="none"
      stroke="#a2a2a8"
      d="M15.5 15.5h-13c-.6 0-1-.4-1-1v-11c0-.6.4-1 1-1h13c.6 0 1 .4 1 1v11c0 .6-.4 1-1 1z"
    />
    <path fill="none" stroke="#a2a2a8" d="M3.5 5.5L9 10l5.5-4.5" />
  </svg>
);

export default EmailIcon;
