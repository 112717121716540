import PropTypes from 'prop-types';

const propTypes = {
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
  children: PropTypes.node,
};

const MatchMedia = ({ minWidth, maxWidth, children }) => {
  const minQuery = (width) => {
    return `(min-width: ${width}px)`;
  };
  const maxQuery = (width) => {
    return `(max-width: ${width}px)`;
  };
  const minMaxQuery = (min, max) => {
    return `(min-width: ${min}px) and (max-width: ${max}px)`;
  };
  const matchMedia = (string) => window.matchMedia(string).matches;

  if (minWidth && maxWidth) {
    return matchMedia(minMaxQuery(minWidth, maxWidth)) && children;
  }

  if (minWidth && !maxWidth) {
    return matchMedia(minQuery(minWidth)) && children;
  }

  if (maxWidth && !minWidth) {
    return matchMedia(maxQuery(maxWidth)) && children;
  }

  return null;
};

MatchMedia.propTypes = propTypes;

export default MatchMedia;
