import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { coStatisticPropTypes } from 'models';
import { statisticTypes } from 'helpers/commonConstants';

import ProgressVisualization from 'components/ProgressVisualization';
import DocumentAffirmedIcon from 'components/Svg/DocumentAffirmedIcon';
import ReportIcon from 'components/Svg/ReportIcon';

import { messages } from './messages';
import { globalMessages } from 'translations';

const propTypes = {
  intl: PropTypes.object,
  statistic: coStatisticPropTypes.isRequired,
};
export const StatisticVisualization = ({
  intl,
  statistic: { type, progressed, remaining },
}) => {
  switch (type) {
    case statisticTypes.AFFIRMATIONS: {
      const total = progressed + remaining;
      return (
        <ProgressVisualization
          portionProgressed={{
            label: intl.formatMessage(messages.affirmationsProgressed),
            value: progressed,
          }}
          portionRemaining={{
            label: intl.formatMessage(messages.affirmationsRemaining),
            value: remaining,
          }}
          icon={<DocumentAffirmedIcon />}
          title={intl.formatMessage(messages.affirmationsTitle)}
        >
          {total === 0 ? '-' : `${Math.round((progressed / total) * 100)}%`}
        </ProgressVisualization>
      );
    }
    case statisticTypes.MISCONDUCTS: {
      return (
        <ProgressVisualization
          portionProgressed={{
            label: intl.formatMessage(messages.misconductsProgressed),
            value: progressed,
          }}
          portionRemaining={{
            label: intl.formatMessage(messages.misconductsRemaining),
            value: remaining,
          }}
          icon={<ReportIcon />}
          title={intl.formatMessage(globalMessages.misconductsTitle)}
        >
          {remaining.toString()}
        </ProgressVisualization>
      );
    }
    default:
      return null;
  }
};

StatisticVisualization.propTypes = propTypes;

export default injectIntl(StatisticVisualization);
