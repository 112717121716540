import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
} from 'helpers/sharedConstants';
import { setToastMessage } from './toastMessages';
import { toastContext, toastType } from '../helpers/toastConstants';

// Actions
export const SET_IMPORT_STATUS = 'importData.SET_IMPORT_STATUS';
export const SET_IMPORT_ACTIONS = 'importData.SET_IMPORT_ACTIONS';
export const SET_IMPORT_ID = 'importData.SET_IMPORT_ID';
export const STORE_IMPORT_DATA = 'importData.STORE_IMPORT_DATA';
export const STORE_IMPORT_DATA_META = 'importData.STORE_IMPORT_DATA_META';
export const SET_IMPORT_DATA_LOADING = 'importData.SET_IMPORT_DATA_LOADING';
export const CLEAR_IMPORT = 'importData.CLEAR_IMPORT';

// Reducer
const initialState = {
  importId: '',
  importStatus: '',
  importDetails: false,
  importActions: false,
  importData: [],
  importDataMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
  },
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IMPORT_ACTIONS:
      return {
        ...state,
        importActions: action.payload,
      };
    case SET_IMPORT_ID:
      return {
        ...state,
        importId: action.payload,
      };
    case STORE_IMPORT_DATA:
      return {
        ...state,
        importData: action.payload,
      };
    case STORE_IMPORT_DATA_META:
      return {
        ...state,
        importDataMeta: action.payload,
      };
    case SET_IMPORT_DATA_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case CLEAR_IMPORT:
      return {
        ...initialState,
      };
    case SET_IMPORT_STATUS:
      return {
        ...state,
        importStatus: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function setImportActions(actions) {
  return { type: SET_IMPORT_ACTIONS, payload: actions };
}

export function setImportId(id) {
  return { type: SET_IMPORT_ID, payload: id };
}

export function setImportDataLoading(bool) {
  return { type: SET_IMPORT_DATA_LOADING, payload: bool };
}

export function clearImport() {
  return { type: CLEAR_IMPORT };
}

export function setImportStatus(status) {
  return { type: SET_IMPORT_STATUS, payload: status };
}

export const storeImportData = (payload) => ({ type: STORE_IMPORT_DATA, payload });
export const storeImportDataMeta = (payload) => ({
  type: STORE_IMPORT_DATA_META,
  payload,
});

// Side Effects
export function storeImportDetails({ id, actions }) {
  return (dispatch) => {
    dispatch(setImportId(id));
    dispatch(setImportActions(actions));
  };
}

export function updateImportStatus(status) {
  return (dispatch) => {
    dispatch(setImportStatus(status));

    switch (status) {
      case 'prepared':
        return dispatch(
          setToastMessage({
            content: { translationKey: 'importPrepared' },
            context: toastContext.USERS_MANAGEMENT,
            type: toastType.INFO,
          })
        );
      case 'scheduled':
        return dispatch(
          setToastMessage({
            content: { translationKey: 'importScheduled' },
            context: toastContext.USERS_MANAGEMENT,
            type: toastType.INFO,
          })
        );
      case 'running':
        return dispatch(
          setToastMessage({
            content: { translationKey: 'importRunning' },
            context: toastContext.USERS_MANAGEMENT,
            type: toastType.WARNING,
          })
        );
      case 'finished':
        return dispatch(
          setToastMessage({
            content: { translationKey: 'importFinished' },
            context: toastContext.USERS_MANAGEMENT,
            type: toastType.SUCCESS,
          })
        );
      default:
        return null;
    }
  };
}

export const getImportData =
  ({ page = PAGINATE_DEFAULT_PAGE, perPage = PAGINATE_DEFAULT_PER_PAGE }) =>
  async (dispatch) => {
    dispatch(setImportDataLoading(true));

    try {
      const res = await api.imports.getCurrentActions({ page, perPage });
      dispatch(storeImportData(res.data.items));
      dispatch(storeImportDataMeta({ page, perPage, totalPages: res.data.pages }));
    } catch (error) {
      // @todo - update error handling
    } finally {
      dispatch(setImportDataLoading(false));
    }
  };
