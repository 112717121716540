import styled from 'styled-components';
import { rem } from 'polished';

import { breakpoints, colorName } from 'styles';

const MOBILE_ICON_SIZE = 80;
const TABLET_ICON_SIZE = {
  small: 60,
  regular: 110,
};

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(MOBILE_ICON_SIZE)};
  height: ${rem(MOBILE_ICON_SIZE)};
  margin: 0 auto;
  margin-bottom: ${({ bottomMargin }) => rem(bottomMargin)};
  background: ${colorName.primary};
  border-radius: 100%;

  & > svg {
    display: block;
    width: 64%;
    height: 64%;
  }

  @media ${breakpoints.tablet} {
    width: ${({ size }) => rem(TABLET_ICON_SIZE[size])};
    height: ${({ size }) => rem(TABLET_ICON_SIZE[size])};
    margin: 0 auto ${({ bottomMargin }) => bottomMargin && rem(bottomMargin + 8)};
  }
`;
