import React from 'react';

function UsersIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8.07 10.68c-2.44 0-4.43-2.06-4.43-4.59S5.63 1.5 8.07 1.5s4.43 2.06 4.43 4.59-1.99 4.59-4.43 4.59zm0-8.18c-1.89 0-3.43 1.61-3.43 3.59s1.54 3.59 3.43 3.59 3.43-1.61 3.43-3.59S9.96 2.5 8.07 2.5zM8.07 20.5H1c-.28 0-.5-.22-.5-.5v-1.64c0-2.21 2.7-5.41 7.57-5.41.28 0 .5.22.5.5s-.22.5-.5.5c-4.59 0-6.57 2.92-6.57 4.41v1.14h6.57c.28 0 .5.22.5.5s-.22.5-.5.5zM17 19.5c-1.08 0-1.96-.9-1.96-2s.88-2 1.96-2 1.96.9 1.96 2-.88 2-1.96 2zm0-3c-.53 0-.96.45-.96 1s.43 1 .96 1 .96-.45.96-1-.43-1-.96-1z"
      />
      <path
        fill="currentColor"
        d="M17.73 23.5h-1.46c-.24 0-.45-.17-.49-.41l-.25-1.36c-.5-.18-.97-.46-1.38-.81l-1.25.45a.51.51 0 01-.61-.22l-.73-1.3a.502.502 0 01.1-.62l1.02-.9a4.47 4.47 0 01-.08-.82c0-.28.03-.56.08-.83l-1.02-.9a.49.49 0 01-.1-.62l.73-1.3c.12-.21.38-.31.61-.22l1.25.46c.41-.35.88-.63 1.38-.81l.25-1.37c.04-.24.25-.41.49-.41h1.46c.24 0 .45.17.49.41l.25 1.36c.5.18.97.46 1.38.81l1.25-.45c.23-.08.49.01.61.23l.73 1.3c.12.21.07.46-.11.62l-1.02.9c.05.27.08.54.08.82v.01c0 .27-.03.55-.08.82l1.02.9c.18.16.22.42.11.62l-.73 1.3c-.12.21-.38.31-.61.23l-1.25-.45c-.41.35-.88.63-1.38.81l-.25 1.37c-.04.21-.25.38-.49.38zm-1.04-1h.62l.23-1.25c.04-.19.17-.34.36-.39a3.33 3.33 0 001.48-.87c.14-.14.34-.18.52-.12l1.14.41.32-.58-.94-.83a.5.5 0 01-.15-.5c.08-.29.12-.58.12-.87 0-.29-.04-.58-.12-.87-.05-.18.01-.38.15-.5l.94-.83-.32-.58-1.14.41a.48.48 0 01-.52-.12 3.24 3.24 0 00-1.48-.87.512.512 0 01-.36-.39l-.23-1.25h-.62l-.23 1.25c-.04.19-.17.34-.36.39-.56.15-1.07.46-1.48.87-.14.14-.34.18-.53.12l-1.14-.42-.32.58.94.83c.14.13.2.32.15.5a3.277 3.277 0 000 1.75c.05.18-.01.38-.15.5l-.94.83.32.58 1.14-.41a.48.48 0 01.52.12c.41.42.92.72 1.48.87.18.05.32.2.36.39l.24 1.25z"
      />
    </svg>
  );
}

export default UsersIcon;
