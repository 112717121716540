import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { ROUTING } from 'routing';

const propTypes = { component: PropTypes.object };

const AuthRoute = ({ component: Component, ...props }) => {
  const { userData, isAuthenticated } = useSelector((state) => state.auth);

  return (
    <Route
      {...props}
      render={(props) =>
        isAuthenticated && userData ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTING.INDEX} />
        )
      }
    />
  );
};

AuthRoute.propTypes = propTypes;

export default AuthRoute;
