import React from 'react';

function DashboardIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="currentColor"
        d="M19 12.5c-.3 0-.5-.2-.5-.5 0-1.7-.7-3.4-1.9-4.6S13.7 5.5 12 5.5c-.9 0-1.7.2-2.6.5-.2.1-.5 0-.6-.3-.1-.2 0-.5.2-.6.9-.4 2-.6 3-.6 2 0 3.9.8 5.3 2.2s2.2 3.3 2.2 5.3c0 .3-.2.5-.5.5zM5 12.5c-.3 0-.5-.2-.5-.5 0-1 .2-2 .6-2.9.1-.3.4-.4.7-.3.2.1.3.4.2.6-.3.8-.5 1.7-.5 2.6 0 .3-.3.5-.5.5zM10.6 11.1c-.1 0-.3 0-.4-.1L6.4 7.1c-.2-.2-.2-.5 0-.7s.5-.2.7 0l3.8 3.8c.2.2.2.5 0 .7-.1.1-.2.2-.3.2z"
      />
      <path
        fill="currentColor"
        d="M12 22.5C6.2 22.5 1.5 17.8 1.5 12S6.2 1.5 12 1.5 22.5 6.2 22.5 12 17.8 22.5 12 22.5zm0-20c-5.2 0-9.5 4.3-9.5 9.5s4.3 9.5 9.5 9.5 9.5-4.3 9.5-9.5-4.3-9.5-9.5-9.5z"
      />
      <path
        fill="currentColor"
        d="M12 14.6c-1.4 0-2.6-1.2-2.6-2.6s1.2-2.6 2.6-2.6 2.6 1.2 2.6 2.6-1.2 2.6-2.6 2.6zm0-4.2c-.9 0-1.6.7-1.6 1.6s.7 1.6 1.6 1.6 1.6-.7 1.6-1.6-.7-1.6-1.6-1.6z"
      />
    </svg>
  );
}

export default DashboardIcon;
