const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'column';
    listContainer.style.gap = '16px';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const LegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, index) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          );
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + 'px';
      boxSpan.style.borderRadius = '2px';
      boxSpan.style.display = 'inline-block';
      boxSpan.style.height = '12px';
      boxSpan.style.marginRight = '10px';
      boxSpan.style.width = '12px';
      boxSpan.style.minWidth = '12px';

      // Text wrapper
      const textWrapper = document.createElement('div');
      textWrapper.style.display = 'flex';
      textWrapper.style.width = '100%';
      textWrapper.style.justifyContent = 'space-between';

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = '#41424B';
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
      textContainer.style.fontSize = '14px';

      const textContainer2 = document.createElement('p');
      textContainer2.style.color = '#41424B';
      textContainer2.style.margin = 0;
      textContainer2.style.padding = 0;
      textContainer2.style.textDecoration = item.hidden ? 'line-through' : '';
      textContainer2.style.fontSize = '14px';

      // console.log(item);

      const text = document.createTextNode(item.text);
      const text2 = document.createTextNode(chart.data.datasets[0].data[index]);
      textContainer.appendChild(text);
      textContainer2.appendChild(text2);

      li.appendChild(boxSpan);
      li.appendChild(textWrapper);
      textWrapper.appendChild(textContainer);
      textWrapper.appendChild(textContainer2);
      ul.appendChild(li);
    });
  },
};
