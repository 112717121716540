import React from 'react';

function SearchIcon(props) {
  return (
    <svg viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.863 13.125l2.985 2.983a.527.527 0 010 .74.523.523 0 01-.739 0l-2.985-2.985A5.571 5.571 0 119.572 4a5.57 5.57 0 014.292 9.125zm-4.292.973a4.5 4.5 0 003.2-1.326 4.516 4.516 0 00.97-4.962 4.516 4.516 0 00-.97-1.44A4.516 4.516 0 007.81 5.4a4.516 4.516 0 00-1.439.97 4.516 4.516 0 00-.97 4.963c.227.538.554 1.023.97 1.439a4.516 4.516 0 003.2 1.326z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SearchIcon;
