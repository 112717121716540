import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from 'components/Button';

import { ButtonsPanel } from '../style';
import { messages } from '../messages';

const propTypes = {
  clearDatePicker: PropTypes.func,
  closeCalendar: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  withoutClearAction: PropTypes.bool,
};

const CalendarInfo = ({
  initialValue,
  clearDatePicker,
  closeCalendar,
  withoutClearAction,
}) => {
  return (
    <ButtonsPanel>
      {initialValue && !withoutClearAction && (
        <Button transparent isFluid onClick={clearDatePicker}>
          <FormattedMessage {...messages.clearDate} />
        </Button>
      )}

      <Button secondary isFluid onClick={closeCalendar}>
        <FormattedMessage {...messages.apply} />
      </Button>
    </ButtonsPanel>
  );
};

CalendarInfo.propTypes = propTypes;

export default CalendarInfo;
