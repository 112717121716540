import styled from 'styled-components';
import { rem } from 'polished';

import { font, colors, breakpoints } from 'styles';

const ICON_SIZE_PX = 40;

export const Header = styled.header`
  display: flex;
  align-items: center;
  margin: 0 0 ${rem(8)};
`;

export const Icon = styled.span`
  display: inline-block;
  width: ${rem(ICON_SIZE_PX)};
  height: ${rem(ICON_SIZE_PX)};
  margin-right: ${rem(8)};
  color: ${colors.darkestGray};
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.small)};
`;

export const Content = styled.div`
  margin: ${rem(16)} 0;
  font-size: ${rem(font.size.gigantic)};
  line-height: 1;

  @media ${breakpoints.smallScreen} {
    font-size: ${rem(font.size.enormous)};
  }
`;

export const Comment = styled.footer`
  margin-top: ${rem(16)};
  font-weight: ${font.weight.regular};
  font-size: ${rem(font.size.small)};
`;
