import React from 'react';

const LogoutIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 22" {...props}>
      <path
        stroke="#A2A2A8"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M5.583 11.083h10.084M12 14.75l3.667-3.667L12 7.417"
      />
      <path
        stroke="#A2A2A8"
        strokeLinecap="round"
        strokeMiterlimit="10"
        d="M13.833 3.75V1H1v20.167h12.833v-2.75"
      />
    </svg>
  );
};

export default LogoutIcon;
