import styled from 'styled-components';
import { hideVisually, rem } from 'polished';

import { breakpoints, colorName, colors, font, transitions } from 'styles';

const RADIOBUTTON_SIZE = 16;

export const RadioButtonWithTooltip = styled.div`
  display: flex;
  margin-bottom: ${rem(16)};
`;

export const RadioButtonLabel = styled.label`
  display: flex;
`;

export const FakeRadioButton = styled.div`
  flex: 0 0 ${rem(RADIOBUTTON_SIZE)};
  width: ${rem(RADIOBUTTON_SIZE)};
  height: ${rem(RADIOBUTTON_SIZE)};
  margin-top: 2px;
  border: 1px solid ${colors.mediumGray};
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px ${colorName.white};
  cursor: pointer;
  transition: border 0.4s ${transitions.spring}, box-shadow 0.4s ${transitions.spring};
`;

export const Label = styled.span`
  display: block;
  margin: 0 0 0 ${rem(12)};
  padding: '2px 0 0 0';
  color: ${colors.mako};
  font-weight: ${font.weight.regular};
  font-size: ${rem(font.size.small)};
  line-height: ${rem(20)};
  cursor: pointer;

  @media ${breakpoints.smallScreen} {
    display: block;
    padding: 0;
  }
`;

export const RadioLabelWrapper = styled.div`
  display: flex;
`;

export const RealRadioButton = styled.input.attrs({ type: 'radio' })`
  ${hideVisually};

  &:hover {
    & + ${RadioLabelWrapper} {
      ${FakeRadioButton} {
        border-color: ${colorName.togglePrimary};
        box-shadow: inset 0 0 0 ${rem(RADIOBUTTON_SIZE / 2)} ${colorName.toggleSecondary};
      }
      ${Label} {
        color: ${colors.black};
      }
    }
  }
  &:focus {
    & + ${RadioLabelWrapper} {
      ${FakeRadioButton} {
        border-color: ${colorName.togglePrimary};
        box-shadow: inset 0 0 0 ${rem(RADIOBUTTON_SIZE / 2)} ${colorName.toggleSecondary},
          0 0 0 4px ${colorName.toggleSecondary};
      }
      ${Label} {
        color: ${colors.black};
      }
    }
  }
  &:checked {
    & + ${RadioLabelWrapper} {
      ${FakeRadioButton} {
        border-color: ${colorName.togglePrimary};
        box-shadow: inset 0 0 0 4px ${colorName.toggleSecondary},
          inset 0 0 0 ${rem(RADIOBUTTON_SIZE)} ${colorName.togglePrimary};
      }
      ${Label} {
        color: ${colors.black};
      }
    }
  }
  &:checked:focus {
    & + ${RadioLabelWrapper} {
      ${FakeRadioButton} {
        box-shadow: inset 0 0 0 4px ${colorName.toggleSecondary},
          inset 0 0 0 ${rem(RADIOBUTTON_SIZE)} ${colorName.togglePrimary},
          0 0 0 4px ${colorName.toggleSecondary};
      }
    }
  }
`;
