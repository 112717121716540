import React from 'react';

function Warning(props) {
  return (
    <svg viewBox="0 0 21 19" {...props}>
      <path
        d="M0 18.136h21L10.5 0 0 18.136zm11.455-2.863h-1.91v-1.91h1.91v1.91zm0-3.818h-1.91V7.636h1.91v3.819z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Warning;
