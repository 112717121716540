import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { logOutUser, updateUserLanguage } from 'stores/auth';
import { SUPPORTED_LOCALES } from 'helpers/sharedConstants';
import { langCodes, langPhrases, langFlags } from 'helpers/commonConstants';
import { history } from 'helpers/appHistory';
import { ROUTING } from 'routing';

import { DropdownListItem } from './DropdownListItem';
import LogoutIcon from 'components/Svg/LogoutIcon';
import UserIcon from 'components/Svg/UserIcon';

import { UserDropdownList, DropdownOutsideClick } from '../style';
import { messages } from '../messages';
import { useCustomConfig } from 'helpers/hooks';

const propTypes = {
  isVisible: PropTypes.bool,
  customConfig: PropTypes.object,
  misconductLanguages: PropTypes.array,
};

const UserDropdown = ({ isVisible }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { languagesFromMisconducts } = useCustomConfig();
  const {
    properties: { misconductLanguages },
  } = useSelector((state) => state.tenantProperties);

  const logOut = () => {
    dispatch(logOutUser());
  };

  const goToAccount = () => {
    history.push(ROUTING.ACCOUNT);
  };

  const languages = languagesFromMisconducts ? misconductLanguages : SUPPORTED_LOCALES;

  return (
    <>
      <UserDropdownList isVisible={isVisible}>
        <DropdownListItem icon={<LogoutIcon />} onClick={logOut} isFluid>
          {intl.formatMessage(messages.logOut)}
        </DropdownListItem>

        <DropdownListItem icon={<UserIcon />} onClick={goToAccount} isFluid>
          {intl.formatMessage(messages.changePassword)}
        </DropdownListItem>

        {languages.map((language) => (
          <DropdownListItem
            key={langCodes[language]}
            icon={langFlags[language]}
            disabled={!languages.includes(langCodes[language])}
            onClick={() => dispatch(updateUserLanguage(langCodes[language]))}
          >
            {langPhrases[language]}
          </DropdownListItem>
        ))}
      </UserDropdownList>

      <DropdownOutsideClick isVisible={isVisible} />
    </>
  );
};

UserDropdown.propTypes = propTypes;

export default UserDropdown;
