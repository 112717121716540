import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
} from 'helpers/sharedConstants';

// Actions
export const STORE_RECIPIENTS_LIST = 'allRecipients.STORE_RECIPIENTS_LIST';
export const STORE_RECIPIENTS_LIST_META = 'allRecipients.STORE_RECIPIENTS_LIST_META';
export const SET_LOADING = 'allRecipients.SET_LOADING';

// Reducer
const initialState = {
  isLoading: true,
  recipientList: [],
  recipientListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_RECIPIENTS_LIST:
      return {
        ...state,
        recipientList: action.payload,
      };
    case STORE_RECIPIENTS_LIST_META:
      return {
        ...state,
        recipientListMeta: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function storeRecipients(recipients) {
  return { type: STORE_RECIPIENTS_LIST, payload: recipients };
}

export function storeRecipientsMeta(meta) {
  return { type: STORE_RECIPIENTS_LIST_META, payload: meta };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

// Side Effects
export function getRecipients({
  id,
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
  query,
  filters = [],
}) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return api.documents
      .getRecipients(id, {
        page,
        perPage,
        query,
        filters,
      })
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(storeRecipients(res.data.items));
        dispatch(
          storeRecipientsMeta({
            page: res.data.current_page,
            perPage,
            totalPages: res.data.pages,
            filters,
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
}
