import styled from 'styled-components';
import { rem } from 'polished';

import { font, colorName, transitions } from 'styles';

const BAR_HEIGHT_PX = 6;
const BAR_SEPARATION_PX = 2;

export const Wrapper = styled.section`
  position: relative;
`;

export const Bar = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: ${rem(BAR_HEIGHT_PX)};
  overflow: hidden;
  border-radius: ${BAR_HEIGHT_PX / 2}px;
  ${({ max }) => max === 0 && `background-color: ${colorName.disabled}`};

  &::before,
  &::after {
    display: inline-block;
    flex: 0 1 0;
    border-radius: ${BAR_HEIGHT_PX / 2}px;
    transition: flex-basis 0.2s ${transitions.spring};
    ${({ max }) => max !== 0 && 'content: ""'};
  }

  &::before {
    flex-basis: ${({ value, max }) => `${Math.round((value / max) * 100)}%`};
    margin-right: ${rem(BAR_SEPARATION_PX)};
    background-color: ${colorName.success};
  }

  &::after {
    flex-basis: ${({ value, max }) => `${Math.round(((max - value) / max) * 100)}%`};
    background-color: ${colorName.error};
  }
`;

export const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: ${rem(12)};
`;

export const Label = styled.span`
  flex: 0 0 auto;
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.medium)};
  line-height: 1;
  text-transform: uppercase;

  &:first-child {
    margin: 0 ${rem(8)} 0 0;
    color: ${colorName.success};
  }

  &:last-child {
    margin: 0 0 0 auto;
    color: ${colorName.error};
  }
`;

export const LabelExplanation = styled.span`
  margin-left: ${rem(4)};
  color: ${colorName.disabled};
  font-size: ${rem(font.size.tiny)};
`;
