import React from 'react';
import PropTypes from 'prop-types';

import { StyledSingleValue } from 'components/AutocompleteSelect/style';

const propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.shape({
    isDark: PropTypes.bool,
  }),
};

export const SingleValue = ({ children, selectProps: { isDark } }) => {
  return <StyledSingleValue isDark={isDark}>{children}</StyledSingleValue>;
};

SingleValue.propTypes = propTypes;
