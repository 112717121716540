import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import api from 'api';
import {
  getOfficerChatMessages,
  postMessageToOfficer,
  postFileToOfficer,
} from 'stores/chatrooms';

import Modal from 'components/Modal';
import Typography from 'components/Typography';
import { ChatMessageInput, ChatMessages } from 'components/Chat';

import { ModalWrapper } from './style';
import { messages } from './messages';

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

const ChatModal = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    isFileProcessing,
    rooms: { officerChat },
  } = useSelector((state) => state.chatrooms);

  useEffect(() => {
    dispatch(getOfficerChatMessages());
  }, [dispatch]);

  const sendMessage = useCallback(
    (text) => {
      dispatch(postMessageToOfficer(text));
    },
    [dispatch]
  );

  const sendFile = useCallback(
    (file) => {
      dispatch(postFileToOfficer(file));
    },
    [dispatch]
  );

  const refreshMessages = useCallback(
    () => dispatch(getOfficerChatMessages()),
    [dispatch]
  );

  return (
    <Modal isOpen autoHeight isNarrowContent onRequestClose={onClose}>
      <ModalWrapper>
        <Typography size="large" bottomMargin={32}>
          {intl.formatMessage(messages.header)}
        </Typography>

        <ChatMessages
          messages={officerChat}
          fileUrlResolver={api.me.resolveFileUrl}
          refreshMessages={refreshMessages}
        />

        <ChatMessageInput
          sendHandler={sendMessage}
          uploadHandler={sendFile}
          areUploadsAllowed
          uploadingInProgress={isFileProcessing}
        />
      </ModalWrapper>
    </Modal>
  );
};

ChatModal.propTypes = propTypes;

export default ChatModal;
