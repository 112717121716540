import React from 'react';

function Attachment(props) {
  return (
    <svg viewBox="0 0 18 18" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit={10}
        d="M16.723 8.555l-6.962 6.962c-1.977 1.977-5.3 1.977-7.278 0-1.977-1.978-1.977-5.3 0-7.278l6.17-6.171c1.425-1.424 3.64-1.424 5.064 0s1.424 3.639 0 5.063L8.02 12.748c-.791.79-2.057.79-2.769 0-.79-.791-.79-2.057 0-2.769L10 5.232"
      />
    </svg>
  );
}

export default Attachment;
