import styled from 'styled-components';
import { rem } from 'polished';
import { font, breakpoints } from 'styles';

export const TypographyElement = styled.p`
  width: ${({ unsetWidth }) => (unsetWidth ? 'unset' : '100%')};
  max-width: ${({ maxWidth }) => maxWidth && rem(maxWidth)};
  margin-top: ${({ topMargin }) => topMargin && rem(topMargin)};
  margin-right: ${({ xMargin }) => (xMargin || xMargin === 0 ? rem(xMargin) : 'auto')};
  margin-bottom: ${({ bottomMargin }) => bottomMargin && rem(bottomMargin)};
  margin-left: ${({ xMargin }) => (xMargin || xMargin === 0 ? rem(xMargin) : 'auto')};
  color: ${({ color }) => color && color};
  font-weight: ${({ weight }) => weight && font.weight[weight]};
  font-size: ${({ size }) => size && rem(font.size[size])};
  line-height: 1.4;
  white-space: ${'pre-line'};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  word-break: ${({ wrapText }) => wrapText && 'break-word'};

  ${({ tabletSize }) =>
    tabletSize &&
    `
    @media ${breakpoints.tablet} {
      font-size: ${rem(font.size[tabletSize])};
    }
  `};
`;
