import api from 'api';
import { resetUsers } from './groupUsers';
import { setToastMessage } from './toastMessages';
import { toastContext, toastType } from 'helpers/toastConstants';

// Actions
export const SET_GROUP_NAME = 'addingGroup.SET_GROUP_NAME';
export const RESET_GROUP = 'addingGroup.RESET_GROUP';
export const SET_ADDED_GROUP = 'addingGroup.SET_ADDED_GROUP';
export const RESET_ADDED_GROUP = 'addingGroup.RESET_ADDED_GROUP';

// Reducer
const initialState = {
  groupName: '',
  addedGroup: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_GROUP_NAME:
      return {
        ...state,
        groupName: action.payload,
      };
    case SET_ADDED_GROUP:
      return {
        ...state,
        addedGroup: action.payload,
      };
    case RESET_ADDED_GROUP:
      return {
        ...state,
        addedGroup: null,
      };
    case RESET_GROUP:
      return {
        ...state,
        groupName: '',
      };
    default:
      return state;
  }
}

// Action Creators
export function resetGroup() {
  return { type: RESET_GROUP };
}

export function resetAddedGroup() {
  return { type: RESET_ADDED_GROUP };
}

export function setNewGroupName(name) {
  return { type: SET_GROUP_NAME, payload: name };
}

export function setAddedGroup(group) {
  return { type: SET_ADDED_GROUP, payload: group };
}

// Side Effects
export function submitGroup(name, users) {
  return (dispatch) => {
    return api.groups
      .addGroup(name, users)
      .then((res) => {
        dispatch(
          setAddedGroup({
            name,
            id: res.data.group_id,
          })
        );
        dispatch(resetGroup());
        dispatch(resetUsers());
      })
      .catch((error) => {
        setToastMessage({
          content: { message: error.response.data.error },
          context: toastContext.ADD_GROUP,
          type: toastType.ALERT,
        })(dispatch);
        dispatch(resetGroup());
        dispatch(resetUsers());
      });
  };
}

export function setGroupName(name) {
  return (dispatch) => {
    dispatch(setNewGroupName(name));
  };
}

export function clearAddedGroup() {
  return (dispatch) => {
    dispatch(resetAddedGroup());
  };
}
