import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { ROUTING } from 'routing';

import AuthRoute from 'components/AuthRoute';

export const CoRoute = (props) => {
  const { hasOfficerRights } = useSelector((state) => state.auth);
  return hasOfficerRights ? <AuthRoute {...props} /> : <Redirect to={ROUTING.INDEX} />;
};

export default CoRoute;
