import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import ChatModal from './ChatModal';
import ChatIcon from 'components/Svg/ChatIcon';

import { FloatingButton } from './style';
import { messages } from './messages';

const FloatingChat = () => {
  const intl = useIntl();
  const { hasUnreadMessages } = useSelector((state) => state.auth);
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <FloatingButton
        onClick={() => setModalOpen(true)}
        aria-label={intl.formatMessage(messages.header)}
        isNew={hasUnreadMessages}
        data-testid="support-chat"
      >
        <ChatIcon />
      </FloatingButton>

      {isModalOpen && <ChatModal onClose={() => setModalOpen(false)} />}
    </>
  );
};

export default FloatingChat;
