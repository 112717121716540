import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ROUTING } from 'routing';

import { Logo, Introduction, LogoWrapper } from './style';

import AppLanguageSwitch from 'containers/public/Auth/Login/AppLanguageSwitch';
import { useCustomConfig } from 'helpers/hooks';

const LoginHeader = () => {
  const { properties } = useSelector((state) => state.tenantProperties);
  const { languagesFromMisconducts } = useCustomConfig();
  const poweredBy = properties?.poweredBy;

  return (
    <Introduction>
      <Link to={ROUTING.INDEX}>
        <LogoWrapper>
          <Logo poweredBy={poweredBy} />
        </LogoWrapper>
      </Link>

      <AppLanguageSwitch
        availableLanguages={
          languagesFromMisconducts ? properties.misconductLanguages : undefined
        }
      />
    </Introduction>
  );
};

export default LoginHeader;
