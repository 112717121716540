// Actions
export const SET_LOADING = 'me.SET_LOADING';
export const SET_FILE_UPLOADING = 'me.SET_FILE_UPLOADING';

// Reducer
export const initialState = {
  isLoading: false,
  isFileUploading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        isLoading: true,
      };
    case SET_FILE_UPLOADING:
      return {
        isFileUploading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload });
export const setFileLoading = (payload) => ({ type: SET_FILE_UPLOADING, payload });
