import React from 'react';

const CloseIcon = (props) => (
  <svg viewBox="0 0 12 12" fill="none" {...props}>
    <title>Close</title>
    <path
      d="M11.706.29a1.003 1.003 0 00-1.416.004L6 4.584 1.71.294A1.003 1.003 0 00.294.29a.997.997 0 00-.002 1.411l4.296 4.296-4.296 4.296A.997.997 0 001 11.997a.994.994 0 00.709-.294L6 7.413l4.29 4.293A.995.995 0 0011 12a.997.997 0 00.708-1.703L7.412 5.998l4.294-4.296a.994.994 0 000-1.411z"
      fill="currentColor"
    />
  </svg>
);

export default CloseIcon;
