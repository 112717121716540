import React from 'react';

const ShieldIcon = (props) => (
  <svg viewBox="0 0 64 66" {...props}>
    <title>Shield</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.87 4.73c8.29.03 16.43 2.22 23.59 6.37l.5.29.01 27.39c.01 3.14-.6 6.24-1.81 9.14-1.21 2.9-2.98 5.53-5.22 7.74-2.24 2.21-4.9 3.97-7.82 5.16a24.358 24.358 0 01-18.48-.06 24.174 24.174 0 01-7.83-5.21 24.003 24.003 0 01-5.22-7.77c-1.21-2.9-1.82-6.01-1.81-9.15V11.24l.5-.29c7.17-4.1 15.31-6.25 23.59-6.22zm22.07 7.81a45.305 45.305 0 00-22.06-5.8A45.07 45.07 0 009.81 12.4v26.24c-.01 2.87.56 5.72 1.66 8.38 1.11 2.66 2.73 5.08 4.78 7.12 2.05 2.04 4.49 3.66 7.17 4.77a22.326 22.326 0 0016.92.05 21.856 21.856 0 0011.95-11.82c1.1-2.65 1.67-5.5 1.66-8.37l-.01-26.23z"
    />
    <path d="M30.32 37.74c-.14-.9-.12-1.71.07-2.42.21-.74.5-1.41.85-2.01.35-.62.76-1.2 1.2-1.73.47-.53.91-1.05 1.31-1.56.4-.53.73-1.06.99-1.59.28-.53.42-1.11.42-1.73 0-.92-.29-1.71-.88-2.35-.57-.67-1.42-1-2.55-1-.78 0-1.52.18-2.23.55-.68.35-1.3.83-1.84 1.45L26 23.87c.76-.83 1.63-1.51 2.62-2.04 1.01-.55 2.17-.83 3.47-.83 1.79 0 3.22.5 4.28 1.49 1.09.97 1.63 2.31 1.63 4.04 0 .76-.14 1.45-.42 2.08-.28.62-.64 1.21-1.06 1.76-.4.55-.84 1.11-1.31 1.66-.45.53-.87 1.08-1.27 1.66-.38.58-.67 1.2-.88 1.87-.21.65-.27 1.37-.18 2.18h-2.56zM31.66 45c-.59 0-1.09-.2-1.49-.59-.4-.42-.6-.93-.6-1.56 0-.67.2-1.2.6-1.59.4-.41.9-.62 1.49-.62s1.09.21 1.49.62c.42.39.64.92.64 1.59 0 .62-.21 1.14-.64 1.56-.4.39-.9.59-1.49.59z" />
  </svg>
);

export default ShieldIcon;
