import { composeUserFullName } from './composeUserFullName';

export const parseError = (error, user) => {
  const errorText = error.response?.data?.error || 'Unknown error';

  if (errorText.includes('owner')) {
    const docNameRegex = /'(.*?)'/g;
    const userNameRegex = /User (\w* \w*)/;
    const docs = errorText.match(docNameRegex);
    const userName = errorText.match(userNameRegex);

    return {
      translationKey: 'userIsOwnerOfDocumentError',
      param: userName[1],
      param2: docs.join(', '),
    };
  }
  if (errorText.includes('Officer')) {
    const userIdRegex = /User with id: (\w*)/;
    const userId = errorText.match(userIdRegex);
    return {
      translationKey: 'userIsOfficerError',
      param: user ? composeUserFullName(user) : userId[1],
    };
  }
  return {
    message: errorText,
  };
};
