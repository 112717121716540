import styled from 'styled-components';
import { rem } from 'polished';

import { colors, colorName, font, transitions, variables } from 'styles';
import { DropdownIndicator, StyledLabel } from 'styles/commonStyles';

const SELECT_HEIGHT = 40;
const SELECT_PADDING = 16;

export const SelectMainWrapper = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => rem(maxWidth)};
  margin-right: ${({ isCentered }) => isCentered && 'auto'};
  margin-bottom: ${({ marginBottom }) => rem(marginBottom)};
  margin-left: ${({ isCentered }) => isCentered && 'auto'};
`;

export const SelectLabel = styled(StyledLabel)`
  position: relative;

  &:after {
    ${DropdownIndicator};
    position: absolute;
    right: 16px;
    bottom: 14px;
    display: block;
    content: '';
    pointer-events: none;
  }
`;

export const StyledSelect = styled.select`
  display: block;
  width: 100%;
  height: ${rem(SELECT_HEIGHT)};
  margin: ${rem(8)} 0 0;
  padding: 0 ${rem(SELECT_PADDING)};
  color: ${({ isDark }) => (isDark ? colorName.white : colorName.black)};
  font-weight: ${font.weight.regular};
  font-size: ${rem(font.size.small)};
  background-color: ${({ isDark }) => (isDark ? colors.mako : colorName.white)};
  border: 1px solid ${colors.gray};
  border-radius: ${rem(variables.borderRadius)};
  outline: 0;
  cursor: pointer;
  transition: border ${transitions.uiTiming} ${transitions.spring};
  appearance: none;

  ${({ required, isSelected }) =>
    required &&
    isSelected &&
    `
      border: 1px solid ${colors.green};
    `};

  ${({ disabled }) =>
    disabled &&
    `
      background: ${colors.grayish};
      cursor: not-allowed;
    `};

  &::-ms-expand {
    display: none;
  }

  &:hover {
    border: 1px solid ${({ isDark }) => (isDark ? colors.white : colors.mediumGray)};
    cursor: pointer;
  }

  &:focus {
    border: 1px solid ${({ isDark }) => (isDark ? colors.white : colors.navy)};
    caret-color: ${({ isDark }) => (isDark ? colors.white : colors.navy)};
    cursor: pointer;
  }
`;
