import React from 'react';
import PropTypes from 'prop-types';

import { StyledPlaceholder } from 'components/AutocompleteSelect/style';

const propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.shape({
    isDark: PropTypes.bool,
  }),
};

export const Placeholder = ({ children, selectProps: { isDark } }) => {
  return <StyledPlaceholder isDark={isDark}>{children}</StyledPlaceholder>;
};

Placeholder.propTypes = propTypes;
