import React from 'react';

const GroupIcon = (props) => {
  return (
    <svg fill="none" {...props} viewBox="0 0 20 20">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.5 3a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-3zM9 6V4h2v2H9zM7.757 8.071a.5.5 0 01.172.686L5.383 13H6.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1.717l2.854-4.757a.5.5 0 01.686-.172zM4.491 14H3v3h3v-3H4.491zm7.752-5.929a.5.5 0 01.686.172L15.783 13H17.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1.117l-2.546-4.243a.5.5 0 01.172-.686zM15.509 14H14v3h3v-3h-1.49z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default GroupIcon;
