import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { StyledContainer } from 'components/AutocompleteSelect/style';

const propTypes = {
  children: PropTypes.node.isRequired,
  selectProps: PropTypes.shape({
    isDark: PropTypes.bool,
    menuIsOpen: PropTypes.bool,
  }),
};

export const SelectContainer = ({
  children,
  selectProps: { isDark, menuIsOpen },
  ...props
}) => {
  return (
    <StyledContainer isMenuOpen={menuIsOpen} isDark={isDark}>
      <components.SelectContainer {...props}>{children}</components.SelectContainer>
    </StyledContainer>
  );
};

SelectContainer.propTypes = propTypes;
