import api from 'api';
import { resetUsers } from './groupUsers';
import { setToastMessage } from './toastMessages';
import { toastContext, toastType } from 'helpers/toastConstants';
import { serializePermissions } from 'deserializers';
import { history } from 'helpers/appHistory';
import { ROUTING } from 'routing';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
} from 'helpers/sharedConstants';
import { defaultRule } from './helpers/mocks';
import { storeMisconductsRighsArray } from './reportDetails';

// Actions
export const STORE_GROUP_DETAILS = 'groupDetails.STORE_GROUP_DETAILS';
export const RESET_GROUP_DETAILS = 'groupDetails.RESET_GROUP_DETAILS';
export const UNDO_USER = 'groupDetails.UNDO_USER';
export const DELETE_USER = 'groupDetails.DELETE_USER';
export const RESET_DELETED_USERS = 'groupDetails.RESET_DELETED_USERS';
export const SET_LOADING_GROUP_DETAILS = 'groupDetails.SET_LOADING_GROUP_DETAILS';
export const STORE_GROUP_USERS = 'groupDetails.STORE_GROUP_USERS';
export const STORE_GROUP_USERS_META = 'groupDetails.STORE_GROUP_USERS_META';
export const SET_LOADING_GROUP_USERS = 'groupDetails.SET_LOADING_GROUP_USERS';
export const STORE_RULES_TEMPLATE = 'groupDetails.STORE_RULES_TEMPLATE';
export const SET_EXISTING_RULES = 'groupDetails.SET_EXISTING_RULES';
export const SET_RULES_PERMISSIONS = 'groupDetails.SET_RULES_PERMISSIONS';
export const ADD_NEW_RULE = 'groupDetails.ADD_NEW_RULE';
export const SET_RULE_QUESTON = 'groupDetails.SET_RULE_QUESTON';
export const SET_RULE_CHOICE = 'groupDetails.SET_RULE_CHOICE';
export const DELETE_RULE = 'groupDetails.DELETE_RULE';
export const SET_RULES_LOADING = 'groupDetails.SET_RULES_LOADING';
export const SET_RULES_PROCESSING = 'groupDetails.SET_RULES_PROCESSING';

// Reducer
const initialState = {
  groupDetails: null,
  rulesTemplate: [],
  rulesData: [],
  rulesPermissions: [],
  deletedUsers: [],
  isLoading: true,
  isUserListLoading: true,
  isRulesLoading: true,
  isRulesProcessing: false,
  groupUsers: [],
  groupUsersMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_GROUP_DETAILS:
      return {
        ...state,
        groupDetails: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        deletedUsers: [...state.deletedUsers, action.payload],
      };
    case UNDO_USER:
      return {
        ...state,
        deletedUsers: state.deletedUsers.filter((m) => m !== action.payload),
      };
    case RESET_GROUP_DETAILS:
      return initialState;
    case RESET_DELETED_USERS:
      return {
        ...state,
        deletedUsers: [],
      };
    case SET_LOADING_GROUP_DETAILS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case STORE_GROUP_USERS:
      return {
        ...state,
        groupUsers: action.payload,
      };
    case STORE_GROUP_USERS_META:
      return {
        ...state,
        groupUsersMeta: action.payload,
      };
    case SET_LOADING_GROUP_USERS:
      return {
        ...state,
        isUserListLoading: action.payload,
      };
    case SET_RULES_LOADING:
      return {
        ...state,
        isRulesLoading: action.payload,
      };
    case SET_RULES_PROCESSING:
      return {
        ...state,
        isRulesProcessing: action.payload,
      };
    case SET_EXISTING_RULES:
      return {
        ...state,
        rulesData: action.payload,
      };
    case SET_RULES_PERMISSIONS:
      return {
        ...state,
        rulesPermissions: action.payload,
      };
    case STORE_RULES_TEMPLATE:
      return {
        ...state,
        rulesTemplate: action.payload,
      };
    case ADD_NEW_RULE:
      return {
        ...state,
        rulesData: [...state.rulesData, defaultRule],
      };
    case DELETE_RULE: {
      const { idx } = action.payload;
      return {
        ...state,
        rulesData: [...state.rulesData.slice(0, idx), ...state.rulesData.slice(idx + 1)],
      };
    }
    case SET_RULE_QUESTON: {
      const { idx, id } = action.payload;
      return {
        ...state,
        rulesData: [
          ...state.rulesData.map((rule, index) =>
            index === idx ? { ...rule, question_id: id } : rule
          ),
        ],
      };
    }
    case SET_RULE_CHOICE: {
      const { idx, id } = action.payload;
      return {
        ...state,
        rulesData: [
          ...state.rulesData.map((rule, index) =>
            index === idx ? { ...rule, choice_id: id } : rule
          ),
        ],
      };
    }
    default:
      return state;
  }
}

// Action Creators
export function storeGroupDetails(data) {
  return { type: STORE_GROUP_DETAILS, payload: data };
}

export function resetGroupDetails() {
  return { type: RESET_GROUP_DETAILS };
}
export function resetDeletedUsers() {
  return { type: RESET_DELETED_USERS };
}
export function deleteUser(user) {
  return { type: DELETE_USER, payload: user };
}

export function undoUser(user) {
  return { type: UNDO_USER, payload: user };
}

export function setLoading(bool) {
  return { type: SET_LOADING_GROUP_DETAILS, payload: bool };
}

export function setUserListLoading(bool) {
  return { type: SET_LOADING_GROUP_USERS, payload: bool };
}

export function storeGroupUsers(data) {
  return { type: STORE_GROUP_USERS, payload: data };
}

export function storeGroupUsersMeta(data) {
  return { type: STORE_GROUP_USERS_META, payload: data };
}

export const setRuleQuestion = ({ idx, id }) => ({
  type: SET_RULE_QUESTON,
  payload: { idx, id },
});

export const setRuleChoice = ({ idx, id }) => ({
  type: SET_RULE_CHOICE,
  payload: { idx, id },
});

export const deleteRule = ({ idx }) => ({
  type: DELETE_RULE,
  payload: { idx },
});

export const setRulesLoading = (payload) => ({ type: SET_RULES_LOADING, payload });
export const storeRulesTemplate = (payload) => ({ type: STORE_RULES_TEMPLATE, payload });
export const addNewRule = (payload) => ({ type: ADD_NEW_RULE, payload });
export const setRulesProcessing = (payload) => ({ type: SET_RULES_PROCESSING, payload });
export const setExistingRules = (payload) => ({ type: SET_EXISTING_RULES, payload });
export const setRulesPermissions = (payload) => ({
  type: SET_RULES_PERMISSIONS,
  payload,
});

// Side Effects
export function getGroupDetails(id) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return api.groups
      .getGroupDetails(id)
      .then((res) => {
        dispatch(storeGroupDetails(res.data));
        dispatch(resetDeletedUsers());
        dispatch(resetUsers());
      })
      .catch(() => {
        //@todo handle error
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}

export function editGroup(id, name, usersToDelete, hasNameChanged) {
  return (dispatch) => {
    dispatch(setLoading(true));
    const groupName = hasNameChanged ? name : null;

    return api.groups
      .editGroupDetails(id, groupName, usersToDelete)
      .then((res) => {
        dispatch(storeGroupDetails(res.data));
        getGroupUsers({ id })(dispatch);
        dispatch(setLoading(false));
        setToastMessage({
          content: { translationKey: 'groupEditedSuccess', param: name },
          context: `${toastContext.GROUPS_DETAILS}${id}`,
          type: toastType.SUCCESS,
        })(dispatch);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        setToastMessage({
          content: { message: error.response.data.error },
          context: `${toastContext.GROUPS_DETAILS}${id}`,
          type: toastType.ALERT,
        })(dispatch);
      });
  };
}

export function deleteGroup(id, name) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return api.groups
      .deleteGroup(id)
      .then(() => {
        setToastMessage({
          content: { translationKey: 'groupDeletedSuccess', param: name },
          context: toastContext.GROUPS_MANAGEMENT,
          type: toastType.SUCCESS,
        })(dispatch);
        history.push(ROUTING.GROUPS);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        setToastMessage({
          content: { message: error.response.data.error },
          context: toastContext.GROUPS_MANAGEMENT,
          type: toastType.ALERT,
        })(dispatch);
        dispatch(resetGroupDetails());
      });
  };
}

export function getGroupUsers({
  id,
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
}) {
  return (dispatch) => {
    dispatch(setUserListLoading(true));
    return api.groups
      .getGroupUsers({ id, page, perPage })
      .then((res) => {
        dispatch(storeGroupUsers(res.data.items));
        dispatch(storeGroupUsersMeta({ page, perPage, totalPages: res.data.pages }));
      })
      .catch(() => {
        //@todo handle error
      })
      .finally(() => {
        dispatch(setUserListLoading(false));
      });
  };
}

export const getCurrentTemplateAndRules = (id) => async (dispatch) => {
  dispatch(setRulesLoading(true));

  try {
    const template = await api.config.getRulesTemplate();
    const currentGroupRules = await api.groups.getGroupRules(id);
    const accessRights = await api.misconduct.getRightsKeys();
    const rightsArray = serializePermissions(accessRights.data);
    const serializedPermissions = serializePermissions(
      currentGroupRules.data.permissions
    );

    dispatch(storeMisconductsRighsArray(rightsArray));
    dispatch(storeRulesTemplate(template.data.questions));
    dispatch(setExistingRules(currentGroupRules.data.rules));
    dispatch(setRulesPermissions(serializedPermissions));
  } catch (error) {
    //@todo: Error handling
  } finally {
    dispatch(setRulesLoading(false));
  }
};

export const saveGroupRules =
  ({ id, rules, permissions, applyRules }) =>
  async (dispatch) => {
    dispatch(setRulesProcessing(true));

    try {
      const serializedPermissions = permissions.map((permission) => permission.value);
      const response = await api.groups.saveGroupRules({
        id,
        rules,
        permissions: serializedPermissions,
        applyRules,
      });
      setToastMessage({
        content: { translationKey: 'groupRulesSaved' },
        context: toastContext.GROUPS_DETAILS,
        type: toastType.SUCCESS,
      })(dispatch);
      return response;
    } catch (error) {
      setToastMessage({
        content: { translationKey: 'groupRulesNotSaved' },
        context: toastContext.GROUPS_DETAILS,
        type: toastType.ALERT,
      })(dispatch);
    } finally {
      dispatch(setRulesProcessing(false));
    }
  };
