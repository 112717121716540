import React from 'react';
import PropTypes from 'prop-types';
import { notificationComponentShape } from 'models';

import { toastType } from 'helpers/toastConstants';

import CircleTick from 'components/Svg/CircleTick';
import Warning from 'components/Svg/Warning';
import AttentionIcon from 'components/Svg/AttentionIcon';
import InfoIcon from 'components/Svg/InfoIcon';

import {
  NotificationIconWrapper,
  NotificationMessage,
  NotificationMessageWrapper,
} from 'styles/commonStyles';

const defaultProps = {
  isNew: false,
  iconWrapper: NotificationIconWrapper,
};

const propTypes = { ...notificationComponentShape, iconWrapper: PropTypes.object };

const Notification = ({
  children,
  type,
  isNew,
  iconWrapper: IconWrapper,
  marginBottom,
}) => {
  const typeToIcon = {
    [toastType.SUCCESS]: <CircleTick />,
    [toastType.INFO]: <InfoIcon />,
    [toastType.WARNING]: <Warning />,
    [toastType.ALERT]: <AttentionIcon />,
  };

  return (
    <NotificationMessageWrapper isNew={isNew} marginBottom={marginBottom}>
      <IconWrapper type={type}>{typeToIcon[type]}</IconWrapper>

      <NotificationMessage>{children}</NotificationMessage>
    </NotificationMessageWrapper>
  );
};

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

export default Notification;

export const AlertNotification = (props) => (
  <Notification type={toastType.ALERT} marginBottom={24} {...props}>
    {props.children}
  </Notification>
);

AlertNotification.propTypes = {
  children: PropTypes.node,
};
