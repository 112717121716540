import React, { Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Router, Switch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import api, {
  ApiContext,
  addApiInterceptor,
  isSessionTimeout,
  isUnauthorized,
  isExpiredPassword,
  isForbidden,
} from 'api';
import { fetchTenantProperties } from 'stores/tenantProperties';
import {
  handleSessionTimeout,
  handleForbidden,
  logOutUserAndClearSession,
  passwordExpired,
} from 'stores/auth';

import AuthRoute from 'components/AuthRoute';
import EmployeeRoute from 'components/EmployeeRoute';
import CoRoute from 'components/CoRoute';
import AnonymousRoute from 'components/AnonymousRoute';
import RouteLoader from 'components/RouteLoader';
import ErrorBoundary from 'components/ErrorBoundary';
import { ScrollToTop } from 'components/ScrollToTop';
import RouteListener from 'components/RouteListener';

import Login from 'containers/public/Auth/Login';
import ForgotPassword from 'containers/public/Auth/ForgotPassword';
import SetPassword from 'containers/public/Auth/SetPassword';
import CheckMisconductStatus from 'containers/public/Misconduct/CheckMisconductStatus';
import MisconductForm from 'containers/public/Misconduct/MisconductForm';
import MultipleMisconductForm from 'containers/public/Misconduct/MultipleMisconductForm';
import NotFound from './NotFound';
import EmployeeSessionTimeout from 'containers/public/SessionTimeout/EmployeeSessionTimeout';
import CoSessionTimeout from 'containers/public/SessionTimeout/CoSessionTimeout';

import { ROUTING } from 'routing';
import { history } from 'helpers/appHistory';
import RecaptchaConfigProvider from 'components/Recaptcha/RecaptchaConfigProvider';
import { langCodes } from 'helpers/commonConstants';
import Unauthorized from './public/Unauthorized';
import LandingPage from './public/Auth/LandingPage';
import { InterceptorProvider } from 'InterceptorProvider';
import { getUserLanguage } from 'helpers/functions';

const CoDashboard = React.lazy(() => import('containers/private/Dashboard/CoDashboard'));
const EmployeeDashboard = React.lazy(() =>
  import('containers/private/Dashboard/EmployeeDashboard')
);
const ReportDetails = React.lazy(() => import('containers/private/ReportDetails'));
const Notifications = React.lazy(() => import('containers/private/Notifications'));
const UserManagement = React.lazy(() => import('containers/private/UserManagement'));
const ManualImport = React.lazy(() =>
  import('containers/private/UserManagement/Import/ManualImport')
);
const FileImport = React.lazy(() =>
  import('containers/private/UserManagement/Import/FileImport')
);
const UserDetails = React.lazy(() => import('containers/private/UserDetails'));
const CurrentUserDocuments = React.lazy(() =>
  import('containers/private/UserDetails/CurrentUserDocuments')
);
const Reports = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'containers/private/Reports')
);
const AddReport = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'containers/private/AddReport')
);
const Trainings = React.lazy(() => import('containers/private/Trainings'));
const Account = React.lazy(() => import('containers/private/Account'));
const Groups = React.lazy(() => import('containers/private/Groups'));
const GroupDetails = React.lazy(() => import('containers/private/Groups/GroupDetails'));
const AddUsers = React.lazy(() => import('containers/private/Groups/AddUsers'));
const AddGroup = React.lazy(() => import('containers/private/Groups/AddGroup'));
const Documents = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'containers/private/Documents')
);
const DocumentEdit = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'containers/private/Documents/DocumentEdit')
);
const NewDocument = React.lazy(() =>
  import(/* webpackPrefetch: true */ 'containers/private/Documents/NewDocument')
);
const DocumentPreview = React.lazy(() => import('containers/private/DocumentPreview'));
const DocumentOwnerPreview = React.lazy(() =>
  import('containers/private/DocumentPreview/DocumentOwnerPreview')
);
const UserDocuments = React.lazy(() => import('containers/private/UserDocuments'));
const UserMisconducts = React.lazy(() => import('containers/private/UserMisconducts'));
const CoChats = React.lazy(() => import('containers/private/CoChats'));

const propTypes = {
  intl: PropTypes.object,
  userLanguage: PropTypes.string,
};

const Application = ({ userLanguage }) => {
  const [translations, setTranslations] = useState();
  const defaultLocale = langCodes.en;
  const locale = getUserLanguage(userLanguage);
  const dispatch = useDispatch();

  addApiInterceptor(null, (error) => {
    if (isSessionTimeout(error)) {
      dispatch(handleSessionTimeout());
    } else if (isUnauthorized(error)) {
      dispatch(logOutUserAndClearSession());
    } else if (isForbidden(error)) {
      dispatch(handleForbidden());
    } else if (isExpiredPassword(error)) {
      dispatch(passwordExpired());
    }
  });

  useEffect(() => {
    const fetchTranslation = async () => {
      try {
        const { data } = await api.config.getTranslation(locale);
        setTranslations(data);
      } catch (error) {
        //@todo handle error
      }
    };

    fetchTranslation();
  }, [locale]);

  useEffect(() => {
    dispatch(fetchTenantProperties(userLanguage));
  }, [dispatch, userLanguage]);

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      defaultLocale={defaultLocale}
      messages={translations ? { ...translations?.[locale] } : {}}
      onError={(err) => {
        if (err.code === 'MISSING_TRANSLATION') {
          return;
        }
        throw err;
      }}
    >
      <ErrorBoundary>
        <RecaptchaConfigProvider>
          <ApiContext.Provider value={{ api }}>
            <InterceptorProvider>
              <Router history={history}>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                  <ScrollToTop>
                    <Suspense fallback={<RouteLoader />}>
                      <Switch>
                        <Route path={ROUTING.INDEX} exact component={LandingPage} />
                        <Route path={ROUTING.LOGIN} exact component={Login} />
                        <Route
                          path={ROUTING.REPORT_MISCONDUCT}
                          component={MisconductForm}
                        />
                        <Route
                          path={ROUTING.SURVEYS_SURVEY}
                          component={MultipleMisconductForm}
                        />

                        <Route
                          path={ROUTING.EMPLOYEE_SESSION_TIMEOUT}
                          exact
                          component={EmployeeSessionTimeout}
                        />
                        <Route
                          path={ROUTING.CO_SESSION_TIMEOUT}
                          exact
                          component={CoSessionTimeout}
                        />
                        <Route
                          path={ROUTING.UNAUTHORIZED}
                          exact
                          component={Unauthorized}
                        />
                        <AnonymousRoute
                          path={ROUTING.FORGOT_PASSWORD}
                          exact
                          component={ForgotPassword}
                        />
                        <Route
                          path={[ROUTING.SET_PASSWORD, ROUTING.RESET_PASSWORD]}
                          exact
                          component={SetPassword}
                        />
                        <AnonymousRoute
                          path={ROUTING.CHECK_STATUS}
                          exact
                          component={CheckMisconductStatus}
                        />

                        <AuthRoute path={ROUTING.ACCOUNT} exact component={Account} />
                        <AuthRoute
                          path={ROUTING.NOTIFICATIONS}
                          exact
                          component={Notifications}
                        />
                        <AuthRoute path={ROUTING.REPORTS} exact component={Reports} />
                        <CoRoute path={ROUTING.ADD_REPORT} component={AddReport} />
                        <AuthRoute path={ROUTING.TRAININGS} exact component={Trainings} />
                        <AuthRoute
                          path={ROUTING.USER_MANAGEMENT}
                          exact
                          component={UserManagement}
                        />
                        <AuthRoute
                          path={ROUTING.USER_DETAILS}
                          exact
                          component={UserDetails}
                        />
                        <AuthRoute path={ROUTING.GROUPS} exact component={Groups} />
                        <AuthRoute
                          path={ROUTING.GROUPS_DETAILS}
                          exact
                          component={GroupDetails}
                        />
                        <AuthRoute
                          path={ROUTING.GROUPS_ADD_USERS}
                          exact
                          component={AddUsers}
                        />
                        <AuthRoute
                          path={ROUTING.GROUPS_ADD_NEW}
                          exact
                          component={AddGroup}
                        />
                        <AuthRoute
                          path={ROUTING.REPORT_PREVIEW}
                          exact
                          component={ReportDetails}
                        />
                        <AuthRoute
                          path={ROUTING.MANUAL_IMPORT}
                          exact
                          component={ManualImport}
                        />
                        <AuthRoute
                          path={ROUTING.FILE_IMPORT}
                          exact
                          component={FileImport}
                        />

                        <EmployeeRoute
                          path={ROUTING.DASHBOARD_EMPLOYEE}
                          exact
                          component={EmployeeDashboard}
                        />
                        <EmployeeRoute
                          path={ROUTING.USER_DOCUMENTS_LIST}
                          exact
                          component={UserDocuments}
                        />
                        <EmployeeRoute
                          path={ROUTING.USER_MISCONDUCTS_LIST}
                          exact
                          component={UserMisconducts}
                        />
                        <EmployeeRoute
                          path={ROUTING.DOCUMENT_PREVIEW}
                          exact
                          component={DocumentPreview}
                        />

                        <EmployeeRoute
                          path={ROUTING.DOCUMENT_OWNER}
                          exact
                          component={DocumentOwnerPreview}
                        />

                        <CoRoute
                          path={ROUTING.DASHBOARD_CO}
                          exact
                          component={CoDashboard}
                        />
                        <CoRoute
                          path={ROUTING.USER_DOCUMENTS}
                          exact
                          component={CurrentUserDocuments}
                        />
                        <CoRoute path={ROUTING.DOCUMENTS} exact component={Documents} />
                        <CoRoute
                          path={ROUTING.NEW_DOCUMENT}
                          exact
                          component={NewDocument}
                        />
                        <CoRoute
                          path={ROUTING.DOCUMENT_EDIT}
                          exact
                          component={DocumentEdit}
                        />
                        <CoRoute path={ROUTING.CO_CHATS} exact component={CoChats} />

                        <Route path="*" component={NotFound} />
                      </Switch>
                    </Suspense>
                  </ScrollToTop>

                  <RouteListener />
                </QueryParamProvider>
              </Router>
            </InterceptorProvider>
          </ApiContext.Provider>
        </RecaptchaConfigProvider>
      </ErrorBoundary>
    </IntlProvider>
  );
};

Application.propTypes = propTypes;

export default connect(
  (state) => ({
    userLanguage: state.auth.userLanguage,
  }),
  null
)(Application);
