import api from 'api';
import { setToastMessage } from './toastMessages';
import { toastContext, toastType } from 'helpers/toastConstants';

// Actions
export const SET_LOADING = 'documentPreview.SET_LOADING';
export const SET_SIGNING_DOCUMENT = 'documentPreview.SET_SIGNING_DOCUMENT';
export const STORE_DOCUMENT_PREVIEW = 'documentPreview.STORE_DOCUMENT_PREVIEW';
export const STORE_DOCUMENT_FILES = 'documentPreview.STORE_DOCUMENT_FILES';
export const SET_NOT_FOUND_ERROR = 'documentPreview.SET_NOT_FOUND_ERROR';

// Reducer
const initialState = {
  isLoading: true,
  document: null,
  isSigningDocument: false,
  notFoundError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case STORE_DOCUMENT_PREVIEW:
      return {
        ...state,
        document: action.payload,
      };
    case STORE_DOCUMENT_FILES:
      return {
        ...state,
        document: {
          ...state.document,
          document_files: action.payload,
        },
      };
    case SET_SIGNING_DOCUMENT:
      return {
        ...state,
        isSigningDocument: action.payload,
      };
    case SET_NOT_FOUND_ERROR:
      return {
        ...state,
        notFoundError: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload });

export const setSigningDocument = (payload) => ({ type: SET_SIGNING_DOCUMENT, payload });

export const storeDocumentPreview = (payload) => ({
  type: STORE_DOCUMENT_PREVIEW,
  payload,
});

export const storeDocumentFiles = (payload) => ({
  type: STORE_DOCUMENT_FILES,
  payload,
});

export const setNotFoundError = (payload) => ({ type: SET_NOT_FOUND_ERROR, payload });

export const getFileURL = (fileId, documentId) => {
  try {
    return api.me.getDocumentFileUrl(fileId, documentId);
  } catch (error) {
    //@todo handle error
  }
};

export const addURLToFiles = async (files, docId) => {
  return Promise.all(
    files.map(async (file) => {
      const url = await getFileURL(file.id, docId);
      return { ...file, url };
    })
  );
};

// Side Effects
export const getDocumentPreview = (documentId) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await api.me.getDocumentPreview(documentId);
    dispatch(storeDocumentPreview(response.data));

    const { document_files } = response.data;
    const filesWithFetchedURL = await addURLToFiles(document_files, documentId);
    dispatch(storeDocumentFiles(filesWithFetchedURL));
  } catch (error) {
    if (error.response.status === 404) {
      dispatch(setNotFoundError(error));
      setToastMessage({
        content: { translationKey: 'documentNotFound' },
        // @todo: update context along with the routing for user's document-previews list
        context: toastContext.DOCUMENTS,
        type: toastType.ALERT,
      })(dispatch);
    }
    // @todo - update error handling for other errors
  } finally {
    dispatch(setLoading(false));
  }
};

export const signDocument = () => async (dispatch, getState) => {
  const {
    documentPreview: { document },
  } = getState();

  dispatch(setSigningDocument(true));
  try {
    await api.me.signDocument(document.id);

    dispatch(setLoading(true));

    const response = await api.me.getDocumentPreview(document.id);
    dispatch(storeDocumentPreview(response.data));
  } catch (error) {
    // @todo: update error handling
  } finally {
    dispatch(setSigningDocument(false));
    dispatch(setLoading(false));
  }
};
