import styled, { css } from 'styled-components';
import { rem } from 'polished';

import { colorName, colors, font, transitions, variables } from 'styles';

const INPUT_HEIGHT = 40;
const ICON_SIZE = 20;
const PADDING_HORIZONTAL = 14;

export const InputWrapper = styled.div`
  margin: 0 0 ${({ marginBottom }) => rem(marginBottom)};
`;

export const InputContentWrapper = styled.div`
  position: relative;
  cursor: pointer;

  &:hover svg {
    color: ${({ isDark }) => (isDark ? colors.darkYellow : colors.mediumGray)};
  }
`;

export const inputStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${rem(INPUT_HEIGHT)};
  padding: ${rem(10)} ${rem(PADDING_HORIZONTAL)};
  overflow: hidden;
  color: ${({ isDark }) => (isDark ? colorName.white : colors.darkestGray)};
  font-size: ${rem(font.size.small)};
  background: ${({ isDark }) => (isDark ? colors.mako : 'transparent')};
  border: 1px solid ${colors.gray};
  border-radius: ${rem(variables.borderRadius)};
  transition: border ${transitions.uiTiming} ${transitions.spring};

  ${({ icon }) =>
    icon &&
    `
      padding: ${rem(10)} ${rem(PADDING_HORIZONTAL + ICON_SIZE)} ${rem(10)} ${rem(
      PADDING_HORIZONTAL
    )}
    `};

  ${({ isSearchInput }) =>
    isSearchInput &&
    `
      padding: ${rem(10)}  ${rem(PADDING_HORIZONTAL)} ${rem(10)}  ${rem(
      PADDING_HORIZONTAL + ICON_SIZE
    )}
    `};

  ${({ wasTouched, hasError }) =>
    wasTouched &&
    `
      border: 1px solid ${hasError ? colorName.error : colors.green};
    `};

  ::placeholder {
    color: ${colors.mediumGray};
  }

  &:focus,
  &:hover,
  &:invalid {
    outline: 0;
    box-shadow: none;
  }

  &:hover {
    border: 1px solid ${({ isDark }) => (isDark ? colors.darkYellow : colors.mediumGray)};
    cursor: pointer;
  }

  &:focus {
    border: 2px solid ${({ isDark }) => (isDark ? colors.darkYellow : colors.navy)};
    caret-color: ${({ isDark }) => (isDark ? colors.darkYellow : colors.navy)};
    cursor: pointer;

    & + div svg {
      color: ${({ isDark }) => (isDark ? colors.darkYellow : colors.navy)};
    }
  }

  &[disabled] {
    user-select: none;
    pointer-events: none;
  }
`;

export const StyledInput = styled.input`
  ${inputStyles};
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${({ isSearchInput }) => !isSearchInput && rem(PADDING_HORIZONTAL)};
  left: ${({ isSearchInput }) => isSearchInput && rem(10)};
  width: ${rem(ICON_SIZE)};
  height: ${rem(ICON_SIZE)};
  color: ${({ isDark }) => (isDark ? colorName.white : colors.mediumGray)};
  transform: translateY(-50%);
`;

export const RequiredMark = styled.span`
  color: ${({ isDark }) => (isDark ? colors.darkRed : colors.darkestRed)};
`;
