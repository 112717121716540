// Actions
export const STORE_TOAST_MESSAGE = 'toastMessages.STORE_TOAST_MESSAGE';
export const STORE_TOAST_CONTEXT = 'toastMessages.STORE_TOAST_CONTEXT';
export const STORE_TOAST_TYPE = 'toastMessages.STORE_TOAST_TYPE';
export const DISMISS_TOAST_MESSAGE = 'toastMessages.DISMISS_TOAST_MESSAGE';

// Reducer
const initialState = {
  context: '',
  content: {},
  type: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_TOAST_MESSAGE:
      return {
        ...state,
        content: action.payload,
      };
    case STORE_TOAST_CONTEXT:
      return {
        ...state,
        context: action.payload,
      };
    case STORE_TOAST_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case DISMISS_TOAST_MESSAGE:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function storeToastMessage(content) {
  return { type: STORE_TOAST_MESSAGE, payload: content };
}

export function storeToastContext(context) {
  return { type: STORE_TOAST_CONTEXT, payload: context };
}

export function storeToastType(type) {
  return { type: STORE_TOAST_TYPE, payload: type };
}

export function hideToastMessage() {
  return { type: DISMISS_TOAST_MESSAGE };
}

// Side Effects
export function setToastMessage({ context, content, type }) {
  return (dispatch) => {
    dispatch(storeToastType(type));
    dispatch(storeToastMessage(content));
    dispatch(storeToastContext(context));
    // Always scroll to top page when displaying toast
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
}

export function dismissToastMessage() {
  return (dispatch) => {
    dispatch(hideToastMessage());
  };
}
