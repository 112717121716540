import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Typography from 'components/Typography';
import { StyledLabel } from 'styles/commonStyles';
import { colorName } from 'styles';
import { RequiredMark, StyledTextarea, TextareaWrapper } from './style';
import { globalMessages } from 'translations';
import Tooltip from 'components/Tooltip';

const defaultPropTypes = {
  isLarge: false,
  marginBottom: 16,
  minLength: 1,
};

const propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  isLarge: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  marginBottom: PropTypes.number,
  minLength: PropTypes.number,
  height: PropTypes.string,
  isResizable: PropTypes.bool,
  value: PropTypes.string,
  tooltip: PropTypes.string,
};

const Textarea = ({
  label,
  id,
  placeholder,
  isLarge,
  onChange,
  required,
  marginBottom,
  minLength,
  value,
  tooltip,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const [hasError, setHasError] = useState(false);
  const [wasTouched, setWasTouched] = useState(false);

  const handleChange = (e) => {
    const valueLength = e.target.value.length;
    const isMinLengthError = valueLength < minLength;
    const isNotEmptyValue = valueLength > 0;
    let isError = false;

    if (required) {
      isError = isMinLengthError;
    } else {
      isError = isNotEmptyValue && isMinLengthError;
    }
    setHasError(isError);

    onChange && onChange(e);
  };

  const handleBlur = () => {
    if (!wasTouched) {
      setWasTouched(true);
      required &&
        setHasError(!value || (value && value.length < minLength) ? true : false);
    }
  };

  return (
    <TextareaWrapper marginBottom={marginBottom}>
      {label && (
        <StyledLabel htmlFor={id}>
          {label}
          {required && <RequiredMark>*</RequiredMark>}
          {tooltip && <Tooltip id={id} content={tooltip} />}
        </StyledLabel>
      )}

      <StyledTextarea
        id={id}
        isLarge={isLarge}
        placeholder={placeholder}
        onBlur={handleBlur}
        onChange={handleChange}
        hasError={hasError}
        wasTouched={wasTouched}
        required={required}
        minLength={minLength}
        value={value}
        {...props}
      />

      {hasError && (
        <Typography size="tiny" color={colorName.error}>
          {formatMessage(globalMessages.minLengthHelperText, { minLength })}
        </Typography>
      )}
    </TextareaWrapper>
  );
};

Textarea.defaultProps = defaultPropTypes;
Textarea.propTypes = propTypes;

export default Textarea;
