import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  resolveDashboardRoute,
  resolveDocumentsRoute,
  resolveMisconductsRoute,
  ROUTING,
} from 'routing';

import MainMenuItem from './MainMenuItem';
import OfficerLogin from './OfficerLogin';
import DashboardIcon from 'components/Svg/DashboardIcon';
import DocumentsIcon from 'components/Svg/DocumentsIcon';
// import TrainingsIcon from 'components/Svg/TrainingsIcon';
import ReportIcon from 'components/Svg/ReportIcon';
import UsersIcon from 'components/Svg/UsersIcon';
import GroupIcon from 'components/Svg/GroupIcon';

import { MainMenu, BurgerWrapper, BurgerMenu, MainMenuWrapper } from '../style';
import { messages } from '../messages';
import { useCustomConfig } from 'helpers/hooks';
import SearchIcon from 'components/Svg/SearchIcon';
import RaisedEmployeeLogin from './ExtendedEmployeeLogin';

const SideMenu = () => {
  const intl = useIntl();
  const { hasOfficerRights, hasExtendedEmployeeRights, hasOwnedMisconducts, isOfficer } =
    useSelector((state) => state.auth);
  const [isMenuOpen, triggerMenu] = useState(false);
  const dashboardRoute = resolveDashboardRoute(hasOfficerRights);
  const documentsRoute = resolveDocumentsRoute(hasOfficerRights);
  const misconductsRoute = resolveMisconductsRoute(hasOfficerRights);
  const { changeWhistleWithLoupe } = useCustomConfig();

  return (
    <MainMenu isOpen={isMenuOpen}>
      <BurgerWrapper onClick={() => triggerMenu((prevState) => !prevState)}>
        <BurgerMenu isOpen={isMenuOpen} />
      </BurgerWrapper>

      <MainMenuWrapper isOpen={isMenuOpen}>
        <MainMenuItem
          to={dashboardRoute}
          label={intl.formatMessage(messages.dashboard)}
          icon={<DashboardIcon />}
        />

        <MainMenuItem
          to={documentsRoute}
          label={intl.formatMessage(messages.documents)}
          icon={<DocumentsIcon />}
        />

        {hasOwnedMisconducts && hasExtendedEmployeeRights && !hasOfficerRights && (
          <MainMenuItem
            to={misconductsRoute}
            label={intl.formatMessage(messages.reports)}
            icon={!changeWhistleWithLoupe ? <ReportIcon /> : <SearchIcon />}
          />
        )}

        {/*<MainMenuItem*/}
        {/*  to={ROUTING.TRAININGS}*/}
        {/*  label={intl.formatMessage(messages.trainings)}*/}
        {/*  icon={<TrainingsIcon />}*/}
        {/*/>*/}

        {hasOfficerRights && (
          <>
            <MainMenuItem
              to={misconductsRoute}
              label={intl.formatMessage(messages.reports)}
              icon={!changeWhistleWithLoupe ? <ReportIcon /> : <SearchIcon />}
            />

            <MainMenuItem
              to={ROUTING.USER_MANAGEMENT}
              label={intl.formatMessage(messages.users)}
              icon={<UsersIcon />}
            />

            <MainMenuItem
              to={ROUTING.GROUPS}
              label={intl.formatMessage(messages.groups)}
              icon={<GroupIcon />}
            />
          </>
        )}
        {isOfficer ? (
          <OfficerLogin isExpanded={isMenuOpen} />
        ) : (
          <RaisedEmployeeLogin isExpanded={isMenuOpen} />
        )}
      </MainMenuWrapper>
    </MainMenu>
  );
};

export default SideMenu;
