import api from 'api';

// Actions
export const SET_MISCONDUCT_DATA = 'checkMisconductStatus.SET_MISCONDUCT_DATA';
export const SET_LOADING = 'checkMisconductStatus.SET_LOADING';
export const SET_ARCHIVE_STATUS = 'checkMisconductStatus.SET_ARCHIVE_STATUS';
export const SET_ERROR = 'checkMisconductStatus.SET_ERROR';
export const CLEAR_STATE = 'checkMisconductStatus.CLEAR_STATE';

// Reducer
const initialState = {
  isLoading: false,
  archived: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_MISCONDUCT_DATA:
      return {
        ...state,
        misconductData: action.payload,
      };
    case SET_ARCHIVE_STATUS:
      return {
        ...state,
        archived: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setMisconductData(data) {
  return { type: SET_MISCONDUCT_DATA, payload: data };
}

export function setArchived(bool) {
  return { type: SET_ARCHIVE_STATUS, payload: bool };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

export function setError(error) {
  return { type: SET_ERROR, payload: error };
}

export function clearState() {
  return { type: CLEAR_STATE };
}

// Side Effects
export function checkStatus(reportToken, executeRecaptcha) {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const recaptchaToken = await executeRecaptcha();

      const response = await api.misconduct.getReportStatus({
        reportToken,
        recaptchaToken,
      });
      dispatch(setMisconductData({ ...response.data, reportToken }));
      dispatch(setArchived(response.data.archived));
    } catch (error) {
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };
}
