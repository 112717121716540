import React, { useEffect, useState } from 'react';
import { DayPickerRangeController } from 'react-dates';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

const START_DATE_INPUT_ID = 'startDate';

const propTypes = {
  dateControllerConfig: PropTypes.object.isRequired,
  selectedDate: PropTypes.shape({
    startDate: momentPropTypes.momentObj,
    endDate: momentPropTypes.momentObj,
  }),
  onChange: PropTypes.func.isRequired,
};

const DatePicker = ({ dateControllerConfig, selectedDate, onChange, ...props }) => {
  const [focusedInput, setFocusedInput] = useState(START_DATE_INPUT_ID);
  const dateObj = selectedDate || {};

  useEffect(() => {
    !selectedDate && setFocusedInput(START_DATE_INPUT_ID);
  }, [selectedDate]);

  const onFocusChange = (focusedInput) => {
    setFocusedInput(!focusedInput ? START_DATE_INPUT_ID : focusedInput);
  };

  return (
    <DayPickerRangeController
      startDate={dateObj.startDate && dateObj.startDate}
      endDate={dateObj.endDate ? dateObj.endDate : dateObj.startDate}
      onDatesChange={onChange}
      focusedInput={focusedInput}
      onFocusChange={onFocusChange}
      {...dateControllerConfig}
      {...props}
    />
  );
};

DatePicker.propTypes = propTypes;

export default DatePicker;
