import styled from 'styled-components';
import { rem } from 'polished';
import { colorName, font, breakpoints } from 'styles';

import OfficerShield from 'assets/images/officer-shield.svg';

export const avatarSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const avatarWidthHeight = {
  [avatarSize.SMALL]: 32,
  [avatarSize.MEDIUM]: 40,
  [avatarSize.LARGE]: 48,
};

const avatarFontSize = {
  [avatarSize.SMALL]: font.size.medium,
  [avatarSize.MEDIUM]: font.size.medium,
  [avatarSize.LARGE]: font.size.big,
};

const avatarFontWeight = {
  [avatarSize.SMALL]: font.weight.regular,
  [avatarSize.MEDIUM]: font.weight.regular,
  [avatarSize.LARGE]: font.weight.regular,
};

export const StyledUserAvatar = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => rem(avatarWidthHeight[size])};
  height: ${({ size }) => rem(avatarWidthHeight[size])};
  color: ${({ officerStyle }) => (officerStyle ? colorName.black : colorName.white)};
  font-weight: ${({ size }) => avatarFontWeight[size]};
  font-size: ${({ size }) => rem(avatarFontSize[size])};
  background: ${({ officerStyle, hue }) =>
    officerStyle ? `url(${OfficerShield}) no-repeat center` : `hsl(${hue}, 70%, 40%)`};
  border-radius: ${({ officerStyle }) => (officerStyle ? '0' : '50%')};

  ${({ size }) =>
    size === avatarSize.SMALL
      ? `
          @media ${breakpoints.mediumScreen} {
            width: ${rem(avatarWidthHeight[avatarSize.MEDIUM])};
            height: ${rem(avatarWidthHeight[avatarSize.MEDIUM])};
          }
        `
      : ''}
`;
