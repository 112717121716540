export const TYPES = {
  GROUP: 'group',
};

export const MISCONDUCT_RIGHTS = {
  READ_ONLY: 'read_report',
  VIEW_WHISTLEBLOWER_FILES: 'view_whistleblower_files',
  READ_CHAT_MESSAGES: 'read_chat_messages',
  WRITE_CHAT_MESSAGES: 'write_chat_messages',
  READ_LOG: 'read_log',
  MANAGE_REMINDERS: 'manage_reminders',
  VIEW_REPORT_FOLDER: 'view_report_folder',
  MANAGE_REPORT_FOLDER: 'manage_report_folder',
  EDIT_NOTES: 'edit_notes',
  READ_NOTES: 'read_notes',
  VIEW_WHISTLEBLOWER_DETAILS: 'view_whistleblower_details',
  WRITE_INTERNAL_CHAT_MESSAGES: 'write_internal_chat_messages',
  READ_INTERNAL_CHAT_MESSAGES: 'read_internal_chat_messages',
  ANONYMIZE: 'anonymize',
  ARCHIVE: 'archive',
  FULL_ACCESS: 'full_access',
};
