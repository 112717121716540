import { rem } from 'polished';
import styled from 'styled-components';

import { breakpoints, colors } from 'styles';

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(24)};
  padding: ${rem(64)};
  color: ${colors.white};
  background-color: ${colors.darkestGray};

  @media ${breakpoints.mediumScreen} {
    flex-direction: row;
    gap: ${rem(96)};
  }
`;

export const LeftContent = styled.div`
  @media ${breakpoints.mediumScreen} {
    max-width: 50%;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};

  @media ${breakpoints.mediumScreen} {
    flex-direction: row;
    gap: ${rem(64)};
  }
`;

export const StyledLink = styled.a`
  width: 100%;
  color: inherit;
  text-decoration-color: ${colors.gray};
  cursor: pointer;

  & p {
    margin: 0;
  }

  &:hover {
    text-decoration-color: ${colors.darkGray};
  }

  &:hover p {
    color: ${colors.darkGray};
  }
`;

export const PoweredLogotype = styled.img`
  width: auto;
  height: ${rem(56)};
  margin: 0 auto 2vh;
`;
