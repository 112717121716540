import styled from 'styled-components';
import { rem } from 'polished';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${({ verticalSpacing }) => verticalSpacing && rem(verticalSpacing)} auto;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  margin: ${rem(28)} auto 0;
`;
