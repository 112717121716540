import styled from 'styled-components';
import { rem } from 'polished';

import { breakpoints } from 'styles';

import CompanyLogo from 'components/CompanyLogo';

const LOGOTYPE_HEIGHT = 84;

export const Logo = styled(CompanyLogo)`
  display: block;
  height: ${rem(LOGOTYPE_HEIGHT)};
  margin: 0 auto 4vh;

  ${({ poweredBy }) =>
    poweredBy &&
    `
    margin-bottom: 0;
  `};
`;

export const LogoWrapper = styled.div`
  & > img {
    display: block;
    width: 100%;
    max-width: 12rem;
    margin: 0 auto;
  }
`;

export const Introduction = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto 6vh;

  @media ${breakpoints.mediumScreen} {
    margin: 0 auto;
  }
`;
