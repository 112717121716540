import React from 'react';
import PropTypes from 'prop-types';

import UserIcon from 'components/Svg/UserIcon';
import GroupIcon from 'components/Svg/GroupIcon';
import CoIcon from 'components/Svg/CoIcon';

import {
  IconWrapper,
  StyledOption,
  StyledOptionLabel,
} from 'components/AutocompleteSelect/style';

const propTypes = {
  innerRef: PropTypes.func,
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  selectProps: PropTypes.shape({
    isDark: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  }),
  data: PropTypes.shape({
    type: PropTypes.string,
  }),
};

const icons = {
  user: UserIcon,
  officer: CoIcon,
  group: GroupIcon,
  employee: UserIcon,
};

export const Option = ({
  innerProps,
  innerRef,
  children,
  isFocused,
  selectProps: { isDark, icon },
  data: { type },
}) => {
  const Icon = icons[type];

  return (
    <StyledOption
      ref={innerRef}
      isDark={isDark}
      icon={icon}
      isFocused={isFocused}
      {...innerProps}
    >
      {icon && <IconWrapper>{Icon && <Icon />}</IconWrapper>}

      <StyledOptionLabel>{children}</StyledOptionLabel>
    </StyledOption>
  );
};

Option.propTypes = propTypes;
