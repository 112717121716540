import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import {
  getMisconductRoomDataByToken,
  createMessageForToken,
  createFileMessageForToken,
} from 'stores/chatrooms';
import api from 'api';

import { ChatMessages, ChatMessageInput } from 'components/Chat';
import Typography from 'components/Typography';

import { colorName } from 'styles';
import { reportMessages } from 'translations';

const propTypes = {
  reportToken: PropTypes.string.isRequired,
  areUploadsAllowed: PropTypes.bool.isRequired,
};

export const MisconductChat = ({ reportToken, areUploadsAllowed }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rooms, isFileProcessing } = useSelector((state) => state.chatrooms);

  const messages = rooms[reportToken];

  const sendMessage = useCallback(
    (message) => dispatch(createMessageForToken({ token: reportToken, text: message })),
    [dispatch, reportToken]
  );

  const uploadFile = useCallback(
    (file) => dispatch(createFileMessageForToken({ token: reportToken, file })),
    [dispatch, reportToken]
  );

  const getFileUrl = useCallback(
    (id) => api.misconduct.getChatFileAsAnonymous({ token: reportToken, id }),
    [reportToken]
  );

  const refreshMessages = useCallback(
    () => dispatch(getMisconductRoomDataByToken(reportToken)),
    [dispatch, reportToken]
  );

  return (
    <>
      <Typography size="large" color={colorName.white} bottomMargin={12}>
        {intl.formatMessage(reportMessages.communication)}
      </Typography>

      <ChatMessages
        isDark
        messages={messages}
        fileUrlResolver={getFileUrl}
        refreshMessages={refreshMessages}
        outgoingMessageTypeString="outcoming"
        whistleblowerPerspective
      />

      <ChatMessageInput
        isDark
        sendHandler={sendMessage}
        uploadHandler={uploadFile}
        areUploadsAllowed={areUploadsAllowed}
        uploadingInProgress={isFileProcessing}
      />
    </>
  );
};

MisconductChat.propTypes = propTypes;

export default MisconductChat;
