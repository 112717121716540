import React from 'react';

const LargeGroupIcon = (props) => {
  return (
    <svg fill="none" viewBox="0 0 64 64" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M25.893 0a1 1 0 00-1 1v12.214a1 1 0 001 1h12.214a1 1 0 001-1V1a1 1 0 00-1-1H25.893zm1 12.214V2h10.214v10.214H26.893zM22.335 20.5a1 1 0 01.343 1.372L11.373 40.713h6.377a1 1 0 011 1V58a1 1 0 01-1 1H1.464a1 1 0 01-1-1V41.714a1 1 0 011-1H9.04l11.923-19.871a1 1 0 011.372-.343zm19.328 0a1 1 0 011.372.343L54.96 40.714h7.577a1 1 0 011 1V58a1 1 0 01-1 1H46.25a1 1 0 01-1-1V41.714a1 1 0 011-1h6.376L41.32 21.872a1 1 0 01.343-1.372zM2.463 57V42.714H16.75V57H2.464zM47.25 42.714V57h14.286V42.714H47.25z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default LargeGroupIcon;
