import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
} from 'helpers/sharedConstants';
import { deserializeLogs, serializePermissions } from 'deserializers';
import { toastContext, toastType } from 'helpers/toastConstants';
import { composeUserFullName } from 'helpers/composeUserFullName';
import { parseError } from 'helpers/parseError';
import { storeMisconductsRighsArray } from './reportDetails';
import { setToastMessage } from './toastMessages';
import { defaultRule } from './helpers/mocks';

// Actions
export const STORE_USER_DETAILS = 'user.STORE_USER_DETAILS';
export const STORE_USER_LOGS = 'user.STORE_USER_LOGS';
export const STORE_USER_LOGS_META = 'user.STORE_USER_LOGS_META';
export const STORE_RULES_TEMPLATE = 'user.STORE_RULES_TEMPLATE';
export const ADD_NEW_RULE = 'user.ADD_NEW_RULE';
export const SET_EXISTING_RULES = 'user.SET_EXISTING_RULES';
export const SET_RULES_PERMISSIONS = 'user.SET_RULES_PERMISSIONS';
export const SET_RULE_QUESTON = 'user.SET_RULE_QUESTON';
export const SET_RULE_CHOICE = 'user.SET_RULE_CHOICE';
export const DELETE_RULE = 'user.DELETE_RULE';
export const SET_LOADING = 'user.SET_LOADING';
export const SET_LOGS_LOADING = 'user.SET_LOGS_LOADING';
export const SET_RULES_LOADING = 'user.SET_RULES_LOADING';
export const SET_RULES_PROCESSING = 'user.SET_RULES_PROCESSING';

// Reducer
const initialState = {
  user: null,
  rulesTemplate: [],
  rulesData: [],
  rulesPermissions: [],
  userLogs: [],
  userLogsMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
  },
  isUserDetailsLoading: true,
  isUserLogLoading: true,
  isRulesLoading: true,
  isRulesProcessing: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isUserDetailsLoading: action.payload,
      };
    case SET_LOGS_LOADING:
      return {
        ...state,
        isUserLogLoading: action.payload,
      };
    case SET_RULES_LOADING:
      return {
        ...state,
        isRulesLoading: action.payload,
      };
    case SET_RULES_PROCESSING:
      return {
        ...state,
        isRulesProcessing: action.payload,
      };
    case SET_EXISTING_RULES:
      return {
        ...state,
        rulesData: action.payload,
      };
    case SET_RULES_PERMISSIONS:
      return {
        ...state,
        rulesPermissions: action.payload,
      };
    case STORE_RULES_TEMPLATE:
      return {
        ...state,
        rulesTemplate: action.payload,
      };
    case ADD_NEW_RULE:
      return {
        ...state,
        rulesData: [...state.rulesData, defaultRule],
      };
    case DELETE_RULE: {
      const { idx } = action.payload;
      return {
        ...state,
        rulesData: [...state.rulesData.slice(0, idx), ...state.rulesData.slice(idx + 1)],
      };
    }
    case SET_RULE_QUESTON: {
      const { idx, id } = action.payload;
      return {
        ...state,
        rulesData: [
          ...state.rulesData.map((rule, index) =>
            index === idx ? { ...rule, question_id: id } : rule
          ),
        ],
      };
    }
    case SET_RULE_CHOICE: {
      const { idx, id } = action.payload;
      return {
        ...state,
        rulesData: [
          ...state.rulesData.map((rule, index) =>
            index === idx ? { ...rule, choice_id: id } : rule
          ),
        ],
      };
    }
    case STORE_USER_DETAILS:
      return {
        ...state,
        user: action.payload,
      };
    case STORE_USER_LOGS:
      return {
        ...state,
        userLogs: action.payload,
      };
    case STORE_USER_LOGS_META:
      return {
        ...state,
        userLogsMeta: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload });
export const setLogsLoading = (payload) => ({ type: SET_LOGS_LOADING, payload });
export const setRulesLoading = (payload) => ({ type: SET_RULES_LOADING, payload });
export const setRulesProcessing = (payload) => ({ type: SET_RULES_PROCESSING, payload });
export const storeRulesTemplate = (payload) => ({ type: STORE_RULES_TEMPLATE, payload });
export const storeUserDetails = (payload) => ({ type: STORE_USER_DETAILS, payload });
export const storeUserLogs = (payload) => ({ type: STORE_USER_LOGS, payload });
export const storeUserLogsMeta = (payload) => ({ type: STORE_USER_LOGS_META, payload });
export const addNewRule = (payload) => ({ type: ADD_NEW_RULE, payload });
export const setExistingRules = (payload) => ({ type: SET_EXISTING_RULES, payload });
export const setRulesPermissions = (payload) => ({
  type: SET_RULES_PERMISSIONS,
  payload,
});

export const setRuleQuestion = ({ idx, id }) => ({
  type: SET_RULE_QUESTON,
  payload: { idx, id },
});

export const setRuleChoice = ({ idx, id }) => ({
  type: SET_RULE_CHOICE,
  payload: { idx, id },
});

export const deleteRule = ({ idx }) => ({
  type: DELETE_RULE,
  payload: { idx },
});

// Side Effects
export const getUserDetails = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await api.users.getUser(id);
    dispatch(storeUserDetails(response.data));
  } catch (error) {
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const getUserLogs =
  ({ id, page = PAGINATE_DEFAULT_PAGE, perPage = PAGINATE_DEFAULT_PER_PAGE }) =>
  async (dispatch) => {
    dispatch(setLogsLoading(true));
    dispatch(storeUserLogs([]));
    dispatch(storeUserLogsMeta({ page, perPage, totalPages: 0 }));

    try {
      const res = await api.users.getLogs({ id, page, perPage });
      const deserializedLogs = deserializeLogs(res.data);
      dispatch(storeUserLogs(deserializedLogs.items));
      dispatch(storeUserLogsMeta({ page, perPage, totalPages: deserializedLogs.pages }));
    } catch (error) {
      // @todo - update error handling
    } finally {
      dispatch(setLogsLoading(false));
    }
  };

export const editUser = (user) => async (dispatch, getState) => {
  const {
    userDetails: {
      user: { first_name, last_name, id },
    },
  } = getState();

  dispatch(setLoading(true));
  try {
    const response = await api.users.editUser({ id, ...user });
    dispatch(storeUserDetails(response.data));
    setToastMessage({
      content: {
        translationKey: 'userEditedSuccess',
        param: `${first_name} ${last_name}`,
      },
      context: toastContext.USER_DETAILS,
      type: toastType.SUCCESS,
    })(dispatch);
  } catch (error) {
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const resetUserPassword = (email) => async (dispatch, getState) => {
  const {
    userDetails: {
      user: { first_name, last_name },
    },
  } = getState();

  try {
    await api.auth.forgotPassword(email);
    setToastMessage({
      content: {
        translationKey: 'userPasswordSent',
        param: `${first_name} ${last_name}`,
      },
      context: toastContext.USER_DETAILS,
      type: toastType.SUCCESS,
    })(dispatch);
  } catch (error) {
    setToastMessage({
      content: { translationKey: 'userPasswordNotSent' },
      context: toastContext.USER_DETAILS,
      type: toastType.ALERT,
    })(dispatch);
  }
};

export const deactivateUser = (id) => async (dispatch, getState) => {
  dispatch(setLoading(true));
  const {
    userDetails: { user },
  } = getState();
  try {
    const response = await api.users.deactivateUser(id);
    dispatch(storeUserDetails(response.data));
    setToastMessage({
      content: {
        translationKey: 'userDeactivated',
        param: composeUserFullName(response.data),
      },
      context: toastContext.USER_DETAILS,
      type: toastType.SUCCESS,
    })(dispatch);
  } catch (error) {
    setToastMessage({
      content: parseError(error, user),
      context: toastContext.USER_DETAILS,
      type: toastType.ALERT,
    })(dispatch);
  } finally {
    dispatch(setLoading(false));
  }
};

export const activateUser = (id) => async (dispatch) => {
  dispatch(setLoading(true));

  try {
    const response = await api.users.activateUser(id);
    dispatch(storeUserDetails(response.data));
    setToastMessage({
      content: {
        translationKey: 'userActivated',
        param: composeUserFullName(response.data),
      },
      context: toastContext.USER_DETAILS,
      type: toastType.SUCCESS,
    })(dispatch);
  } catch (error) {
    setToastMessage({
      content: { translationKey: 'userNotActivated' },
      context: toastContext.USER_DETAILS,
      type: toastType.ALERT,
    })(dispatch);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getCurrentTemplateAndRules = (id) => async (dispatch) => {
  dispatch(setRulesLoading(true));

  try {
    const template = await api.config.getRulesTemplate();
    const currentUserRules = await api.users.getUserRules(id);
    const accessRights = await api.misconduct.getRightsKeys();
    const rightsArray = serializePermissions(accessRights.data);
    const serializedPermissions = serializePermissions(currentUserRules.data.permissions);
    const filteredRulesData = currentUserRules.data.rules.filter((rule) =>
      template.data.questions.find(
        (ruleTemplateItem) => ruleTemplateItem.id === rule.question_id
      )
    );

    dispatch(storeMisconductsRighsArray(rightsArray));
    dispatch(storeRulesTemplate(template.data.questions));
    dispatch(setExistingRules(filteredRulesData));
    dispatch(setRulesPermissions(serializedPermissions));
  } catch (error) {
    //@todo: Error handling
  } finally {
    dispatch(setRulesLoading(false));
  }
};

export const saveUserRules =
  ({ id, rules, permissions, applyRules }) =>
  async (dispatch) => {
    dispatch(setRulesProcessing(true));

    try {
      const serializedPermissions = permissions.map((permission) => permission.value);
      const response = await api.users.saveUserRules({
        id,
        rules,
        permissions: serializedPermissions,
        applyRules,
      });
      setToastMessage({
        content: { translationKey: 'userRulesSaved' },
        context: toastContext.USER_DETAILS,
        type: toastType.SUCCESS,
      })(dispatch);
      return response;
    } catch (error) {
      setToastMessage({
        content: { translationKey: 'userRulesNotSaved' },
        context: toastContext.USER_DETAILS,
        type: toastType.ALERT,
      })(dispatch);
    } finally {
      dispatch(setRulesProcessing(false));
    }
  };
