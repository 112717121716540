import styled from 'styled-components';
import { rem } from 'polished';
import { colorName, font } from 'styles';

export const StyledBadge = styled.span`
  height: ${rem(16)};
  margin: 0 ${rem(8)} 0 0;
  padding: 0 ${rem(4)};
  color: ${colorName.white};
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.tiny)};
  line-height: ${rem(16)};
  text-transform: uppercase;
  background-color: ${colorName.secondary};
  border-radius: ${rem(2)};
`;
