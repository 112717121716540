import React from 'react';

const PeopleIcon = (props) => {
  return (
    <svg viewBox="0 0 64 64" fill="none" {...props}>
      <path
        stroke="#000"
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M37.625 20.875a8.458 8.458 0 1116.917 0c0 4.671-3.787 10.875-8.459 10.875-4.67 0-8.458-6.204-8.458-10.875z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M34.503 40.803a3.557 3.557 0 011.97-1.577c2.067-.67 5.36-1.434 9.61-1.434 4.25 0 7.544.764 9.612 1.434 1.483.48 2.472 1.879 2.472 3.438v6.002h-14.5"
      />
      <path
        stroke="#000"
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M11.042 19.667a9.667 9.667 0 1119.333 0c0 5.338-4.328 12.083-9.666 12.083-5.339 0-9.667-6.745-9.667-12.083zM5 44.652a4.843 4.843 0 013.235-4.562c2.908-1.035 7.48-2.299 12.474-2.299 4.992 0 9.565 1.264 12.473 2.299a4.844 4.844 0 013.235 4.562V53.5H5v-8.848z"
      />
    </svg>
  );
};

export default PeopleIcon;
