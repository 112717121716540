import React from 'react';
import PropTypes from 'prop-types';

import { StyledMultiValueLabel } from 'components/AutocompleteSelect/style';

const propTypes = {
  children: PropTypes.node,
};

export const MultiValueLabel = ({ children, ...props }) => {
  return <StyledMultiValueLabel {...props}>{children}</StyledMultiValueLabel>;
};

MultiValueLabel.propTypes = propTypes;
