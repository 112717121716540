import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import Typography from 'components/Typography';
import Modal, { StandardModalContent } from 'components/Modal';
import RoundIconWrap from 'components/RoundIconWrap';
import LargeGroupIcon from 'components/Svg/LargeGroupIcon';
import Button from 'components/Button';

import { colors } from 'styles';
import { ButtonWrapper } from './styles';
import { globalMessages } from 'translations';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  icon: PropTypes.func,
  heading: PropTypes.node.isRequired,
  description: PropTypes.node,
  isNarrowContent: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  icon: LargeGroupIcon,
};

export const SimpleModal = ({
  isOpen,
  closeHandler,
  icon: Icon,
  heading,
  description,
  children,
  isNarrowContent,
  ...props
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeHandler}
    isFullWidth
    isNarrowContent={isNarrowContent}
    {...props}
  >
    <StandardModalContent isNarrowContent={isNarrowContent}>
      <RoundIconWrap icon={<Icon />} />

      <Typography size="bigger" tabletSize="huge" bottomMargin={16}>
        {heading}
      </Typography>

      {description && (
        <Typography
          size="small"
          tabletSize="medium"
          bottomMargin={32}
          color={colors.darkGray}
        >
          {description}
        </Typography>
      )}

      <ButtonWrapper>{children}</ButtonWrapper>
    </StandardModalContent>
  </Modal>
);

SimpleModal.propTypes = propTypes;
SimpleModal.defaultProps = defaultProps;

const buttonPropTypes = {
  onClick: PropTypes.func,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
};

const defaultButtonProps = {
  maxWidth: 180,
};

export const PrimaryButton = ({ onClick, children, ...props }) => (
  <Button onClick={onClick} primary isFluid {...props}>
    {children}
  </Button>
);

PrimaryButton.propTypes = buttonPropTypes;
PrimaryButton.defaultProps = defaultButtonProps;

export const SecondaryButton = ({ onClick, children, ...props }) => (
  <Button onClick={onClick} secondary isFluid {...props}>
    {children}
  </Button>
);

SecondaryButton.propTypes = buttonPropTypes;
SecondaryButton.defaultProps = defaultButtonProps;

export const CancelButton = (props) => (
  <SecondaryButton {...props}>
    <FormattedMessage {...globalMessages.cancel} />
  </SecondaryButton>
);
