import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createRoot } from 'react-dom/client';

import configureStore from 'store';
import { suppressUnwantedWarnings } from 'helpers/suppressUnwantedWarnings';

import Application from 'containers';

import { GlobalStyle } from 'styles';

const { store, persistor } = configureStore();
const container = document.getElementById('root');
const root = createRoot(container);

suppressUnwantedWarnings();

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GlobalStyle />

      <Application />
    </PersistGate>
  </Provider>
);
