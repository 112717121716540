import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { object } from 'prop-types';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import { ApiContext } from 'api';
import { ROUTING } from 'routing';
import { logOutUser } from 'stores/auth';
import { clearStorageAndCookies } from 'helpers';

import EntryWrapper from 'components/EntryWrapper';
import Button from 'components/Button';
import Typography from 'components/Typography';
import LoginInput from 'components/LoginInput';
import LoginHeader from 'components/LoginHeader';
import CheckmarkIcon from 'components/Svg/CheckmarkIcon';
import CrossIcon from 'components/Svg/CrossIcon';
import { AlertNotification } from 'components/Notification';

import { LoginMiddleSectionForm } from 'styles/commonStyles';
import { colors } from 'styles';
import { globalMessages } from 'translations';
import { messages } from '../Login/messages';
import { validatePassword } from './validatePassword';
import {
  PasswordRequirement,
  PasswordRequirementsHeading,
  PasswordRequirementsContainer,
} from './style';

const setPasswordBaseRoute = ROUTING.SET_PASSWORD.split(':')[0];

const propTypes = {
  intl: object,
};

const SetPassword = ({ intl }) => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const { pathname } = useLocation();
  const { api } = useContext(ApiContext);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isFlowFinished, setFlowFinished] = useState(false);
  const [error, setError] = useState('');
  const minLength = new URLSearchParams(useLocation().search).get('min_length') || 10;
  const passwordRequirements = validatePassword(password, minLength);
  const passwordValid = passwordRequirements.every(({ fulfilled }) => fulfilled);
  const isPasswordMatching = password === passwordConfirmation;
  const isButtonEnabled = passwordValid && isPasswordMatching;
  const isFirstPasswordReset = pathname.includes(setPasswordBaseRoute);

  useEffect(() => {
    clearStorageAndCookies();
    dispatch(logOutUser());
  }, [dispatch]);

  const handlePasswordResetCall = useCallback(
    async (token, password) => {
      isFirstPasswordReset
        ? await api.auth.acceptInvitation({ token, password })
        : await api.auth.resetPassword({ token, password });
    },
    [api.auth, isFirstPasswordReset]
  );

  const submitPassword = useCallback(
    async (event) => {
      event.preventDefault();
      setLoading(true);

      try {
        await handlePasswordResetCall(token, password);
        setFlowFinished(true);
      } catch (error) {
        setError(error.response?.data?.error);
      } finally {
        setLoading(false);
      }
    },
    [handlePasswordResetCall, token, password]
  );

  return (
    <EntryWrapper>
      <Helmet>
        <meta name="google" content="notranslate" />
      </Helmet>

      <LoginHeader isPasswordSetView />

      {isFlowFinished ? (
        <LoginMiddleSectionForm>
          <Typography
            size="small"
            bottomMargin={24}
            textAlign="center"
            weight="bold"
            color={colors.darkGray}
            maxWidth={220}
          >
            {intl.formatMessage(messages.passwordSet)}
          </Typography>

          <Button component={Link} to={ROUTING.INDEX} primary isFluid>
            {intl.formatMessage(messages.goToLogin)}
          </Button>
        </LoginMiddleSectionForm>
      ) : (
        <LoginMiddleSectionForm onSubmit={submitPassword} autocomplete="off">
          {error && <AlertNotification> {error} </AlertNotification>}

          <LoginInput
            type="password"
            autocomplete="new-password"
            label={intl.formatMessage(globalMessages.newPassword)}
            placeholder={intl.formatMessage(globalMessages.newPassword)}
            onChange={(e) => setPassword(e.target.value)}
            invalid={!passwordValid}
          />

          <LoginInput
            type="password"
            autocomplete="new-password"
            label={intl.formatMessage(globalMessages.confirmPassword)}
            placeholder={intl.formatMessage(globalMessages.confirmPassword)}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            invalid={!isPasswordMatching}
          />

          <PasswordRequirementsContainer>
            <PasswordRequirementsHeading>
              {intl.formatMessage(messages.passwordRequirementsHeading)}
            </PasswordRequirementsHeading>

            {passwordRequirements.map(({ key, param, fulfilled }) => (
              <PasswordRequirement key={key} fulfilled={fulfilled}>
                {fulfilled ? (
                  <CheckmarkIcon
                    aria-label={intl.formatMessage(messages.passwordRequirementFulfilled)}
                  />
                ) : (
                  <CrossIcon
                    aria-label={intl.formatMessage(
                      messages.passwordRequirementNotFulfilled
                    )}
                  />
                )}
                <span>{intl.formatMessage(messages[key], { param })}</span>
              </PasswordRequirement>
            ))}
          </PasswordRequirementsContainer>

          <Button
            primary
            isFluid
            disabled={!isButtonEnabled || isLoading}
            isProcessing={isLoading}
            type="submit"
          >
            {intl.formatMessage(globalMessages.accept)}
          </Button>
        </LoginMiddleSectionForm>
      )}
    </EntryWrapper>
  );
};

SetPassword.propTypes = propTypes;

export default injectIntl(SetPassword);
