import React from 'react';
import PropTypes from 'prop-types';

import { StyledMenuList } from 'components/AutocompleteSelect/style';

const propTypes = {
  children: PropTypes.node.isRequired,
  innerRef: PropTypes.func.isRequired,
  selectProps: PropTypes.shape({
    isDark: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    maxMenuHeight: PropTypes.number,
  }),
};

export const MenuList = ({
  children,
  innerRef,
  selectProps: { isDark, icon, maxMenuHeight },
}) => {
  return (
    <StyledMenuList
      ref={innerRef}
      isDark={isDark}
      icon={icon}
      maxMenuHeight={maxMenuHeight && maxMenuHeight}
    >
      {children}
    </StyledMenuList>
  );
};

MenuList.propTypes = propTypes;
