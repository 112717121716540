import api from 'api';
import {
  clearFormStructure,
  setFormId,
  setHasNextStep,
  setHasPreviousStep,
  storeFormStep,
  setStepsCount,
  setCurrentStep,
} from './misconductForm';
import { storeCustomSidebarText } from './anonymousMisconductForm';
import { deserializeReportData } from 'deserializers';

// Actions
export const SET_LOADING = 'officerMisconductForm.SET_LOADING';
export const SET_ERROR = 'officerMisconductForm.SET_ERROR';
export const SET_INTAKE_METHOD = 'officerMisconductForm.SET_INTAKE_METHOD';
export const SET_TOKEN_EMAIL = 'officerMisconductForm.SET_TOKEN_EMAIL';
export const CLEAR_STATE = 'officerMisconductForm.CLEAR_STATE';

// Reducer
const initialState = {
  isLoading: false,
  intakeMethod: '',
  tokenEmail: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_INTAKE_METHOD:
      return {
        ...state,
        intakeMethod: action.payload,
      };
    case SET_TOKEN_EMAIL:
      return {
        ...state,
        tokenEmail: action.payload,
      };
    case CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
}

// Action Creators

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

export function setError(error) {
  return { type: SET_ERROR, payload: error };
}

export function setIntakeMethod(value) {
  return { type: SET_INTAKE_METHOD, payload: value };
}

export function setTokenEmail(value) {
  return { type: SET_TOKEN_EMAIL, payload: value };
}

export function clearIntakeMethod() {
  return { type: CLEAR_STATE };
}

// Side Effects
export function initializeOfficerForm() {
  return async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const langCode = getState().auth.userLanguage;

      // const recaptchaToken = await executeRecaptcha();

      const surveyIdResponse = await api.misconduct.initializeSurvey(
        langCode
        // recaptchaToken
      );
      const surveyId = surveyIdResponse.data.response_id;
      const formStepResponse = await api.misconduct.getSurveyStep(langCode, surveyId);

      const customInstructions = await api.misconduct.getCustomInstructions(langCode);
      const serializedData = deserializeReportData(formStepResponse.data.response);

      dispatch(setHasNextStep(serializedData.has_next_step));
      dispatch(setHasPreviousStep(serializedData.has_previous_step));
      dispatch(setFormId(surveyId));
      dispatch(storeFormStep(serializedData.current_step));
      dispatch(setStepsCount(serializedData.steps_count));
      dispatch(setCurrentStep(serializedData.step_number));
      // @todo check if it's necessary
      dispatch(storeCustomSidebarText(customInstructions.data.report_instructions));
    } catch (error) {
      dispatch(setError(error.message));
      // @todo: What should we display if something went wrong?
    } finally {
      dispatch(setLoading(false));
    }
  };
}

export function updateIntakeMethod(value) {
  return (dispatch) => {
    dispatch(setIntakeMethod(value));
  };
}

export function clearOfficerForm() {
  return (dispatch) => {
    dispatch(clearIntakeMethod());
    dispatch(clearFormStructure());
  };
}
