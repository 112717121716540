import styled from 'styled-components';
import { rem } from 'polished';

import { globalSize, breakpoints, colors } from 'styles';

const CONTAINER_PADDING = globalSize.defaultPadding;
const LOGIN_CONTAINER_WIDTH = 360;

export const LoginTemplate = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  padding: ${rem(CONTAINER_PADDING)};
  overflow: hidden;
  background: ${colors.white};

  @media ${breakpoints.smallScreen} {
    display: flex;
    padding: 0;
  }
`;

export const PictureWrapper = styled.div`
  position: relative;
  display: block;
  flex: 0 0 55%;
  max-width: 55%;
  ${({ imageUrl }) => imageUrl && `background: url(${imageUrl})`};
  background-position: center;
  background-size: cover;

  @media ${breakpoints.mediumScreen} {
    flex: 0 0 62%;
    max-width: 62%;
  }
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: ${rem(LOGIN_CONTAINER_WIDTH)};
  min-height: calc(100vh - ${rem(CONTAINER_PADDING * 2)});
  margin: 0 auto;

  @media ${breakpoints.smallScreen} {
    flex: 0 0 45%;
    max-width: 45%;
    padding: ${rem(CONTAINER_PADDING * 3)};
  }

  @media ${breakpoints.mediumScreen} {
    flex: 0 0 38%;
    max-width: 38%;
  }
`;

export const TextWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: none;
  width: 100%;
  padding: ${rem(96)};
  padding-top: ${rem(200)};
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

  @media ${breakpoints.smallScreen} {
    display: block;
  }
`;
