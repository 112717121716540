import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { usePrevious } from 'helpers/hooks';
import { isDeeplyEqual } from 'helpers';

import Message from './parts/Message';
import EmptyState from './parts/EmptyState';

import { ChatWrapper, MessageBox } from './style';
import { colors } from 'styles';

const propTypes = {
  messages: PropTypes.array,
  isDark: PropTypes.bool,
  backgroundColor: PropTypes.string,
  roomId: PropTypes.string,
  fileUrlResolver: PropTypes.func.isRequired,
  refreshMessages: PropTypes.func.isRequired,
  pauseRefresh: PropTypes.bool,
  outgoingMessageTypeString: PropTypes.string,
  whistleblowerPerspective: PropTypes.bool,
};

const defaultProps = {
  outgoingMessageTypeString: 'outgoing',
};

const REFRESH_PERIOD_MS = 30000;

export const ChatMessages = ({
  messages,
  isDark,
  backgroundColor,
  fileUrlResolver,
  refreshMessages,
  roomId,
  pauseRefresh = false,
  outgoingMessageTypeString,
  whistleblowerPerspective = false,
}) => {
  const conversationStarted = !!messages?.length;
  const messageBoxRef = useRef(null);
  const previousMessages = usePrevious(messages);

  useEffect(() => {
    if (!isDeeplyEqual(messages, previousMessages)) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
    }
  }, [messageBoxRef, previousMessages, messages]);

  useEffect(() => {
    refreshMessages();
  }, [refreshMessages]);

  useEffect(() => {
    const autoRefresh = setInterval(refreshMessages, REFRESH_PERIOD_MS);

    if (pauseRefresh) {
      clearInterval(autoRefresh);
    }
    return () => clearInterval(autoRefresh);
  }, [refreshMessages, pauseRefresh]);

  return (
    <ChatWrapper
      backgroundColor={backgroundColor || (isDark ? colors.mako : colors.white)}
    >
      <MessageBox ref={messageBoxRef}>
        {conversationStarted ? (
          messages.map((message) => (
            <Message
              roomId={roomId}
              key={message.id}
              message={message}
              isOutcoming={message.type === outgoingMessageTypeString}
              fileUrlResolver={fileUrlResolver}
              isDark={isDark}
              whistleblowerPerspective={whistleblowerPerspective}
            />
          ))
        ) : (
          <EmptyState />
        )}
      </MessageBox>
    </ChatWrapper>
  );
};

ChatMessages.propTypes = propTypes;
ChatMessages.defaultProps = defaultProps;
