import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { ROUTING } from '../routing';

import RoundIconWrap from 'components/RoundIconWrap';
import NotOnMobileIcon from 'components/Svg/NotOnMobileIcon';
import Typography from 'components/Typography';
import Button from 'components/Button';

import { colors } from 'styles';
import { NotFoundWrapper, NotFoundLogo, NotFoundButtons } from 'styles/commonStyles';
import { globalMessages } from 'translations';

const NotFound = () => {
  const history = useHistory();
  const intl = useIntl();

  // @todo: Check for CO and display secondary button differently based on auth
  return (
    <NotFoundWrapper>
      <NotFoundLogo />
      <RoundIconWrap icon={<NotOnMobileIcon />} />
      <Typography textAlign="center" size="medium" tabletSize="large" bottomMargin={16}>
        {intl.formatMessage(globalMessages.notFoundHeader)}
      </Typography>

      <Typography
        textAlign="center"
        size="small"
        tabletSize="medium"
        color={colors.darkGray}
        bottomMargin={32}
      >
        {intl.formatMessage(globalMessages.notFoundMessage)}
      </Typography>

      <NotFoundButtons>
        <Button isFluid primary onClick={() => history.goBack()}>
          {intl.formatMessage(globalMessages.goBack)}
        </Button>

        <Button secondary isFluid component={Link} to={ROUTING.DASHBOARD_EMPLOYEE}>
          {intl.formatMessage(globalMessages.returnToDashboard)}
        </Button>
      </NotFoundButtons>
    </NotFoundWrapper>
  );
};

export default NotFound;
