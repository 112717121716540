import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
  SORT_DIRECTION_DESCENDING,
} from 'helpers/sharedConstants';

const DEFAULT_SORT_PARAMS = { key: 'date_created', direction: SORT_DIRECTION_DESCENDING };
const DEFAULT_ARCHIVED_SORT_PARAMS = {
  key: 'date_created',
  direction: SORT_DIRECTION_DESCENDING,
};

// Actions
export const STORE_REPORTS_LIST = 'reports.STORE_REPORTS_LIST';
export const STORE_REPORTS_COLUMNS = 'reports.STORE_REPORTS_COLUMNS';
export const STORE_REPORTS_LIST_META = 'reports.STORE_REPORTS_LIST_META';
export const STORE_REPORTS_FILTERS = 'reports.STORE_REPORTS_FILTERS';
export const SET_REPORTS_LOADING = 'reports.SET_REPORTS_LOADING';
export const SET_SEARCH = 'reports.SET_SEARCH';
export const SET_FILTERS = 'reports.SET_FILTERS';

export const STORE_ARCHIVED_REPORTS_LIST = 'reports.STORE_ARCHIVED_REPORTS_LIST';
export const STORE_ARCHIVED_REPORTS_COLUMNS = 'reports.STORE_ARCHIVED_REPORTS_COLUMNS';
export const STORE_ARCHIVED_REPORTS_LIST_META =
  'reports.STORE_ARCHIVED_REPORTS_LIST_META';

// Reducer
const initialState = {
  reportColumns: [],
  reportList: [],
  reportListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
    sortParams: DEFAULT_SORT_PARAMS,
  },
  reportsFilters: [],
  archivedReportColumns: [],
  archivedReportList: [],
  archivedReportListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
    sortParams: DEFAULT_ARCHIVED_SORT_PARAMS,
  },
  isLoading: true,
  filters: {},
  search: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_REPORTS_LIST:
      return {
        ...state,
        reportList: action.payload,
      };
    case STORE_REPORTS_COLUMNS:
      return {
        ...state,
        reportColumns: action.payload,
      };
    case STORE_ARCHIVED_REPORTS_COLUMNS:
      return {
        ...state,
        archivedReportColumns: action.payload,
      };
    case STORE_REPORTS_LIST_META:
      return {
        ...state,
        reportListMeta: action.payload,
      };
    case STORE_REPORTS_FILTERS:
      return {
        ...state,
        reportsFilters: action.payload,
      };
    case STORE_ARCHIVED_REPORTS_LIST:
      return {
        ...state,
        archivedReportList: action.payload,
      };
    case STORE_ARCHIVED_REPORTS_LIST_META:
      return {
        ...state,
        archivedReportListMeta: action.payload,
      };
    case SET_REPORTS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function storeReports(data) {
  return { type: STORE_REPORTS_LIST, payload: data };
}

export function storeReportColumns(data) {
  return { type: STORE_REPORTS_COLUMNS, payload: data };
}

export function storeArchivedReportColumns(data) {
  return { type: STORE_ARCHIVED_REPORTS_COLUMNS, payload: data };
}

export function storeReportsMeta(data) {
  return { type: STORE_REPORTS_LIST_META, payload: data };
}

export function storeReportsFilters(data) {
  return { type: STORE_REPORTS_FILTERS, payload: data };
}

export function storeArchivedReports(data) {
  return { type: STORE_ARCHIVED_REPORTS_LIST, payload: data };
}

export function storeArchivedReportsMeta(data) {
  return { type: STORE_ARCHIVED_REPORTS_LIST_META, payload: data };
}

export function setReportsLoading(data) {
  return { type: SET_REPORTS_LOADING, payload: data };
}

export function setSearch(data) {
  return { type: SET_SEARCH, payload: data };
}

export function setFilters(data) {
  return { type: SET_FILTERS, payload: data };
}

// Side Effects
export function getReports({
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
  sortParams = DEFAULT_SORT_PARAMS,
  query,
  filters,
}) {
  return (dispatch) => {
    dispatch(setReportsLoading(true));
    return api.misconduct
      .getReportsList({
        page,
        per_page: perPage,
        sort_by: sortParams?.key,
        sort_order: sortParams?.direction,
        query,
        filters,
      })
      .then((res) => {
        dispatch(storeReports(res.data.items));
        dispatch(storeReportColumns(res.data.columns));
        dispatch(
          storeReportsMeta({
            page: res.data.current_page,
            perPage,
            totalPages: res.data.pages,
            sortParams,
          })
        );
        dispatch(storeReportsFilters(res.data.filters));
        dispatch(setSearch(query));
        dispatch(setFilters(filters));
        dispatch(setReportsLoading(false));
      })
      .catch(() => {});
  };
}

export function getArchivedReports({
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
  sortParams = DEFAULT_ARCHIVED_SORT_PARAMS,
  query,
  filters,
}) {
  return (dispatch) => {
    dispatch(setReportsLoading(true));
    return api.misconduct
      .getArchivedReportsList({
        page,
        perPage,
        sortBy: sortParams?.key,
        sortOrder: sortParams?.direction,
        query,
        filters,
      })
      .then((res) => {
        dispatch(storeArchivedReports(res.data.items));
        dispatch(storeArchivedReportColumns(res.data.columns));
        dispatch(
          storeArchivedReportsMeta({
            page: res.data.current_page,
            perPage,
            totalPages: res.data.pages,
            sortParams,
          })
        );
        dispatch(storeReportsFilters(res.data.filters));
        dispatch(setSearch(query));
        dispatch(setFilters(filters));
        dispatch(setReportsLoading(false));
      })
      .catch(() => {});
  };
}
