import React from 'react';

const OfficerShieldIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.403 16.458C4.032 19.055 6.553 21 10 21s5.969-1.952 7.597-4.552C19.217 13.861 20 10.573 20 7.688v-4.22a.66.66 0 00-.607-.653h-.003l-.013-.001-.05-.005a29.018 29.018 0 01-.95-.113 33.51 33.51 0 01-2.475-.431c-1.96-.42-4.161-1.1-5.49-2.125a.675.675 0 00-.879.047c-.486.47-1.261.888-2.213 1.245-.94.352-1.997.625-2.999.832a34.704 34.704 0 01-3.636.544l-.056.005-.013.001H.613A.661.661 0 000 3.47v4.219c0 2.897.782 6.185 2.403 8.77zM1.333 4.062l.55-.065c.704-.09 1.67-.233 2.712-.448 1.04-.215 2.171-.505 3.2-.89.818-.308 1.61-.69 2.242-1.168 1.578 1.034 3.781 1.67 5.582 2.056a34.854 34.854 0 003.048.51v3.63c0 2.684-.734 5.724-2.205 8.072C15 18.096 12.854 19.688 10 19.688c-2.854 0-5-1.586-6.462-3.918-1.47-2.345-2.205-5.385-2.205-8.082V4.063z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default OfficerShieldIcon;
