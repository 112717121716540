import { defineMessages } from 'react-intl.macro';

export const messages = defineMessages({
  clearDate: {
    id: 'Datepicker.emptyState',
    defaultMessage: 'Clear date',
    description: 'Clear date button label',
  },
  apply: {
    id: 'Datepicker.apply',
    defaultMessage: 'Apply',
    description: 'Apply button label',
  },
});
