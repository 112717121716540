import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { ROUTING } from 'routing';
import AuthRoute from 'components/AuthRoute';

export const EmployeeRoute = (props) => {
  const { isCo } = useSelector((state) => state.auth);
  const isEmployee = !isCo;

  return isEmployee ? <AuthRoute {...props} /> : <Redirect to={ROUTING.INDEX} />;
};

export default EmployeeRoute;
