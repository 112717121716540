import React from 'react';

const DocumentSearchIcon = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_282_1703)">
      <path
        d="M20.8335 28.3816C20.8335 30.3836 21.6288 32.3035 23.0444 33.7191C24.4599 35.1347 26.3799 35.93 28.3818 35.93C30.3838 35.93 32.3037 35.1347 33.7193 33.7191C35.1349 32.3035 35.9302 30.3836 35.9302 28.3816C35.9302 26.3797 35.1349 24.4598 33.7193 23.0442C32.3037 21.6286 30.3838 20.8333 28.3818 20.8333C26.3799 20.8333 24.4599 21.6286 23.0444 23.0442C21.6288 24.4598 20.8335 26.3797 20.8335 28.3816Z"
        stroke="#2E2E38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.1667 39.1667L33.7534 33.7534"
        stroke="#2E2E38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1668 39.1666H2.50016C2.05814 39.1666 1.63421 38.9911 1.32165 38.6785C1.00909 38.3659 0.833496 37.942 0.833496 37.5V2.49998C0.833496 2.05795 1.00909 1.63403 1.32165 1.32147C1.63421 1.00891 2.05814 0.833313 2.50016 0.833313H24.6552C25.0972 0.833407 25.521 1.00906 25.8335 1.32165L32.0118 7.49998C32.3244 7.81247 32.5001 8.23632 32.5002 8.67831V15.8333"
        stroke="#2E2E38"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_282_1703">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DocumentSearchIcon;
