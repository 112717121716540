import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import EntryWrapper from 'components/EntryWrapper';
import Button from 'components/Button';
import LoginInput from 'components/LoginInput';
import LoginHeader from 'components/LoginHeader';
import { AlertNotification } from 'components/Notification';

import { LoginMiddleSectionForm } from 'styles/commonStyles';

import { Wrapper } from '../style';
import { messages } from '../messages';
import { Link } from 'react-router-dom';
import { ROUTING } from 'routing';
import { globalMessages } from 'translations';
import LandingHeader from 'containers/public/Auth/LandingPage/LandingHeader';

const propTypes = {
  tokenSubmitted: PropTypes.func,
  statusCheckError: PropTypes.string,
};

const TokenForm = ({ statusCheckError, tokenSubmitted }) => {
  const intl = useIntl();
  const { isLoading } = useSelector((state) => state.checkMisconductStatus);

  const [token, setToken] = useState('');

  const updateToken = useCallback((evt) => setToken(evt.target.value), [setToken]);

  const submitForm = useCallback(
    (evt) => {
      evt.preventDefault();
      tokenSubmitted(token);
    },
    [token, tokenSubmitted]
  );

  return (
    <EntryWrapper>
      <LoginHeader isOnStatusCheck />

      <LoginMiddleSectionForm onSubmit={submitForm} autocomplete="off">
        <LandingHeader descriptionText={intl.formatMessage(messages.typeInToken)} />

        <Wrapper>
          {statusCheckError && (
            <AlertNotification>
              {intl.formatMessage(messages.enterTokenAgain)}
            </AlertNotification>
          )}

          {/* input wrapped in form to achieve working disabled autoComplete */}
          <form autoComplete="off">
            <LoginInput
              type="text"
              autocomplete="off"
              label={intl.formatMessage(messages.reportToken)}
              placeholder={intl.formatMessage(messages.reportToken)}
              onChange={updateToken}
            />
          </form>

          <Button primary isFluid type="submit" isProcessing={isLoading}>
            {intl.formatMessage(messages.checkTheStatus)}
          </Button>
          <Button
            component={Link}
            to={ROUTING.INDEX}
            secondary
            isFluid
            data-testid="check-report-button"
          >
            {intl.formatMessage(globalMessages.back)}
          </Button>
        </Wrapper>
      </LoginMiddleSectionForm>
    </EntryWrapper>
  );
};

TokenForm.propTypes = propTypes;

export default TokenForm;
