import styled from 'styled-components';
import { rem } from 'polished';

import { SkeletonWrapper } from 'components/Skeletons/style';
import { colors, globalSize, variables, font, breakpoints, transitions } from 'styles';

const EXCERPT_LENGTH = 280;

export const TableWrapper = styled.table`
  display: block;
  width: 100%;
  margin: 0 0 ${rem(24)};
  padding: 0;
  border-collapse: separate;
  border-spacing: 0;

  @media ${breakpoints.mediumScreen} {
    display: table;
    border-spacing: 0 ${rem(8)};
  }
`;

export const TableExpandIcon = styled.div`
  margin: 0 0 0 auto;

  & > svg {
    display: block;
    width: ${rem(12)};
    height: ${rem(6)};
    margin: ${rem(2)} 0 0 0;
    color: ${colors.mediumGray};
    transition: transform ${transitions.uiTiming} ${transitions.spring};
  }

  @media ${breakpoints.mediumScreen} {
    display: none;
  }
`;

export const Column = styled.td`
  display: none;
  font-weight: ${font.weight.regular};
  font-size: ${rem(font.size.small)};
  transition: color ${transitions.uiTiming} ${transitions.spring};

  &:first-child {
    display: flex;
    align-items: center;
    min-height: ${rem(24)};

    & > svg {
      display: block;
      width: ${rem(21)};
      height: ${rem(21)};
      margin: 0 ${rem(8)} 0 0;
      color: ${colors.mediumGray};

      @media ${breakpoints.mediumScreen} {
        float: left;
      }
    }

    & > a {
      display: inline-block;
      flex: 0 0 auto;
      margin: 0 ${rem(12)} 0 0;
      vertical-align: middle;

      @media ${breakpoints.mediumScreen} {
        flex: 0 0 ${rem(EXCERPT_LENGTH)};
        max-width: ${rem(EXCERPT_LENGTH)};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    @media ${breakpoints.mediumScreen} {
      display: table-cell;
      height: auto;
    }
  }

  ${({ name }) =>
    name &&
    `
    align-items: center;
    padding: ${rem(2)} 0;
    
    &:before {
      flex: 0 0 40%;
      margin: 0 ${rem(4)} 0 0;
      padding: 0 0 0 ${rem(24)};
      content: "${name}";
      line-height: 1;
      text-transform: uppercase;
      font-weight: ${font.weight.bold};
      font-size: ${rem(font.size.tiny)};
      color: ${colors.darkGray};
      
      @media ${breakpoints.mediumScreen} {
        display: none;
      }
    }
  `}

  @media ${breakpoints.mediumScreen} {
    display: table-cell;
    text-align: ${({ align }) => align && align};
  }
`;

export const Row = styled.tr`
  display: block;
  width: 100%;
  margin: 0 0 ${rem(8)};
  padding: ${rem(globalSize.defaultPadding / 2)} ${rem(globalSize.defaultPadding)};
  border-radius: ${rem(variables.borderRadius)};

  ${({ isSkeleton }) =>
    isSkeleton &&
    `
    padding: 0;
    margin: 0;
    background: transparent;
    
    ${Column} {
      &:first-child {
        height: auto;
        
        ${SkeletonWrapper} {
          width: 100%;
        }
        
        @media ${breakpoints.mediumScreen} {
          display: table-cell;
        }
      }
    }
  `}

  ${({ isExpanded }) =>
    isExpanded &&
    `
    ${TableExpandIcon} {
      & > svg {
        transform: rotateX(180deg);
      }
    }
    
    ${Column} {
      &:first-child {
        margin: 0 0 ${rem(4)};
      }
      
      &:not(:first-child) {
        display: flex;
      }
    }
  `}
  
  ${({ isTableHeader }) =>
    isTableHeader &&
    `
    display: none;
    
    @media ${breakpoints.mediumScreen} {
      display: table-row;
      font-size: ${rem(font.size.tiny)};
      font-weight: ${font.weight.regular};
      text-transform: uppercase;
      color: ${colors.darkGray};
      background: transparent;
    }
  `}
  
  ${({ isInactive }) =>
    isInactive &&
    `
    ${Column} {      
      &:not(:last-child) {
        color: ${colors.gray};
        
         & > * {
          color: ${colors.gray};
         }
         
         & > div > span {
          background: ${colors.lighterGray};
          color: ${colors.gray};
         }
      }
    }
  `}
  
  ${({ isHighlighted }) =>
    isHighlighted &&
    `
    background: ${colors.lightGray} !important;
    border: 1px solid ${colors.grayish};
  `}
  
  @media ${breakpoints.mediumScreen} {
    display: table-row;

    ${({ isHighlighted }) =>
      isHighlighted &&
      `
        ${Column} {
          background: ${colors.lightGray} !important;
          border: 1px solid ${colors.grayish};
        }
      `}
  }
`;

export const HeadColumn = styled.th`
  padding: 0 0 ${rem(12)};
  text-align: ${({ align }) => align || 'left'};

  @media ${breakpoints.mediumScreen} {
    padding: 0 ${rem(8)} ${rem(12)};
  }
`;
