import styled from 'styled-components';
import { rem } from 'polished';

import { breakpoints, colors } from 'styles';
import { TypographyElement } from 'components/Typography/style';

export const Wrapper = styled.div`
  margin: 0 0 ${rem(12)};

  & > button {
    margin: ${rem(12)} 0 0;
  }
`;

export const DetailsGroup = styled.div`
  margin: 0 0 ${rem(16)} 0;
  padding: 0 0 ${rem(16)} 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grayish};
  }

  @media ${breakpoints.smallScreen} {
    margin: 0 0 ${rem(16)} 0;

    & > ${TypographyElement} {
      margin: 0 0 ${rem(16)} 0;
    }
  }
`;

export const DetailItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 ${rem(16)} 0;

  & > p:first-child {
    flex: 0 0 100%;
    margin: 0 0 ${rem(6)} 0;

    @media ${breakpoints.tablet} {
      flex: 0 0 ${rem(150)};
      margin: 0 ${rem(50)} 0 0;
    }
  }

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

export const ReportStatusColumn = styled.div`
  max-width: 100%;
`;
