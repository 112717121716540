import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
} from 'helpers/sharedConstants';

// Actions
export const STORE_NOTIFICATIONS_LIST = 'notifications.STORE_NOTIFICATIONS_LIST';
export const STORE_NOTIFICATIONS_LIST_META =
  'notifications.STORE_NOTIFICATIONS_LIST_META';
export const SET_LOADING = 'notifications.SET_LOADING';

// Reducer
const initialState = {
  notificationList: [],
  isLoading: true,
  notificationListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_NOTIFICATIONS_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case STORE_NOTIFICATIONS_LIST_META:
      return {
        ...state,
        notificationListMeta: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function storeNotifications(data) {
  return { type: STORE_NOTIFICATIONS_LIST, payload: data };
}

export function storeNotificationsMeta(data) {
  return { type: STORE_NOTIFICATIONS_LIST_META, payload: data };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

// Side Effects
export function getNotifications({
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
}) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return api.notifications
      .getNotifications({ page, perPage })
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(storeNotifications(res.data.items));
        dispatch(storeNotificationsMeta({ page, perPage, totalPages: res.data.pages }));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
}
