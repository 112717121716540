import React from 'react';

function NotOnMobileIcon(props) {
  return (
    <svg viewBox="0 0 47 47" fill="none" {...props}>
      <path stroke="#000" strokeMiterlimit={10} strokeWidth={2} d="M6.886 8.3h27" />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M7 34h18m-4.614 7.6h-9.9a3.6 3.6 0 01-3.6-3.6V5.6a3.6 3.6 0 013.6-3.6h19.8a3.6 3.6 0 013.6 3.6v16.2"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth={2}
        d="M42 32.414L31.414 43m10.172 0L31 32.414"
      />
    </svg>
  );
}

export default NotOnMobileIcon;
