export const logTypes = {
  DOCUMENT_CREATED: 'document_created',
  DOCUMENT_UPDATED: 'document_updated',
  DOCUMENT_GROUP_ASSIGNED: 'document_group_assigned',
  DOCUMENT_GROUP_UPDATED: 'document_group_updated',
  DOCUMENT_GROUP_UNASSIGNED: 'document_group_unassigned',
  DOCUMENT_USER_UPDATED: 'document_user_updated',
  DOCUMENT_USER_ASSIGNED: 'document_user_assigned',
  DOCUMENT_USER_UNASSIGNED: 'document_user_unassigned',
  EMPLOYEE_SIGNATURE_REQUESTED: 'employee_signature_requested',
  EMPLOYEE_SIGNATURE_CANCELED: 'employee_signature_canceled',
  EMPLOYEE_SIGNED_DOCUMENT: 'employee_signed_document',
  MISCONDUCT_STATUS_CHECKED: 'misconduct_status_checked',
  MISCONDUCT_REPORT_CREATED: 'misconduct_report_created',
  MISCONDUCT_CHAT_UPLOAD_ALLOWED_CHANGED: 'misconduct_chat_upload_allowed_changed',
  MISCONDUCT_STATUS_CHANGED: 'misconduct_status_changed',
  MISCONDUCT_PRIORITY_CHANGED: 'misconduct_priority_changed',
  MISCONDUCT_ASSIGNEE_CHANGED: 'misconduct_assignee_changed',
  MISCONDUCT_FILE_ATTACHED: 'misconduct_file_attached',
  MISCONDUCT_FILE_REMOVED: 'misconduct_file_removed',
  MISCONDUCT_EMAIL_ASSIGNED: 'misconduct_email_assigned',
  MISCONDUCT_FULLNAME_ASSIGNED: 'misconduct_fullname_assigned',
  MISCONDUCT_PHONE_ASSIGNED: 'misconduct_phone_assigned',
  MISCONDUCT_SHARING_USER_ASSIGNED: 'misconduct_sharing_user_assigned',
  MISCONDUCT_SHARING_USER_UNASSIGNED: 'misconduct_sharing_user_unassigned',
  USER_CREATED: 'user_created',
  USER_EDITED: 'user_edited',
  USER_ARCHIVED: 'user_archived',
  USER_ACTIVATED: 'user_activated',
  USER_GROUP_REMOVED: 'user_group_removed',
  USER_GROUP_ADDED: 'user_group_added',
  USER_DOCUMENT_SIGNATURE_REQUESTED: 'user_document_signature_requested',
  USER_DOCUMENT_SIGNATURE_CANCELED: 'user_document_signature_canceled',
  USER_DOCUMENT_SIGNED: 'user_document_signed',
  USER_DOCUMENT_ADDED: 'user_document_added',
  USER_DOCUMENT_REMOVED: 'user_document_removed',
  MISCONDUCT_ARCHIVED: 'misconduct_archived',
  MISCONDUCT_UNARCHIVED: 'misconduct_unarchived',
  MISCONDUCT_ANONYMIZED: 'misconduct_anonymized',
  MISCONDUCT_DEANONYMIZED: 'misconduct_deanonymized',
  USER_REPORT_ACCESS_CHANGED: 'user_report_access_changed',
  USER_REPORT_ACCESS_REVOKED: 'user_report_access_revoked',
  USER_REPORT_ACCESS_BLOCKED: 'user_report_access_blocked',
  USER_REPORT_ACCESS_UNBLOCKED: 'user_report_access_unblocked',
  REPORT_INTERNAL_NOTE_EDITED: 'report_internal_note_edited',
  REPORT_USER_ACCESS_CHANGED: 'misconduct_user_permissions_changed',
  REPORT_USER_ACCESS_REVOKED: 'misconduct_user_permissions_revoked',
  REPORT_GROUP_ACCESS_CHANGED: 'misconduct_group_permissions_changed',
  REPORT_GROUP_ACCESS_REVOKED: 'misconduct_group_permissions_revoked',
};

export const dataTypeShapes = {
  [logTypes.DOCUMENT_CREATED]: {
    document_id: 'string',
    document_name: 'string',
  },
  [logTypes.DOCUMENT_GROUP_UPDATED]: {
    group_id: 'string',
    group_name: 'string',
  },
  [logTypes.DOCUMENT_GROUP_ASSIGNED]: {
    group_id: 'string',
    group_name: 'string',
  },
  [logTypes.DOCUMENT_GROUP_UNASSIGNED]: {
    group_id: 'string',
    group_name: 'string',
  },
  [logTypes.DOCUMENT_USER_UPDATED]: {
    user_first_name: 'string',
    user_last_name: 'string',
    user_id: 'string',
  },
  [logTypes.MISCONDUCT_SHARING_USER_ASSIGNED]: {
    first_name: 'string',
    last_name: 'string',
  },
  [logTypes.MISCONDUCT_SHARING_USER_UNASSIGNED]: {
    first_name: 'string',
    last_name: 'string',
  },
  [logTypes.DOCUMENT_USER_ASSIGNED]: {
    user_first_name: 'string',
    user_last_name: 'string',
    user_id: 'string',
  },
  [logTypes.DOCUMENT_USER_UNASSIGNED]: {
    user_first_name: 'string',
    user_last_name: 'string',
    user_id: 'string',
  },
  [logTypes.DOCUMENT_UPDATED]: {
    document_id: 'bool',
    changes: 'array',
  },
  [logTypes.EMPLOYEE_SIGNATURE_REQUESTED]: {
    user_id: 'string',
    user_first_name: 'string',
    user_last_name: 'string',
  },
  [logTypes.EMPLOYEE_SIGNATURE_CANCELED]: {
    user_id: 'string',
    user_first_name: 'string',
    user_last_name: 'string',
  },
  [logTypes.EMPLOYEE_SIGNED_DOCUMENT]: {
    user_id: 'string',
    user_first_name: 'string',
    user_last_name: 'string',
  },
  [logTypes.MISCONDUCT_ARCHIVED]: {
    user_id: 'string',
    user_first_name: 'string',
    user_last_name: 'string',
  },
  [logTypes.MISCONDUCT_ANONYMIZED]: {
    optional: 'string', // @todo: Handle empty data logs later
  },
  [logTypes.MISCONDUCT_DEANONYMIZED]: {
    optional: 'string',
  },
  [logTypes.MISCONDUCT_UNARCHIVED]: {
    user_id: 'string',
    user_first_name: 'string',
    user_last_name: 'string',
  },
  [logTypes.MISCONDUCT_STATUS_CHECKED]: {
    report_token: 'string',
  },
  [logTypes.MISCONDUCT_REPORT_CREATED]: {
    report_token: 'string',
    misconduct_title: 'string',
  },
  [logTypes.MISCONDUCT_CHAT_UPLOAD_ALLOWED_CHANGED]: {
    report_token: 'string',
    chat_file_upload_allowed: 'bool',
  },
  [logTypes.MISCONDUCT_ASSIGNEE_CHANGED]: {
    report_token: 'string',
    assigned_user_first_name: 'string',
    assigned_user_last_name: 'string',
    assigned_user_id: 'string',
  },
  [logTypes.MISCONDUCT_FILE_ATTACHED]: {
    attached_file_extension: 'string',
    attached_file_id: 'string',
    attached_file_name: 'string',
  },
  [logTypes.MISCONDUCT_FILE_REMOVED]: {
    attached_file_extension: 'string',
    attached_file_id: 'string',
    attached_file_name: 'string',
  },
  [logTypes.MISCONDUCT_STATUS_CHANGED]: {
    report_token: 'string',
    status: 'string',
  },
  [logTypes.MISCONDUCT_STATUS_CHANGED]: {
    report_token: 'string',
    priority: 'string',
  },
  [logTypes.MISCONDUCT_EMAIL_ASSIGNED]: {
    report_token: 'string',
    email: 'string',
  },
  [logTypes.MISCONDUCT_PHONE_ASSIGNED]: {
    report_token: 'string',
    phone: 'string',
  },
  [logTypes.MISCONDUCT_FULLNAME_ASSIGNED]: {
    report_token: 'string',
    fullname: 'string',
  },
  [logTypes.USER_CREATED]: {
    user_id: 'string',
    user_first_name: 'string',
    user_last_name: 'string',
  },
  [logTypes.USER_ARCHIVED]: {
    user_id: 'string',
  },
  [logTypes.USER_ACTIVATED]: {
    user_id: 'string',
  },
  [logTypes.USER_EDITED]: {
    user_id: 'string',
    changes: 'array',
  },
  [logTypes.USER_GROUP_ADDED]: {
    group_id: 'string',
    group_name: 'string',
  },
  [logTypes.USER_GROUP_REMOVED]: {
    group_id: 'string',
    group_name: 'string',
  },
  [logTypes.USER_DOCUMENT_SIGNATURE_REQUESTED]: {
    document_id: 'string',
    document_name: 'string',
  },
  [logTypes.USER_DOCUMENT_SIGNATURE_CANCELED]: {
    document_id: 'string',
    document_name: 'string',
  },
  [logTypes.USER_DOCUMENT_SIGNED]: {
    document_id: 'string',
    document_name: 'string',
  },
  [logTypes.USER_DOCUMENT_ADDED]: {
    document_id: 'string',
    document_name: 'string',
  },
  [logTypes.USER_DOCUMENT_REMOVED]: {
    document_id: 'string',
    document_name: 'string',
  },
  [logTypes.USER_REPORT_ACCESS_CHANGED]: {
    report_id: 'string',
    report_name: 'string',
    permissions: 'array',
  },
  [logTypes.USER_REPORT_ACCESS_REVOKED]: {
    report_id: 'string',
    report_name: 'string',
  },
  [logTypes.USER_REPORT_ACCESS_BLOCKED]: {
    report_id: 'string',
    report_name: 'string',
  },
  [logTypes.USER_REPORT_ACCESS_UNBLOCKED]: {
    report_id: 'string',
    report_name: 'string',
  },
  [logTypes.REPORT_INTERNAL_NOTE_EDITED]: {
    report_id: 'string',
    report_name: 'string',
  },
  [logTypes.REPORT_USER_ACCESS_CHANGED]: {
    first_name: 'string',
    last_name: 'string',
    user_id: 'string',
    permissions: 'array',
  },
  [logTypes.REPORT_USER_ACCESS_REVOKED]: {
    first_name: 'string',
    last_name: 'string',
    user_id: 'string',
  },
  [logTypes.REPORT_GROUP_ACCESS_CHANGED]: {
    group_name: 'string',
    group_id: 'string',
    permissions: 'array',
  },
  [logTypes.REPORT_USER_ACCESS_REVOKED]: {
    group_name: 'string',
    group_id: 'string',
  },
};
