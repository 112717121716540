import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTING } from 'routing';
import Button from 'components/Button';
import { globalMessages } from 'translations';

import SessionTimeoutLayout from './SessionTimeoutLayout';

const CoSessionTimeout = () => {
  const intl = useIntl();

  return (
    <SessionTimeoutLayout
      title={intl.formatMessage(globalMessages.coSessionExpiredHeader)}
      subtitle={intl.formatMessage(globalMessages.coSessionExpiredMessage)}
    >
      <Button primary component={Link} to={ROUTING.INDEX}>
        {intl.formatMessage(globalMessages.goToDashboard)}
      </Button>
    </SessionTimeoutLayout>
  );
};

export default CoSessionTimeout;
