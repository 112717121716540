import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from 'styles';

export const SkeletonBody = styled.tbody`
  display: block;

  @media ${breakpoints.mediumScreen} {
    display: table-row-group;
  }
`;

export const SkeletonWrapper = styled.div`
  margin: 0 0 ${rem(4)} 0;
`;
