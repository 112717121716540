import React from 'react';

import Typography from 'components/Typography';

import { colors } from 'styles';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from './messages';

const propTypes = {
  headerText: PropTypes.string,
  descriptionText: PropTypes.string,
};

export const LandingHeader = ({ headerText, descriptionText }) => {
  const intl = useIntl();
  return (
    <>
      <Typography size="huge" weight="light" data-testid="greeting">
        {headerText ? headerText : intl.formatMessage(messages.hello)}
      </Typography>
      <Typography size="small" color={colors.darkGray} bottomMargin={12}>
        {descriptionText
          ? descriptionText
          : intl.formatMessage(messages.loginReportingHeader)}
      </Typography>
    </>
  );
};

LandingHeader.propTypes = propTypes;

export default LandingHeader;
