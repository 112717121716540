import React from 'react';
import PropTypes from 'prop-types';

import FilesList from './parts/FilesList';
import Dropzone from './parts/Dropzone';

import { DropZoneWrapper } from './style';

const defaultPropTypes = {
  maxNumOfFiles: 1,
};

const propTypes = {
  dropzoneProps: PropTypes.object,
  filesProps: PropTypes.object,
  maxNumOfFiles: PropTypes.number,
  isChatUploader: PropTypes.bool,
  filesOnly: PropTypes.bool,
  allowDelete: PropTypes.bool,
};

const FileUploader = ({
  dropzoneProps,
  filesProps,
  maxNumOfFiles,
  isChatUploader,
  filesOnly,
  allowDelete,
}) => {
  const fileCount = filesProps?.files?.length;

  return (
    <>
      {fileCount > 0 && !isChatUploader && (
        <FilesList {...filesProps} allowDelete={allowDelete} />
      )}

      {!filesOnly && (
        <DropZoneWrapper
          isChatUploader={isChatUploader}
          isHidden={!isChatUploader && fileCount === maxNumOfFiles}
        >
          <Dropzone isChatUploader={isChatUploader} {...dropzoneProps} />
        </DropZoneWrapper>
      )}
    </>
  );
};

FileUploader.propTypes = propTypes;
FileUploader.defaultProps = defaultPropTypes;

export default FileUploader;
