import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ROUTING } from 'routing';

import AppLanguageSwitch from 'containers/public/Auth/Login/AppLanguageSwitch';
import MisconductLanguageSwitch from 'containers/public/Misconduct/MisconductForm/MisconductLanguageSwitch';
import SingleColumn from 'components/SingleColumn';
import RoundIconWrap from 'components/RoundIconWrap';
import FlagIcon from 'components/Svg/FlagIcon';
import Typography from 'components/Typography';
import Button from 'components/Button';

import { colors } from 'styles';
import { SelectionWrapper } from './style';
import { CenteredContent, HeaderLogo } from 'styles/commonStyles';
import { globalMessages } from 'translations';

const propTypes = {
  intl: PropTypes.object,
  isMisconductForm: PropTypes.bool,
  selectedLanguage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  availableLanguages: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onConfirmationClick: PropTypes.func.isRequired,
  description: PropTypes.node.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const LanguageSelectBanner = ({
  intl,
  selectedLanguage,
  isLoading,
  isMisconductForm,
  onConfirmationClick,
  description,
  availableLanguages,
}) => {
  return (
    <SingleColumn isWhiteBackground isFullscreen>
      <CenteredContent>
        <Link to={ROUTING.INDEX}>
          <HeaderLogo isCentered isRestricted />
        </Link>

        <RoundIconWrap icon={<FlagIcon />} />

        <Typography size="big" tabletSize="large" bottomMargin={12}>
          {intl.formatMessage(globalMessages.preferredLanguage)}
        </Typography>

        <Typography
          size="normal"
          tabletSize="medium"
          bottomMargin={32}
          color={colors.darkGray}
          maxWidth={460}
        >
          {description}
        </Typography>

        <SelectionWrapper>
          {isMisconductForm ? (
            <MisconductLanguageSwitch availableLanguages={availableLanguages} />
          ) : (
            <AppLanguageSwitch availableLanguages={availableLanguages} />
          )}
        </SelectionWrapper>

        <Button
          isFluid
          primary
          maxWidth={414}
          disabled={!selectedLanguage}
          isProcessing={isLoading}
          onClick={onConfirmationClick}
        >
          {intl.formatMessage(globalMessages.continue)}
        </Button>
      </CenteredContent>
    </SingleColumn>
  );
};

LanguageSelectBanner.propTypes = propTypes;

export default injectIntl(LanguageSelectBanner);
