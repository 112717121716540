export const deserializeUser = (user) => {
  const { id, first_name, last_name } = user;

  return {
    value: id,
    label: `${last_name} ${first_name}`,
    ...user,
  };
};

export const deserializeGroup = (group) => ({
  value: group.id,
  label: group.name,
  ...group,
});

export const deserializeRecipient = ({ type, data }) =>
  type === 'user'
    ? { ...deserializeUser(data), type }
    : { ...deserializeGroup(data), type };

export const deserializeReport = ({
  id,
  date_created,
  date_closed,
  language,
  intake_method,
  report_name,
  assigned_to,
  status,
  required_summary,
  chat_file_upload_allowed,
  priority,
  params,
  is_anonymized,
  archived,
  archive_date,
  archive_reminder,
  archive_reversable,
  notification_date,
  permissions,
  days_open,
}) => ({
  id,
  dateCreated: date_created,
  dateClosed: date_closed,
  language,
  intakeMethod: intake_method,
  daysOpen: days_open,
  reportName: report_name,
  requiredSummary: required_summary,
  isAnonymized: is_anonymized,
  isArchived: archived,
  archiveDate: archive_date,
  archiveReminder: archive_reminder,
  archiveReversable: archive_reversable,
  notificationDate: notification_date,
  permissions,
  reportState: {
    assignee: assigned_to && {
      value: assigned_to.id,
      label: `${assigned_to.last_name} ${assigned_to.first_name}`,
      officer: assigned_to.officer,
    },
    status,
    priority,
    // priority: priority && {
    //   value: priority.value, label:
    // },
  },
  isUploadAllowed: chat_file_upload_allowed,
  reportInfo: params,
});

export const deserializeFiles = (data) => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    extension: item.extension,
    anonymizedAt: item.anonymized_at,
    folder: item.folder,
  }));
};

export const serializePermissions = (data) => {
  return data.map((permission) => ({
    value: permission.key,
    type: permission.type,
    key: permission.key,
    label: permission.label,
    derivedPermissions: permission.derived_permissions,
  }));
};

export const deserializeUserGroups = (data) => {
  return data.map((item) => ({
    ...item,
    permissions: [
      ...item.permissions.map((permission) => ({ ...permission, value: permission.key })),
    ],
  }));
};

export const serializeUserGroups = (data) => {
  return data.map((item) => ({
    ...item,
    permissions: [...item.permissions.map((permission) => permission.key)],
  }));
};

export const deserializeUserDashboard = ({ user, notifications, documents }) => ({
  user,
  notifications,
  documents,
});

export const deserializeExtendedUserDashboard = ({
  user,
  notifications,
  documents,
  misconducts,
}) => ({
  user,
  notifications,
  documents,
  misconducts: misconducts.items,
  columns: misconducts.columns,
});

export const deserializeCoDashboard = ({
  user,
  notifications,
  documents,
  misconducts,
  statistics,
  // statisticsFilterOptions,
}) => ({
  user,
  notifications,
  documents,
  misconducts: misconducts.items,
  columns: misconducts.columns,
  statistics,
  // statisticsFilterOptions,
});

export const deserializeReportData = (reportData) => {
  return {
    has_next_step: reportData.has_next_step,
    has_previous_step: reportData.has_previous_step,
    steps_count: reportData.steps_count,
    step_number: reportData.step_number,
    summary_modal: reportData.summary_modal,
    current_step: {
      ...reportData.current_step,
      sections: reportData.current_step.sections.map((section) => ({
        ...section,
        inputs: section.inputs.map((input) => ({ ...input, value: '' })),
      })),
    },
  };
};

export const deserializeReportSummaryData = (reportSummaryData) => {
  return {
    ...reportSummaryData,
    submitted_steps: reportSummaryData.submitted_steps.map((step) => ({
      ...step,
      sections: step.sections.map((section) => ({
        ...section,
        inputs: section.inputs.map((input) => ({
          ...input,
          selectedAnswer: input.choices.find((choice) => choice.id === input.answer),
        })),
      })),
    })),
  };
};

export const deserializeReportReminders = ({
  receive_info_reminder,
  summary_reminder,
}) => ({
  receiveInfo: {
    dueDate: receive_info_reminder.due_date,
    active: !!receive_info_reminder.active,
    type: receive_info_reminder.interval,
    message: receive_info_reminder.message,
    forwardMessage: !!receive_info_reminder.forward_message,
  },
  summary: {
    dueDate: summary_reminder.due_date,
    active: !!summary_reminder.active,
    type: summary_reminder.interval,
    message: summary_reminder.message,
    forwardMessage: !!summary_reminder.forward_message,
  },
});

export const serializeReportReminders = ({ receiveInfo, summary }) => ({
  receive_info_reminder: {
    active: receiveInfo.active,
    interval: receiveInfo.type,
    message: receiveInfo.message,
    forward_message: receiveInfo.forwardMessage,
  },
  summary_reminder: {
    active: summary.active,
    interval: summary.type,
    message: summary.message,
    forward_message: summary.forwardMessage,
  },
});

export const deserializeDocument = ({ owner, ...document }) => ({
  owner: deserializeUser(owner),
  ...document,
  alerts: [
    ...document.alerts.map((alert) => {
      return { ...alert, active: true };
    }),
  ],
});

export const deserializeDocumentVersion = ({ version_number, files }) => ({
  versionNumber: version_number,
  files,
});

export const deserializeLogs = ({ items, pages }) => ({
  items: items.map((log) => ({
    ...log,
    performer: !!Object.keys(log.performer).length && {
      id: log.performer.id,
      firstName: log.performer.first_name,
      lastName: log.performer.last_name,
    },
  })),
  pages,
});

export const prepareStepAnswersPayload = (answersData) => {
  return answersData.sections
    .map((section) => section.inputs.map((input) => ({ ...input })))
    .flat()
    .map(({ id, answer }) => ({ question_id: id, answer: answer }));
};
