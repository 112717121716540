import React, { useCallback } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

import {
  Control,
  SimpleMenu,
  MenuList,
  Option,
  SingleValue,
  MultiValueContainer,
  MultiValueLabel,
  Placeholder,
  Input,
  SelectContainer,
} from './subcomponents';

import { SelectWrapper, StyledLabel } from './style';

const propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  marginBottom: PropTypes.number,
};

const components = {
  Control,
  SimpleMenu,
  MenuList,
  SingleValue,
  MultiValueContainer,
  MultiValueLabel,
  Option,
  Placeholder,
  Input,
  IndicatorSeparator: () => null,
  SelectContainer,
};

const SimpleSelect = ({
  label,
  id,
  placeholder,
  options,
  onChange,
  isMulti,
  marginBottom,
  ...props
}) => {
  const handleChange = useCallback(
    (value) => {
      const emptyValue = isMulti ? [] : null;
      onChange({
        target: {
          value: value || emptyValue,
          id: id || null,
        },
      });
    },
    [onChange, id, isMulti]
  );

  return (
    <SelectWrapper marginBottom={marginBottom || marginBottom === 0 ? marginBottom : 16}>
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}

      <ReactSelect
        inputId={id}
        onChange={(value, selectProps) => handleChange(value, selectProps)}
        components={components}
        options={options}
        placeholder={placeholder}
        maxMenuHeight={500}
        isClearable={false}
        isMulti={isMulti}
        {...props}
      />
    </SelectWrapper>
  );
};

SimpleSelect.propTypes = propTypes;

export default SimpleSelect;
