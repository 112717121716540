import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { SelectMainWrapper, StyledSelect, SelectLabel } from './style';
import { globalMessages } from 'translations';

const propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  isDark: PropTypes.bool,
  isCentered: PropTypes.bool,
  placeholder: PropTypes.string,
  marginBottom: PropTypes.number,
  maxWidth: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultPropTypes = {
  marginBottom: 16,
};

const SimpleSelect = ({
  label,
  id,
  options,
  required,
  isDark,
  placeholder = '',
  marginBottom,
  maxWidth,
  onChange,
  value,
  isCentered,
  ...props
}) => {
  const intl = useIntl();
  const [isSelected, setIsSelected] = useState(false);
  const placeholderExists = placeholder.length > 0;

  const blurHandler = (e) => {
    setIsSelected(e.target.value !== '');
  };

  const handleSelectChange = (e) => {
    onChange(e);
  };

  return (
    <SelectMainWrapper
      marginBottom={marginBottom}
      maxWidth={maxWidth}
      isCentered={isCentered}
    >
      <SelectLabel htmlFor={id} isDark={isDark}>
        {label}
        {required && label && '*'}

        <StyledSelect
          id={id}
          required={required}
          isDark={isDark}
          onBlur={blurHandler}
          isSelected={isSelected}
          onChange={handleSelectChange}
          value={value}
          {...props}
        >
          <option value="" hidden>
            {!placeholderExists
              ? intl.formatMessage(globalMessages.selectPlaceholder)
              : placeholder}
          </option>

          {options.map(({ name, label, value, id }) => (
            <option key={label || name || id} value={id || value} aria-label={value}>
              {name || label}
            </option>
          ))}
        </StyledSelect>
      </SelectLabel>
    </SelectMainWrapper>
  );
};

SimpleSelect.propTypes = propTypes;
SimpleSelect.defaultProps = defaultPropTypes;

export default SimpleSelect;
