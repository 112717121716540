import api from 'api';
import { DashboardNotificationsHandler } from 'stores/helpers/dashboardNotifications';
import { toastContext } from 'helpers/toastConstants';
import {
  deserializeExtendedUserDashboard,
  deserializeUserDashboard,
} from 'deserializers';

// Actions
export const STORE_DASHBOARD = 'userDashboard.STORE_DASHBOARD';
export const STORE_MISCONDUCT_COLUMNS = 'userDashboard.STORE_MISCONDUCT_COLUMNS';
export const SET_LOADING = 'userDashboard.SET_LOADING';
export const SET_NOTIFICATION_READ = 'userDashboard.SET_NOTIFICATION_READ';

// Helper
const notificationHandler = new DashboardNotificationsHandler({
  actionName: SET_NOTIFICATION_READ,
  errorToastContext: toastContext.DASHBOARD_EMPLOYEE,
});

// Reducer
const initialState = {
  isLoading: true,
  misconductColumns: [],
  dashboard: {
    notifications: [],
    documents: [],
    misconducts: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case STORE_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case STORE_MISCONDUCT_COLUMNS:
      return {
        ...state,
        misconductColumns: action.payload,
      };
    case SET_NOTIFICATION_READ:
      return notificationHandler.reducer(state, action);
    default:
      return state;
  }
}

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload });

export const storeDashboard = (payload) => ({ type: STORE_DASHBOARD, payload });
export const storeMisconductsColumns = (payload) => ({
  type: STORE_MISCONDUCT_COLUMNS,
  payload,
});

// Side Effects
export const getUserDashboard = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await api.userDashboard.getDashboard();
    const serializedResponse = deserializeUserDashboard(response.data);
    dispatch(storeDashboard(serializedResponse));
  } catch (error) {
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const getExtendedUserDashboard = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await api.extendedUserDashboard.getDashboard();
    const serializedResponse = deserializeExtendedUserDashboard(response.data);
    dispatch(storeDashboard(serializedResponse));
    dispatch(storeMisconductsColumns(serializedResponse.columns));
  } catch (error) {
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const markNotificationAsRead = notificationHandler.markNotificationAsRead;
