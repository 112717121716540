import styled from 'styled-components';
import { rem } from 'polished';

import { TypographyElement } from 'components/Typography/style';
import { StyledButton } from 'components/Button/style';

import { breakpoints, colorName, colors, font } from 'styles';

export const Header = styled.header`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 ${rem(24)} 0;
  padding: ${({ withSeparator }) => (withSeparator ? `0 0 ${rem(12)} 0` : '0')};
  border-bottom: ${({ withSeparator }) =>
    withSeparator ? `1px solid ${colorName.separator}` : '0'};

  @media ${breakpoints.tablet} {
    flex-wrap: nowrap;
    padding: ${({ withSeparator }) => (withSeparator ? `0 0 ${rem(20)} 0` : '0')};
  }

  ${TypographyElement} {
    flex-basis: 85%;
    margin: 0 0 ${rem(16)};

    @media ${breakpoints.tablet} {
      flex-basis: auto;
      margin: 0;
    }
  }

  ${StyledButton} {
    flex-grow: 1;

    &:not(:last-child) {
      margin: 0 ${rem(16)} 0 0;
    }

    @media ${breakpoints.tablet} {
      flex-grow: 0;
    }
  }
`;

export const ExtraLabelWrapper = styled.span`
  display: block;
  color: ${colors.gray};
  font-size: ${rem(font.size.tiny)};

  @media ${breakpoints.tablet} {
    display: inline;
    margin-left: ${rem(8)};
  }
`;
