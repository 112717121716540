import React from 'react';

function SendIcon(props) {
  return (
    <svg viewBox="0 0 18 18" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17 9L1 1l3 8h13z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
        d="M17 9L1 17l3-8h13z"
      />
    </svg>
  );
}

export default SendIcon;
