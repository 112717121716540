import api from 'api';
import { sortOptions } from 'containers/private/Dashboard/CoDashboard/parts/BarChartSection';
import { timeFilterOptions } from 'containers/private/Dashboard/CoDashboard/parts/StatisticsSection';
import { deserializeCoDashboard } from 'deserializers';
import { toastContext } from 'helpers/toastConstants';
import { DashboardNotificationsHandler } from 'stores/helpers/dashboardNotifications';

// Actions
export const STORE_DASHBOARD = 'coDashboard.STORE_DASHBOARD';
export const STORE_MISCONDUCT_COLUMNS = 'coDashboard.STORE_MISCONDUCT_COLUMNS';
export const SET_LOADING = 'coDashboard.SET_LOADING';
export const SET_NOTIFICATION_READ = 'coDashboard.SET_NOTIFICATION_READ';
export const STORE_TIME_FILTER = 'coDashboard.STORE_TIME_FILTER';
export const STORE_DONUT_STATISTICS = 'coDashboard.STORE_DONUT_STATISTICS';
export const STORE_DONUT_FILTERS = 'coDashboard.STORE_DONUT_FILTERS';
export const STORE_BAR_STATISTICS = 'coDashboard.STORE_BAR_STATISTICS';
export const STORE_BAR_CATEGORY = 'coDashboard.STORE_BAR_CATEGORY';
export const STORE_BAR_SORT = 'coDashboard.STORE_BAR_SORT';
export const STORE_STATISTICS_GROUPS = 'coDashboard.STORE_STATISTICS_GROUPS';

// Helper
const notificationHandler = new DashboardNotificationsHandler({
  actionName: SET_NOTIFICATION_READ,
  errorToastContext: toastContext.DASHBOARD_CO,
});

// Reducer
const initialState = {
  isLoading: true,
  misconductColumns: [],
  dashboard: {
    notifications: [],
    documents: [],
    misconducts: [],
    statistics: [],
    // statisticsFilterOptions: {},
  },
  timeFilter: timeFilterOptions[0],
  donutStatistics: null,
  donutFilters: [],
  barStatistics: null,
  barCategory: {},
  barSort: sortOptions[5],
  statisticsGroups: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case STORE_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
      };
    case STORE_MISCONDUCT_COLUMNS:
      return {
        ...state,
        misconductColumns: action.payload,
      };
    case STORE_TIME_FILTER:
      return {
        ...state,
        timeFilter: action.payload,
      };
    case STORE_DONUT_STATISTICS:
      return {
        ...state,
        donutStatistics: action.payload,
      };
    case STORE_DONUT_FILTERS:
      return {
        ...state,
        donutFilters: action.payload,
      };
    case STORE_BAR_STATISTICS:
      return {
        ...state,
        barStatistics: action.payload,
      };
    case STORE_BAR_CATEGORY:
      return {
        ...state,
        barCategory: action.payload,
      };
    case STORE_BAR_SORT:
      return {
        ...state,
        barSort: action.payload,
      };
    case STORE_STATISTICS_GROUPS:
      return {
        ...state,
        statisticsGroups: action.payload,
      };
    case SET_NOTIFICATION_READ:
      return notificationHandler.reducer(state, action);
    default:
      return state;
  }
}

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload });

export const storeCoDashboard = (payload) => ({ type: STORE_DASHBOARD, payload });
export const storeMisconductColumns = (payload) => ({
  type: STORE_MISCONDUCT_COLUMNS,
  payload,
});

export const storeTimeFilter = (payload) => ({
  type: STORE_TIME_FILTER,
  payload,
});
export const storeDonutStatistics = (payload) => ({
  type: STORE_DONUT_STATISTICS,
  payload,
});
export const storeDonutFilters = (payload) => ({
  type: STORE_DONUT_FILTERS,
  payload,
});

export const storeBarStatistics = (payload) => ({
  type: STORE_BAR_STATISTICS,
  payload,
});
export const storeBarCategory = (payload) => ({
  type: STORE_BAR_CATEGORY,
  payload,
});
export const storeBarSort = (payload) => ({
  type: STORE_BAR_SORT,
  payload,
});
export const storeStatisticsGroups = (payload) => ({
  type: STORE_STATISTICS_GROUPS,
  payload,
});

// Side Effects
export const getCoDashboard = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await api.coDashboard.getDashboard();
    const serializedResponse = deserializeCoDashboard(response.data);

    dispatch(storeCoDashboard(serializedResponse));
    dispatch(storeMisconductColumns(serializedResponse.columns));
  } catch (error) {
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDonutStatistics =
  ({ timeFilter, donutFilters }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await api.coDashboard.getDonutStatistics({
        group_by: 'status',
        donutFilters,
        filters: {
          date_created: timeFilter.dateCreated,
          date_closed: timeFilter.dateClosed,
        },
      });

      dispatch(storeDonutStatistics(response.data));
      dispatch(storeDonutFilters(donutFilters));
      dispatch(storeTimeFilter(timeFilter));
    } catch (error) {
      // @todo - update error handling
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getBarStatistics =
  ({ timeFilter, barCategory, barSort }) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await api.coDashboard.getBarStatistics({
        group_by: barCategory.value,
        filters: {
          date_created: timeFilter.dateCreated,
          date_closed: timeFilter.dateClosed,
        },
        sort_by: barSort.sortBy,
        sort_order: barSort.sortOrder,
      });

      dispatch(storeBarStatistics(response.data));
      dispatch(storeBarCategory(barCategory));
      dispatch(storeBarSort(barSort));
    } catch (error) {
      // @todo - update error handling
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getStatisticsGroups = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await api.coDashboard.getStatisticsGroups();
    const serializedData = response.data.map((item) => ({
      value: item.id,
      label: item.label,
    }));
    dispatch(storeStatisticsGroups(serializedData));
  } catch (error) {
    // @todo - update error handling
  } finally {
    dispatch(setLoading(false));
  }
};

export const markNotificationAsRead = notificationHandler.markNotificationAsRead;
