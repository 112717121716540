import styled from 'styled-components';
import { rem } from 'polished';

import { breakpoints, colorName, colors, font } from 'styles';
import { TypographyElement } from 'components/Typography/style';
import CircleTick from 'components/Svg/Tick';

const TIMELINE_HEIGHT = 70;
const REGULAR_DOT_SIZE = 20;
const TICK_ICON_SIZE = 18;

export const TimelineWrapper = styled.div`
  margin: 0 0 ${rem(34)} 0;
  padding: 0 ${rem(16)};

  @media ${breakpoints.tablet} {
    margin: 0 0 ${rem(64)} 0;

    & > ${TypographyElement} {
      margin: 0;
    }
  }

  @media ${breakpoints.smallScreen} {
    padding: 0;
  }
`;

export const TimelineList = styled.ul`
  display: flex;
  width: 100%;
  height: ${rem(TIMELINE_HEIGHT)};
  padding: 0 ${rem(8)};
  list-style-type: none;
`;

export const TimelineItem = styled.li`
  display: flex;
  flex: 1 1 auto;
  align-items: center;

  &:last-child {
    flex: 0;
  }
`;

export const TimelineContent = styled.span`
  position: relative;
  flex: 0 0 ${rem(REGULAR_DOT_SIZE)};
  width: ${rem(REGULAR_DOT_SIZE)};
  height: ${rem(REGULAR_DOT_SIZE)};
  background-color: ${colorName.white};
  border: 1px solid ${({ isDark }) => (isDark ? colorName.white : colorName.secondary)};
  border-radius: 100%;

  ${({ isItemCurrent }) =>
    isItemCurrent &&
    `
    background-color: ${colors.green};
    box-shadow: inset 0 0 0 0.125rem ${colorName.white};
  `};
`;

export const TimelineDot = styled.span`
  position: relative;
  display: block;
  height: 100%;
  border-radius: 100%;

  &:after {
    position: absolute;
    bottom: -${rem(40)};
    left: -${rem(6)};
    color: ${({ isDark }) => (isDark ? colorName.white : colorName.secondary)};
    font-weight: ${({ isItemCurrent }) =>
      isItemCurrent ? font.weight.bold : font.weight.regular};
    font-size: ${rem(font.size.small)};
    white-space: nowrap;
    text-transform: capitalize;
    transform: translateX(-25%);
    content: attr(data-content);
  }
`;

export const TimelineLine = styled.div`
  width: 100%;
  height: 1px;
  margin: auto;
  background-color: ${({ isDark }) => (isDark ? colorName.white : colorName.secondary)};
`;

export const TickIcon = styled(CircleTick)`
  position: absolute;
  top: 0;
  left: 0;
  width: ${rem(TICK_ICON_SIZE)};
  height: ${rem(TICK_ICON_SIZE)};
  color: ${colors.shark};
  background-color: ${colorName.white};
  border-radius: 100%;
`;
