import React from 'react';

function CheckmarkIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 6" {...props}>
      <path
        fill="currentColor"
        d="M6.5284 0.348853C6.78792 0.0881567 7.21056 0.0881567 7.47192 0.347004C7.73329 0.605851 7.73329 1.0274 7.47192 1.2881L3.33388 5.4804C3.13584 5.68105 2.81116 5.67837 2.61645 5.4745L0.526436 3.28615C0.266922 3.02546 0.266922 2.6039 0.52829 2.34506C0.789658 2.08621 1.2123 2.08621 1.47181 2.34691L2.97212 3.90292L6.5284 0.348853Z"
      />
    </svg>
  );
}

export default CheckmarkIcon;
