import React, { useState } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  dateControllerConfig: PropTypes.object.isRequired,
  selectedDate: momentPropTypes.momentObj,
};

const SingleDatePicker = ({ dateControllerConfig, selectedDate, onChange }) => {
  const [focused, setFocused] = useState(true);

  return (
    <DayPickerSingleDateController
      onDateChange={onChange}
      focused={focused}
      date={selectedDate}
      onFocusChange={() => setFocused(true)}
      {...dateControllerConfig}
    />
  );
};

SingleDatePicker.propTypes = propTypes;

export default SingleDatePicker;
