import styled from 'styled-components';
import { rem } from 'polished';

import { colors, colorName, font, variables, breakpoints } from 'styles';

const NAV_BTN_SIZE = 16;

export const DatePickerComponent = styled.div`
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${rem(maxWidth)};
  `};
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
  border: 1px solid transparent;
  border-radius: ${rem(variables.borderRadius)};

  ${({ isDayPickerOpen }) =>
    isDayPickerOpen &&
    `
    border: 1px solid ${colors.navy};
    
    & > div input:focus{
      border: 2px solid transparent;
    }

    & > div input,
    & > div input:hover {
      border: 1px solid transparent;
    }
    
    & > div svg {
      color: ${colors.navy} !important;
    }
  `};

  .DayPicker__horizontal {
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    border-radius: inherit;

    div div {
      max-width: 100%;
    }

    .CalendarMonth_caption {
      padding-top: ${rem(16)};
    }

    .DayPicker_weekHeader {
      top: ${rem(54)};

      &_ul {
        display: flex;
        justify-content: space-between;
      }
    }

    .CalendarMonthGrid__horizontal {
      position: static;
    }

    tr td:first-child {
      border-top-left-radius: 100%;
      border-bottom-left-radius: 100%;
    }

    tr td:last-child {
      border-top-right-radius: 100%;
      border-bottom-right-radius: 100%;
    }

    .CalendarDay {
      color: ${colorName.black};
      font-weight: ${font.weight.regular};
      border: 1px solid transparent;
      border-radius: 100%;
      box-shadow: none;

      &:hover {
        border: 1px solid transparent;
      }

      .dayContent {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }

      &__selected {
        background: ${colors.maroonLight};

        .dayContent {
          color: ${colorName.white};
          background: ${colorName.secondary};
        }

        &:hover {
          color: ${colorName.white};
          background: ${colors.maroonLight};
          border: 1px solid transparent;
          border-radius: 100%;
        }

        &_start,
        &_start:hover {
          border-radius: 100% 0 0 100%;
        }

        &_end,
        &_end:hover {
          border-radius: 0 100% 100% 0;
        }

        &_span {
          background: ${colors.maroonLight};
          border-radius: 0;

          &:hover {
            background: ${colors.maroonLight};

            .dayContent {
              color: ${colorName.white};
              background: ${colorName.secondary};
            }
          }
        }
      }

      &__hovered_span:hover,
      &__hovered_span {
        color: ${'red'};
        background: ${colors.maroonLight};
        border-radius: 0;
      }
    }

    @media (min-width: 340px) {
      .CalendarMonthGrid__horizontal {
        position: absolute;
      }
    }

    @media ${breakpoints.tablet} {
      .CalendarMonth_caption {
        padding-top: ${rem(10)};
        font-size: ${rem(font.size.large)};
      }
    }
  }
`;

export const NavArrowWrapper = styled.div`
  position: absolute;
  top: ${rem(18)};
  left: ${({ next }) => (next ? 'initial' : rem(22))};
  width: ${rem(NAV_BTN_SIZE)};
  height: ${rem(NAV_BTN_SIZE)};

  ${({ next }) =>
    next &&
    `
    right: ${rem(22)};
    transform: rotate(180deg);
  `};
`;

export const ButtonsPanel = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${rem(22)} ${rem(22)};
  border-radius: inherit;
`;

export const RequiredMark = styled.span`
  color: ${colors.darkestRed};
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${rem(14)};
  width: ${rem(20)};
  height: ${rem(20)};
  color: ${({ isDark }) => (isDark ? colorName.white : colors.gray)};
  transform: translateY(-50%);
`;
