import React from 'react';

function CalendarIcon() {
  return (
    <svg fill="none" viewBox="0 0 20 20">
      <path stroke="currentColor" strokeMiterlimit={10} d="M19.063 6.875H.938" />
      <path
        stroke="currentColor"
        strokeLinecap="square"
        strokeMiterlimit={10}
        d="M17.5 2.5h-15c-.863 0-1.563.7-1.563 1.563v11.875c0 .862.7 1.562 1.563 1.562h15c.863 0 1.563-.7 1.563-1.563V4.063c0-.863-.7-1.563-1.563-1.563zM5.313.625v3.75m9.375-3.75v3.75"
      />
    </svg>
  );
}

export default CalendarIcon;
