import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import QuestionMarkIcon from 'components/Svg/QuestionMarkIcon';
import MarkdownParser from 'components/MarkdownParser';
import { colors, font } from 'styles';

const defaultPropTypes = {
  clickable: true,
  isMarkdown: true,
};

const propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isMarkdown: PropTypes.bool,
};

const ReactTooltip = ({ id, content, isMarkdown, ...props }) => {
  return (
    <>
      <QuestionMarkIcon
        width={14}
        height={14}
        data-tooltip-id={id}
        style={{ minWidth: '14px', marginLeft: '8px' }}
      />
      <Tooltip
        id={id}
        openOnClick
        content={
          isMarkdown ? (
            <MarkdownParser
              source={content}
              fontSize={font.size.small}
              color={colors.mediumGray}
            />
          ) : (
            content
          )
        }
        style={{
          zIndex: '999',
          maxWidth: '70vw',
          color: colors.athensGray,
          backgroundColor: colors.darkestGray,
          borderRadius: '4px',
          padding: '0 10px',
        }}
        multiline={true}
        {...props}
      />
    </>
  );
};

ReactTooltip.defaultProps = defaultPropTypes;
ReactTooltip.propTypes = propTypes;

export default ReactTooltip;
