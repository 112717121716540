import React from 'react';
import { useIntl } from 'react-intl';

import { NoMessagesWrapper } from '../style';
import { messages } from '../messages';

const EmptyState = () => {
  const intl = useIntl();

  return <NoMessagesWrapper>{intl.formatMessage(messages.emptyState)}</NoMessagesWrapper>;
};

export default EmptyState;
