import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
  SORT_DIRECTION_DESCENDING,
} from 'helpers/sharedConstants';

const USER_DOCS_DEFAULT_SORT_PARAMS = {
  key: 'date_added',
  direction: SORT_DIRECTION_DESCENDING,
};
const OWNED_DOCS_DEFAULT_SORT_PARAMS = {
  key: 'date_created',
  direction: SORT_DIRECTION_DESCENDING,
};

// Actions
export const STORE_DOCUMENTS_LIST = 'userDocuments.STORE_DOCUMENTS_LIST';
export const STORE_OWNED_DOCUMENTS_LIST = 'userDocuments.STORE_OWNED_DOCUMENTS_LIST';
export const STORE_DOCUMENTS_LIST_META = 'userDocuments.STORE_DOCUMENTS_LIST_META';
export const STORE_OWNED_DOCUMENTS_LIST_META =
  'userDocuments.STORE_OWNED_DOCUMENTS_LIST_META';
export const SET_LOADING = 'userDocuments.SET_LOADING';

// Reducer
const initialState = {
  isLoading: false,
  documentsList: [],
  ownedDocumentsList: [],
  documentsListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
    sortParams: USER_DOCS_DEFAULT_SORT_PARAMS,
  },
  ownedDocumentsListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
    sortParams: OWNED_DOCS_DEFAULT_SORT_PARAMS,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_DOCUMENTS_LIST:
      return {
        ...state,
        documentsList: action.payload,
      };
    case STORE_OWNED_DOCUMENTS_LIST:
      return {
        ...state,
        ownedDocumentsList: action.payload,
      };
    case STORE_DOCUMENTS_LIST_META:
      return {
        ...state,
        documentsListMeta: action.payload,
      };
    case STORE_OWNED_DOCUMENTS_LIST_META:
      return {
        ...state,
        ownedDocumentsListMeta: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function storeDocuments(documents) {
  return { type: STORE_DOCUMENTS_LIST, payload: documents };
}

export function storeOwnedDocuments(documents) {
  return { type: STORE_OWNED_DOCUMENTS_LIST, payload: documents };
}

export function storeDocumentsMeta(meta) {
  return { type: STORE_DOCUMENTS_LIST_META, payload: meta };
}

export function storeOwnedDocumentsMeta(meta) {
  return { type: STORE_OWNED_DOCUMENTS_LIST_META, payload: meta };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

// Side Effects
export function getDocuments({
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
  sortParams = USER_DOCS_DEFAULT_SORT_PARAMS,
  query,
}) {
  return (dispatch) => {
    dispatch(setLoading(true));

    return api.me
      .getMyDocuments({
        page,
        perPage,
        sortBy: sortParams?.key,
        sortOrder: sortParams?.direction,
        query,
      })
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(storeDocuments(res.data.items));
        dispatch(
          storeDocumentsMeta({
            page: res.data.current_page,
            perPage,
            totalPages: res.data.pages,
            sortParams,
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
}

export function getOwnedDocuments({
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
  sortParams = OWNED_DOCS_DEFAULT_SORT_PARAMS,
  query,
}) {
  return (dispatch) => {
    dispatch(setLoading(true));

    return api.me
      .getOwnedDocuments({
        page,
        perPage,
        sortBy: sortParams?.key,
        sortOrder: sortParams?.direction,
        query,
      })
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(storeOwnedDocuments(res.data.items));
        dispatch(
          storeOwnedDocumentsMeta({
            page,
            perPage,
            totalPages: res.data.pages,
            sortParams,
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
}
