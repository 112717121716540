const PASSWORD_STRENGTH_REQUIREMENTS = [
  {
    key: 'passwordUpperLowerCaseLetters',
    validator: (password) => /(?=.*[a-z])(?=.*[A-Z])/.test(password),
  },
  { key: 'passwordNumbers', validator: (password) => /.*\d/.test(password) },
  {
    key: 'passwordSpecialCharacters',
    validator: (password) => /.*[^A-Za-z0-9]/.test(password),
  },
];

export function validatePassword(password, minLength) {
  const passwordLengthRequirement = {
    key: 'passwordLength',
    param: minLength,
    validator: (password) => password?.length >= minLength,
  };
  return [passwordLengthRequirement, ...PASSWORD_STRENGTH_REQUIREMENTS].map(
    (requirement) => ({
      key: requirement.key,
      param: requirement.param,
      fulfilled: requirement.validator(password),
    })
  );
}
