import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ROUTING } from 'routing';

import DocumentConfirmedIcon from 'components/Svg/DocumentConfirmedIcon';
import { PrimaryButton, SimpleModal } from 'components/SimpleModal';

import { globalMessages } from 'translations';

const ReportAddedModal = () => {
  const intl = useIntl();
  const history = useHistory();

  return (
    <SimpleModal
      isOpen={true}
      closeHandler={() => history.push(ROUTING.INDEX)}
      heading={intl.formatMessage(globalMessages.reportSubmitted)}
      icon={DocumentConfirmedIcon}
    >
      <PrimaryButton onClick={() => history.push(ROUTING.INDEX)}>
        {intl.formatMessage(globalMessages.goToDashboard)}
      </PrimaryButton>
    </SimpleModal>
  );
};

export default ReportAddedModal;
