import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { setUserLanguage } from 'stores/auth';
import { appLanguagesSelectData } from 'helpers/commonConstants';

import Select from 'components/SimpleSelect';
import { fetchTenantProperties } from 'stores/tenantProperties';

const propTypes = {
  availableLanguages: PropTypes.array,
};

const AppLanguageSwitch = ({ availableLanguages }) => {
  const { userLanguage } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const languageSwitch = (e) => {
    dispatch(setUserLanguage(e.target.value));
    dispatch(fetchTenantProperties(e.target.value));
  };
  const languages = availableLanguages
    ? appLanguagesSelectData.filter((lang) => availableLanguages.includes(lang.value))
    : appLanguagesSelectData;

  return (
    <Select
      id="app-language"
      options={languages}
      value={userLanguage}
      onChange={languageSwitch}
      marginBottom={0}
      maxWidth={168}
      data-testid="app-language-switcher"
    />
  );
};

AppLanguageSwitch.propTypes = propTypes;

export default AppLanguageSwitch;
