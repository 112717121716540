import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
  SORT_DIRECTION_DESCENDING,
} from 'helpers/sharedConstants';

const USER_MISCONDUCTS_DEFAULT_SORT_PARAMS = {
  key: 'date_created',
  direction: SORT_DIRECTION_DESCENDING,
};

// Actions
export const STORE_MISCONDUCTS_LIST = 'userMisconducts.STORE_MISCONDUCTS_LIST';
export const STORE_MISCONDUCTS_COLUMNS = 'userMisconducts.STORE_MISCONDUCTS_COLUMNS';
export const STORE_MISCONDUCTS_LIST_META = 'userMisconducts.STORE_DOCUMENTS_LIST_META';
export const SET_LOADING = 'userMisconducts.SET_LOADING';
export const STORE_MISCONDUCTS_FILTERS = 'reports.STORE_MISCONDUCTS_FILTERS';
export const SET_SEARCH = 'reports.SET_SEARCH';
export const SET_FILTERS = 'reports.SET_FILTERS';

// Reducer
const initialState = {
  isLoading: false,
  misconductColumns: [],
  misconductsList: [],
  misconductsListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
    sortParams: USER_MISCONDUCTS_DEFAULT_SORT_PARAMS,
  },
  misconductsFilters: [],
  filters: {},
  search: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_MISCONDUCTS_LIST:
      return {
        ...state,
        misconductsList: action.payload,
      };
    case STORE_MISCONDUCTS_COLUMNS:
      return {
        ...state,
        misconductColumns: action.payload,
      };
    case STORE_MISCONDUCTS_LIST_META:
      return {
        ...state,
        misconductsListMeta: action.payload,
      };
    case STORE_MISCONDUCTS_FILTERS:
      return {
        ...state,
        misconductsFilters: action.payload,
      };
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function storeMisconducts(misconducts) {
  return { type: STORE_MISCONDUCTS_LIST, payload: misconducts };
}

export function storeColumns(columns) {
  return { type: STORE_MISCONDUCTS_COLUMNS, payload: columns };
}

export function storeMisconductsMeta(meta) {
  return { type: STORE_MISCONDUCTS_LIST_META, payload: meta };
}

export function storeMisconductsFilters(data) {
  return { type: STORE_MISCONDUCTS_FILTERS, payload: data };
}

export function setSearch(data) {
  return { type: SET_SEARCH, payload: data };
}

export function setFilters(data) {
  return { type: SET_FILTERS, payload: data };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

// Side Effects
export function getMisconducts({
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
  sortParams = USER_MISCONDUCTS_DEFAULT_SORT_PARAMS,
  query,
  filters,
}) {
  return (dispatch) => {
    dispatch(setLoading(true));

    return api.me
      .getMyMisconducts({
        page,
        perPage,
        sortBy: sortParams?.key,
        sortOrder: sortParams?.direction,
        query,
        filters,
      })
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(storeMisconducts(res.data.items));
        dispatch(storeColumns(res.data.columns));
        dispatch(
          storeMisconductsMeta({ page, perPage, totalPages: res.data.pages, sortParams })
        );
        dispatch(storeMisconductsFilters(res.data.filters));
        dispatch(setSearch(query));
        dispatch(setFilters(filters));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
}
