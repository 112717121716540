import styled from 'styled-components';

import { font, breakpoints } from 'styles';

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25vh;

  @media ${breakpoints.mediumScreen} {
    /* 1: Menu width plus breakpoints content padding for optical centered loader */
    width: calc(100vw - 212px); /* 1 */
  }
`;

export const Paragraph = styled.p`
  font-weight: ${font.weight.bold};
`;
