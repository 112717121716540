import React from 'react';

const ArrowIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.707 1.293a1 1 0 010 1.414L6.414 8l5.293 5.293a1 1 0 01-1.414 1.414l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default ArrowIcon;
