import React from 'react';
import PropTypes from 'prop-types';
import { font } from 'styles';

import { StyledSpan } from './style';

const sizePropTypes = PropTypes.oneOf(Object.keys(font.size));
const weightPropTypes = PropTypes.oneOf(Object.keys(font.weight));

const propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.any,
  size: sizePropTypes,
  weight: weightPropTypes,
  color: PropTypes.string,
};

const InnerText = ({ component, children, weight, color, size, ...props }) => {
  return (
    <StyledSpan as={component} weight={weight} size={size} color={color} {...props}>
      {children}
    </StyledSpan>
  );
};

InnerText.propTypes = propTypes;

export default InnerText;
