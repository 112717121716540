import Tooltip from 'components/Tooltip';
import React from 'react';

import { propTypes, defaultPropTypes } from './props';

import {
  RadioButtonLabel,
  RealRadioButton,
  FakeRadioButton,
  Label,
  RadioButtonWithTooltip,
  RadioLabelWrapper,
} from './style';

const RadioButton = ({ isChecked, label, name, tooltip, optionId, ...props }) => {
  return (
    <RadioButtonWithTooltip>
      <RadioButtonLabel {...props}>
        <RealRadioButton defaultChecked={isChecked} name={`${name}-radio`} />
        <RadioLabelWrapper style={{ display: 'flex' }}>
          <FakeRadioButton />
          {label && <Label>{label}</Label>}
        </RadioLabelWrapper>
      </RadioButtonLabel>
      {tooltip && <Tooltip id={`${optionId}-radio`} content={tooltip} />}
    </RadioButtonWithTooltip>
  );
};

RadioButton.defaultProps = defaultPropTypes;
RadioButton.propTypes = propTypes;

export default RadioButton;
