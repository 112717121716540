import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Spinner from 'components/Spinner';
import WrapperWithMenu from 'components/WrapperWithMenu';
import SingleColumn from 'components/SingleColumn';

import { colors } from 'styles';

import { LoaderWrapper, Paragraph } from './style';
import { globalMessages } from 'translations';

const propTypes = {
  label: PropTypes.string,
};

const RouteLoader = ({ label }) => {
  const intl = useIntl();

  const labels = {
    1: intl.formatMessage(globalMessages.loading),
    2: intl.formatMessage(globalMessages.pleaseWait),
    3: intl.formatMessage(globalMessages.justMoment),
  };

  const getRandomLabel = () => {
    return labels[Math.floor(Math.random() * 3) + 1];
  };

  return (
    <WrapperWithMenu>
      <SingleColumn withNotifications={false}>
        <LoaderWrapper>
          <Spinner color={colors.darkYellow} size={48} />
          <Paragraph>{!label ? getRandomLabel() : label}</Paragraph>
        </LoaderWrapper>
      </SingleColumn>
    </WrapperWithMenu>
  );
};

RouteLoader.propTypes = propTypes;

export default RouteLoader;
