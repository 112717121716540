import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import { rem } from 'polished';

import { font, colors } from '../styles';

export const GlobalStyle = createGlobalStyle`
 ${styledNormalize}

 @font-face {
  font-weight: 400;
  font-family: 'Noto Sans';
  font-style: normal;
  font-display: swap;
  src: url('/fonts/NotoSans-Regular.ttf');
}

 @font-face {
  font-weight: 700;
  font-family: 'Noto Sans';
  font-style: bold;
  font-display: swap;
  src: url('/fonts/NotoSans-Bold.ttf');
}
 
 body {
  margin: 0;
  padding: 0;
  font-weight: ${font.weight.regular};
  font-size: ${rem(font.size.normal)};
  font-family: ${font.family};
  background: ${colors.lighterGray};
  
  &.Modal--open {
    overflow: hidden;
  }
 }
 
 * {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  &:before,
  &:after {
    box-sizing: border-box;
  }
 }
 
 button,
 input {
  font-family: inherit;
 }
 
 .root {
  position: relative;
  z-index: 10;
  min-height: 95vh;
 }
 
 .ModalPortal {
  z-index: 20;
 }
`;
