import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Button from 'components/Button';
import Typography, { typographySizePropTypes } from 'components/Typography';

import { HeadingWrapper, BackButton, BackArrowIcon } from './style';

const propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  withBackButton: PropTypes.bool,
  backAction: PropTypes.func,
  withoutSeparator: PropTypes.bool,
  marginBottom: PropTypes.number,
  history: PropTypes.object,
  size: typographySizePropTypes,
  tabletSize: typographySizePropTypes,
  component: PropTypes.string,
  withBackFilledButton: PropTypes.bool,
};

const defaultProps = {
  size: 'big',
  tabletSize: 'huge',
  component: 'h1',
  marginBottom: 36,
};

const PageHeader = ({
  label,
  withBackButton,
  backAction,
  withoutSeparator,
  marginBottom,
  size,
  tabletSize,
  component,
  history,
  withBackFilledButton,
}) => {
  const goBack = () => {
    if (backAction) {
      backAction();
    } else {
      history.goBack();
    }
  };

  const defineGoBackButton = () => {
    if (!withBackButton && !backAction) return;

    if (withBackFilledButton) {
      return (
        <Button onClick={goBack} isDark primary isIcon aria-label="Go back">
          <BackArrowIcon />
        </Button>
      );
    } else {
      return (
        <BackButton onClick={goBack}>
          <BackArrowIcon />
        </BackButton>
      );
    }
  };

  return (
    <HeadingWrapper withoutSeparator={withoutSeparator} marginBottom={marginBottom}>
      {defineGoBackButton()}
      <Typography
        component={component}
        size={size}
        tabletSize={tabletSize}
        bottomMargin={0}
      >
        {label}
      </Typography>
    </HeadingWrapper>
  );
};

PageHeader.propTypes = propTypes;
PageHeader.defaultProps = defaultProps;

export default withRouter(PageHeader);
