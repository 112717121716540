import api from 'api';

export const STATUS_INITIAL = 'initial';
export const STATUS_LOADING = 'loading';
export const STATUS_READY = 'ready';

// Actions
export const SET_SURVEY_EXISTS = 'survey.SET_SURVEY_EXISTS';
export const SET_LOADING = 'survey.SET_LOADING';
export const SET_READY = 'survey.SET_READY';

// Reducer
const initialState = {
  exists: null,
  status: STATUS_INITIAL,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_SURVEY_EXISTS:
      return {
        ...state,
        exists: action.payload,
        status: STATUS_READY,
      };
    case SET_LOADING:
      return {
        ...state,
        status: STATUS_LOADING,
      };
    case SET_READY:
      return {
        ...state,
        status: STATUS_READY,
      };
    default:
      return state;
  }
}

// Action Creators
export function setSurveyExists(exists) {
  return { type: SET_SURVEY_EXISTS, payload: exists };
}

export function setLoading() {
  return { type: SET_LOADING };
}

export function setReady() {
  return { type: SET_READY };
}

// Side Effects
export function fetchSurvey(path) {
  return async (dispatch) => {
    dispatch(setLoading());

    try {
      const surveyExists = await api.config
        .getSurvey(path)
        .then(() => true)
        .catch((err) => {
          if (err.status === 404) {
            return false;
          } else {
            throw err;
          }
        });

      dispatch(setSurveyExists(surveyExists));
    } catch (error) {
      console.error('An error occured while fetching survey: ', error); // eslint-disable-line no-console
      dispatch(setReady());
    }
  };
}
