import styled, { css } from 'styled-components';
import { rem } from 'polished';

import { colors, colorName, transitions, variables } from 'styles';

const ICON_SIZE = 40;
const DROPZONE_HEIGHT = 248;
const PROGRESS_MAX_WIDTH = 300;

export const FakeInputStyles = css`
  display: flex;
  align-items: center;
  margin: 0 0 ${rem(16)} 0;
  border: 1px solid ${colors.gray};
  border-radius: ${rem(variables.borderRadius)};
`;

export const DropzoneBox = styled.div`
  ${FakeInputStyles};
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: ${rem(DROPZONE_HEIGHT)};
  text-align: center;
  border: 1px solid
    ${({ isDragActive }) => (isDragActive ? colorName.secondary : colors.gray)};

  ${({ isChatUploader }) =>
    isChatUploader &&
    `
    align-items: center;
    margin: 0 ${rem(4)} 0 0;
    width: 100%;
    height: ${rem(ICON_SIZE)};
    border: 0;
    
    & > svg {
      width: ${rem(ICON_SIZE / 2)};
      height: ${rem(ICON_SIZE / 2)};
    }
  `};
`;

export const DropZoneWrapper = styled.div`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};

  ${({ isChatUploader }) =>
    isChatUploader &&
    `
    width: ${rem(ICON_SIZE - 6)};
    height: ${rem(ICON_SIZE)};
  `};
`;

export const IconWrapper = styled.div`
  width: ${rem(ICON_SIZE)};
  height: ${rem(ICON_SIZE)};
  margin: 0 0 ${rem(16)} 0;
  color: ${colorName.secondary};
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  max-width: ${rem(PROGRESS_MAX_WIDTH)};
  height: ${rem(6)};
  margin: 0 0 ${rem(16)} 0;
  overflow: hidden;
  background-color: ${colors.grayish};
  border-radius: ${rem(variables.borderRadius)};
`;

export const ProgressIndicator = styled.p`
  width: ${({ progress }) => rem(progress)};
  height: ${rem(6)};
  margin: 0;
  background-color: ${colorName.secondary};
  border-radius: ${rem(variables.borderRadius)};
  transition: background 0.8s ${transitions.spring};
`;

export const ProgressText = styled.span`
  margin: 0 ${rem(12)} 0 0;
`;

export const FileLabel = styled.span`
  margin: 0 ${rem(10)} 0 0;
`;

export const ProgressWrapperSkeleton = styled.div`
  width: 100%;
  max-width: ${rem(320)};
  height: ${rem(16)};
  margin: 0 auto;
`;

export const ModalFilesListWrapper = styled.div`
  margin-bottom: 32px;
`;

export const ModalButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
