import styled from 'styled-components';
import { rem, transitions } from 'polished';
import { colorName, colors, font, variables } from 'styles';
import { DropdownIndicator } from 'styles/commonStyles';
import { StyledButton } from 'components/Button/style';
import { globalSize } from '../../styles';

const ICON_SIZE = 20;
const CONTROL_HEIGHT = 44;
const SELECT_PADDING = 16;
const OPTION_HEIGHT = 32;
const MULTIVALUE_HEIGHT = 24;

export const SelectWrapper = styled.div`
  flex: 1 1 auto;
  margin: ${({ marginBottom }) => marginBottom && `0 0 ${rem(marginBottom)}`};
`;

export const StyledLabel = styled.label`
  display: block;
  margin: 0 0 ${rem(8)};
  color: ${({ isDark }) => (isDark ? colorName.white : colorName.black)};
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.small)};
`;

export const StyledControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${rem(CONTROL_HEIGHT)};
  padding: 0 0 0 ${rem(10)};
  font-weight: ${font.weight.regular};
  background-color: ${({ isDark }) => (isDark ? colors.mako : colorName.white)};
  border: 1px solid ${({ isMenuOpen }) => (isMenuOpen ? colors.navy : colors.gray)};
  border-radius: ${rem(variables.borderRadius)};
  outline: 0;
  transition: border ${transitions.uiTiming} ${transitions.spring};

  ${({ isDark, isMenuOpen }) =>
    isDark &&
    `
    border: 1px solid ${isMenuOpen ? colorName.white : colors.gray};
  `};

  ${({ isError, isMenuOpen }) =>
    isError &&
    !isMenuOpen &&
    `
    border: 1px solid ${colorName.error};
  `};
`;

export const IconWrapper = styled.span`
  width: ${rem(ICON_SIZE)};
  height: ${rem(ICON_SIZE)};
  color: ${colors.mediumGray};
`;

export const StyledInput = styled.div`
  .selectInput {
    margin-left: -${rem(2)};
    color: ${({ isDark }) => (isDark ? colorName.white : colorName.black)};
  }
`;

export const StyledMenu = styled.div`
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  margin-top: ${rem(-3)};
  background-color: ${({ isDark }) => (isDark ? colors.mako : colorName.white)};
  border: 1px solid ${({ isDark }) => (isDark ? colorName.white : colors.navy)};
  border-top: 0;
  border-radius: 0 0 ${rem(variables.borderRadius)} ${rem(variables.borderRadius)};
`;

export const StyledMenuDecorator = styled.div`
  margin: 0 ${({ icon }) => (icon ? rem(20 + ICON_SIZE) : rem(20))} ${rem(8)};
  border-top: 1px solid ${({ isDark }) => (isDark ? colors.darkestGray : colors.gray)};
`;

export const StyledMenuButtonWrapper = styled.div`
  height: auto;
  padding: ${rem(globalSize.defaultPadding)};
  text-align: right;

  & > ${StyledButton} {
    min-height: auto;
    margin: 0;
    padding: 0 ${rem(globalSize.defaultPadding / 3)};
    color: ${({ isDark }) => (isDark ? colorName.primary : colorName.secondary)};

    &:hover {
      color: ${({ isDark }) => (isDark ? colors.orange : colors.lightViolet)};
    }
  }
`;

export const StyledMenuList = styled.div`
  max-height: ${({ maxMenuHeight }) => rem(maxMenuHeight)};
  overflow: auto;
  background-color: ${({ isDark }) => (isDark ? colors.mako : colorName.white)};
  border-radius: inherit;
`;

export const StyledMultiValueContainer = styled.div`
  display: flex;
  height: ${rem(MULTIVALUE_HEIGHT)};
  margin: ${rem(2)};
  color: ${({ isDark }) => (isDark ? colorName.white : colors.darkGray)};
  background-color: ${({ isDark }) => (isDark ? colors.darkGray : colors.athensGray)};
  border-radius: ${rem(variables.borderRadius)};

  ${({ type }) =>
    type === 'read' &&
    `
    background-color: ${colors.lightBlue};
  `};

  ${({ type }) =>
    type === 'write' &&
    `
    background-color: ${colors.reddish};
  `};
`;

export const StyledMultiValueLabel = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${rem(4)} 0 ${rem(SELECT_PADDING)};
  overflow: hidden;
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.tiny)};
  line-height: 1;
  white-space: nowrap;
  text-transform: uppercase;
  text-overflow: ellipsis;
  border-radius: inherit;
`;

export const StyledOption = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(OPTION_HEIGHT)};
  padding: 0 0 0 ${rem(10)};
  color: ${({ isDark }) => (isDark ? colorName.white : colorName.black)};
  font-size: ${rem(font.size.small)};
  background-color: ${({ isFocused }) =>
    isFocused ? colors.maroonLight : 'transparent'};
  cursor: pointer;

  ${({ isDark, isFocused }) =>
    isDark &&
    `
      background-color: ${isFocused ? colors.darkGray : 'transparent'};
    `};
`;

export const StyledOptionLabel = styled.span`
  padding: 0 ${rem(10)};
`;

export const StyledPlaceholder = styled.div`
  position: absolute;
  top: 50%;
  color: ${colors.mediumGray};
  font-size: ${rem(font.size.small)};
  transform: translateY(-50%);
`;

export const StyledContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: ${rem(8)};
  }

  background-color: ${({ isDark }) => (isDark ? colors.mako : colorName.white)};
`;

export const StyledSingleValue = styled.div`
  color: ${({ isDark }) => (isDark ? colorName.white : colorName.black)};
  font-size: ${rem(font.size.small)};
`;

export const DropdownIndicatorIcon = styled.span`
  ${DropdownIndicator};
`;

const plainMessageStyles = (baseStyles) => ({
  ...baseStyles,
  padding: rem(SELECT_PADDING),
  fontSize: rem(font.size.small),
});

export const overrideSelectStyles = ({ minQueryLength }) => ({
  dropdownIndicator: (baseStyles, selectState) => {
    const {
      selectProps: { menuIsOpen, inputValue },
    } = selectState;
    const hasQuery = inputValue.length >= minQueryLength;
    const isInversed = menuIsOpen && hasQuery;

    return {
      ...baseStyles,
      padding: `${rem(4)} ${rem(14)} 0`,
      transition: 'transform .2s ease',
      transform: isInversed ? 'rotate(180deg)' : null,
      cursor: 'pointer',
    };
  },
  loadingIndicator: (baseStyles, selectState) => {
    const {
      selectProps: { inputValue },
    } = selectState;

    return {
      ...baseStyles,
      display: inputValue.length >= minQueryLength ? 'flex' : 'none',
    };
  },
  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    cursor: 'pointer',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: `${rem(4)} ${rem(8)}`,
  }),
  noOptionsMessage: plainMessageStyles,
  loadingMessage: plainMessageStyles,
});
