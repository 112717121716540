import React from 'react';
import PropTypes from 'prop-types';

import ArrowIcon from 'components/Svg/ArrowIcon';

import { NavArrowWrapper } from '../style';

const propTypes = {
  next: PropTypes.bool,
};

const CalendarNav = ({ next }) => (
  <NavArrowWrapper next={next}>
    <ArrowIcon />
  </NavArrowWrapper>
);

CalendarNav.propTypes = propTypes;

export default CalendarNav;
