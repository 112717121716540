import styled from 'styled-components';
import { rem } from 'polished';

import { TypographyElement } from 'components/Typography/style';
import { StyledTextarea } from 'components/Textarea/style';
import { StyledButton } from 'components/Button/style';

import { breakpoints } from 'styles';
import { SectionStyles } from 'styles/commonStyles';

export const SectionWrapper = styled.div`
  ${SectionStyles};

  & > ${TypographyElement} {
    @media ${breakpoints.smallScreen} {
      margin: 0 0 ${rem(40)} 0;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 ${rem(16)} 0;

  & > *:first-child {
    flex: 0 0 100%;
    margin: 0 0 ${rem(8)} 0;

    @media ${breakpoints.tablet} {
      flex: 0 0 ${rem(245)};
      margin: 0 ${rem(16)} 0 0;
    }
  }

  & > *:nth-child(2) {
    @media ${breakpoints.smallScreen} {
      width: 100%;
      margin: 0;
    }
  }

  @media ${breakpoints.tablet} {
    flex-direction: row;
  }
`;

export const AnonymizeWrapper = styled.div`
  text-align: right;

  ${StyledTextarea} {
    flex: 0 0 100%;
    margin: 0 0 ${rem(4)} 0;
  }

  ${StyledButton} {
    margin: 0 0 0 auto;
  }
`;
