import React from 'react';

function CircleTick(props) {
  return (
    <svg viewBox="0 0 20 20" {...props}>
      <title>CircleTick</title>
      <path
        fill="currentColor"
        d="M7.98 15.024l9.015-9.014-1.418-1.467-7.596 7.62-3.558-3.581L3.005 10l4.976 5.024zM10 0c1.827 0 3.518.457 5.072 1.37a9.478 9.478 0 013.558 3.558A9.835 9.835 0 0120 10a9.835 9.835 0 01-1.37 5.072 9.478 9.478 0 01-3.558 3.558A9.835 9.835 0 0110 20a9.835 9.835 0 01-5.072-1.37 9.657 9.657 0 01-3.558-3.582A9.79 9.79 0 010 10a9.79 9.79 0 011.37-5.048A9.842 9.842 0 014.952 1.37 9.79 9.79 0 0110 0z"
      />
    </svg>
  );
}

export default CircleTick;
