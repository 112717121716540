import api from 'api';
import { setToastMessage } from './toastMessages';
import { toastContext, toastType } from 'helpers/toastConstants';

// Actions
export const SET_VERSIONS = 'documentVersions.SET_VERSIONS';
export const SET_LOADING = 'documentVersions.SET_LOADING';
export const CLEAR_DATA = 'documentVersions.CLEAR_DATA';

// Reducer
const initialState = {
  isLoading: false,
  versions: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_VERSIONS:
      return {
        ...state,
        versions: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function setVersions(payload) {
  return { type: SET_VERSIONS, payload };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

export function clearData() {
  return { type: CLEAR_DATA };
}

// Side Effects
export function getDocumentVersions(documentId) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const versions = await api.documents.getVersions(documentId);
      dispatch(setVersions(versions));
    } catch (error) {
      dispatch(
        setToastMessage({
          content: {
            translationKey: 'documentGetVersionsFailed',
          },
          context: toastContext.DOCUMENT_EDIT,
          type: toastType.ALERT,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
}
