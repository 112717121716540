import styled from 'styled-components';
import { rem } from 'polished';

import CompanyLogo from 'components/CompanyLogo';

import { breakpoints } from 'styles';

const MAX_WRAPPER_WIDTH = 440;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: ${rem(MAX_WRAPPER_WIDTH)};
  height: 100vh;
  margin: 0 auto;
  padding: ${rem(16)};

  @media ${breakpoints.tablet} {
    height: auto;
    margin: ${rem(62)} auto 0;
  }
`;

export const Logo = styled(CompanyLogo)`
  display: block;
  height: ${rem(84)};
  margin: 0 auto ${rem(76)};

  @media ${breakpoints.smallScreen} {
    margin: 0 0 ${rem(76)};
  }
`;
