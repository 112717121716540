import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { STATUS_READY } from 'stores/tenantProperties';

import { LogoImg } from './style';

const propTypes = {
  className: PropTypes.string,
};

const CompanyLogoSmall = ({ className }) => {
  const { status, properties } = useSelector((state) => state.tenantProperties);

  return (
    <LogoImg
      src={properties.smallLogoUrl}
      className={className}
      alt="Logotype"
      visible={status === STATUS_READY}
    />
  );
};

CompanyLogoSmall.propTypes = propTypes;

export default CompanyLogoSmall;
