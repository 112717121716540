import Notification from 'components/Notification';
import React from 'react';
import PropTypes from 'prop-types';

import { notificationComponentShape } from 'models';

import CloseIcon from 'components/Svg/CloseIcon';

import {
  ToastNotificationBar,
  ToastNotificationButton,
  ToastNotificationIconWrapper,
} from 'styles/commonStyles';

const propTypes = {
  ...notificationComponentShape,
  closeHandler: PropTypes.func.isRequired,
};

const ToastNotification = ({ children, type, isNew, closeHandler, ...props }) => {
  return (
    <ToastNotificationBar role="alert" type={type} {...props}>
      <Notification type={type} isNew={isNew} iconWrapper={ToastNotificationIconWrapper}>
        {children}
      </Notification>

      <ToastNotificationButton onClick={closeHandler} type={type}>
        <CloseIcon />
      </ToastNotificationButton>
    </ToastNotificationBar>
  );
};

ToastNotification.propTypes = propTypes;

export default ToastNotification;
