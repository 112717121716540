import React from 'react';

function MessagesIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="transparent"
        stroke="#A2A2A8"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M5.4 4.22h21.2c.62 0 1.22.25 1.67.69.44.44.69 1.04.69 1.67v12.96c0 .62-.25 1.22-.69 1.67-.44.44-1.04.69-1.67.69h-9.42l-8.24 5.89V21.9H5.4c-.62 0-1.22-.25-1.67-.69a2.36 2.36 0 01-.69-1.67V6.58c0-.62.25-1.22.69-1.67s1.05-.69 1.67-.69h0z"
      />
      <path
        className="stroke"
        fill="transparent"
        stroke="#A2A2A8"
        strokeLinecap="square"
        strokeMiterlimit="10"
        d="M6.58 17.77h7.66M17.77 17.77h7.66M6.58 14.23h13.54M23.66 14.23h1.77"
      />
    </svg>
  );
}

export default MessagesIcon;
