import { Row, SectionWrapper } from 'components/MisconductDetails/style';
import Typography from 'components/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { colors, font } from 'styles';
import * as CONST from 'components/MisconductFormParts/constants';
import { getDateRangeStr, getDateStr } from 'helpers';
import Button from 'components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { globalMessages, reportMessages } from 'translations';
import {
  DocumentLink,
  HintWrapper,
  Separator,
  StepsButtonWrapper,
  SummarySectionHeader,
  TermsCheckboxWrapper,
} from './style';
import FilesList from 'components/FileUploader/parts/FilesList';
import PropTypes from 'prop-types';
import PenIcon from 'components/Svg/PenIcon';
import Checkbox from 'components/Checkbox';
import { messages } from './messages';
import { useRouteMatch } from 'react-router';
import { ROUTING } from 'routing';
import { sendMisconduct, setPrivacyPolicyAccepted } from 'stores/anonymousMisconductForm';
import MarkdownParser from 'components/MarkdownParser';
import { CancelButton, PrimaryButton, SimpleModal } from 'components/SimpleModal';
import WarningOutlineIcon from 'components/Svg/WarningOutlineIcon';
import { useApi } from '../../api';

const propTypes = {
  goToSpecificStep: PropTypes.func.isRequired,
};

const ReportSummary = ({ goToSpecificStep }) => {
  const dispatch = useDispatch();
  const { api } = useApi();
  const { formSummary } = useSelector((state) => state.misconductForm);
  const intl = useIntl();
  const { path } = useRouteMatch();
  const isAnonymousReport = [ROUTING.REPORT_MISCONDUCT, ROUTING.SURVEYS_SURVEY].includes(
    path
  );
  const { isLoading, privacyPolicyAccepted, files } = useSelector(
    (state) => state.anonymousMisconductForm
  );
  const { properties } = useSelector((state) => state.tenantProperties);
  const [editStepId, setEditStepId] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const termsLink = (
    <DocumentLink
      href={properties.termsOfUseFile}
      rel="noopener noreferrer"
      target="_blank"
    >
      {properties.termsOfUseLabel}
    </DocumentLink>
  );

  const privacyPolicyLink = (
    <DocumentLink
      href={properties.privacyPolicyFile}
      rel="noopener noreferrer"
      target="_blank"
    >
      {properties.privacyPolicyLabel}
    </DocumentLink>
  );

  const privacyPolicyCheckboxChange = (event) => {
    dispatch(setPrivacyPolicyAccepted(event.target.checked));
  };

  const handleSubmit = () => {
    dispatch(sendMisconduct(api));
  };

  const getAnswer = (type, answer, selectedAnswer) => {
    switch (type) {
      case CONST.DATE:
        return Array.isArray(answer)
          ? getDateRangeStr(answer)
          : getDateStr(answer, 'DD-MM-YYYY');
      case CONST.DATE_RANGE:
        return getDateRangeStr(answer);
      case CONST.RADIO:
        return selectedAnswer.label;
      case CONST.SELECT:
        return selectedAnswer.label;
      default:
        return answer;
    }
  };

  return (
    <>
      <Typography size="huge" bottomMargin={24} testingId="summary-header">
        {intl.formatMessage(reportMessages.summaryHeader)}
      </Typography>
      {!!formSummary.description?.length && (
        <HintWrapper>
          <MarkdownParser
            source={formSummary.description}
            fontSize={font.size.small}
            color={colors.darkestGray}
          />
        </HintWrapper>
      )}
      <Separator />
      {formSummary.submitted_steps.map((step) =>
        step.sections.map((section) => {
          return (
            <SectionWrapper key={section.id}>
              <SummarySectionHeader>
                <Typography size="medium" tabletSize="large" bottomMargin={32}>
                  {section.label}
                </Typography>
                <Button
                  secondary
                  onClick={() => setEditStepId(step.id)}
                  data-testid={`${section.id}-edit`}
                  leftIcon={<PenIcon />}
                >
                  {intl.formatMessage(globalMessages.edit)}
                </Button>
              </SummarySectionHeader>

              {section?.inputs.map(({ id, label, type, answer, selectedAnswer }) => {
                return (
                  <Row key={id}>
                    <Typography size="normal" bottomMargin={0}>
                      {label}
                    </Typography>

                    <Typography size="normal" weight="bold" bottomMargin={0}>
                      {answer.length ? getAnswer(type, answer, selectedAnswer) : '—'}
                    </Typography>
                  </Row>
                );
              })}
            </SectionWrapper>
          );
        })
      )}
      <SectionWrapper>
        <SummarySectionHeader>
          <Typography size="medium" tabletSize="large" bottomMargin={24}>
            {intl.formatMessage(reportMessages.whistleblowerFiles)}
          </Typography>
          <Button secondary onClick={() => setEditStepId('files')} leftIcon={<PenIcon />}>
            {intl.formatMessage(globalMessages.edit)}
          </Button>
        </SummarySectionHeader>

        {files.length ? (
          <FilesList
            files={files}
            // fileUrlResolver={api.misconduct.getMisconductFile}
            isDownloadable={false}
            isAnonymizable={false}
            misconductId={formSummary.id}
          />
        ) : (
          '—'
        )}
      </SectionWrapper>
      <TermsCheckboxWrapper>
        {isAnonymousReport && (
          <Typography as="div" size="small" color={colors.mediumGray} bottomMargin={16}>
            <Checkbox
              isDark={true}
              isChecked={privacyPolicyAccepted}
              onChange={privacyPolicyCheckboxChange}
              testingId={'regulations-checkbox'}
              label={
                <FormattedMessage
                  {...messages.privacyPolicyDeclaration}
                  values={{ privacyPolicyLink, termsLink }}
                />
              }
            />
          </Typography>
        )}
      </TermsCheckboxWrapper>

      <StepsButtonWrapper>
        <Button
          primary
          isDark
          isFluid
          onClick={handleSubmit}
          disabled={isAnonymousReport ? !privacyPolicyAccepted : false}
          isProcessing={isLoading}
          data-testid="submit-button"
        >
          {intl.formatMessage(globalMessages.submit)}
        </Button>
      </StepsButtonWrapper>

      {editStepId && (
        <SimpleModal
          isOpen={true}
          closeHandler={() => setEditStepId('')}
          heading={intl.formatMessage(messages.editModalHeading)}
          description={intl.formatMessage(messages.editModalDescription)}
          icon={WarningOutlineIcon}
        >
          <CancelButton onClick={() => setEditStepId('')} />

          <PrimaryButton
            data-testid="confirm-edit"
            onClick={() =>
              editStepId !== 'files' ? goToSpecificStep(editStepId) : goToSpecificStep()
            }
          >
            {intl.formatMessage(globalMessages.edit)}
          </PrimaryButton>
        </SimpleModal>
      )}
    </>
  );
};

ReportSummary.propTypes = propTypes;
export default ReportSummary;
