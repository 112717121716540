import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
  SORT_DIRECTION_DESCENDING,
} from 'helpers/sharedConstants';

const DEFAULT_SORT_PARAMS = { key: 'date_created', direction: SORT_DIRECTION_DESCENDING };

// Actions
export const STORE_DOCUMENTS_LIST = 'allDocuments.STORE_DOCUMENTS_LIST';
export const STORE_DOCUMENTS_LIST_META = 'allDocuments.STORE_DOCUMENTS_LIST_META';
export const SET_LOADING = 'allDocuments.SET_LOADING';

// Reducer
const initialState = {
  isLoading: false,
  documentsList: [],
  documentsListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
    sortParams: DEFAULT_SORT_PARAMS,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_DOCUMENTS_LIST:
      return {
        ...state,
        documentsList: action.payload,
      };
    case STORE_DOCUMENTS_LIST_META:
      return {
        ...state,
        documentsListMeta: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function storeDocuments(documents) {
  return { type: STORE_DOCUMENTS_LIST, payload: documents };
}

export function storeDocumentsMeta(meta) {
  return { type: STORE_DOCUMENTS_LIST_META, payload: meta };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

// Side Effects
export function getDocuments({
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
  sortParams = DEFAULT_SORT_PARAMS,
  query,
}) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return api.documents
      .getDocuments({
        page,
        perPage,
        sortBy: sortParams?.key,
        sortOrder: sortParams?.direction,
        query,
      })
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(storeDocuments(res.data.items));
        dispatch(
          storeDocumentsMeta({
            page: res.data.current_page,
            perPage,
            totalPages: res.data.pages,
            sortParams,
          })
        );
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
}
