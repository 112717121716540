import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { injectIntl } from 'react-intl';

import { dismissToastMessage } from 'stores/toastMessages';
import ToastNotification from 'components/ToastNotification';

import { messages } from './messages';

const propTypes = {
  location: PropTypes.object,
  context: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      translationKey: PropTypes.string,
      param: PropTypes.string,
      message: PropTypes.string,
    }),
  ]),
  type: PropTypes.string,
  dismissToastMessage: PropTypes.func,
  intl: PropTypes.object,
};

const ToastMessages = ({ context, content, type, dismissToastMessage, intl }) => {
  const { pathname } = useLocation();
  const isToastRendered = context.length > 0 && pathname.includes(context);

  useEffect(() => {
    isToastRendered && window.scrollTo(0, 0);

    return () => {
      isToastRendered && dismissToastMessage();
    };
  }, [dismissToastMessage, isToastRendered]);

  if (isToastRendered) {
    return (
      <ToastNotification type={type} closeHandler={dismissToastMessage}>
        {content.translationKey
          ? intl.formatMessage(messages[content.translationKey], {
              param: content.param,
              param2: content.param2,
            })
          : content.message}
      </ToastNotification>
    );
  }

  return null;
};

ToastMessages.propTypes = propTypes;

const mapStateToProps = (state) => ({
  context: state.toastMessages.context,
  content: state.toastMessages.content,
  type: state.toastMessages.type,
});
const mapDispatchToProps = { dismissToastMessage };

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ToastMessages));
