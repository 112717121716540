import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { DropdownIndicatorIcon } from '../style';

const propTypes = {
  selectProps: PropTypes.shape({
    isDark: PropTypes.bool,
  }),
};

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIndicatorIcon isDark={props.selectProps.isDark} />
    </components.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = propTypes;
