import React from 'react';

const CoIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9 5a2 2 0 100 4v1a3 3 0 113-3l-1.122.688A2 2 0 009 5zm1.5 11h-6a.5.5 0 01-.5-.5v-1c0-.746.472-1.626 1.293-2.3.66-.542 1.563-.972 2.707-1.132l.21.98c-1.007.129-1.76.496-2.282.924-.669.55-.928 1.17-.928 1.528v.5h4l1.5 1zm3.854-8.854a.5.5 0 00-.708 0l-.328.329A3.5 3.5 0 0110.843 8.5H10.5a.5.5 0 00-.5.5v2.441a4.5 4.5 0 001.884 3.662l1.825 1.304a.5.5 0 00.582 0l1.825-1.304A4.5 4.5 0 0018 11.441V9a.5.5 0 00-.5-.5h-.343a3.5 3.5 0 01-2.475-1.025l-.328-.329zM11 9.497a4.5 4.5 0 003-1.29 4.5 4.5 0 003 1.29v1.944a3.5 3.5 0 01-1.466 2.849L14 15.386l-1.534-1.096A3.5 3.5 0 0111 11.442V9.496z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default CoIcon;
