import React from 'react';
import PropTypes from 'prop-types';

import Modal, { StandardModalContent } from 'components/Modal';
import LargeGroupIcon from 'components/Svg/LargeGroupIcon';

import { Wrapper } from './styles';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  icon: PropTypes.func,
  heading: PropTypes.node.isRequired,
  description: PropTypes.node,
  isNarrowContent: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  icon: LargeGroupIcon,
};

export const InstructionModal = ({
  isOpen,
  closeHandler,
  children,
  isNarrowContent,
  ...props
}) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeHandler}
    isFullWidth
    isFullHeight
    isNarrowContent={isNarrowContent}
    {...props}
  >
    <Wrapper>
      <StandardModalContent isNarrowContent={isNarrowContent} align="left">
        {children}
      </StandardModalContent>
    </Wrapper>
  </Modal>
);

InstructionModal.propTypes = propTypes;
InstructionModal.defaultProps = defaultProps;
