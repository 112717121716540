import { defineMessages } from 'react-intl.macro';

export const globalMessages = defineMessages({
  language: {
    id: 'commonMessages.language',
    defaultMessage: 'Language',
    description: 'Login Language phrase',
  },
  email: {
    id: 'commonMessages.email',
    defaultMessage: 'Email',
    description: 'Email Input label',
  },
  fullName: {
    id: 'commonMessages.fullName',
    defaultMessage: 'Full Name',
    description: 'Full name Input label',
  },
  phone: {
    id: 'commonMessages.phone',
    defaultMessage: 'Phone',
    description: 'Phone Input label',
  },
  password: {
    id: 'commonMessages.password',
    defaultMessage: 'Password',
    description: 'Password Input label',
  },
  forgotPassword: {
    id: 'commonMessages.forgotPassword',
    defaultMessage: 'Forgot your password?',
    description: 'Link to reset password',
  },
  next: {
    id: 'commonMessages.next',
    defaultMessage: 'Next',
    description: 'Mainly used for button labels',
  },
  login: {
    id: 'commonMessages.login',
    defaultMessage: 'Log in',
    description: 'Login button label',
  },
  logTab: {
    id: 'commonMessages.logTab',
    defaultMessage: 'Log',
    description: 'Log tab label',
  },
  goToLoginPage: {
    id: 'commonMessages.goToLoginPage',
    defaultMessage: 'Go to Login Page',
    description: 'Button Go to Login Page',
  },
  goToDashboard: {
    id: 'commonMessages.goToDashboard',
    defaultMessage: 'Go to dashboard',
    description: 'Button Go to Dashboard Page',
  },
  cancel: {
    id: 'commonMessages.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel Action',
  },
  ok: {
    id: 'commonMessages.ok',
    defaultMessage: 'OK',
    description: 'OK Action',
  },
  accept: {
    id: 'commonMessages.accept',
    defaultMessage: 'Accept',
    description: 'Accept Action',
  },
  close: {
    id: 'commonMessages.close',
    defaultMessage: 'Close',
    description: 'Close Icon Label',
  },
  save: {
    id: 'commonMessages.save',
    defaultMessage: 'Save',
    description: 'Save Button Label',
  },
  saveChanges: {
    id: 'commonMessages.saveChanges',
    defaultMessage: 'Save changes',
    description: 'Save Changes Button Label',
  },
  saveChangesHeader: {
    id: 'commonMessages.saveChangesHeader',
    defaultMessage: 'Saving changes',
    description: 'Save Changes Modal Header',
  },
  saveAndReturn: {
    id: 'commonMessages.saveAndReturn',
    defaultMessage: 'Save and return to login page',
    description: 'Save Changes and return  Modal Header',
  },
  saveChangesSubheader: {
    id: 'commonMessages.saveChangesSubheader',
    defaultMessage:
      'Are you sure? You will not be able to access earlier version of the data. Please note that all changes will be tracked in the log.',
    description: 'Save Changes Modal Subheader',
  },
  back: {
    id: 'commonMessages.back',
    defaultMessage: 'Back',
    description: 'Back Button Label',
  },
  loading: {
    id: 'commonMessages.loading',
    defaultMessage: 'Loading...',
    description: 'Loading Label no 1',
  },
  pleaseWait: {
    id: 'commonMessages.pleaseWait',
    defaultMessage: 'Please wait...',
    description: 'Loading Label no 2',
  },
  justMoment: {
    id: 'commonMessages.justMoment',
    defaultMessage: 'Just a moment...',
    description: 'Loading Label no 3',
  },
  returnToLogin: {
    id: 'commonMessages.returnToLogin',
    defaultMessage: 'Return to login page',
    description: 'Return to login page button label',
  },
  tryDesktopVersion: {
    id: 'commonMessages.tryDesktopVersion',
    defaultMessage: 'Try desktop version',
    description: 'Feature not available header',
  },
  tryHigherResolution: {
    id: 'commonMessages.tryHigherResolution',
    defaultMessage: 'Try higher resolution',
    description:
      'Feature not available header (version not targeted to device but device width)',
  },
  featureUnavailable: {
    id: 'commonMessages.featureUnavailable',
    defaultMessage: 'This feature is unavailable on mobile devices',
    description: 'Feature not available subheader',
  },
  featureUnavailableOnResolution: {
    id: 'commonMessages.featureUnavailableOnResolution',
    defaultMessage: 'This feature is unavailable on lower resolutions.',
    description: 'Feature not available subheader (Resolution targeted)',
  },
  returnToDashboard: {
    id: 'commonMessages.returnToDashboard',
    defaultMessage: 'Return to dashboard',
    description: 'Feature not button label',
  },
  goBack: {
    id: 'commonMessages.goBack',
    defaultMessage: 'Go back',
    description: 'Go back button label',
  },
  continue: {
    id: 'commonMessages.continue',
    defaultMessage: 'Continue',
    description: 'Continue button Label',
  },
  active: {
    id: 'commonMessages.active',
    defaultMessage: 'Active',
    description: 'Active Pill label',
  },
  inactive: {
    id: 'commonMessages.inactive',
    defaultMessage: 'Inactive',
    description: 'Inactive Pill label',
  },
  invited: {
    id: 'commonMessages.invited',
    defaultMessage: 'Invited',
    description: 'Invited Pill label',
  },
  confirmed: {
    id: 'commonMessages.confirmed',
    defaultMessage: 'Confirmed',
    description: 'Confirmed Status for Pills',
  },
  archived: {
    id: 'commonMessages.archived',
    defaultMessage: 'Archived',
    description: 'Archived Status for Pills',
  },
  available: {
    id: 'commonMessages.available',
    defaultMessage: 'Available',
    description: 'Available Status for Pills',
  },
  overdue: {
    id: 'commonMessages.overdue',
    defaultMessage: 'Overdue',
    description: 'Overdue Status for Pills',
  },
  pending: {
    id: 'commonMessages.pending',
    defaultMessage: 'Pending',
    description: 'Pending Status for Pills',
  },
  new: {
    id: 'commonMessages.new',
    defaultMessage: 'New',
    description: 'New Status for Pills',
  },
  edit: {
    id: 'commonMessages.edit',
    defaultMessage: 'Edit',
    description: 'Edit Header',
  },
  newPassword: {
    id: 'commonMessages.newPassword',
    defaultMessage: 'New password',
    description: 'Password input label',
  },
  confirmPassword: {
    id: 'commonMessages.confirmPassword',
    defaultMessage: 'Confirm password',
    description: 'Confirm Password input label',
  },
  contact: {
    id: 'commonMessages.contact',
    defaultMessage: 'Contact EY',
    description: 'Contact Button label',
  },
  infoTab: {
    id: 'commonMessages.infoTab',
    defaultMessage: 'Info',
    description: 'Info Tab Label',
  },
  accessTab: {
    id: 'commonMessages.accessTab',
    defaultMessage: 'Access',
    description: 'Access Tab Label',
  },
  remindersTab: {
    id: 'commonMessages.remindersTab',
    defaultMessage: 'Reminders',
    description: 'Misconduct Reminders Tab Label',
  },
  exportTo: {
    id: 'commonMessages.exportTo',
    defaultMessage: 'Export to',
    description: 'Export to button label',
  },
  preferredLanguage: {
    id: 'commonMessages.preferredLanguage',
    defaultMessage: 'Preferred language',
    description: 'Preferred language header',
  },
  chooseLanguage: {
    id: 'commonMessages.chooseLanguage',
    defaultMessage: 'Please choose your preferred language.',
    description: 'Preferred language subheader',
  },
  tableHeaderName: {
    id: 'commonMessages.tableHeaderName',
    defaultMessage: 'Name',
    description: 'Sorting Table header',
  },
  tableHeaderOwner: {
    id: 'commonMessages.tableHeaderOwner',
    defaultMessage: 'Owner',
    description: 'Sorting Table header',
  },
  tableHeaderStatus: {
    id: 'commonMessages.tableHeaderStatus',
    defaultMessage: 'Status',
    description: 'Sorting Table header',
  },
  tableHeaderVersion: {
    id: 'commonMessages.tableHeaderVersion',
    defaultMessage: 'Version',
    description: 'Sorting Table header',
  },
  tableHeaderConfirmations: {
    id: 'commonMessages.tableHeaderConfirmations',
    defaultMessage: 'Confirmations',
    description: 'Sorting Table header',
  },
  tableHeaderDateAdded: {
    id: 'commonMessages.tableHeaderDateAdded',
    defaultMessage: 'Date added',
    description: 'Sorting Table header',
  },
  tableHeaderDueDate: {
    id: 'commonMessages.tableHeaderDueDate',
    defaultMessage: 'Due date',
    description: 'Sorting Table header',
  },
  tableHeaderReminder: {
    id: 'commonMessages.tableHeaderReminder',
    defaultMessage: 'Reminder',
    description: 'Sorting Table header',
  },
  createNewDocument: {
    id: 'commonMessages.createNewDocument',
    defaultMessage: 'Create new document',
    description: 'Create New Document button label',
  },
  misconductsTitle: {
    id: 'commonMessages.misconductsTitle',
    defaultMessage: 'New and recently edited reports',
    description: 'Misconduct header title',
  },
  noDocumentsDesc: {
    id: 'commonMessages.noDocumentsDesc',
    defaultMessage: 'There are no new documents that need your confirmation.',
    description: 'Dashboard documents empty state description',
  },
  noMisconductsDesc: {
    id: 'commonMessages.noMisconductsDesc',
    defaultMessage: 'There are no new reports.',
    description: 'Dashboard misconducts empty state description',
  },
  noNotificationsDesc: {
    id: 'commonMessages.noNotificationsDesc',
    defaultMessage: 'There are no new notifications',
    description: 'Dashboard notifications empty state description',
  },
  noNotificationsTitle: {
    id: 'commonMessages.noNotificationsTitle',
    defaultMessage: 'You are up to date',
    description: 'Dashboard notifications empty state title',
  },
  notificationsTitle: {
    id: 'commonMessages.notificationsTitle',
    defaultMessage: 'Notifications',
    description: 'Notifications section title',
  },
  dateAddedLabel: {
    id: 'commonMessages.dateAddedLabel',
    defaultMessage: 'Date added',
    description: 'Date added label',
  },
  descriptionLabel: {
    id: 'commonMessages.descriptionLabel',
    defaultMessage: 'Description of the document',
    description: 'Description label',
  },
  dueDateLabel: {
    id: 'commonMessages.dueDateLabel',
    defaultMessage: 'Due date',
    description: 'Due date label',
  },
  versionLabel: {
    id: 'commonMessages.versionLabel',
    defaultMessage: 'Version',
    description: 'Version label',
  },
  notesHeading: {
    id: 'commonMessages.notesHeading',
    defaultMessage: '[TD] Note',
    description: 'Notes section heading',
  },
  notesInfo: {
    id: 'commonMessages.notesInfo',
    defaultMessage: '[TD] Make sure no one else is editing the note right now',
    description: 'Notes info tip',
  },
  filesHeading: {
    id: 'commonMessages.filesHeading',
    defaultMessage: 'Files',
    description: 'Files section heading',
  },
  whistleblowerFiles: {
    id: 'commonMessages.whistleblowerFiles',
    defaultMessage: '[TD] Files uploaded during the communication with the whistleblower',
    description: 'Whistleblower Files section heading',
  },
  chatFiles: {
    id: 'commonMessages.chatFiles',
    defaultMessage: 'Chat files',
    description: 'Chat files heading',
  },
  yes: {
    id: 'commonMessages.yes',
    defaultMessage: 'Yes',
    description: 'Yes',
  },
  updateFiles: {
    id: 'commonMessages.updateFiles',
    defaultMessage: 'Update files',
    description: 'File update button label',
  },
  notFoundHeader: {
    id: 'commonMessages.notFoundHeader',
    defaultMessage: 'Ooops! Page not Found!',
    description: '404 title',
  },
  notFoundMessage: {
    id: 'commonMessages.notFoundMessage',
    defaultMessage: 'Seems like the page you are looking for does not exist.',
    description: '404 message',
  },
  sessionExpiredHeader: {
    id: 'commonMessages.sessionExpiredHeader',
    defaultMessage: 'Your session has expired',
    description: 'session expired title',
  },
  sessionExpiredMessage: {
    id: 'commonMessages.sessionExpiredMessage',
    defaultMessage: 'Please log in again',
    description: 'session expired message',
  },
  coSessionExpiredHeader: {
    id: 'commonMessages.coSessionExpiredHeader',
    defaultMessage: 'Your session as Compliance Officer has expired',
    description: 'session as officer expired title',
  },
  coSessionExpiredMessage: {
    id: 'commonMessages.coSessionExpiredMessage',
    defaultMessage: 'Please log in into Compliance Officer view again',
    description: 'session as officer expired message',
  },
  unauthorizedHeader: {
    id: 'commonMessages.unauthorizedHeader',
    defaultMessage: "[TD] You can't access this page",
    description: 'unauthorized page title',
  },
  unauthorizedMessage: {
    id: 'commonMessages.unauthorizedMessage',
    defaultMessage: '[TD] Please ask your compliance officer to get access to this page',
    description: 'unauthorized message',
  },
  oneDay: {
    id: 'commonMessages.oneDay',
    defaultMessage: '1 day before due date',
    description: 'Document Alert Interval',
  },
  twoDays: {
    id: 'commonMessages.twoDays',
    defaultMessage: '2 days before due date',
    description: 'Document Alert Interval',
  },
  threeDays: {
    id: 'commonMessages.threeDays',
    defaultMessage: '3 days before due date',
    description: 'Document Alert Interval',
  },
  fourDays: {
    id: 'commonMessages.fourDays',
    defaultMessage: '4 days before due date',
    description: 'Document Alert Interval',
  },
  fiveDays: {
    id: 'commonMessages.fiveDays',
    defaultMessage: '5 days before due date',
    description: 'Document Alert Interval',
  },
  sixDays: {
    id: 'commonMessages.sixDays',
    defaultMessage: '6 days before due date',
    description: 'Document Alert Interval',
  },
  sevenDays: {
    id: 'commonMessages.sevenDays',
    defaultMessage: '7 days before due date',
    description: 'Document Alert Interval',
  },
  twoWeeks: {
    id: 'commonMessages.twoWeeks',
    defaultMessage: '2 weeks before due date',
    description: 'Document Alert Interval',
  },
  threeWeeks: {
    id: 'commonMessages.threeWeeks',
    defaultMessage: '3 weeks before due date',
    description: 'Document Alert Interval',
  },
  fourWeeks: {
    id: 'commonMessages.fourWeeks',
    defaultMessage: '4 weeks before due date',
    description: 'Document Alert Interval',
  },
  twoMonths: {
    id: 'commonMessages.twoMonths',
    defaultMessage: '2 months before due date',
    description: 'Document Alert Interval',
  },
  threeMonths: {
    id: 'commonMessages.threeMonths',
    defaultMessage: '3 months before due date',
    description: 'Document Alert Interval',
  },
  documentConfirmationsTab: {
    id: 'commonMessages.documentConfirmationsTab',
    defaultMessage: 'Confirmations',
    description: 'Document confirmations tab',
  },
  minLengthHelperText: {
    id: 'commonMessages.minLengthHelperText',
    defaultMessage: 'Minimum number of characters: {minLength}',
    description: 'Min. length helper text',
  },
  submit: {
    id: 'commonMessages.submit',
    defaultMessage: 'Submit',
    description: 'Submit button',
  },
  caseFiles: {
    id: 'commonMessages.caseFiles',
    defaultMessage: 'Report folder',
    description: 'Case files tab label',
  },
  officersChat: {
    id: 'commonMessages.officersChat',
    defaultMessage: 'Chat',
    description: 'Officers Chat tab label',
  },
  whistleblowerDetails: {
    id: 'commonMessages.whistleblowerDetails',
    defaultMessage: 'Whistleblower details',
    description: 'whistleblower details tab label',
  },
  sendTokenTo: {
    id: 'commonMessages.sendTokenTo',
    defaultMessage: 'Send report token to:',
    description: 'Send token to whistleblower label',
  },
  officerLogin: {
    id: 'commonMessages.officerLogin',
    defaultMessage: 'Login for users with access rights',
    description: 'Toggle button label',
  },
  nextStep: {
    id: 'commonMessages.nextStep',
    defaultMessage: 'Go to next step',
    description: 'Mainly used for button labels',
  },
  reportSubmitted: {
    id: 'AddReport.reportSubmitted',
    defaultMessage: 'Report has been submitted',
    description: 'Modal Heading',
  },
  writeDownYourToken: {
    id: 'TokenModal.writeDownYourToken',
    defaultMessage:
      'Write down your token to at it will allow you to anonymously log back in and follow-up on your report.',
    description: 'Modal subheader',
  },
  uponReviewing: {
    id: 'TokenModal.uponReviewing',
    defaultMessage:
      'Upon review of your report, further information might be required. Please log back in regularly to check report updates. You will be able to chat with us and upload files without disclosing your identity.',
    description: 'Modal subheader second part',
  },
  pleaseNote: {
    id: 'TokenModal.pleaseNote',
    defaultMessage:
      'Please note that you will not have a possibility to come back to this screen!',
    description: 'Modal Red text under subheader',
  },
  leaveEmail: {
    id: 'TokenModal.leaveEmail',
    defaultMessage:
      'Leave your personal data to get whistleblower protection. You may as well leave your email address to get a message including the token code and to stay informed about updates.',
    description: 'Checkbox label',
  },
  hideEmail: {
    id: 'TokenModal.hideEmail',
    defaultMessage: '[TD] Hide your email',
    description: 'Checkbox label',
  },
  provideEmail: {
    id: 'TokenModal.provideEmail',
    defaultMessage:
      'You can check report updates anonymously using the token. Provide an email address to receive update notifications. Your report and personal data are still kept confidential.',
    description: 'Email Notification header',
  },
  downloadFile: {
    id: 'MisconductForm.downloadFile',
    defaultMessage: 'Download guidelines',
    description: 'file link',
  },
  selectPlaceholder: {
    id: 'commonMessages.selectPlaceholder',
    defaultMessage: 'Select...',
    description: 'Select UI placeholder',
  },
  saveNote: {
    id: 'commonMessages.saveNote',
    defaultMessage: '[TD] Save note',
    description: 'Save note',
  },
  filters: {
    id: 'commonMessages.filters',
    defaultMessage: 'Filters',
    description: 'Filters button text',
  },
  exit: {
    id: 'commonMessages.exit',
    defaultMessage: 'Exit',
    description: 'Exit',
  },
  clear: {
    id: 'commonMessages.clear',
    defaultMessage: 'Clear',
    description: 'Clear',
  },
  clearAll: {
    id: 'commonMessages.clearAll',
    defaultMessage: 'Clear all',
    description: 'Clear all',
  },
  refresh: {
    id: 'commonMessages.refresh',
    defaultMessage: 'Refresh',
    description: 'Refresh',
  },
  sort: {
    id: 'commonMessages.sort',
    defaultMessage: 'Sort',
    description: 'Sort label',
  },
  footerText: {
    id: 'commonMessages.footerText',
    defaultMessage:
      'EY refers to the global organization, and may refer to one or more, of the member firms of Ernst & Young Global Limited, each of which is a separate legal entity. Ernst & Young Global Limited, a UK company limited by guarantee, does not provide services to clients.',
    description: 'Footer text',
  },
  instruction: {
    id: 'commonMessages.instruction',
    defaultMessage: 'Instruction',
    description: 'Instruction button text',
  },
  defaultError: {
    id: 'commonMessages.defaultError',
    defaultMessage: 'An error ocurred. Please try again.',
    description: 'Default error message',
  },
});
