import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import * as CONST from 'components/MisconductFormParts/constants';
import { ROUTING } from 'routing';
import { getTranslatedOptions, getDateRangeStr } from 'helpers';
import { whistleblowerVisibleStatuses } from 'helpers/reportConstants';
import api from 'api';

import DoubleColumnLayout from 'components/DoubleColumnLayout';
import PageHeader from 'components/PageHeader';
import Typography from 'components/Typography';
import ProgressSteps from 'components/ProgressSteps';
import FilesList from 'components/FileUploader/parts/FilesList';
import MisconductChat from './MisconductChat';

import { HeaderLogo } from 'styles/commonStyles';
import { ReportStatusColumn, DetailsGroup } from '../style';
import { reportMessages } from 'translations';
import { messages } from '../messages';
import { Row } from 'components/MisconductDetails/style';

const propTypes = {
  misconductData: PropTypes.object.isRequired,
  archived: PropTypes.bool,
};

const MisconductDetails = ({ misconductData, archived }) => {
  const intl = useIntl();

  const {
    files,
    status,
    params,
    chat_file_upload_allowed: areUploadsAllowed,
    reportToken,
  } = misconductData;

  const statusesArray = getTranslatedOptions(
    whistleblowerVisibleStatuses,
    intl.formatMessage
  );

  return (
    <DoubleColumnLayout
      isWhiteBackground
      columnContentSmallPadding
      columnComponent={
        <ReportStatusColumn>
          {!archived && (
            <MisconductChat
              reportToken={reportToken}
              areUploadsAllowed={areUploadsAllowed}
            />
          )}
        </ReportStatusColumn>
      }
    >
      <Link to={ROUTING.INDEX}>
        <HeaderLogo />
      </Link>

      <PageHeader label={intl.formatMessage(reportMessages.reportStatus)} />

      {!archived ? (
        <>
          <ProgressSteps steps={statusesArray} currentStep={status} />

          {params.map((section) => {
            return (
              <div key={section.id}>
                <Typography size="medium" tabletSize="large" bottomMargin={32}>
                  {section.label}
                </Typography>

                <DetailsGroup>
                  {section.inputs.map((input) => {
                    return (
                      <Row key={input.id}>
                        <Typography size="normal" bottomMargin={0}>
                          {input.label}
                        </Typography>

                        <Typography size="normal" weight="bold" bottomMargin={0}>
                          {/* @todo: Replace with just date_range and prepare array parser to handle just one date */}
                          {input.type === CONST.DATE || input.type === CONST.DATE_RANGE
                            ? `${getDateRangeStr(input.value)}`
                            : input.value}
                        </Typography>
                      </Row>
                    );
                  })}
                </DetailsGroup>
              </div>
            );
          })}

          <Typography size="medium" tabletSize="large" bottomMargin={24}>
            {intl.formatMessage(reportMessages.whistleblowerFiles)}
          </Typography>

          <FilesList
            files={files}
            fileUrlResolver={api.misconduct.getWhistleblowerAttachementFile}
            isDownloadable
          />
        </>
      ) : (
        <Typography size="large" bottomMargin={32}>
          {intl.formatMessage(messages.misconductHasBeenArchived)}
        </Typography>
      )}
    </DoubleColumnLayout>
  );
};

MisconductDetails.propTypes = propTypes;

export default MisconductDetails;
