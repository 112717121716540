import api from 'api';
import { toastType } from 'helpers/toastConstants';
import { setToastMessage } from 'stores/toastMessages';

export class DashboardNotificationsHandler {
  constructor({ actionName, errorToastContext }) {
    this.actionName = actionName;
    this.errorToastContext = errorToastContext;
  }

  reducer = (state, action) => ({
    ...state,
    dashboard: {
      ...state.dashboard,
      notifications: state.dashboard.notifications.filter(
        ({ id }) => id !== action.payload
      ),
    },
  });

  setNotificationRead = (payload) => ({ type: this.actionName, payload });

  markNotificationAsRead = (id) => async (dispatch) => {
    try {
      await api.notifications.markAsRead(id);
      dispatch(this.setNotificationRead(id));
    } catch (error) {
      setToastMessage({
        content: { translationKey: 'notificationReadFailure' },
        context: this.errorToastContext,
        type: toastType.ALERT,
      })(dispatch);
    }
  };
}
