import styled from 'styled-components';
import { rem } from 'polished';

import { colorName, colors, font, transitions } from 'styles';

const VALIDATION_ICON_SIZE = 8;

export const PasswordRequirementsContainer = styled.div`
  margin-bottom: ${rem(48)};
`;

export const PasswordRequirementsHeading = styled.div`
  color: ${colors.darkGray};
  font-weight: ${font.weight.bold};
  font-size: ${rem(font.size.tiny)};
  line-height: 1.5;
`;

export const PasswordRequirement = styled.div`
  margin-top: ${rem(8)};
  color: ${({ fulfilled }) => (fulfilled ? colorName.success : colorName.error)};
  font-weight: ${font.weight.regular};
  font-size: ${rem(font.size.tiny)};
  transition: color ${transitions.uiTiming} ${transitions.spring};

  svg {
    width: ${rem(VALIDATION_ICON_SIZE)};
    height: ${rem(VALIDATION_ICON_SIZE)};
    margin-right: ${rem(6)};
  }
`;
