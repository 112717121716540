import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
} from 'helpers/sharedConstants';
import { composeUserFullName } from 'helpers/composeUserFullName';

// Actions
export const STORE_DOCUMENTS_LIST = 'specificUserDocuments.STORE_DOCUMENTS_LIST';
export const STORE_DOCUMENTS_LIST_META =
  'specificUserDocuments.STORE_DOCUMENTS_LIST_META';
export const SET_LOADING = 'specificUserDocuments.SET_LOADING';
export const STORE_USER_META = 'specificUserDocuments.STORE_USER_META';

// Reducer
const initialState = {
  isLoading: false,
  user: '',
  documentsList: [],
  documentsListMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case STORE_DOCUMENTS_LIST:
      return {
        ...state,
        documentsList: action.payload,
      };
    case STORE_DOCUMENTS_LIST_META:
      return {
        ...state,
        documentsListMeta: action.payload,
      };
    case STORE_USER_META:
      return {
        ...state,
        user: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function storeDocuments(documents) {
  return { type: STORE_DOCUMENTS_LIST, payload: documents };
}

export function storeDocumentsMeta(meta) {
  return { type: STORE_DOCUMENTS_LIST_META, payload: meta };
}

export function storeUserMeta(userData) {
  return { type: STORE_USER_META, payload: userData };
}

export function setLoading(bool) {
  return { type: SET_LOADING, payload: bool };
}

// Side Effects
export function getDocuments({
  id,
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
}) {
  return (dispatch) => {
    dispatch(setLoading(true));

    return api.users
      .getUserDocuments({ id, page, perPage })
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(storeDocuments(res.data.items));
        dispatch(storeDocumentsMeta({ page, perPage, totalPages: res.data.pages }));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
}

export function getUserName(userID) {
  return (dispatch) => {
    return api.users.getUser(userID).then((res) => {
      const userName = composeUserFullName(res.data);
      dispatch(storeUserMeta(userName));
    });
  };
}
