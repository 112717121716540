import { messages } from 'containers/private/Documents/messages';
import { dailyIntervals, weeklyIntervals, monthlyIntervals } from './commonConstants';

export const DOCUMENT_ALLOWED_FILETYPES = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/vnd.ms-outlook',
  'application/octet-stream',
];

export const DOCUMENT_MAX_FILE_SIZE = 5280000;
export const DOCUMENT_MAX_FILES_ON_DROP = 5;

export const intervals = [...dailyIntervals, ...weeklyIntervals, ...monthlyIntervals];

export const notifications = [
  {
    name: messages.firstAlert,
    value: 0,
  },
  {
    name: messages.secondAlert,
    value: 1,
  },
  {
    name: messages.thirdAlert,
    value: 2,
  },
];
