import React from 'react';

function CrossIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 8" {...props}>
      <path
        fill="currentColor"
        d="M7.80374 0.193905C7.54237 -0.0649423 7.11974 -0.0649423 6.86022 0.195754L4 3.05602L1.13978 0.195754C0.880265 -0.0649423 0.457627 -0.0649423 0.196258 0.193905C-0.0651097 0.452752 -0.0651097 0.874304 0.194405 1.135L3.05833 3.99896L0.194405 6.86292C-0.0651097 7.12362 -0.0651097 7.54517 0.196258 7.80402C0.326016 7.93344 0.496554 7.99815 0.667092 7.99815C0.83763 7.99815 1.01002 7.93344 1.13978 7.80217L4 4.9419L6.86022 7.80402C6.98998 7.93529 7.16237 8 7.33291 8C7.50345 8 7.67398 7.93529 7.80374 7.80586C8.06511 7.54702 8.06511 7.12547 7.80559 6.86477L4.94167 3.99896L7.80374 1.135C8.06511 0.874304 8.06511 0.452752 7.80374 0.193905Z"
      />
    </svg>
  );
}

export default CrossIcon;
