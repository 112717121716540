import React from 'react';
import PropTypes from 'prop-types';

import { IconWrapper } from './style';

const iconSizes = ['small', 'regular'];

const defaultPropTypes = {
  bottomMargin: 24,
  size: 'regular',
};

const propTypes = {
  bottomMargin: PropTypes.number,
  icon: PropTypes.node.isRequired,
  size: PropTypes.oneOf(iconSizes),
};

const RoundIconWrap = ({ icon, ...props }) => {
  return <IconWrapper {...props}>{icon}</IconWrapper>;
};

RoundIconWrap.defaultProps = defaultPropTypes;
RoundIconWrap.propTypes = propTypes;

export default RoundIconWrap;
