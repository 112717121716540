export const SHORT_TEXT = 'short-text';
export const LONG_TEXT = 'long-text';
export const DATE = 'date';
export const DATE_RANGE = 'date_range';
export const STRING = 'string';
export const EMAIL = 'email';
export const SELECT = 'select';
export const RADIO = 'radio';
export const HINT = 'hint';
export const MIN_VALUE_LENGTH = 1;
