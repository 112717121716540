import api from 'api';
import {
  PAGINATE_DEFAULT_PAGE,
  PAGINATE_DEFAULT_PER_PAGE,
} from 'helpers/sharedConstants';

// Actions
export const STORE_RECIPIENT_GROUP_USERS = 'recipientsGroups.STORE_RECIPIENT_GROUP_USERS';
export const STORE_RECIPIENT_GROUP_META = 'recipientsGroups.STORE_RECIPIENT_GROUP_META';
export const SET_LOADING = 'recipientsGroups.SET_LOADING_STATE';

// Reducer
const initialState = {
  isLoading: false,
  recipientsGroups: {},
  recipientsGroupsMeta: {},
  defaultMeta: {
    perPage: PAGINATE_DEFAULT_PER_PAGE,
    page: PAGINATE_DEFAULT_PAGE,
    totalPages: 1,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case STORE_RECIPIENT_GROUP_USERS:
      return {
        ...state,
        recipientsGroups: {
          ...state.recipientsGroups,
          [action.payload.id]: action.payload.users,
        },
      };
    case STORE_RECIPIENT_GROUP_META:
      return {
        ...state,
        recipientsGroupsMeta: {
          ...state.recipientsGroupsMeta,
          [action.payload.id]: action.payload.meta,
        },
      };
    default:
      return state;
  }
}

// Action Creators
export const setLoading = (payload) => ({ type: SET_LOADING, payload });

export const storeRecipientGroupUsers = (payload) => ({
  type: STORE_RECIPIENT_GROUP_USERS,
  payload,
});

export function storeRecipientGroupMeta(data) {
  return { type: STORE_RECIPIENT_GROUP_META, payload: data };
}

// Side Effects
export function getRecipientGroupUsers({
  id,
  page = PAGINATE_DEFAULT_PAGE,
  perPage = PAGINATE_DEFAULT_PER_PAGE,
}) {
  return (dispatch) => {
    dispatch(setLoading(true));
    return api.groups
      .getGroupUsers({ id, page, perPage })
      .then((res) => {
        dispatch(storeRecipientGroupUsers({ id, users: res.data.items }));
        dispatch(
          storeRecipientGroupMeta({
            id,
            meta: { page, perPage, totalPages: res.data.pages },
          })
        );
      })
      .catch(() => {
        //@todo handle error
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
}
